export default {
  // 'price.locales': '',
  'price.locales.Use.now': '立即使用',
  'price.locales.free': '免费',
  'price.locales.Excluding.hardware.terminal': '不含硬件终端',
  'price.locales.Basic.Edition': '基础版',
  'price.locales.Standard.Edition': '标准版',
  'price.locales.Exclusive.Edition': '尊享版',
  'price.locales.Advanced.Edition': '高级版',
  'price.locales.recommend': '推荐',
  'price.locales.month': '月',
  'price.locales.contain.Unlimited.invitation': '无限制邀请',
  'price.locales.contain.Unrestricted.registration': '无限制登记',
  'price.locales.contain.Email.notification': '邮件通知',
  'price.locales.contain.SMS.notification': '短信通知',
  'price.locales.contain.Custom.brand': '自定义品牌',
  'price.locales.contain.Including.all.functions.of.the.basic.version': '含基础版所有功能 +',
  'price.locales.contain.Label.printing': '标签打印',
  'price.locales.contain.Confidentiality.agreement': '保密协议',
  'price.locales.contain.Access.type.customization': '访问类型自定义',
  'price.locales.contain.Statistical.report': '统计报表',
  'price.locales.contain.facetime': 'Facetime',
  'price.locales.contain.Display.board.function': '展板功能',
  'price.locales.contain.Display.Panel.monitoring': '展板监控',
  'price.locales.contain.Start.the.meeting.now': '立即开始会议',
  'price.locales.contain.Meeting.sign.in': '会议签到',
  'price.locales.contain.Pre.meeting.reminder': '会议前提醒',
  'price.locales.contain.Extension.of.the.session': '会议延长',
  'price.locales.contain.One.key.maintenance.conference.room': '一键维护会议室',
  'price.locales.contain.Participant.conflict.reminder': '参会人冲突提醒',
  'price.locales.contain.Conference.equipment.borrowing': '会议设备借用',
  'price.locales.contain.Conference.room.up.and.down': '会议室上下线',
  'price.locales.contain.Number.of.meeting.rooms': '会议室数量',
  'price.locales.contain.Cleaning.service': '保洁服务',
  'price.locales.contain.Meeting.agenda': '会议议程',
  'price.locales.contain.Conference.billing': '会议计费',
  'price.locales.contain.Conference.services': '会议服务',
  'price.locales.contain.Appointment.cycle.meeting': '预约周期会议',
  'price.locales.contain.Book.extra.long.meetings': '预约超长会议',
  'price.locales.contain.Book.multi.room.meetings': '预约多会议室会议',
  'price.locales.contain.Meeting.room.reservation.authority': '会议室预约权限',
  'price.locales.contain.Meeting.approval': '会议审批',
  'price.locales.contain.Meeting.lock.time': '会议锁定时间',
  'price.locales.contain.Enterprise.wechat': '企业微信',
  'price.locales.contain.Reminder.before.the.end.of.the.meeting': '会议结束前提醒',
  'price.locales.contain.Batch.invitation': '批量邀请',
  'price.locales.contain.Certificate.identification': '证件识别',
  'price.locales.contain.Blacklist.managemen': '黑名单管理',
  'price.locales.contain.Group.invitation': '团体邀请',
  'price.locales.contain.Temporary.audit': '临时审核',
  'price.locales.contain.Nail.nail': '钉钉',
  'price.locales.contain.Network.conference': '网络会议',
  'price.locales.contain.SMS.advance.notice': '短信提前通知',
  'price.locales.contain.Email.advance.notice': '邮件提前通知',
  'price.locales.contain.Including.all.functions.of.Standard.Edition': '含标准版所有功能 +',
  'price.locales.contain.Including.all.functions.of.Standard.Advanced': '含高级版所有功能 +',

  'price.locales.workspace.desc1': '含20个工位',
  'price.locales.workspace.desc2': '含20个工位',
  'price.locales.workspace.standard.include1': '工位预定',
  'price.locales.workspace.standard.include2': '工位查询',
  'price.locales.workspace.standard.include3': '地图管理',

  'price.locales.workspace.advanced.include1': '推荐预定',
  'price.locales.workspace.advanced.include2': '工位搬迁',
  'price.locales.workspace.advanced.include3': '设施管理',
  'price.locales.workspace.advanced.include4': '统计报表',
  'price.locales.workspace.advanced.include5': '地图高级版',
  'price.locales.workspace.advanced.include6': '企业微信',
  'price.locales.workspace.advanced.include7': '钉钉',

  'price.locales.esg.standard.include1': '用电分析',
  'price.locales.esg.standard.include2': '用水分析',
  'price.locales.esg.standard.include3': '环境分析',
  'price.locales.esg.standard.include4': '用电/水分项统计',
  'price.locales.esg.standard.include5': '用水监控及告警',
  'price.locales.esg.standard.include6': '用电监控及告警',
  'price.locales.esg.standard.include7': '告警日志',
  'price.locales.esg.standard.include8': '自动抄表及报表',
  'price.locales.esg.standard.include9': '能耗大屏',
  'price.locales.esg.standard.include10': '环境大屏',
  'price.locales.esg.desc1': '50个设备起购',
  'price.locales.esg.device': '设备',

  'price.locales.bdp.standard.include1': '播放媒体管理',
  'price.locales.bdp.standard.include2': '资源分组管理',
  'price.locales.bdp.standard.include3': '播放内容审批',
  'price.locales.bdp.standard.include4': '节目布局自主设计',
  'price.locales.bdp.standard.include5': '内容切换策略控制',
  'price.locales.bdp.standard.include6': '可翻页多屏播放',
  'price.locales.bdp.standard.include7': '设备远程监控和管理',
  'price.locales.bdp.standard.include8': '设备定时开关机',
  'price.locales.bdp.standard.include9': '内容预下发和离线播放',

  'price.locales.bdp.advantage.include1': '资源地图展示',
  'price.locales.bdp.advantage.include2': '环境信息展示',
  'price.locales.bdp.advantage.include3': '空间资源操作互动',
  'price.locales.bdp.desc1': '信发',
  'price.locales.bdp.desc2': '互动大屏',
  'price.locales.bdp.desc3': '播放终端',

  'price.locales.smt.desc1': '会议室',
};
