export default {
  // "download.locales":"",
  "download.locales.title":"Virsical Cloud",
  "download.locales.Scan":"Scan the QR code and download the latest Virsical cloud client",
  "download.locales.iPad":"You can also use the iPad to open the app store, search for \"Virsical cloud\" and click download",
  "download.locales.Applet":"Applet",

  "download.locales.clickdown":"Click to download",
  "download.locales.title1":"Virsical Cloud APP",
  "download.locales.visitor.mini":"Smart Visitor Mini Program",
  "download.locales.toilet.mini":"Smart Toilet Mini Program",
}
