export default {
  // 'register.locales': '',
  'register.locales.tips': '註冊立即獲得30天的免費試用',
  'register.locales.tips1': '更多威思客雲高級版本',
  'register.locales.phone.tips': '現在就撥打諮詢熱線：',
  'register.Registered.account': '註冊賬號',
  'register.locales.Registered': '註 冊',
  'register.locales.bind': '綁 定',
  'register.bind.account': '綁定賬號',
  'register.verification.phone': '手機驗證',
  'register.verification.email': '郵箱驗證',
  'register.Get.verification.code': '獲取驗證碼',
  'register.locales.China.phone': '中國',
  'register.locales.HK.phone': '香港',
  'register.locales.password': '請輸入密碼',
  'register.locales.Privacy.services': '請閱讀服務條款和隱私政策',
  'register.locales.Privacy.services.read': '我已閱讀並同意威思客雲的',
  'register.locales.Terms.of.service': '《服務條款》',
  'register.locales.Privacy.policy': '《隱私政策》',
  'register.locales.username': '請輸入賬號',
  'register.locales.verification.code': '重新獲取',
  'register.Resend.in.seconds': '秒後重發',
  'register.Please.select.the.authentication.method': '請選擇驗證方式',
  'register.Please.bind.your.account.in.virsical.cloud': '請綁定您在威思客雲的賬號',
  'register.Welcome.to.sign.up.for.virsical.cloud.service': '歡迎註冊威思客雲服務',

  // 'register.locales.error': '',
  'register.locales.error.company': '請輸入公司名稱',
  'register.locales.error.contacts': '請輸入聯繫人姓名',
  'register.locales.error.email': '請輸入聯繫人郵箱',
  'register.locales.error.phone': '請輸入聯繫人手機',
  'register.locales.error.company.length': '公司長度不得超過50個字符',
  'register.locales.error.contacts.length': '姓名長度為2-20個字符',
  'register.locales.error.email.length': '郵箱最大長度為50個字符',
  'register.locales.error.password.length':
    '請輸入8到16位密碼（必需包含數字及字母，可包含如下特殊字符：.!@#$%^&*-_）',
  'register.locales.error.phone.error': '請輸入正確的手機號',
  'register.locales.error.email.error': '請輸入有效的郵箱地址',
  'register.locales.error.password.error': '密碼格式不正確',
  'register.locales.error.Verification.error': '請輸入正確的驗證碼',
  'register.locales.error.Verification': '請輸入驗證碼',

  'register.and': '和',

  'register.verification.success': '驗證成功！',
  'register.verification.img': '請輸入圖形驗證碼',
  'register.verification.img.tip': '4位圖形驗證碼',
};
