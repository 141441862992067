/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { connect, formatMessage, getLocale, history } from 'umi';
import { Button, Input, Form, message } from 'antd';
import { CLOUD_AES_KEY } from '@/utils/constant';
import { encryptAes } from '@/utils/encryption';
import loginCn from '@/assets/login/loginCn.png';
import loginEn from '@/assets/login/loginEn.png';
import uncheck from '@/assets/uncheck.png';
import checked from '@/assets/bigcheck.png';
import * as service from '@/services/cloud';
import { zhIntl } from '@/utils/intlUtils';
import styles from './index.less';

const EditPwd = ({ location, dispatch }) => {
  const [form] = Form.useForm();
  const userId = location?.state?.userId;
  const editType = location?.state?.editType;

  const [passRule, setPassRule] = useState(false);
  const [newPwd, setNewPwd] = useState('');
  const [loading, setLoading] = useState(false);
  const [initData, setInitData] = useState('');

  const { run: updatePwdById } = useRequest(
    (params) => {
      return editType === 'first' ? service.updatePwdById(params) : service.resetPwdById(params);
    },
    {
      manual: true,
      onSuccess: (res) => {
        if (res?.code === 0) {
          message.success(formatMessage({ id: 'login.editpwd.success' }));
          history.push('/login');
          setTimeout(() => {
            setLoading(false);
          }, 300);
        } else {
          message.error(formatMessage({ id: res.msg }));
          setTimeout(() => {
            setLoading(false);
          }, 300);
        }
      },
      onBefore: () => setLoading(true),
    },
  );

  useEffect(() => {
    if (initData.pwdRegex) {
      if (newPwd) {
        const reg = new RegExp(initData.pwdRegex);
        if (reg?.test(newPwd)) {
          setPassRule(true);
        } else {
          setPassRule(false);
        }
      }
    }
  }, [newPwd]);

  useEffect(() => {
    dispatch({ type: 'cloud/getCommonInitData' }).then((res) => {
      setInitData(res);
    });
  }, [])

  return (
    <div className={styles.body}>
      <img src={getLocale() === 'en-US' ? loginEn : loginCn} className={styles.img} />
      <div className={styles.content}>
        <div className={styles.area}>
          <div className={styles.title} style={{ marginBottom: 50 }}>
            {editType === 'first'
              ? formatMessage({ id: 'login.editpwd.title.first' })
              : formatMessage({ id: 'login.editpwd.title.expired' })}
          </div>
          <Form
            className="login-form"
            layout="vertical"
            form={form}
            onFinish={(v) => {
              updatePwdById({
                newPwd: encryptAes(v.newPwd, CLOUD_AES_KEY),
                pwd: encryptAes(v.pwd, CLOUD_AES_KEY),
                reNewPwd: encryptAes(v.reNewPwd, CLOUD_AES_KEY),
                userId,
              });
            }}
          >
            <Form.Item
              label={<div className={styles.labelHide} />}
              name="pwd"
              rules={[
                { required: true, message: formatMessage({ id: 'login.editpwd.pwd1.tip' }) },
                // {
                //   // 密码验证规则，改为动态配置
                //   pattern: initData.pwdRegex,
                //   message: formatMessage({ id: 'login.resetpwd.rule.err1' }),
                // },
              ]}
            >
              <Input.Password
                maxLength={50}
                placeholder={formatMessage({ id: 'login.editpwd.pwd1' })}
                style={{ marginTop: 10 }}
              />
            </Form.Item>
            <Form.Item
              label={<div className={styles.labelHide} />}
              name="newPwd"
              rules={[
                { required: true, message: formatMessage({ id: 'login.editpwd.pwd2.tip' }) },
                {
                  // 密码验证规则，改为动态配置
                  pattern: initData.pwdRegex,
                  message: formatMessage({ id: 'login.resetpwd.rule.err1' }),
                },
              ]}
            >
              <Input.Password
                maxLength={50}
                placeholder={formatMessage({ id: 'login.editpwd.pwd2' })}
                style={{ marginTop: 10 }}
                onChange={(e) => setNewPwd(e.target.value)}
              />
            </Form.Item>
            {newPwd.length > 0 && (
              <div className={styles.rules}>
                <div
                  className={styles.rulesItem}
                  style={{ color: passRule ? '#2FA599' : '#9CA4B0' }}
                >
                  <img width={14} src={passRule ? checked : uncheck} />
                  {getLocale() === 'en-US' ? initData.pwdTipEn : initData.pwdTipZh}
                </div>
              </div>
            )}

            <Form.Item
              label={<div className={styles.labelHide} />}
              name="reNewPwd"
              rules={[
                { required: true, message: formatMessage({ id: 'login.editpwd.pwd3.tip' }) },
                {
                  validator: (rule, value, callback) => {
                    if (value && value !== newPwd) {
                      return callback(formatMessage({ id: 'login.resetpwd.rule.err2' }));
                    }
                    return callback();
                  },
                },
                {
                  // 密码验证规则，改为动态配置
                  pattern: initData.pwdRegex,
                  message: formatMessage({ id: 'login.resetpwd.rule.err1' }),
                },
              ]}
            >
              <Input.Password
                maxLength={50}
                placeholder={formatMessage({ id: 'login.editpwd.pwd3' })}
                style={{ marginTop: 10 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
              >
                {formatMessage({ id: 'login.ok' })}
              </Button>
            </Form.Item>
          </Form>
          {/* <div className={styles.copy}>
            {getLocale() === 'en-US'
              ? COPYRIGHT_enUS
              : getLocale() === 'zh-TW'
                ? COPYRIGHT_zhTW
                : COPYRIGHT}
          </div> */}
        </div>
        <a className={styles.back} onClick={() => history.push('/')}>
          {zhIntl('返回首页')}
        </a>
      </div>

      <style jsx="true">
        {`
          .ant-modal-body {
            padding: 0 0 0 20px;
            background: #fff;
          }
          .ant-input {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
          }
          .ant-legacy-form-item {
            margin-bottom: 0;
          }
          .ant-form-item-required {
            display: none !important;
          }
        `}
      </style>
    </div>
  );
};

export default connect((state) => state)(EditPwd);
