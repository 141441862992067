/**
 * Created by XuQiang on 2017/2/27.
 */
import * as FileSaver from 'file-saver';
import { getSign } from 'virsical-sign';
// import { getSign } from 'virsical-sign';
import { SING_KEY, RESTFUL_PATH } from './constant';

export const down = (downName, url) => {
  // if (url.indexOf('?') > -1) {
  //   url += `&lang=${localStorage.getItem('locale') || 'zh_CN'}`;
  // } else {
  //   url += `?lang=${localStorage.getItem('locale') || 'zh_CN'}`;
  // }
  // url += `&vsktid=${sessionStorage.getItem('tokenSn') || ''}`;

  // let signUrl = url;
  const timestamp = new Date().getTime();
  const ACCESS_TOKEN = 'access_token';
  const tokenSign = sessionStorage.getItem(ACCESS_TOKEN)?.replace('bearer ', '') || null;

  let signPath;
  if (url) {
    signPath = url?.replace(`/${RESTFUL_PATH.cloudOms}`, '');
  }

  const newOptions = {
    method: 'get',
    headers: {
      // sign: '',
      Authorization: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}` || '',
      'Content-Type': 'application/json;',
      [`vsk-auth`]: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)?.replace('bearer ', '')}`,
      [`vsk-signature`]: getSign(signPath, tokenSign, SING_KEY, timestamp),
      [`vsk-timestamp`]: timestamp,
    },
  };

  // const { params } = body;
  // newOptions.body = JSON.stringify(params || '');

  // if (newOptions.body) {
  //   if (signUrl.indexOf('?') > -1) {
  //     signUrl += `&reqstr=${newOptions.body}`;
  //   } else {
  //     signUrl += `?reqstr=${newOptions.body}`;
  //   }
  // }

  // newOptions.headers.sign = getSign(signUrl, sessionStorage.getItem('token'));
  // 假设后端提供以json格式导出配置文件的接口为"/get/setting/file?type=json"
  fetch(url, newOptions).then((response) => {
    /**
     * 获取响应的头部content-disposition内容, 例子内容为"attachment; filename="CD.json""
     * "attachment"标识: 代表后端期望前端把响应的body当做文件处理并弹出文件下载框
     * "filename"标识: 代表后端期望前端在保存文件时显示的默认文件名
     * 在"RFC 6266", "RFC 2183"规范中规定了content-disposition的意义
     * 一般的浏览器内部会按照规范实现触发下载框的功能
     * 比如在浏览器地址栏中直接输入请求url获得的响应, content-disposition的内容带有attachment标识时就会当做下载文件来处理
     * 当filename标识不存在时设一个默认的文件名, 比如Chrome设的默认文件名就是"file"
     * 但是fetch并没有对这个规范做相应的实现, 那么就需要我们自行判断并进行实现.
     */
    const disposition = response.headers.get('content-disposition');
    window.console.log(disposition, '7777777');
    if (disposition && disposition.match(/attachment/)) {
      let filename = disposition.replace(/attachment;.*filename=/gi, '').replace(/"/g, '');
      filename = decodeURI(filename);
      response.blob().then((blob) => {
        FileSaver.saveAs(blob, filename);
      });
    }
  });
};

// 白名单导出
export const whiteDown = (downName, url) => {
  // if (url.indexOf('?') > -1) {
  //   url += `&lang=${localStorage.getItem('locale') || 'zh_CN'}`;
  // } else {
  //   url += `?lang=${localStorage.getItem('locale') || 'zh_CN'}`;
  // }
  // url += `&vsktid=${sessionStorage.getItem('tokenSn') || ''}`;

  // let signUrl = url;
  const timestamp = new Date().getTime();
  const ACCESS_TOKEN = 'access_token';
  const tokenSign = sessionStorage.getItem(ACCESS_TOKEN)?.replace('bearer ', '') || null;

  let signPath;
  if (url) {
    signPath = url?.replace(`/${RESTFUL_PATH.cloudOms}`, '');
  }

  const newOptions = {
    method: 'get',
    headers: {
      // sign: '',
      // Authorization: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)}` || '',
      'Content-Type': 'application/json;',
      // [`vsk-auth`]: `bearer ${sessionStorage.getItem(ACCESS_TOKEN)?.replace('bearer ', '')}`,
      [`vsk-signature`]: getSign(signPath, tokenSign, SING_KEY, timestamp),
      [`vsk-timestamp`]: timestamp,
    },
  };

  // const { params } = body;
  // newOptions.body = JSON.stringify(params || '');

  // if (newOptions.body) {
  //   if (signUrl.indexOf('?') > -1) {
  //     signUrl += `&reqstr=${newOptions.body}`;
  //   } else {
  //     signUrl += `?reqstr=${newOptions.body}`;
  //   }
  // }

  // newOptions.headers.sign = getSign(signUrl, sessionStorage.getItem('token'));
  // 假设后端提供以json格式导出配置文件的接口为"/get/setting/file?type=json"
  fetch(url, newOptions).then((response) => {
    /**
     * 获取响应的头部content-disposition内容, 例子内容为"attachment; filename="CD.json""
     * "attachment"标识: 代表后端期望前端把响应的body当做文件处理并弹出文件下载框
     * "filename"标识: 代表后端期望前端在保存文件时显示的默认文件名
     * 在"RFC 6266", "RFC 2183"规范中规定了content-disposition的意义
     * 一般的浏览器内部会按照规范实现触发下载框的功能
     * 比如在浏览器地址栏中直接输入请求url获得的响应, content-disposition的内容带有attachment标识时就会当做下载文件来处理
     * 当filename标识不存在时设一个默认的文件名, 比如Chrome设的默认文件名就是"file"
     * 但是fetch并没有对这个规范做相应的实现, 那么就需要我们自行判断并进行实现.
     */
    const disposition = response.headers.get('content-disposition');
    window.console.log(disposition, '7777777');
    if (disposition && disposition.match(/attachment/)) {
      let filename = disposition.replace(/attachment;.*filename=/gi, '').replace(/"/g, '');
      filename = decodeURI(filename);
      response.blob().then((blob) => {
        FileSaver.saveAs(blob, filename);
      });
    }
  });
};

const ieDown = (url) => {
  window.open(url);
};
export const isIE = () => {
  const explorer = window.navigator.userAgent;
  return (
    explorer.indexOf('MSIE') >= 0 ||
    explorer.indexOf('Trident/7.0') >= 0 ||
    explorer.indexOf('Edge') >= 0
  );
};
export const downloadFile = (fileName, url) => {
  if (isIE()) {
    ieDown(url);
  } else {
    const aLink = document.createElement('a');
    const evt = document.createEvent('MouseEvents');
    // var evt = document.createEvent("HTMLEvents")
    evt.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null,
    );
    // evt.initEvent("click", false, false)//initEvent 不加后两个参数在FF下会报错, 感谢 Barret Lee 的反馈
    aLink.download = fileName;
    aLink.href = url;
    aLink.dispatchEvent(evt);
  }
};
