import React from 'react';
import { formatMessage } from 'umi';
import Partner from '@/components/Partner';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import styles from './index.less';
import banner8 from '../../assets/banner8.png';
import banner9 from '../../assets/banner9.png';
import banner10 from '../../assets/banner10.png';
import bg from '@/assets/case/bg.png';
import { zhIntl } from '@/utils/intlUtils';

const mediaList = [
  {
    img: banner8,
    title: formatMessage({ id: "case.tabs.sub.tabs1.title" }),
    text: formatMessage({ id: "case.tabs.sub.tabs1.text" }),
  },
  {
    img: banner9,
    title: formatMessage({ id: "case.tabs.sub.tabs2.title" }),
    text: formatMessage({ id: "case.tabs.sub.tabs2.text" }),
  },
  {
    img: banner10,
    title: formatMessage({ id: "case.tabs.sub.tabs3.title" }),
    text: formatMessage({ id: "case.tabs.sub.tabs3.text" }),
  },
];

const Case = () => {
  return (
    <div className={styles.case}>
      <div className={styles.bg}>
        <img src={bg} alt='' />
        <div className={styles.name}>
          <div className={styles.content}>
            <LightSpeed right cascade>
              <div className={styles.productName}>{zhIntl('客户案例')}</div>
            </LightSpeed>
            <Zoom bottom cascade>
              <div className={styles.pageRoute}>
                <span>{zhIntl('首页')}</span> &gt;{' '}
                <span style={{ color: '#fff' }}>{zhIntl('客户案例')}</span>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
      <div className={styles.info} >
        <Fade bottom cascade>
          <div className={styles.title}>
            {zhIntl('威思客云系统 助力行业升级')}
          </div>
        </Fade>
        <LightSpeed left cascade>
          <div className={styles.subTitle}>
            {zhIntl('客户覆盖《财富》500强60%以上的大型企业及多家中小型企业遍及互联网、金融、教育、医疗等多个行业')}
          </div>
        </LightSpeed>
      </div>
      <Partner />
      <div className={styles.caseArea}>
        <div className={styles.caseTitle}>{zhIntl('全部案例')}</div>
        <div className={styles.caseList}>
          {mediaList.map((it) => (
            <div className={styles.caseItem} key={it.title}>
              <div className={styles.imgArea}>
                <img src={it.img} alt='' />
              </div>
              <div className={styles.textArea}>
                <div className={styles.textTitle}>{it.title}</div>
                <div className={styles.text}>{it.text}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Case
