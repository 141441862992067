import React, { useEffect, useState } from 'react';
import { history, connect } from 'umi';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import { zhIntl } from '@/utils/intlUtils';
import { productList } from './components/const';
import check from '@/assets/bigcheck.png';
import styles from './index.less';
import { Button } from 'antd';

const Page = ({
  history: {
    location: {
      query: { pdtype = 'vst' },
    },
  },
}) => {
  const [productCode, setProductCode] = useState('vst');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    setProductCode(pdtype);
    setTimeout(() => {
      setShow(false);
    }, 200);
  }, [pdtype]);

  return !show ? (
    <div className={styles.product}>
      <div className={styles.bg}>
        <img src={productList?.[productCode]?.bg} alt="" />
        <div className={styles.name}>
          <div className={styles.content}>
            <LightSpeed right cascade>
              <div className={styles.productName}>
                {zhIntl(productList?.[productCode]?.productName)}
              </div>
            </LightSpeed>
            <Zoom bottom cascade>
              <div className={styles.pageRoute}>
                <span>{zhIntl('首页')}</span> &gt; <span>{zhIntl('产品')}</span> &gt;{' '}
                <span style={{ color: '#fff' }}>
                  {zhIntl(productList?.[productCode]?.productName)}
                </span>
              </div>
            </Zoom>
          </div>
        </div>
      </div>

      <div className={styles.info}>
        <Fade bottom cascade>
          <div className={styles.title}>
            <div>{productList?.[productCode]?.title}</div>
          </div>
        </Fade>
        <LightSpeed left cascade>
          <div className={styles.subTitle}>{productList?.[productCode]?.subTitle}</div>
        </LightSpeed>
      </div>
      {productList?.[productCode]?.list?.length > 0 ? (
        <div className={styles.advantage}>
          <Slide left>
            <div className={`${styles.advantageList} ${styles.imgArea}`}>
              <img src={productList?.[productCode]?.banner} alt="" />
              <Button
                style={{
                  width: 160,
                  height: 60,
                  borderRadius: 27,
                  margin: '0 auto',
                  fontSize: 16,
                  fontWeight: 600,
                }}
                type="primary"
                onClick={() => history.push('/register')}
              >
                {zhIntl('免费体验')}
              </Button>
            </div>
          </Slide>
          <Slide right>
            <div className={styles.advantageList}>
              {productList?.[productCode]?.list?.map((item) => (
                <div className={styles.advantageItem} key={item.title}>
                  <div className={styles.listTitle}>
                    <img src={check} style={{ marginRight: 14, marginTop: -4 }} width={22} alt="" />
                    <span>{item.title}</span>
                  </div>
                  <div className={styles.listDesc}>{item.desc}</div>
                </div>
              ))}
            </div>
          </Slide>
        </div>
      ) : null}

      <div className={styles.feature} style={{ padding: productCode === 'map' && '0 0 50px 0' }}>
        <div className={styles.featureTitle}>{productList?.[productCode]?.featureTitle}</div>
        <div className={styles.featureList} style={{ marginTop: productCode === 'map' && 0 }}>
          {productList?.[productCode]?.feature?.map((item) => (
            <div className={styles.featureItem} key={item.title}>
              <img src={item.icon} alt="" width={100} />
              <div className={styles.itemTitle}>{item.title}</div>
              <div className={styles.itemDesc}>{item.desc}</div>
            </div>
          ))}
        </div>
      </div>

      {productList?.[productCode]?.isTools ? (
        <>
          <div className={styles.info}>
            <Fade bottom cascade>
              <div className={styles.title}>
                <div>{productList?.[productCode]?.toolsTitle}</div>
              </div>
            </Fade>
            <LightSpeed left cascade>
              <div className={styles.subTitle}>{productList?.[productCode]?.toolsSubTitle}</div>
            </LightSpeed>
          </div>
          <div className={styles.toolsArea}>
            <div className={styles.toolsList}>
              {productList?.[productCode]?.toolsList?.map((item) =>
                item.key % 2 === 1 ? (
                  <div className={styles.toolsListItem} key={item.key}>
                    <Slide left>
                      <div>
                        <div className={styles.toolsTitle}>{item.title}</div>
                        <div className={styles.toolsDesc}>{item.desc}</div>
                      </div>
                    </Slide>
                    <Slide right>
                      <img src={item.src} alt="" />
                    </Slide>
                  </div>
                ) : (
                  <div className={styles.toolsListItem} key={item.key}>
                    <Slide left>
                      <img src={item.src} alt="" />
                    </Slide>
                    <Slide right>
                      <div>
                        <div className={styles.toolsTitle}>{item.title}</div>
                        <div className={styles.toolsDesc}>{item.desc}</div>
                      </div>
                    </Slide>
                  </div>
                ),
              )}
            </div>
          </div>
        </>
      ) : null}
    </div>
  ) : null;
};

export default connect((state) => state)(Page);
