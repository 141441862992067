/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { formatMessage, history, connect, getLocale } from 'umi';
// import { useMount } from 'ahooks';
import { Form, Input, Select, Button, Divider } from 'antd';
import { zhIntl } from '@/utils/intlUtils';
import VerifySlideFixed from '@/components/SliderVerify';
import SecurityCode from '@/components/SecurityCode';
import SecurityBind from '@/components/SecurityBind';
import { countryAreaCode } from '@/utils/constant';
import loginCn from '@/assets/login/loginCn.png';
import loginEn from '@/assets/login/loginEn.png';
import micsoft from '../../assets/micsoft.png';
import feishu from '../../assets/feishu.png';
import styles from './index.less';

const Login = ({ dispatch, cloud }) => {
  const [form] = Form.useForm();
  const { phoneAreaCode } = cloud;

  const [account, setAccount] = useState('');
  const [areaCode, setAreaCode] = useState(
    sessionStorage.getItem('isChina') === 'true' ? '+86' : '+852',
  );
  const [imgUrl, setImgUrl] = useState('');
  const [randomStr, setRandomStr] = useState('');
  const [showVerify, setShowVerify] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSecurity, setShowSecurity] = useState(false);
  const [securityFlag, setSecurityFlag] = useState('');
  const [showSlider, setShowSlider] = useState(false);
  const [showFeishu, setShowFeishu] = useState(false);
  const [showTeams, setShowTeams] = useState(false);
  const [showSecurityBind, setShowSecurityBind] = useState(false);
  const [bindData, setBindData] = useState({});
  const [qrInfo, setQrInfo] = useState('');

  const resetImgUrl = () => {
    const str = `${Math.floor(Math.random() * 8999999 + 1000000)}${Date.now()}`;
    const url = `${window.location.origin}/vsk/code?randomStr=${str}`;
    setImgUrl(url);
    setRandomStr(str);
  };

  useEffect(() => {
    dispatch({ type: 'cloud/getSet' }).then((res) => {
      if (res?.length > 0) {
        if (
          res?.find((item) => item.module === 'login' && item.paramKey === 'teams_enable')
            ?.paramValue === '1'
        ) {
          setShowTeams(true);
        }
        if (
          res?.find((item) => item.module === 'login' && item.paramKey === 'feishu_enable')
            ?.paramValue === '1'
        ) {
          setShowFeishu(true);
        }
      }
    });
    dispatch({ type: 'cloud/getCommonInitData' }).then((res) => {
      if (res?.['verification-code']) {
        if (res?.['verification-code'] === '1') {
          resetImgUrl();
        }
        setShowVerify(res?.['verification-code']);
      }
    });
    dispatch({ type: 'cloud/getAreaCode' });
  }, []);

  const handlerLogin = (values) => {
    dispatch({
      type: 'cloud/cloudAuth',
      payload: {
        ...values,
        randomStr: showVerify === '1' ? randomStr : showVerify === '2' ? 'captcha' : undefined,
      },
    }).then((res) => {
      if ((res?.code === 1 && showVerify === '1') || !res) {
        resetImgUrl();
      }
      if (res?.code === 1 && res?.msg === '101079') {
        setShowSecurity(true);
        setSecurityFlag(res?.data);
      }
      if (res?.code === 1 && res?.msg === '101083') {
        setBindData(res?.data);
        if (res?.data?.userId) {
          dispatch({
            type: 'cloud/generateById',
            payload: {
              userId: res?.data?.userId,
            },
          }).then((data) => {
            if (data) {
              setQrInfo(data);
              setShowSecurityBind(true);
            }
          });
        }
      }
    });
  };

  const handleSubmit = (e) => {
    form.setFieldsValue({ username: account.trim() });
    e.preventDefault();
    form.validateFields().then((values) => {
      setLoading(true);
      if (!values?.username?.includes('@')) {
        values.username = `${areaCode}${values.username}`;
      }
      if (showVerify === '2') {
        setShowSlider(true);
      } else {
        handlerLogin(values);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  return (
    <div className={styles.body}>
      <img src={getLocale() === 'en-US' ? loginEn : loginCn} className={styles.img} />
      <div className={styles.content}>
        <div className={styles.area}>
          <div className={styles.title}>{formatMessage({ id: 'login.title' })}</div>
          <div className={styles.form}>
            <Form form={form}>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: formatMessage({ id: 'login.account.tip' }) },
                  {
                    pattern:
                      Number(account).toString().length < 6 || account.includes('@')
                        ? /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
                        : /^\d{6,15}$/,
                    message: formatMessage({ id: 'login.account.regex.err' }),
                  },
                ]}
              >
                <Input.Group compact style={{ display: 'flex' }}>
                  {Number(account).toString().length < 6 || account.includes('@') ? null : (
                    <Select
                      style={{ height: 55 }}
                      value={areaCode}
                      onChange={(e) => setAreaCode(e)}
                      optionLabelProp="label"
                    >
                      {countryAreaCode(phoneAreaCode)}
                    </Select>
                  )}
                  <Input
                    style={{
                      width:
                        Number(account).toString().length < 6 || account.includes('@')
                          ? '100%'
                          : 'calc(100% - 80)',
                    }}
                    placeholder={formatMessage({ id: 'login.account' })}
                    allowClear
                    value={account}
                    maxLength={50}
                    onChange={(e) => setAccount(e.target.value)}
                  />
                </Input.Group>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: formatMessage({ id: 'login.password.tip' }) }]}
              >
                <Input
                  type="password"
                  key="login-pass"
                  placeholder={formatMessage({ id: 'login.password' })}
                  suffix={
                    <a onClick={() => history.push('/login/find')}>
                      {formatMessage({ id: 'login.password.forget' })}
                    </a>
                  }
                  maxLength={50}
                  autoComplete="new-password"
                />
              </Form.Item>
              {showVerify === '1' ? (
                <Form.Item
                  name="code"
                  rules={[{ required: true, message: zhIntl('请输入验证码') }]}
                >
                  <Input
                    style={{ textIndent: 11 }}
                    placeholder={zhIntl('请输入验证码')}
                    maxLength={4}
                    suffix={<img src={imgUrl} width={70} onClick={resetImgUrl} alt="" />}
                  />
                </Form.Item>
              ) : null}
              <Form.Item>
                <Button
                  htmlType="submit"
                  style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
                  type="primary"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  {formatMessage({ id: 'login.title1' })}
                </Button>
                <div className={`${styles.register} ${styles.active}`}>
                  <a onClick={() => history.push('/register')}>
                    {formatMessage({ id: 'login.register.free' })}
                  </a>
                  <a onClick={() => history.push('/login/active')}>
                    {formatMessage({ id: 'login.active' })}
                  </a>
                </div>
                {showFeishu || showTeams ? (
                  <>
                    <Divider plain style={{ color: '#9CA4B0' }}>
                      {formatMessage({ id: 'login.third' })}
                    </Divider>
                    <div className={styles.thirdLogin}>
                      {showTeams ? (
                        <Button
                          style={{
                            width: showFeishu && showTeams ? 185 : '100%',
                            height: 55,
                            fontSize: 15,
                          }}
                          onClick={() => {
                            dispatch({
                              type: 'cloud/microsoftLogin',
                            }).then((res) => {
                              if (res) {
                                window.open(res);
                              }
                            });
                          }}
                        >
                          <img src={micsoft} width={16} alt="" style={{ marginRight: 10 }} />
                          <span style={{ position: 'relative', top: 3 }}>
                            {formatMessage({ id: 'login.micro365' })}
                          </span>
                        </Button>
                      ) : null}

                      {showFeishu ? (
                        <Button
                          style={{
                            width: showFeishu && showTeams ? 185 : '100%',
                            height: 55,
                            fontSize: 15,
                          }}
                          onClick={() => {
                            dispatch({
                              type: 'cloud/feishuLogin',
                            }).then((res) => {
                              if (res) {
                                window.open(res);
                              }
                            });
                          }}
                        >
                          <img src={feishu} width={16} alt="" style={{ marginRight: 20 }} />
                          <span style={{ position: 'relative', top: 3 }}>
                            {formatMessage({ id: 'login.feishu' })}
                          </span>
                        </Button>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </Form.Item>
            </Form>
          </div>
        </div>
        <a className={styles.back} onClick={() => history.push('/')}>
          {zhIntl('返回首页')}
        </a>
      </div>

      {showSlider && (
        <VerifySlideFixed
          isSlideShow={showSlider}
          dispatch={dispatch}
          verifyPointFixedChild={(data) => setShowSlider(data)}
          onCheckSuccess={(value) => {
            if (value) {
              setTimeout(() => {
                setShowSlider(false);
                handlerLogin({ ...form.getFieldsValue(), code: value });
              }, 800);
            }
          }}
        />
      )}

      {showSecurity && (
        <SecurityCode
          visible={showSecurity}
          onCancel={() => setShowSecurity(false)}
          onOk={(v) => {
            dispatch({
              type: 'cloud/securityCodeCheck',
              payload: { securityCode: v, flag: securityFlag },
            });
          }}
        />
      )}

      {showSecurityBind && (
        <SecurityBind
          qrInfo={qrInfo}
          visible={showSecurityBind}
          onCancel={() => setShowSecurityBind(false)}
          onOk={(v) => {
            dispatch({
              type: 'cloud/setToTPBindLogin',
              payload: {
                securityCode: v,
                userId: bindData?.userId,
                totpString: qrInfo,
                flag: bindData?.flag,
              },
            });
          }}
        />
      )}

      <style>
        {`
          .ant-select-dropdown {
            width: auto !important;
            border-radius: 14px !important;
            padding: 16px 0 !important;
          }
        `}
      </style>
    </div>
  );
};

export default connect((state) => state)(Login);
