export default {
  // 'price.locales': '',
  'price.locales.Use.now': '立即使用',
  'price.locales.free': '免費',
  'price.locales.Excluding.hardware.terminal': '不含硬件終端',
  'price.locales.Basic.Edition': '基礎版',
  'price.locales.Standard.Edition': '標準版',
  'price.locales.Exclusive.Edition': '尊享版',
  'price.locales.Advanced.Edition': '高級版',
  'price.locales.recommend': '推薦',
  'price.locales.month': '月',
  'price.locales.contain.Unlimited.invitation': '無限制邀請',
  'price.locales.contain.Unrestricted.registration': '無限制登記',
  'price.locales.contain.Email.notification': '郵件通知',
  'price.locales.contain.SMS.notification': '短訊通知',
  'price.locales.contain.Custom.brand': '自定義品牌',
  'price.locales.contain.Including.all.functions.of.the.basic.version': '含基礎版所有功能 +',
  'price.locales.contain.Label.printing': '標籤打印',
  'price.locales.contain.Confidentiality.agreement': '保密協議',
  'price.locales.contain.Access.type.customization': '訪問類型自定義',
  'price.locales.contain.Statistical.report': '統計報表',
  'price.locales.contain.facetime': 'Facetime',
  'price.locales.contain.Display.board.function': '展板功能',
  'price.locales.contain.Display.Panel.monitoring': '展板監控',
  'price.locales.contain.Start.the.meeting.now': '立即開始會議',
  'price.locales.contain.Meeting.sign.in': '會議簽到',
  'price.locales.contain.Pre.meeting.reminder': '會議前提醒',
  'price.locales.contain.Extension.of.the.session': '會議延長',
  'price.locales.contain.One.key.maintenance.conference.room': '一鍵維護會議室',
  'price.locales.contain.Participant.conflict.reminder': '參會人衝突提醒',
  'price.locales.contain.Conference.equipment.borrowing': '會議設備借用',
  'price.locales.contain.Conference.room.up.and.down': '會議室上下線',
  'price.locales.contain.Number.of.meeting.rooms': '會議室數量',
  'price.locales.contain.Cleaning.service': '保潔服務',
  'price.locales.contain.Meeting.agenda': '會議議程',
  'price.locales.contain.Conference.billing': '會議計費',
  'price.locales.contain.Conference.services': '會議服務',
  'price.locales.contain.Appointment.cycle.meeting': '預約周期會議',
  'price.locales.contain.Book.extra.long.meetings': '預約超長會議',
  'price.locales.contain.Book.multi.room.meetings': '預約多會議室會議',
  'price.locales.contain.Meeting.room.reservation.authority': '會議室預約權限',
  'price.locales.contain.Meeting.approval': '會議審批',
  'price.locales.contain.Meeting.lock.time': '會議鎖定時間',
  'price.locales.contain.Enterprise.wechat': '企業微信',
  'price.locales.contain.Reminder.before.the.end.of.the.meeting': '會議結束前提醒',
  'price.locales.contain.Batch.invitation': '批量邀請',
  'price.locales.contain.Certificate.identification': '證件識別',
  'price.locales.contain.Blacklist.managemen': '黑名單管理',
  'price.locales.contain.Group.invitation': '團體邀請',
  'price.locales.contain.Temporary.audit': '臨時審核',
  'price.locales.contain.Nail.nail': '釘釘',
  'price.locales.contain.Network.conference': '網絡會議',
  'price.locales.contain.SMS.advance.notice': '短訊提前通知',
  'price.locales.contain.Email.advance.notice': '郵件提前通知',
  'price.locales.contain.Including.all.functions.of.Standard.Edition': '含標準版所有功能 +',
  'price.locales.contain.Including.all.functions.of.Standard.Advanced': '含高級版所有功能 +',

  'price.locales.workspace.desc1': '含20個工位',
  'price.locales.workspace.desc2': '含20個工位',
  'price.locales.workspace.standard.include1': '工位預定',
  'price.locales.workspace.standard.include2': '工位查詢',
  'price.locales.workspace.standard.include3': '地圖管理',

  'price.locales.workspace.advanced.include1': '推薦預定',
  'price.locales.workspace.advanced.include2': '工位搬遷',
  'price.locales.workspace.advanced.include3': '設施管理',
  'price.locales.workspace.advanced.include4': '統計報表',
  'price.locales.workspace.advanced.include5': '地圖高級版',
  'price.locales.workspace.advanced.include6': '企業微信',
  'price.locales.workspace.advanced.include7': '釘釘',

  'price.locales.esg.standard.include1': '用電分析',
  'price.locales.esg.standard.include2': '用水分析',
  'price.locales.esg.standard.include3': '環境分析',
  'price.locales.esg.standard.include4': '用電/水分項統計',
  'price.locales.esg.standard.include5': '用水監控及告警',
  'price.locales.esg.standard.include6': '用電監控及告警',
  'price.locales.esg.standard.include7': '告警日志',
  'price.locales.esg.standard.include8': '自動抄表及報表',
  'price.locales.esg.standard.include9': '能耗大屏',
  'price.locales.esg.standard.include10': '環境大屏',
  'price.locales.esg.desc1': '50個設備起購',
  'price.locales.esg.device': '設備',

  'price.locales.bdp.standard.include1': '播放媒體管理',
  'price.locales.bdp.standard.include2': '資源分組管理',
  'price.locales.bdp.standard.include3': '播放內容審批',
  'price.locales.bdp.standard.include4': '節目布局自主設計',
  'price.locales.bdp.standard.include5': '內容切換策略控制',
  'price.locales.bdp.standard.include6': '可翻頁多屏播放',
  'price.locales.bdp.standard.include7': '設備遠程監控和管理',
  'price.locales.bdp.standard.include8': '設備定時開關機',
  'price.locales.bdp.standard.include9': '內容預下發和離線播放',

  'price.locales.bdp.advantage.include1': '資源地圖展示',
  'price.locales.bdp.advantage.include2': '環境信息展示',
  'price.locales.bdp.advantage.include3': '空間資源操作互動',
  'price.locales.bdp.desc1': '信發',
  'price.locales.bdp.desc2': '互動大屏',
  'price.locales.bdp.desc3': '播放終端',

  'price.locales.smt.desc1': '會議室',
};
