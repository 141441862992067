import React from 'react';
import { formatMessage } from 'umi';
import { getWebConfig } from '@/utils/tools';
import { zhIntl } from '@/utils/intlUtils';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import virsicalCloud from '@/assets/download/virsicalCloud.png';
import ipadQrCode from '../../assets/down-iPad.png';
import miniAppCode from '../../assets/down-mini.jpg';
import toiletMini from '@/assets/download/toiletMini.png';
import fmqrcode from '@/assets/download/fmqrcode.png';
// import iPhone from '@/assets/download/iPhone.png';
// import andro from '@/assets/download/andro.png';
import ipad from '@/assets/download/ipad.png';
import mini from '@/assets/download/minisoft.png';
import iPhoneAndro from '@/assets/download/iPhoneAndro.png';
import bg from '@/assets/product/price/bg.png';
import styles from './index.less';

const data = [
  {
    title: formatMessage({ id: 'download.locales.title1' }),
    desc: [
      zhIntl('扫二维码，下载最新威思客云客户端'),
      zhIntl('或者在 Apple App Store 中， 搜索 “威思客云”，进行下载'),
    ],
    list: [
      { name: 'iPhone & Android', icon: iPhoneAndro, qrcode: virsicalCloud, key: 1 },
      // { name: 'Android', icon: andro, qrcode: '', key: 2 },
      {
        name: formatMessage({ id: 'download.locales.toilet.mini' }),
        icon: mini,
        qrcode: toiletMini,
        key: 3,
      },
    ],
  },
  {
    title: formatMessage({ id: 'common.visitor' }),
    desc: [zhIntl('扫二维码，下载最新智能访客客户端')],
    list: [
      { name: 'iPad', icon: ipad, qrcode: ipadQrCode, key: 4 },
      {
        name: formatMessage({ id: 'download.locales.visitor.mini' }),
        icon: mini,
        qrcode: miniAppCode,
        key: 5,
      },
    ],
  },
  {
    title: formatMessage({ id: 'index.fm' }),
    desc: [zhIntl('扫二维码，下载最新设备设施管理客户端')],
    list: [{ name: 'iPhone & Android', icon: iPhoneAndro, qrcode: fmqrcode, key: 6 }],
  },
];

const Download = () => {
  return (
    <div className={styles.product}>
      <div className={styles.bg}>
        <img src={bg} alt="" />
        <div className={styles.name}>
          <div className={styles.content}>
            <LightSpeed right cascade>
              <div className={styles.productName}>{zhIntl('产品下载')}</div>
            </LightSpeed>
            <Zoom bottom cascade>
              <div className={styles.pageRoute}>
                <span>{zhIntl('首页')}</span> &gt; <span>{zhIntl('产品')}</span> &gt;{' '}
                <span style={{ color: '#fff' }}>{zhIntl('产品下载')}</span>
              </div>
            </Zoom>
          </div>
        </div>
      </div>

      {data?.map((item) => (
        <div className={styles.info} key={item.title}>
          <Fade bottom cascade>
            <div className={styles.title}>{item.title}</div>
          </Fade>
          <LightSpeed left cascade>
            <div className={styles.subTitle}>
              {item.desc.map((it) => (
                <div key={it}>{it}</div>
              ))}
            </div>
          </LightSpeed>
          <div className={styles.listArea}>
            {item.list.map((it) =>
              (it.key === 3 || it.key === 5) &&
              sessionStorage.getItem('isChina') === 'false' ? null : (
                <div key={it.key} className={it.key === 2 ? styles.androWrap : styles.itemWrap}>
                  <div className={styles.item}>
                    <img className={styles.icon} src={it.icon} alt="" />
                    <div className={styles.name}>{it.name}</div>
                    {it.key === 2 ? (
                      <div className={styles.clickdown}>
                        <span
                          style={{ marginLeft: 5 }}
                          onClick={async () => {
                            const datas = await getWebConfig();
                            window.location.href =
                              datas?.androidDownload ||
                              'https://download.virsical.cn/download/apps/android.apk';
                          }}
                        >
                          {formatMessage({ id: 'download.locales.clickdown' })}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <img className={styles.qrcode} src={it.qrcode} />
                </div>
              ),
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Download;
