/* eslint-disable camelcase */
import index from './en_US/index';
import common from './en_US/common';
import login from './en_US/login';
import hardware from './en_US/hardware';
import help from './en_US/help';
import about_cn from './en_US/about';
import case_cn from './en_US/case';
import en_cn from './en_US/index';
import case_en from './en_US/case';
import about_en from './en_US/about';
import product_en from './en_US/product';
import download_en from './en_US/download';
import banner_en from './en_US/banner';
import price_en from './en_US/price';
import register_en from './en_US/register';
import intl_en from './en_US/intl.json';
import error from './en_US/error';

export default {
  ...en_cn,
  ...banner_en,
  ...price_en,
  ...product_en,
  ...download_en,
  ...case_en,
  ...index,
  ...common,
  ...login,
  ...hardware,
  ...help,
  ...register_en,
  ...about_cn,
  ...case_cn,
  ...about_en,
  ...intl_en,
  ...error,
};
