import React from "react";
import Slide from 'react-reveal/Slide';
import { history } from "umi";
import { Button } from 'antd'
import contact from '@/assets/home/contact.png'
import jieyue from '@/assets/home/jieyue.png';
import jiandan from '@/assets/home/jiandan.png';
import gaoxiao from '@/assets/home/gaoxiao.png';
import { zhIntl } from 'wafer-i18n-utils';
import styles from './index.less';

const ContactUs = () => {
  const part4Data = [
    {
      title: zhIntl('成本节约'),
      desc: zhIntl('通过对会议室、工位、前台、网络、音视频设备、门禁等统一智能化管理，降低企业运营、管理成本'),
      icon: jieyue,
      key: 'jieyue',
    },
    {
      title: zhIntl('简单便捷'),
      desc: zhIntl('统一平台化管理，多终端设备支持，多应用系统集成，后台 管理与员工使用体验更加简单、便捷'),
      icon: jiandan,
      key: 'jiandan',
    },
    {
      title: zhIntl('高效安全'),
      desc: zhIntl('端到端数据加密传输，完善安全认证机制，基于角色应用授 权管理，保障企业应用和数据安全'),
      icon: gaoxiao,
      key: 'gaoxiao',
    },
  ]

  return (
    <div className={styles.part4} style={{ background: `url(${contact})` }}>
      <div className={styles.content}>
        <Slide left>
          <div className={styles.left}>
            {part4Data.map(item => (
              <div className={styles.leftItem} key={item.key}>
                <img src={item.icon} />
                <div>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.desc}>{item.desc}</div>
                </div>
              </div>
            ))}
          </div>
        </Slide>
        <Slide right>
          <div className={styles.right}>
            <div>
              <div className={styles.title}>{zhIntl('联系我们')}</div>
              <div className={styles.phone}>400-685-3160</div>
              <div className={styles.tip}>{zhIntl('详情请拨打咨询热线或立即申请免费试用')}</div>
            </div>
            <Button
              type='primary'
              style={{ width: 160, height: 55, fontSize: 16, borderRadius: 27 }}
              onClick={() => history.push('/register')}
            >
              {zhIntl('免费试用')}
            </Button>
          </div>
        </Slide>
      </div>
    </div>
  )
}

export default ContactUs
