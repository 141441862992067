export default {
  'case.tabs.tabs1': 'Enterprise',
  'case.tabs.tabs2': 'Game',
  'case.tabs.tabs3': 'Video',
  'case.tabs.tabs4': 'Internet',
  'case.tabs.tabs5': 'Finance',
  'case.tabs.tabs6': 'Medical',
  'case.tabs.tabs7': 'Education',
  'case.tabs.tabs8': 'Government Affairs',
  'case.header.title': 'Industry Case',
  'case.header.title.subtitle': 'Virsical cloud system helps the industry upgrade',
  'case.header.title.subtitle.top':
    'Customers cover more than 60% of Fortune 500 large enterprises and a number of small and medium-sized enterprises',
  'case.header.title.subtitle.btm': 'Internet, finance, education, medical treatment, etc',
  'case.header.btn.go': 'Experience Now',
  'case.all.industry.cases': 'All Cases',
  'case.tabs.sub.tabs1.title': 'Frieslandcampina',
  'case.tabs.sub.tabs2.title': 'Alibaba Cloud',
  'case.tabs.sub.tabs3.title': 'Meituan Comments',
  'case.tabs.sub.tabs1.text':
    'Virsical joins hands with feishilan dairy company, a world-famous enterprise, to meet its needs for visitor appointment, check-in and visitor information management statistics. Feishilan can formulate scientific visitor management strategies according to the number of visitors counted in the office, give visitors a humanized reception experience and show the charm of intelligent technology.',
  'case.tabs.sub.tabs2.text':
    'As a leader in digital space management solutions, Virsical joins hands with Alibaba cloud innovation center to help park operators and Alibaba cloud maker platform better manage and operate the entrepreneurship incubation platform!',
  'case.tabs.sub.tabs3.text':
    "According to the enterprise scenario requirements for meituan to build intelligent buildings and achieve the objectives of visitor access authorization and access control authority management and control, Virsical made automatic reservations for meituan's successful landing visitors, realized the update and transformation of visitor management mode and opened the channel of intelligent access.",
};
