/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-lonely-if */
/* eslint-disable dot-notation */
/* eslint-disable prefer-object-spread */
import { message, Modal } from 'antd';
import { history as router, formatMessage, setLocale } from 'umi';
import * as cloudServices from '@/services/cloud';
import {
  CLOUD_AES_KEY,
  CLOUD_CLIENT_ID,
  CLOUD_CLIENT_SECRET,
  CLOUD_USER_ID,
  CLOUD_USER_SECRET,
} from '@/utils/constant';
import { encryptAes, decryptAes, base64String, encodeAuth } from '@/utils/encryption';

export default {
  namespace: 'cloud',
  state: {
    authInfo: {},
    userInfo: {},
    findPassData: {},
    loginType: 'login',
    productTabKey: 1,
    sysConfig: '',
    phoneAreaCode: [],
  },
  reducers: {
    save(state, { payload }) {
      // return Object.assign({}, state, { ...payload });
      return { ...state, ...payload };
    },
  },
  effects: {
    *loginAction(_, { put }) {
      yield put({ type: 'save', payload: { loginType: 'login', isLink: true } });
      router.push('/login');
    },
    // 云服务用户名密码认证
    *cloudAuth({ payload: { username, password, isInit, type, code, randomStr } }, { call, put }) {
      // 清除缓存数据
      if (localStorage.getItem('access_token')) localStorage.removeItem('access_token');
      if (sessionStorage.getItem('access_token')) sessionStorage.removeItem('access_token');
      const passwordAes = encryptAes(password, CLOUD_AES_KEY);
      const Authorization = 'Basic bG9naW4tcGM6QTYjZE05JmlOODUy';
      const params = {
        username,
        password: passwordAes,
        grant_type: 'password',
        scope: 'server',
        Authorization,
      };
      if (code) {
        params.code = code;
        params.randomStr = randomStr;
      }
      const result = yield call(cloudServices.cloudLogin, params);
      if (result) {
        if (result && result.access_token) {
          yield put({
            type: 'save',
            payload: {
              userInfo: result,
            },
          });
          sessionStorage.setItem('auth', encodeAuth(result));
          sessionStorage.setItem('access_token', `${result.token_type} ${result.access_token}`);
          window['access_token'] = `${result.access_token}`;
          if (type === 'bind') {
            return 'success';
          }
          if (!isInit) {
            setTimeout(() => {
              window.location.href = `/vsk/main/ssoLogin?key=${encodeAuth(
                result,
              )}&original_domain=${window.btoa(window.location.origin)}`;
            }, 500);
          }
        } else {
          if (!result.msg) {
            message.error(formatMessage({ id: 'login.error.unknow' }));
          } else {
            if (result.msg === '101054') {
              router.push({
                pathname: '/login/editPwd',
                state: {
                  userId: result.data,
                  editType: 'first',
                },
              });
            } else if (result.msg === '101055') {
              router.push({
                pathname: '/login/editPwd',
                state: {
                  userId: result.data,
                  editType: 'expired',
                },
              });
            } else if (result.msg !== '101079' && result.msg !== '101083') {
              message.error(formatMessage({ id: result.msg }));
            }
          }
        }
        return result;
      }
    },
    *clientAuth(_, { call, put }) {
      const Authorization = 'Basic bG9naW4tcGM6QTYjZE05JmlOODUy';
      const result = yield call(cloudServices.clientAuth, {
        Authorization,
      });
      if (result) {
        if (result.access_token) {
          yield put({
            type: 'save',
            payload: {
              authInfo: result,
            },
          });
          // sessionStorage.setItem('auth', JSON.stringify(result));
          sessionStorage.setItem('access_token', `${result.token_type} ${result.access_token}`);
          /* yield put({
            type: `tryData/getPackageList`,
          });
          yield put({
            type: `tryData/getPackageListPay`,
          }); */
        } else {
          message.error(formatMessage({ id: result.msg }));
        }
      }
    },
    *loginOut(_, { call, put }) {
      yield call(cloudServices.loginOut);
      sessionStorage.clear();
      yield put({
        type: 'save',
        payload: {
          authInfo: {},
          userInfo: {},
        },
      });
      router.push('/login');
    },
    *detectionAuthority({ payload, callBack = null }, { call, put }) {
      const { tenant_id: tenantId, username } = payload;
      const authorization = `${payload.token_type} ${payload.access_token}`;

      const result = yield call(cloudServices.detectionAuthority, {
        tenantId,
        username,
        authorization,
      });
      if (result) {
        if (result.code === 0) {
          yield put({
            type: 'save',
            payload: {
              userInfo: result.data,
            },
          });
          sessionStorage.setItem('userInfo', JSON.stringify(result.data));
          // router.push('/');
          if (callBack) {
            yield new Promise((res) => {
              callBack();
              res();
            });
          }
        } else {
          message.error(
            formatMessage({ id: result.msg }) || formatMessage({ id: 'login.error.auth' }),
          );
        }
      } else {
        message.error(formatMessage({ id: 'login.error.auth' }));
      }
    },
    *sendMail({ payload, callBack = null }, { put, call }) {
      const { code, msg, data } = yield call(cloudServices.sendMail, {
        mail: payload.account,
        code: payload.code,
        randomStr: payload.randomStr,
      });
      if (code === 0) {
        if (callBack) {
          callBack();
        }
        return true;
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    *sendCaptcha({ payload, callBack = null }, { put, call }) {
      const { code, msg, data } = yield call(cloudServices.sendCaptcha, {
        mail: payload.verification,
        code: payload.code,
        randomStr: payload.randomStr,
      });
      if (code === 0) {
        if (callBack) {
          callBack();
        }
        return 'success';
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    *checkResetCode({ payload }, { call }) {
      const { code, msg } = yield call(cloudServices.checkReset, payload);
      if (code === 0) {
        return 'success';
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    *resetPass({ payload, callBack }, { call }) {
      payload.npassword = encodeURI(encryptAes(payload.password, CLOUD_AES_KEY));
      payload.rpassword = encodeURI(encryptAes(payload.rpassword, CLOUD_AES_KEY));
      const res = yield call(cloudServices.resetPassword, payload);
      if (res.code === 0) {
        return callBack();
      } else {
        message.error(formatMessage({ id: res.msg }));
      }
    },
    *sendMailCode({ payload, callBack, errorCallBack = null }, { call }) {
      const res = yield call(cloudServices.sendMailCode, {
        verification: payload.verification,
        source: payload.source || 2,
        randomStr: payload.randomStr,
        code: payload.code,
      });
      if (!res || res.code !== 0) {
        if (errorCallBack) errorCallBack();
        return message.error(formatMessage({ id: res.msg }));
      }
      if (res.data === 200 || res.data === true || res.code === 0) {
        if (callBack) {
          callBack();
        }
        if (res.code === 0) {
          return 'success';
        }
      }
      if (res.data === 400) {
        if (errorCallBack) errorCallBack();
        return message.error(formatMessage({ id: 'login.captcha.expired' }));
      }
      if (payload.source === 1 && res.data === false) {
        return callBack();
      }
      if (res.data === false) {
        if (errorCallBack) errorCallBack();
        return message.error(formatMessage({ id: 'login.user.not.exist' }));
      }
    },
    *checkEmailCode({ payload }, { call, put }) {
      const res = yield call(cloudServices.checkMailCode, {
        verification: payload.username || payload.contactMail,
        code: payload.verifyCode || payload.code,
      });
      if (res.code !== 0 && res.msg) {
        message.error(formatMessage({ id: res.msg }));
        return false;
      }
      if (!res || res.code !== 0) {
        return false;
      }
      if (res.data === 400) {
        message.error(formatMessage({ id: 'login.captcha.expired' }));
        return false;
      }
      if (res.data === 500) {
        message.error(formatMessage({ id: 'login.captcha.error' }));
        return false;
      }
      if (res.data === false) {
        message.error(formatMessage({ id: 'login.user.not.exist' }));
        return false;
      }

      yield put({ type: 'save', payload: { tenantData: res.data || [] } });
      return true;
    },
    *activeUser({ payload }, { call }) {
      payload.password = encodeURI(encryptAes(payload.password, CLOUD_AES_KEY));
      const res = yield call(cloudServices.activeUser, payload);
      if (!res || res.code !== 0) {
        return false;
      }
      return true;
    },
    *microsoftLogin(_, { call }) {
      const { code, data, msg } = yield call(cloudServices.microsoftLogin);
      if (code === 0) {
        return data;
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    *feishuLogin(_, { call }) {
      const { code, data, msg } = yield call(cloudServices.feishuLogin);
      if (code === 0) {
        return data;
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    *getCommonInitData(_, { call }) {
      let response = yield call(cloudServices.commonInitData);

      if (typeof response === 'string') {
        try {
          response = decryptAes(response, CLOUD_AES_KEY);
          if (response) {
            response = JSON.parse(response);
          }
        } catch (e) {
          window.console.log('兼容老版本接口，整个数据加密， 解密失败', e);
        }
      }
      if (response.code === 0) {
        let data = response.data;
        if (data) {
          try {
            if (typeof data === 'string') {
              data = decryptAes(data, CLOUD_AES_KEY);
              data = JSON.parse(data);
            }
            if (data?.open_cloud === 'true') {
              sessionStorage.setItem('from', 'cloud');
            }
            window.encryptStrength = data?.['encrypt-strength'] || '0';
            return data;
          } catch (e) {
            window.console.log('init/data 解析数据失败');
          }
        }
      } else {
        message.error(formatMessage({ id: response.msg }));
      }
    },
    *getSet(_, { call, put }) {
      const { code, data, msg } = yield call(cloudServices.getSet);
      if (code === 0) {
        const list = data?.filter((item) => item.module === 'version');
        const current = list.find((item) => item.paramValue?.includes(window.location.host));
        if (list.length > 0) {
          sessionStorage.setItem(
            'china',
            list.find((item) => item.paramKey === 'china')?.paramValue || '',
          );
          sessionStorage.setItem(
            'intl',
            list.find((item) => item.paramKey === 'intl')?.paramValue || '',
          );
        }
        if (sessionStorage.getItem('setLang') !== 'true') {
          if (current?.paramKey === 'china') {
            sessionStorage.setItem('isChina', 'true');
            setLocale('zh-CN');
          } else {
            sessionStorage.setItem('isChina', 'false');
            setLocale('en-US');
          }
        }
        sessionStorage.setItem('setLang', 'true');
        const ssoList = data?.filter((item) => item.module === 'login');
        return ssoList;
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    // 双因素验证
    *securityCodeCheck({ payload }, { call }) {
      const result = yield call(cloudServices.securityCodeCheck, { payload });
      if (result && result.code !== 1) {
        sessionStorage.setItem('auth', encodeAuth(result));
        sessionStorage.setItem('access_token', `${result.token_type} ${result.access_token}`);
        window['access_token'] = `${result.access_token}`;
        setTimeout(() => {
          window.location.href = `/vsk/main/ssoLogin?key=${encodeAuth(
            result,
          )}&original_domain=${window.btoa(window.location.origin)}`;
        }, 500);
      } else {
        message.error(formatMessage({ id: result.msg }));
      }
    },
    // 滑动验证
    *getPicture({ payload }, { call }) {
      const result = yield call(cloudServices.getPicture, { ...payload });
      return result;
    },
    *reqCheck({ payload }, { call }) {
      const result = yield call(cloudServices.reqCheck, { ...payload });
      return result;
    },
    // 获取双因素绑定二维码
    *generateById({ payload }, { call }) {
      const result = yield call(cloudServices.generateById, { ...payload });
      if (result.code === 0) {
        return result.data;
      } else {
        message.error(formatMessage({ id: result.msg }));
      }
    },
    // 双因素绑定
    *setToTPBindLogin({ payload }, { call }) {
      const result = yield call(cloudServices.setToTPBindLogin, { ...payload });
      if (result && result.code !== 1) {
        sessionStorage.setItem('auth', encodeAuth(result));
        sessionStorage.setItem('access_token', `${result.token_type} ${result.access_token}`);
        window['access_token'] = `${result.access_token}`;
        setTimeout(() => {
          window.location.href = `/vsk/main/ssoLogin?key=${encodeAuth(
            result,
          )}&original_domain=${window.btoa(window.location.origin)}`;
        }, 500);
      } else {
        message.error(formatMessage({ id: result.msg }));
      }
    },
    // 获取手机区号
    *getAreaCode(_, { call, put }) {
      const result = yield call(cloudServices.getAreaCode);
      if (result.code === 0) {
        yield put({
          type: 'save',
          payload: {
            phoneAreaCode: result.data,
          },
        });
      } else {
        message.error(formatMessage({ id: result.msg }));
      }
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        try {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          const userInfo = sessionStorage.getItem('userInfo');
          const authInfo = sessionStorage.getItem('auth');
          if (userInfo && authInfo) {
            dispatch({
              type: 'save',
              payload: {
                userInfo: JSON.parse(userInfo),
                authInfo,
              },
            });
          }
        } catch { }
        // eslint-disable-next-line spaced-comment
        /*if (!userInfo && pathname.endsWith('try')) {
          sessionStorage.clear();
          dispatch({
            type: 'clientAuth',
          });
        }*/
      });
    },
  },
};
