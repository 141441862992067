/*
 * title: 修改密码
 */
/* eslint-disable */
import React, { useReducer, useEffect } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message } from 'antd';
import { history as router } from 'umi';
import { Link } from 'umi';
import { connect } from 'dva';
import styles from './index.less';
import logo from '@/assets/logo.png';
import { COPYRIGHT } from '@/utils/constant';
import { ROUTE_MAP, INIT_PASSWORD } from '../../utils/constant';
// import { formatMessage } from 'umi-plugin-locale'; //formatMessage({ id: 'index.start' })

const Page = function ({ form, dispatch, history }) {
  const { getFieldDecorator } = form;

  const [state, setState] = useReducer(
    (state, data) => {
      return { ...state, ...data };
    },
    { passRule: { len: false, char: false, num: false }, email: '', type: 'init', loading: false },
  );

  const { passRule, email, type, loading } = state;

  useEffect(() => {
    const { email, activeId, type, code } = history.location.query;
    if (email) {
      setState({ email });
    }
    if (type) {
      setState({ type });
    }
    if (type !== 'reset' && email && activeId) {
      setState({ loading: true });
      dispatch({
        type: 'cloud/cloudAuth',
        payload: {
          username: email,
          password: INIT_PASSWORD,
          isInit: true,
        },
      });
      dispatch({
        type: 'init/save',
        payload: {
          activeId,
        },
      });
      setState({ loading: false });
    }
    if (type === 'reset' && code) {
      setState({ loading: true });
      const code = location.search
        .replace('?', '')
        .split('&')
        .find((v) => v.indexOf('code=') > -1)
        .replace('code=', ''); //防止转义
      dispatch({
        type: 'cloud/checkResetCode',
        payload: { code },
        callBack: () => {
          dispatch({ type: 'cloud/save', payload: { loginType: type } });
          setState({ loading: false });
        },
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === 'init') {
          dispatch({
            type: 'init/submit',
            payload: values,
          });
        } else if (type === 'reset') {
          values.code = location.search
            .replace('?', '')
            .split('&')
            .find((v) => v.indexOf('code=') > -1)
            .replace('code=', ''); //防止转义

          dispatch({
            type: 'cloud/resetPass',
            payload: values,
            callBack: () => {
              message.success('密码修改成功');
              router.push(ROUTE_MAP.login);
            },
          });
        }
      }
    });
  };

  const checkPass = (key) => {
    setTimeout(() => {
      const val = form.getFieldValue(key) || '';
      if (val.length >= 8) {
        passRule.len = true;
      } else {
        passRule.len = false;
      }
      if (/[a-zA-Z]/.test(val)) {
        passRule.char = true;
      } else {
        passRule.char = false;
      }
      if (/[0-9]/.test(val)) {
        passRule.num = true;
      } else {
        passRule.num = false;
      }
      setState({ passRule });
    });
  };

  return (
    <div className={styles.body}>
      <div className={styles.logo}>
        <Link to={ROUTE_MAP.index}>
          <img src={logo} className={styles.logoImg} />
        </Link>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{type === 'init' ? '您的初始密码' : '更改您的密码'}</div>
        <div className={styles.desc}>请{email ? '为' + email : ''}输入新密码，请确保包含至少：</div>
        <div className={styles.rule}>
          <div className={styles.ruleItem}>
            <CheckCircleFilled
              className={`${styles.ruleIcon} ${passRule.len ? styles.ruleOk : ''}`}
            />
            <span className={styles.ruleText}>8个字符</span>
          </div>
          <div className={styles.ruleItem}>
            <CheckCircleFilled
              className={`${styles.ruleIcon} ${passRule.char ? styles.ruleOk : ''}`}
            />
            <span className={styles.ruleText}>1个字母</span>
          </div>
          <div className={styles.ruleItem}>
            <CheckCircleFilled
              className={`${styles.ruleIcon} ${passRule.num ? styles.ruleOk : ''}`}
            />
            <span className={styles.ruleText}>1个数字</span>
          </div>
        </div>
        <div className={styles.form}>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('password', {
                validateFirst: true,
                rules: [
                  { required: true, message: type === 'init' ? '请输入初始密码' : '请输入新密码' },
                  {
                    // 密码验证规则
                    // 最短8位，最长16位 {8,16}
                    // 可以包含小写大母 [a-z] 和大写字母 [A-Z]
                    // 可以包含数字 [0-9]
                    // 可以包含下划线 [ _ ] 和减号 [ - ]
                    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z|\.!@#$%^&*-_]{8,16}$/,
                    message: '密码不正确',
                  },
                ],
              })(
                <Input
                  type="password"
                  placeholder="请输入密码"
                  maxLength={16}
                  onChange={() => checkPass('password')}
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('rpassword', {
                validateFirst: true,
                rules: [
                  { required: true, message: '请确认密码' },

                  {
                    validator: (rule, value, callback) => {
                      if (value !== form.getFieldValue('password')) {
                        return callback('两次密码不一致');
                      }
                      callback();
                    },
                  },
                  {
                    // 密码验证规则
                    // 最短8位，最长16位 {8,16}
                    // 可以包含小写大母 [a-z] 和大写字母 [A-Z]
                    // 可以包含数字 [0-9]
                    // 可以包含下划线 [ _ ] 和减号 [ - ]
                    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z|\.!@#$%^&*-_]{8,16}$/,
                    message: '密码不正确',
                  },
                ],
              })(
                <Input
                  type="password"
                  maxLength={16}
                  placeholder={type === 'init' ? '请确认初始密码' : '请确认新密码'}
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.submit}
                block={true}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                {type === 'init' ? '提 交' : '更改密码'}
              </Button>
              <div className={styles.home}>
                <a onClick={() => router.push(ROUTE_MAP.index)}>
                  返回首页
                </a>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.copy}>{COPYRIGHT}</div>
    </div>
  );
};

export default Form.create({ name: 'normal_login' })(connect((state) => state)(Page));
