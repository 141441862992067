/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import React, { useReducer, useEffect, useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { connect, formatMessage, getLocale, history } from 'umi';
import { useMount } from 'ahooks';
import { Form, Button, Input, Select, message } from 'antd';
import VerifySlideFixed from '@/components/SliderVerify';
import { countryAreaCode } from '@/utils/constant';
import styles from './index.less';
import loginCn from '@/assets/login/loginCn.png';
import loginEn from '@/assets/login/loginEn.png';
import uncheck from '@/assets/uncheck.png';
import bigcheck from '@/assets/bigcheck.png';
import { zhIntl } from '@/utils/intlUtils';

const Page = ({ dispatch, cloud }) => {
  const [form] = Form.useForm();
  const { phoneAreaCode } = cloud;
  const [state, setState] = useReducer(
    (state, data) => {
      return { ...state, ...data };
    },
    {
      validCode: true,
      type: 'forget',
      sending: false,
      activeWait: 0,
      account: '',
      areaCode: sessionStorage.getItem('isChina') === 'true' ? '+86' : '+852',
      codeText: formatMessage({ id: 'register.Get.verification.code' }),
      passRule: false,
      pwdCode: '',
      seconds: 5,
      verifyCode: '',
      showSlider: false,
    },
  );
  const [initData, setInitData] = useState('');

  const {
    validCode,
    type,
    account,
    areaCode,
    codeText,
    passRule,
    pwdCode,
    seconds,
    verifyCode,
    showSlider,
  } = state;

  const noSendSms = (time = 60, check = false) => {
    if (check) {
      const maxTime = localStorage.getItem('forgetSmsTime');
      if (!maxTime) return;
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) return;
    } else {
      localStorage.setItem('forgetSmsTime', Date.now() + time * 1000);
    }
    setState({
      validCode: false,
      codeStyle: styles.noClick,
    });
    window.tVal = setInterval(() => {
      const maxTime = localStorage.getItem('forgetSmsTime');
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) {
        clearInterval(window.tVal);
        setState({
          validCode: true,
          codeStyle: '',
          codeText: formatMessage({ id: 'register.locales.verification.code' }),
        });
        localStorage.removeItem('forgetSmsTime');
      } else {
        setState({
          codeText: `${hasTime}${formatMessage({ id: 'register.Resend.in.seconds' })}`,
        });
      }
    }, 1000);
  };

  const jumpTime = () => {
    let s = 5;
    setInterval(() => {
      setState({ seconds: (s -= 1) });
    }, 1000);
  };

  useEffect(() => {
    setState({
      type: 'forget',
      verifyCode: '',
      showSlider: false,
    });
    noSendSms(0, true);
    return () => {
      if (window.tVal) {
        clearInterval(window.tVal);
      }
    };
  }, []);

  useEffect(() => {
    if (type === 'success') {
      jumpTime();
    }
  }, [type]);

  useEffect(() => {
    if (seconds <= 0) {
      clearTimeout(jumpTime);
      history.replace('/login');
    }
  }, [seconds]);

  const handleSubmit = (e) => {
    form.setFieldsValue({ username: account.trim() });
    e.preventDefault();
    form.validateFields().then((values) => {
      if (type === 'forget') {
        if (!values?.username?.includes('@')) {
          values.username = `${areaCode}${values.username}`;
        }
        setState({ verifyCode: values.verifyCode });
        dispatch({
          type: 'cloud/checkResetCode',
          payload: {
            mail: values.username,
            verifyCode: values.verifyCode,
          },
        }).then((res) => {
          if (res === 'success') {
            setState({ type: 'newpwd' });
          }
        });
      }

      if (type === 'newpwd') {
        dispatch({
          type: 'cloud/resetPass',
          payload: {
            ...values,
            code: pwdCode,
            account: account?.includes('@') ? account : `${areaCode}${account}`,
            verifyCode,
          },
          callBack: () => {
            message.success(formatMessage({ id: 'login.resetpwd.success' }));
            setState({ type: 'success' });
          },
        });
      }
    });
  };

  const checkPass = (key) => {
    setTimeout(() => {
      const val = form.getFieldValue(key) || '';
      if (initData.pwdRegex) {
        if (val) {
          const reg = new RegExp(initData.pwdRegex);
          if (reg?.test(val)) {
            setState({ passRule: true });
          } else {
            setState({ passRule: false });
          }
        }
      }
    });
  };

  useMount(() => {
    dispatch({ type: 'cloud/getAreaCode' });
    dispatch({ type: 'cloud/getCommonInitData' }).then((res) => {
      setInitData(res);
    });
  });

  return (
    <div className={styles.body}>
      <img src={getLocale() === 'en-US' ? loginEn : loginCn} className={styles.img} />
      <div className={styles.content}>
        <div className={styles.area}>
          <div className={styles.title}>
            {type === 'forget' ? formatMessage({ id: 'login.password.find' }) : ''}
          </div>
          <div className={styles.form}>
            <Form form={form}>
              {type === 'forget' && (
                <>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({ id: 'login.account.tip' }),
                      },
                      {
                        pattern:
                          Number(account).toString().length < 6 || account.includes('@')
                            ? /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
                            : /^\d{6,15}$/,
                        message: formatMessage({ id: 'login.account.regex.err' }),
                      },
                    ]}
                  >
                    <Input.Group compact style={{ display: 'flex' }}>
                      {Number(account).toString().length < 6 || account.includes('@') ? null : (
                        <Select
                          value={areaCode}
                          key="login-phone"
                          onChange={(e) => setState({ areaCode: e })}
                          optionLabelProp="label"
                        >
                          {countryAreaCode(phoneAreaCode)}
                        </Select>
                      )}
                      <Input
                        style={{
                          width:
                            Number(account).toString().length < 6 || account.includes('@')
                              ? '100%'
                              : 'calc(100% - 80)',
                        }}
                        key="login-mail"
                        placeholder={formatMessage({ id: 'login.account' })}
                        allowClear
                        value={account}
                        maxLength={50}
                        onChange={(e) => setState({ account: e.target.value })}
                      />
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    name="verifyCode"
                    rules={[
                      { required: true, message: formatMessage({ id: 'login.verify.code.tip' }) },
                      {
                        max: 6,
                        message: formatMessage({ id: 'login.verify.code.err' }),
                      },
                    ]}
                  >
                    <Input
                      key="find-pass"
                      placeholder={formatMessage({ id: 'login.verify.code' })}
                      maxLength={6}
                      suffix={
                        <div className={styles.captch}>
                          <a
                            onClick={() => {
                              form.setFieldsValue({ username: account.trim() });
                              form.validateFields(['username']).then(() => {
                                if (validCode) {
                                  setState({ showSlider: true });
                                }
                              });
                            }}
                          >
                            {codeText}
                          </a>
                        </div>
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      {formatMessage({ id: 'login.next' })}
                    </Button>
                  </Form.Item>
                </>
              )}

              {type === 'newpwd' && (
                <>
                  <div className={styles.findTitle} style={{ marginBottom: 30 }}>
                    {formatMessage({ id: 'login.resetpwd.title' })}
                  </div>
                  <div className={styles.rules}>
                    <div className={styles.rulesItem}>
                      {formatMessage({ id: 'login.resetpwd.rule.title' })}
                    </div>
                    <div className={styles.rulesItem} style={{ color: passRule ? '#2FA599' : '#9CA4B0' }}>
                      <img width={14} src={passRule ? bigcheck : uncheck} />
                      {getLocale() === 'en-US' ? initData.pwdTipEn : initData.pwdTipZh}
                    </div>
                  </div>
                  <Form.Item
                    name="password"
                    validateFirst={true}
                    rules={[
                      { required: true, message: formatMessage({ id: 'login.resetpwd.enter1' }) },
                      {
                        // 密码验证规则，改为动态配置
                        pattern: initData.pwdRegex,
                        message: formatMessage({ id: 'login.resetpwd.rule.err1' }),
                      },
                    ]}
                  >
                    <Input.Password
                      maxLength={50}
                      placeholder={formatMessage({ id: 'login.resetpwd.enter1' })}
                      style={{ marginTop: 10 }}
                      onChange={() => checkPass('password')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="rpassword"
                    validateFirst={true}
                    rules={[
                      { required: true, message: formatMessage({ id: 'login.resetpwd.enter2' }) },

                      {
                        validator: (rule, value, callback) => {
                          if (value !== form.getFieldValue('password')) {
                            return callback(formatMessage({ id: 'login.resetpwd.rule.err2' }));
                          }
                          callback();
                        },
                      },
                      {
                        // 密码验证规则，改为动态配置
                        pattern: initData.pwdRegex,
                        message: formatMessage({ id: 'login.resetpwd.rule.err1' }),
                      },
                    ]}
                  >
                    <Input.Password
                      maxLength={50}
                      placeholder={formatMessage({ id: 'login.resetpwd.enter2' })}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
                      type="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      {formatMessage({ id: 'login.resetpwd.submit' })}
                    </Button>
                  </Form.Item>
                </>
              )}

              {type === 'success' && (
                <div style={{ textAlign: 'center' }}>
                  <img src={bigcheck} width={80} />
                  <div className={styles.findTitle} style={{ margin: '40px 0 30px 0' }}>
                    {formatMessage({ id: 'login.resetpwd.success.title' })}
                  </div>
                  <div>
                    {seconds}s&nbsp; {formatMessage({ id: 'login.resetpwd.success.tip1' })}
                  </div>
                  <div>{formatMessage({ id: 'login.resetpwd.success.tip2' })}</div>
                  <Button
                    type="primary"
                    style={{
                      width: '100%',
                      height: 55,
                      fontSize: 18,
                      fontWeight: 500,
                      marginTop: 30,
                    }}
                    onClick={() => history.replace('/login')}
                  >
                    {formatMessage({ id: 'login.resetpwd.backlogin' })}
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
        <a className={styles.back} onClick={() => history.push('/')}>
          {zhIntl('返回首页')}
        </a>
      </div>

      {showSlider && (
        <VerifySlideFixed
          isSlideShow={showSlider}
          dispatch={dispatch}
          verifyPointFixedChild={(data) => setState({ showSlider: data })}
          onCheckSuccess={(value) => {
            if (value) {
              setTimeout(() => {
                setState({ showSlider: false });
                if (validCode) {
                  setState({ codeStyle: styles.noClick });
                  dispatch({
                    type: 'cloud/sendCaptcha',
                    payload: {
                      verification: account.includes('@')
                        ? account.trim()
                        : `${areaCode}${account.trim()}`,
                      // source: 1,
                      randomStr: 'captcha',
                      code: value,
                    },
                    callBack: () => {
                      noSendSms();
                    },
                    errorCallBack: () => {
                      setState({ codeStyle: '' });
                    },
                  }).then((res) => {
                    if (res === 'success') {
                      message.success(formatMessage({ id: 'register.verification.success' }));
                    }
                  });
                }
              }, 800);
            }
          }}
        />
      )}

      <style jsx="true">
        {`
          .ant-modal-body {
            padding: 0 0 0 20px;
            background: #fff;
            border-radius: 16px;
          }
          .ant-modal-content {
            border-radius: 16px;
          }
          .ant-input {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
          }
          .ant-select-dropdown {
            width: auto !important;
            padding: 16px 0 !important;
            border-radius: 14px !important;
          }
        `}
      </style>
    </div>
  );
};

export default connect((state) => state)(Page);
