export default {
  'help.use': 'Using Help',
  'help.use.tip':
    'This article introduces you a series of introductory operations to facilitate your quick start and use.',
  'help.use.first': 'First time using',
  'help.smt': 'Meeting',
  'help.sws': 'Workspace',
  'help.vst': 'Visitor',

  // 首次使用
  'help.first.title1': '1、Register',
  'help.first.title2': '2、Sign In',
  'help.first.title3': '3、Site and language switch',
  'help.first.info1': 'Visit the website ',
  'help.first.info1.tip': '，Click “Register”, Jump to the registration page',
  'help.first.info2':
    'After entering the relevant information, click "Sign Up", and then automatically jump to the login page after completion',
  'help.first.info3': 'Enter the email/mobile and password to enter the system',
  'help.first.info4': 'Visit the website ',
  'help.first.info4.tip': ', click “Sign In”',
  'help.first.info5':
    'Jump to the login page, enter your email/mobile, password and enter the system',
  'help.first.info6':
    ' If you are a Teams user, please click to login with your Mircosoft 365 account.',
  'help.first.info7':
    ' Supports switching between China and International stations, and can choose to display in simplified Chinese or English',

  // 会议
  'help.smt.title1': '1、Meeting Booking',
  'help.smt.title2': '2、Meeting Search',
  'help.smt.stitle1': '(1) Administrator Login',
  'help.smt.stitle2': '(2) Create Area',
  'help.smt.stitle3': '(3) Create meeting room',
  'help.smt.stitle4': '(4) Book a meeting room',
  'help.smt.info1': 'Click "Management side" in the upper right corner',
  'help.smt.info2':
    'Select "System Management" in the left menu, then select "Area Management", click on the "..." symbol',
  'help.smt.info3': 'Click "Add Child"',
  'help.smt.info4':
    'Fill in the corresponding information, " Area type" please select "楼层", "Regional function" please select "会议室区域"',
  'help.smt.info5': 'After submission, complete the creation of the area',
  'help.smt.info6':
    'Select "Meeting Management" on the left menu, then select "Room Management", click "Add Room"',
  'help.smt.info7': 'Fill in the information with the "*" symbol and select the area',
  'help.smt.info8': 'After confirming, the meeting room is created successfully',
  'help.smt.info9': 'Administrators and users can login ',
  'help.smt.info9.tip':
    '. If you are currently on the management side, please click "Client" in the upper right corner to switch.',
  'help.smt.info10':
    'Select "Smart Meeting" on the left menu, then select "Meeting Booking", click "Create Meeting"',
  'help.smt.info11':
    'After filling in the name of the meeting room, selecting the meeting room, setting time and participants, submit',
  'help.smt.info12': 'The meeting is booking successfully',
  'help.smt.info13':
    'Select "Smart Meeting" on the left menu, and then select "Meeting List", you can view the meeting information related to yourself.',

  // 工位
  'help.sws.title1': '1、Booking',
  'help.sws.title2': '2、Booking Record',
  'help.sws.stitle1': '(1) Create Workday',
  'help.sws.stitle2': '(2) Create Space',
  'help.sws.stitle3': '(3) Upload Map',
  'help.sws.stitle4': '(4) Map setting station location',
  'help.sws.stitle5': '(5) Binding station',
  'help.sws.stitle6': '(6) Booking',
  'help.sws.info1':
    'Select "System Management" on the left menu, then select "Workday Management", click "Add", Enter the name of the weekday and time period.',
  'help.sws.info2': 'Enter the working day name and time period',
  'help.sws.info3': 'Click "OK" to complete the addition of working days',
  'help.sws.info4':
    'On the left menu, select "Space Operation", then select "Space Info", and click "Add Station".Enter the relevant information of the station, select "Hot Desk" for the station type, select " Workplace Department ", and finally click "Ok" to complete the addition',
  'help.sws.info5':
    'Select "Map Management" on the left menu, then select "Management", click "Map Configuration"',
  'help.sws.info6': 'Click "Upload" to upload the map and submit it after confirmation',
  'help.sws.info7': 'Click "Edit"',
  'help.sws.info8':
    'Display the floor plan, click the blue station icon on the right, and then click anywhere on the map to set the position of the station',
  'help.sws.info9': 'Click save to complete the setting of the station position',
  'help.sws.info10': 'Click " Space binding"',
  'help.sws.info11':
    'Click the position of the workstation that has been set, and then click "Resource Binding" in the upper left corner',
  'help.sws.info12':
    'Select the station, set the "Hot Desk ", only the "mobile station" can be booked',
  'help.sws.info13': 'Click Ok to complete the binding',
  'help.sws.info14': 'Administrators and users can login to ',
  'help.sws.info14.tip':
    '. If you are currently on the management side, please click "Client" in the upper right corner to switch.',
  'help.sws.info15':
    'In the left menu, select "Smart Workspace", then select "Booking", after setting the "Location", the floor plan will be displayed, click on the workstation in the figure',
  'help.sws.info16': 'Display the reservation information, click "Confirm booking"',
  'help.sws.info17': 'The booking is successful',
  'help.sws.info18':
    'Select "Smart Workspace" in the left menu, and then select " Booking Record", you can inquire about the relevant reservations of the current user.',

  // 访客
  'help.vst.title1': '1、Invitations',
  'help.vst.title2': '2、Sign In',
  'help.vst.title3': '3、Visits',
  'help.vst.stitle1': '(1) Create Invitation',
  'help.vst.info1':
    'After the user logs in, select "Visitor Management" in the left menu, then select " Invitations", and click "New"',
  'help.vst.info2': 'Click the "+" sign to add visitor information',
  'help.vst.info3': 'Click "OK" when finished to complete the invitation',
  'help.vst.info4':
    'Select the invitation information that has been visited in the invitation record, click "Details", and click "Sign in" to complete the sign in',
  'help.vst.info5': 'Invitation status changed',
  'help.vst.info6':
    'select " Visitor Management" in the left menu, then select " Visits ", search visitor visit records',

  'help.sms.get.success': 'Sent Successfully',
  'help.sms.title': 'SMS Self-Service Search',
  'help.sms.account': 'Email/Mobile',
  'help.sms.account.tip': 'Please enter your email/mobile',
  'help.sms.search': 'Search',
  'help.sms.reset': 'Reset',
  'help.sms.export': 'Export',
  'help.sms.company.name': 'Company Name',
  'help.sms.time': 'Time',
  'help.sms.num': 'SMS Number',
  'help.sms.send.time': 'Send Time',
  'help.sms.send.content': 'Send Content',
  'help.sms.operate.success': 'Successful Operation',
  'help.sms.search.success': 'Search Successfully',

  'help.table.tip1': 'This page has',
  'help.table.tip2': 'items of data, total',
  'help.table.tip3': 'items',

  'help.sms.table.tip': "※ The number of SMS is based on the operator's calculation data",
};
