/* eslint-disable prefer-object-spread */
/* eslint-disable no-param-reassign */
import { message, Modal } from 'antd';
import { history as router, formatMessage } from 'umi';
import * as services from './service';
import { encryptAes } from '@/utils/encryption';
import { CLOUD_AES_KEY } from '@/utils/constant';

export default {
  namespace: 'tryData',
  state: {
    packageList: [],
    packageListPay: [],
    areaCode: [],
    highLight: '0',
    isShow: false,
    modalType: '',
  },
  reducers: {
    save(state, { payload }) {
      return Object.assign({}, state, { ...payload });
    },
  },
  effects: {
    *getPackageList(_, { call, put }) {
      const data = yield call(services.getPackageList);
      if (data) {
        if (data.code === 0) {
          yield put({
            type: 'save',
            payload: {
              packageList: data.data,
            },
          });
        } else {
          message.error(formatMessage({ id: data.msg }));
        }
      }
    },
    *getPackageListPay(_, { call, put }) {
      const data = yield call(services.getPackageListPay);
      if (data) {
        if (data.code === 0) {
          data.data = data.data.map((v) => {
            v.productVersionName = `${v.productVersionName} / ${v.timeStr} / ${v.unitPrice} 元`;
            return v;
          });
          yield put({
            type: 'save',
            payload: {
              packageListPay: data.data,
            },
          });
        } else {
          message.error(formatMessage({ id: data.msg }));
        }
      }
    },
    *getCode({ payload }, { call }) {
      const data = yield call(services.getCode, payload.phone);
      if (data) {
        if (data.code !== 0) {
          message.error(formatMessage({ id: data.msg }));
          payload.errorCallBack();
        } else {
          payload.callBack();
        }
      }
    },
    *checkCode({ payload }, { call, put }) {
      const data = yield call(services.checkCode, {
        phone: payload.contactTel,
        code: payload.code,
      });
      // const data = { code: 0 }
      if (data) {
        if (data.code === 0) {
          yield put({
            type: 'submit',
            payload,
          });
        } else {
          message.error(formatMessage({ id: data.msg }));
        }
      }
    },
    *submit({ payload }, { call }) {
      payload.password = encryptAes(payload.password, CLOUD_AES_KEY);
      const data = yield call(services.submit, payload);

      /* const tip = payload.registerType.toString() === '1' ? {
        title: '注册成功',
        content: '注册成功，已发送激活邮件，请激活后登录',
      } : {
        title: '申请成功',
        content: '我们的咨询顾问会尽快与您核实信息',
      } */
      const tip = {
        title: formatMessage({ id: 'login.register.success.tip' }),
        // content: '注册成功，请登录',
        okText: formatMessage({ id: 'login.ok' }),
      };

      if (data) {
        if (data.code === 0) {
          localStorage.removeItem('smsTime');
          Modal.success({
            ...tip,
            onOk() {
              router.push('/login');
            },
          });
        } else {
          message.error(formatMessage({ id: data.msg }));
        }
      }
    },
    *thirdRegister({ payload }, { call }) {
      payload.password = encryptAes(payload.password, CLOUD_AES_KEY);
      const { code, msg } = yield call(services.thirdRegister, payload);
      if (code === 0) {
        message.success(formatMessage({ id: 'login.register.success' }));
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
    *thirdBind({ payload }, { call }) {
      const { code, msg } = yield call(services.thirdBind, payload);
      if (code === 0) {
        message.success(formatMessage({ id: 'login.bind.success' }));
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
  },
  subscriptions: {
    set({ dispatch, history }) {
      return history.listen(({ pathname }) => {
        if (pathname.includes('/cloud/web/bind') || pathname.includes('/cloud/web/register')) {
          if (history.location.query) {
            const { entrySource, productCode, terminal, teamsTenantId, azureUserid } =
              history.location.query;
            dispatch({
              type: 'save',
              payload: {
                entrySource,
                productCode,
                terminal,
                teamsTenantId,
                azureUserid,
              },
            });
          }
        }
      });
    },
  },
};
