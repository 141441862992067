/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { history, getLocale } from 'umi';
import Fade from 'react-reveal/Fade';
import { COPYRIGHT, COPYRIGHT_enUS, COPYRIGHT_zhTW } from '@/utils/constant';
import logoCnWhite from '@/assets/logo/logoCnWhite.png';
import logoEnWhite from '@/assets/logo/logoEnWhite.png';
// import wx from '@/assets/qrcode/wx.png';
import styles from './index.less';
import { zhIntl } from 'wafer-i18n-utils';

const Footer = () => {
  const linkList = [
    {
      title: zhIntl('产品'),
      key: 1,
      list: [
        { title: `- ${zhIntl('智能访客')}`, link: '/product?pdtype=vst' },
        { title: `- ${zhIntl('智能会议')}`, link: '/product?pdtype=smt' },
        { title: `- ${zhIntl('空间管理')}`, link: '/product?pdtype=sws' },
        { title: `- ${zhIntl('设备设施管理')}`, link: '/product?pdtype=fm' },
        { title: `- ${zhIntl('环境能碳管理')}`, link: '/product?pdtype=esg' },
        { title: `- ${zhIntl('企业数字洞察')}`, link: '/product?pdtype=bdp' },
      ],
    },
    {
      title: zhIntl('服务中心'),
      key: 2,
      list: [
        { title: zhIntl('短信自助查询'), link: '/sms' },
        { title: zhIntl('使用帮助'), link: '/help' },
      ],
    },
    {
      title: zhIntl('关于我们'),
      key: 3,
      list: [{ title: zhIntl('企业简介'), link: '/about' }],
    },
  ];

  return (
    <div className={styles.footer}>
      <div className={styles.footerWrap}>
        <div className={styles.content}>
          <Fade bottom>
            <div className={styles.infoArea}>
              <div style={{ marginBottom: 0 }}>
                <img
                  src={getLocale() === 'en-US' ? logoEnWhite : logoCnWhite}
                  height={60}
                  alt=""
                  style={{ marginBottom: 50 }}
                />
                <div>{zhIntl('销售热线: (全球)')} 852-39008859</div>
                <div>{zhIntl('服务热线: (全球)')} 8610-85188341</div>
                <div>Email: sales@virsical.com</div>
                <a target={'_blank'} rel="noreferrer" href={window.location.origin}>
                  {window.location.origin}
                </a>
              </div>
              {/* <img className={styles.qrcode} src={wx} alt="" /> */}
            </div>
          </Fade>
          <Fade bottom>
            <div className={styles.linkArea}>
              {linkList.map((item) => (
                <div
                  className={styles.col}
                  key={item.key}
                  style={{ marginLeft: getLocale() === 'en-US' ? 60 : 100 }}
                >
                  <div className={styles.colTitle}>{item.title}</div>
                  {item.list.map((list) => (
                    <div
                      className={styles.colItem}
                      key={list.link}
                      onClick={() => history.push(list.link)}
                    >
                      {list.title}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Fade>
        </div>
      </div>
      <Fade bottom>
        <div className={styles.copy}>
          {getLocale() === 'en-US'
            ? COPYRIGHT_enUS
            : getLocale() === 'zh-TW'
            ? COPYRIGHT_zhTW
            : COPYRIGHT}
        </div>
      </Fade>
    </div>
  );
};

export default Footer;
