export default {
  'case.tabs.tabs1':"企业",
  'case.tabs.tabs2':"游戏",
  'case.tabs.tabs3':"视频",
  'case.tabs.tabs4':"互联网",
  'case.tabs.tabs5':"金融",
  'case.tabs.tabs6':"医疗",
  'case.tabs.tabs7':"教育",
  'case.tabs.tabs8':"政务",
  'case.header.title':"行业案例",
  'case.header.title.subtitle':"威思客云系统 助力行业升级",
  'case.header.title.subtitle.top':"客户覆盖《财富》500强60%以上的大型企业及多家中小型企业遍及",
  'case.header.title.subtitle.btm':"互联网、金融、教育、医疗等多个",
  'case.header.btn.go':"立即体验",
  'case.all.industry.cases':"全部案例",
  'case.tabs.sub.tabs1.title':"菲仕兰",
  'case.tabs.sub.tabs2.title':"阿里云",
  'case.tabs.sub.tabs3.title':"美团点评",
  'case.tabs.sub.tabs1.text':'威思客携手全球知名企业菲仕兰乳制品公司，满足其访客预约、签到、访客信息管理统计的需求，菲仕兰可按照办公地统计访客量制定科学的访客管理策略，赋予访客人性化的接待体验，展现了智能科技的魅力。',
  'case.tabs.sub.tabs2.text':'威思客作为数字化空间管理解决方案领导者，牵手阿里云创新中心，帮助园区运营者及阿里云创客平台自身更好管理运营创业孵化平台！',
  'case.tabs.sub.tabs3.text':'根据美团建设智能楼宇的企业场景需求，以及达成访客通行授权、门禁权限管理控制的目标，威思客为美团成功落地访客自动预约，实现了访客管理模式更新转换，打通智能通行的渠道。',
}
