/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { formatMessage, history, connect, getLocale } from 'umi';
import { Form, Input, Select, Button, message } from 'antd';
import { useMount } from 'ahooks';
import VerifySlideFixed from '@/components/SliderVerify';
import { countryAreaCode } from '@/utils/constant';
import { zhIntl } from '@/utils/intlUtils';
import loginCn from '@/assets/login/loginCn.png';
import loginEn from '@/assets/login/loginEn.png';
import styles from './index.less';

const Page = ({ dispatch, cloud }) => {
  const { tenantData = [], phoneAreaCode } = cloud;
  const [form] = Form.useForm();

  const [account, setAccount] = useState('');
  const [areaCode, setAreaCode] = useState(
    sessionStorage.getItem('isChina') === 'true' ? '+86' : '+852',
  );
  const [loading, setLoading] = useState(false);
  const [codeStyle, setCodeStyle] = useState('');
  const [validCode, setValidCode] = useState(true);
  const [codeText, setCodeText] = useState(formatMessage({ id: 'register.Get.verification.code' }));
  const [pageData, setPageData] = useState({});
  const [type, setType] = useState('active');
  const [showSlider, setShowSlider] = useState(false);
  const [initData, setInitData] = useState('');

  function setAwait(key, seconds, callBack = () => { }) {
    localStorage.setItem(key, seconds);
    const timerId = 'timer_' + key;
    if (window[timerId]) return;
    window[timerId] = window.setInterval(() => {
      let second = localStorage.getItem(key);
      if (parseInt(second, 10) <= 0) {
        window.clearInterval(window[timerId]);
        localStorage.removeItem(key);
        delete window[timerId];
        return;
      }
      second = parseInt(second, 10) - 1;
      localStorage.setItem(key, second);
      callBack(parseInt(second, 10));
    }, 1000);
  }

  const noSendSms = (time = 60, check = false) => {
    if (check) {
      const maxTime = localStorage.getItem('regisSmsTime');
      if (!maxTime) return;
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) return;
    } else {
      localStorage.setItem('regisSmsTime', Date.now() + time * 1000);
    }
    setValidCode(false);
    setCodeStyle(styles.noClick);
    window.tVal = setInterval(() => {
      const maxTime = localStorage.getItem('regisSmsTime');
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) {
        clearInterval(window.tVal);
        setValidCode(true);
        setCodeStyle('');
        setCodeText(formatMessage({ id: 'register.locales.verification.code' }));
        localStorage.removeItem('regisSmsTime');
      } else {
        setCodeText(`${hasTime}${formatMessage({ id: 'register.Resend.in.seconds' })}`);
      }
    }, 1000);
  };

  const handleSubmit = (e) => {
    form.setFieldsValue({ username: account.trim() });
    e.preventDefault();
    form.validateFields().then((values) => {
      setLoading(true);
      if (!values?.username?.includes('@')) {
        values.username = `${areaCode}${values.username}`;
      }
      if (type === 'active') {
        dispatch({ type: 'cloud/checkEmailCode', payload: values }).then(res => {
          console.log('res', res)
          if (res) {
            setType('select');
            setAreaCode(sessionStorage.getItem('isChina') === 'true' ? '+86' : '+852');
            setAccount('');
            setPageData({ ...pageData, verification: values.username });
          }
        })
      }
      if (type === 'select') {
        setType('passwd');
        setPageData({ ...pageData, tenantId: values.tenantId });
      }
      if (type === 'passwd') {
        const data = { ...pageData, password: values.password };
        const res = dispatch({ type: 'cloud/activeUser', payload: data });
        if (res) {
          message.success(formatMessage({ id: 'login.active.success' }));
          form.setFieldsValue({ password: '' });
          setAwait('activeWait', 0);
          history.replace('/login');
        } else {
          message.error(formatMessage({ id: 'login.active.failed' }));
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useMount(() => {
    dispatch({ type: 'cloud/getAreaCode' });
  });

  useEffect(() => {
    dispatch({ type: 'cloud/getCommonInitData' }).then((res) => {
      setInitData(res);
    });
  }, [])

  return (
    <div className={styles.body}>
      <img src={getLocale() === 'en-US' ? loginEn : loginCn} className={styles.img} />
      <div className={styles.content}>
        <div className={styles.area}>
          <div className={styles.title}>
            {type === 'active'
              ? zhIntl('激活')
              : type === 'select'
                ? formatMessage({ id: 'login.company.tip' })
                : zhIntl('请初始化您的密码')}
          </div>
          <div className={styles.form}>
            {type === 'active' && (
              <Form form={form}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'login.account.tip' }),
                    },
                    {
                      pattern:
                        Number(account).toString().length < 6 || account.includes('@')
                          ? /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
                          : /^\d{6,15}$/,
                      message: formatMessage({ id: 'login.account.regex.err' }),
                    },
                    {
                      max: 50,
                      message: formatMessage({ id: 'login.account.regex.max' }),
                    },
                  ]}
                >
                  <Input.Group compact style={{ display: 'flex' }}>
                    {Number(account).toString().length < 6 || account.includes('@') ? null : (
                      <Select
                        value={areaCode}
                        onChange={(e) => setAreaCode(e)}
                        optionLabelProp="label"
                      >
                        {countryAreaCode(phoneAreaCode)}
                      </Select>
                    )}
                    <Input
                      style={{
                        width:
                          Number(account).toString().length < 6 || account.includes('@')
                            ? '100%'
                            : 'calc(100% - 80)',
                      }}
                      maxLength={50}
                      placeholder={formatMessage({ id: 'login.account' })}
                      allowClear
                      value={account}
                      onChange={(e) => setAccount(e.target.value)}
                    />
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  name="verifyCode"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'login.verify.code.tip' }),
                    },
                    {
                      pattern: /^\d{2,6}$/,
                      message: formatMessage({ id: 'register.locales.error.Verification.error' }),
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({ id: 'login.verify.code' })}
                    maxLength={6}
                    suffix={
                      <a
                        className={codeStyle}
                        onClick={() => {
                          form.setFieldsValue({ username: account.trim() });
                          form.validateFields(['username']).then(() => {
                            if (validCode) {
                              setShowSlider(true);
                            }
                          });
                        }}
                      >
                        {codeText}
                      </a>
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                  >
                    {formatMessage({ id: 'login.next' })}
                  </Button>
                  <div className={styles.register}>
                    <a onClick={() => history.push('/login')}>
                      {formatMessage({ id: 'login.direct' })}
                    </a>
                  </div>
                </Form.Item>
              </Form>
            )}
            {type === 'select' && (
              <Form form={form}>
                <Form.Item
                  name="tenantId"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'login.company.select' }),
                    },
                  ]}
                >
                  <Select
                    placeholder={formatMessage({ id: 'login.company.select' })}
                    style={{ width: '100%' }}
                    allowClear
                  >
                    {tenantData?.map((i) => {
                      return (
                        <Select.Option key={i.tenantId} value={i.tenantId}>
                          {i.tenantName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                  >
                    {formatMessage({ id: 'login.next' })}
                  </Button>
                  <div className={styles.register}>
                    <a onClick={() => history.push('/login')}>
                      {formatMessage({ id: 'login.direct' })}
                    </a>
                  </div>
                </Form.Item>
              </Form>
            )}
            {type === 'passwd' && (
              <Form form={form}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({ id: 'login.password.tip' }),
                    },
                    {
                      // 密码验证规则，改为动态配置
                      pattern: initData.pwdRegex,
                      message: getLocale() === 'en-US' ? initData.pwdTipEn : initData.pwdTipZh,
                    },
                  ]}
                >
                  <Input
                    key="init-pass"
                    type="password"
                    placeholder={formatMessage({ id: 'login.password.init' })}
                    allowClear
                    maxLength={16}
                  />
                </Form.Item>
                <Form.Item
                  name="rpassword"
                  rules={[
                    { required: true, message: formatMessage({ id: 'login.password.sure.tip' }) },
                    {
                      // 密码验证规则，改为动态配置
                      pattern: initData.pwdRegex,
                      message: getLocale() === 'en-US' ? initData.pwdTipEn : initData.pwdTipZh,
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (value !== form.getFieldValue('password')) {
                          return callback(formatMessage({ id: 'login.password.wrong' }));
                        }
                        callback();
                      },
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder={formatMessage({ id: 'login.password.sure' })}
                    allowClear
                    maxLength={16}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleSubmit}
                  >
                    {formatMessage({ id: 'login.complete' })}
                  </Button>
                  <div className={styles.register}>
                    <a style={{ visibility: 'hidden' }} onClick={() => history.push('/login')}>
                      {formatMessage({ id: 'login.direct' })}
                    </a>
                  </div>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
        <a className={styles.back} onClick={() => history.push('/')}>
          {zhIntl('返回首页')}
        </a>
      </div>

      {showSlider && (
        <VerifySlideFixed
          isSlideShow={showSlider}
          dispatch={dispatch}
          verifyPointFixedChild={(data) => setShowSlider(data)}
          onCheckSuccess={(value) => {
            if (value) {
              setTimeout(() => {
                setShowSlider(false);
                if (validCode) {
                  dispatch({
                    type: 'cloud/sendMailCode',
                    payload: {
                      verification: account.includes('@')
                        ? account.trim()
                        : `${areaCode}${account.trim()}`,
                      source: 2,
                      randomStr: 'captcha',
                      code: value,
                    },
                    callBack: () => {
                      noSendSms();
                    },
                    errorCallBack: () => {
                      setCodeStyle('');
                    },
                  }).then((res) => {
                    if (res === 'success') {
                      message.success(formatMessage({ id: 'register.verification.success' }));
                    }
                  });
                }
              }, 800);
            }
          }}
        />
      )}

      <style>
        {`
          .ant-select-dropdown {
            width: auto !important;
            border-radius: 14px !important;
            padding: 16px 0 !important;
          }
        `}
      </style>
    </div>
  );
};

export default connect((state) => state)(Page);
