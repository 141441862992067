export default {
  'about.Where.we.work': '我们的工作地点',
  'about.us': '关于我们',
  'about.us.btn': '立即体验',
  'about.us.subt':
    '威发系统成立于1988年，隶属于威讯控股有限公司（港股代码：01087)。拥有30年网络系统集成及20余年独立软件开发经验。',
  'about.us.subt2':
    '威发系统立足中国，面向亚太及全球，在香港、北京，上海，广州，成都、苏州、深圳等地设有分公司和办事处，在西安设有软件研发中心。威发系统始终专注于互联网IT基础建设、云服务及网络安全、可视化空间管理及智能办公、移动办公等领域，是业界领先的新一代系统集成及智能办公应用服务提供商。',
  'about.Guangzhou.name': '广州',
  'about.Suzhou.name': '苏州',
  'about.Beijing.name': '北京',
  'about.Shanghai.name': '上海',
  'about.Shanghai.address': '上海市长宁区天山路1717号SOHO天山广场T2-1502室',
  'about.Guangzhou.address': '广州市番禺区南村镇万博二路79号万达广场B1栋8屋805-806室',
  'about.Suzhou.address': '苏州市工业园区金鸡湖大道1355号国际科技园4期A0302',
  'about.Beijing.address': '北京市东城区东长安街1号东方广场东二办公楼19层1907-1909室',
  'about.Suzhou.tel': '电话：(86512)69172780',
  'about.Guangzhou.tel': '电话：(8620) 38105888',
  'about.Beijing.tel': '电话：(8610) 85184988',
  'about.Guangzhou.fax': '传真：(8620) 38478669',
  'about.Beijing.fax': '传真：(8610) 85188340',
  'about.Suzhou.fax': '传真：(86512)69172789',
  'about.Suzhou.zipCode': '邮编：215021',
  'about.Beijing.zipCode': '邮编：100738',
  'about.Shanghai.tel': '电话：(8621) 32093700',
  'about.Shanghai.fax': '传真：(8621) 32093877',
  'about.Shanghai.zipCode': '邮编：200051',
  'about.Shenzhen.name': '深圳',
  'about.Shenzhen.address': '深圳市福田区福华一路98号卓越大厦2006室',
  'about.Shenzhen.tel': '电话：(86755) 23618250、23618367',
  // 'about.Shenzhen.fax':"",
  // 'about.Shenzhen.zipCode':'',
  // 'about.HongKong.zipCode':'',
  'about.HongKong.name': '香港',
  'about.HongKong.address': '香港北角英皇道101号13楼2号室',
  'about.HongKong.tel': '电话：(852) 39008800',
  'about.HongKong.fax': '传真：(852) 28891231',

  'about.virsical.title': '关于威思客',
  'about.virsical.tip1':
    '威发系统是一家 IT 解决方案公司，拥有超过 30 年的系统集成经验以及超过 18 年的独立商业软件开发经验。',
  'about.virsical.tip2':
    '威思客智能办公解决方案套件专注于数字化办公、楼宇和园区管理解决方案，具有可视化、交互式访客管理、智能会议管理、工作空间管理、消息发布、无线接入、视频协作和即时消息等功能，旨在提升员工体验和工作效率。',
  'about.virsical.tip3': '威思客引领数字化转型！',
};
