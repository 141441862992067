export default {
  'hardware.banner1':
    '依靠AIOT技術，充分利用各類傳感器，實時采集多維數據，實時反饋自適，創造一個「以人為本」的辦公新生態，將「人-物-空間-環境」實現智慧聯結和管理，全方位實現辦公數智化。',
  'hardware.title': '硬件設備',
  // 硬件产品第一行
  'hardware.row1.1.title': '窄邊框會議展板（標準版）',
  'hardware.row1.1.info1': '10.1寸IPS，1920*1200分辨率',
  'hardware.row1.1.info2': '10點電容式觸摸屏',
  'hardware.row1.1.info3': '四核處理器 Arm Cortex-A17，Mali-T764 GPU',
  'hardware.row1.1.info4': '2GB內存、支持無線及藍牙',

  'hardware.row1.2.title': '窄邊框會議展板（人臉識別版）',
  'hardware.row1.2.info1': '10.1寸IPS，1920*1200分辨率',
  'hardware.row1.2.info2': '300W高清攝像頭',
  'hardware.row1.2.info3': '支持FID card read、支持ZIGBEE網關',
  'hardware.row1.2.info4': '10點電容式觸摸屏',
  'hardware.row1.2.info5': '1四核處理器 Arm Cortex-A17，Mali-T764 GPU',
  'hardware.row1.2.info6': '2GB內存、支持無線及藍牙',

  'hardware.row1.3.title': '輕薄會議展板',
  'hardware.row1.3.info1': '10.1寸IPS，1280*800分辨率',
  'hardware.row1.3.info2': '200W高清攝像頭',
  'hardware.row1.3.info3': '支持LED',
  'hardware.row1.3.info4': '多點觸摸屏',
  'hardware.row1.3.info5': '四核處理器 Arm Cortex-A17',
  'hardware.row1.3.info6': '2GB內存、支持無線',

  // 硬件产品第二行
  'hardware.row2.1.title': '訪客一體機',
  'hardware.row2.1.info1': '21.5寸、32寸顯示器',
  'hardware.row2.1.info2': 'Windows、安卓系統',
  'hardware.row2.1.info3': '支持身份證讀卡器、二維碼讀卡器',
  'hardware.row2.1.info4': '活體雙目攝像頭',
  'hardware.row2.1.info5': '訪客證打印機',

  'hardware.row2.2.title': '桌面一體機',
  'hardware.row2.2.info1': '13.3寸IPS雙面屏，1920*1080分辨率',
  'hardware.row2.2.info2': '支持護照掃描攝像頭',
  'hardware.row2.2.info3': '二維碼掃描攝像頭',
  'hardware.row2.2.info4': '支持身份證讀卡器',
  'hardware.row2.2.info5': '支持無線連接',

  'hardware.row2.3.title': '播放盒',
  'hardware.row2.3.info1': '四核處理器 Arm Cortex-A17，Mali-T764 GPU',
  'hardware.row2.3.info2': '2GB內存',
  'hardware.row2.3.info3': '支持LVDS、EDP顯示屏接口, HDMI接口',
  'hardware.row2.3.info4': '支持無線連接',
  'hardware.row2.3.info5': '支持藍牙',

  // 硬件产品第三行
  'hardware.row3.1.title': '漢朔水墨屏會議展板',
  'hardware.row3.1.info1': '7.5寸版本',
  'hardware.row3.1.info2': 'EPD點陣電子紙',
  'hardware.row3.1.info3': '電池工作壽命5年',

  'hardware.row3.2.title': '漢朔10.2寸',
  'hardware.row3.2.info1': '10.2寸版本',
  'hardware.row3.2.info2': 'EPD點陣電子紙',
  'hardware.row3.2.info3': '电池工作寿命5年',

  'hardware.row3.3.title': '電子工牌基站',
  'hardware.row3.3.info1': '4路天線並行通信',
  'hardware.row3.3.info2': '基站通信半徑15米',
  'hardware.row3.3.info3': '私有協議Esl-Working V2.0',
  'hardware.row3.3.info4': '基站支持POE和AC適配器供電',

  // 硬件产品第四行
  'hardware.row4.1.title': '高清播放盒',
  'hardware.row4.1.info1': '八核處理器 Mali-T764 GPU，4GB內存',
  'hardware.row4.1.info2': '支持兩路 EDP 接口支持 4K eDP 顯示接口',
  'hardware.row4.1.info3': '支持無線、藍牙',

  'hardware.row4.2.title': '人體感應器',
  'hardware.row4.2.info1': '綠米',
  'hardware.row4.2.info2': 'ZigBee協議',
  'hardware.row4.2.info3': '網關覆蓋半徑10米',

  'hardware.row4.3.title': '智能插座',
  'hardware.row4.3.info1': '南京物聯',
  'hardware.row4.3.info2': 'ZigBee協議',
  'hardware.row4.3.info3': '網關覆蓋半徑10米',

  // 硬件产品第五行
  'hardware.row5.1.title': '觸摸大屏一體機',
  'hardware.row5.1.info1': '55寸显示屏',

  'hardware.row5.2.title': '觸摸大屏一體機',
  'hardware.row5.2.info1': '65寸顯示屏',

  'hardware.row5.3.title': '觸摸大屏一體機',
  'hardware.row5.3.info1': '75寸顯示屏',

  // 硬件产品第六行
  'hardware.row6.1.title': '觸摸大屏一體機',
  'hardware.row6.1.info1': 'X86S — 86寸顯示屏',
  'hardware.row6.1.info2': 'X98S — 98寸顯示屏',

  'hardware.row6.2.title': '廁位占用門鎖',

  'hardware.row6.3.title': '廁位占用指示牌',

  // 机器人
  'hardware.banner2':
    '以安卓為基礎的OS系統支持對不同功能開發的無限可能性。如辦公、醫療看護、酒店、零售等場景',
  'hardware.product.title': '產品介紹',
  'hardware.product.tip1':
    '內置兩大系統，一個是可視化的用戶操作系統，另一個便是賦予訪客機器人自主移動的ROBOX導航系統，配合全身上下多達60個傳感器，包括360度的激光雷達、深感攝像頭、飛行時間線性傳感器等。它可以實時感知周圍環境，構建二維地圖，「聰明」地規劃自己的行動路線，自主規避各種障礙物，實現主動移動。',
  'hardware.product.tip2':
    '具備接受語音指令、面部識別功能，可在播放音樂或者視頻的同時自動識別使用者面孔，並隨著使用者蹲下或起身來調整屏幕上下傾斜的角度，跟隨使用者走動、轉彎。',
  'hardware.product.tip3':
    '集成了人工智能和人機交互技術。在辦公場所、零售商店、酒店、醫院、政務大廳、餐飲以及教育等領域被大量投入使用。',
  'hardware.param': '參數配置',
  'hardware.func': '功能',
  'hardware.func.yingbin': '迎賓接待',
  'hardware.func.gensui': '智能跟隨',
  'hardware.func.renlian': '人臉識別',
  'hardware.func.jiangjie': '引導講解',
  'hardware.func.zixun': '問答咨詢',
  'hardware.func.xunhang': '巡航宣傳',
  'hardware.func.hudong': '互動娛樂',

  'hardware.func.zhongkong': '智能中控',
  'hardware.func.huichong': '自動回充',
  'hardware.func.shipin': '視頻通話',
  'hardware.func.yuancheng': '遠程控製',
  'hardware.func.music': '在線音樂',
  'hardware.func.news': '新聞資訊',
  'hardware.func.sheying': '錄像攝影',

  'hardware.param.patent': '專利技術',
  'hardware.param.patent.1.msg1': 'ROBOX導航系統',
  'hardware.param.patent.1.msg2': '人機交互及自主導航規劃',
  'hardware.param.patent.2.msg1': '二維地圖構建',
  'hardware.param.patent.2.msg2': '自主導航',
  'hardware.param.patent.2.msg3': '用戶檢測及追蹤',
  'hardware.param.patent.2.msg4': '智能避障',
  'hardware.param.patent.2.msg5': '路徑規劃',
  'hardware.param.patent.2.msg6': '面部輪廓識別',
  'hardware.param.patent.3.msg1': '實時傳感器融合和數據分析確保全自動導航的安全性',
  'hardware.param.patent.3.msg2': '全自動導航系統',

  'hardware.param.system': '操作系統',
  'hardware.param.system.1.msg1':
    '訪客機器人通過聽說讀看的能力，始終保持學習狀態，更好地服務用戶，提供極度直觀的操作方式。',
  'hardware.param.system.2.title': '語音交互',
  'hardware.param.system.2.msg1':
    '訪客機器人使用自動語音識別技術和遠場語音識別技術（ASR）、自然語言處理（NLP）以及文本到語音（TTS）引擎實現聽取、識別、理解和響應用戶的語音指令等功能。',
  'hardware.param.system.3.title': '用戶界面',
  'hardware.param.system.3.msg1':
    '訪客機器人定製的高清顯示屏讓你的所說，所聽，所見都有增強的視聽效果，以獲得極致的人機交互體驗。',
  'hardware.param.system.4.msg1':
    '訪客機器人集成了多個頂尖的人工智能引擎，通過這些引擎的相互協作為您提供最好的服務。它的導航系統可以自我學習周圍的環境，了解機器人在哪裏，它看到什麽，它要去哪裏。',
  'hardware.param.system.5.title': '開放性',
  'hardware.param.system.5.msg1': '對第三方開放的應用程序',
  'hardware.param.system.5.msg2': '提供SDK（軟件開發工具包）',

  'hardware.param.program': '內置程序',
  'hardware.param.program.ai': 'AI助手',
  'hardware.param.program.home': '訪客機器人到家',
  'hardware.param.program.tonghua': '視頻通話',
  'hardware.param.program.news1': '新聞資訊',
  'hardware.param.program.dj': '私人DJ',
  'hardware.param.program.sheying1': '無憂攝影',
  'hardware.param.program.help': '移動幫手',

  'hardware.param.connection': '連接',
  'hardware.param.connection.2.title': '藍牙',
  'hardware.param.connection.2.msg1': '具備藍牙4.0短距離無線連接設備和配件的能力',

  'hardware.param.size': '尺寸',
  'hardware.param.size.1.title': '大小',
  'hardware.param.size.1.msg1': '長：45cm',
  'hardware.param.size.1.msg2': '寬：35cm',
  'hardware.param.size.1.msg3': '高：100cm',
  'hardware.param.size.2.title': '重量',
  'hardware.param.size.2.msg1': '26磅/12kg',

  'hardware.param.display': '屏幕顯示',
  'hardware.param.display.1.title': '10.1寸LCD高清顯示',
  'hardware.param.display.1.msg1': '電容式多點觸摸屏',
  'hardware.param.display.1.msg2': '具有1600萬色的IPS液晶顯示屏（1920X1200）',
  'hardware.param.display.1.msg3': '觸摸屏像素密度：224（ppi）',
  'hardware.param.display.2.msg1': '最大亮度340 cd / m²（典型值）',
  'hardware.param.display.2.msg2': '防指紋塗層',

  'hardware.param.tiltable': '可傾斜屏幕',
  'hardware.param.tiltable.1.msg1': '自動面部追蹤傾斜屏幕角度',
  'hardware.param.tiltable.1.msg2': '傾斜角度：-15°～+ 55°',

  'hardware.param.micro': '麥克風',
  'hardware.param.micro.1.msg1': '麥克風陣列',
  'hardware.param.micro.1.msg2': '4個全向數字麥克風',
  'hardware.param.micro.1.msg3': '實時定位',
  'hardware.param.micro.1.msg4': '波束成型',
  'hardware.param.micro.1.msg5': '聲學回聲消除',
  'hardware.param.micro.1.msg6': '環境降噪',

  'hardware.param.audio': '音頻',
  'hardware.param.audio.1.msg1': '20W喇叭音頻輸出',
  'hardware.param.audio.1.msg2': '高保真均衡器',
  'hardware.param.audio.1.msg3': '1個低音炮',
  'hardware.param.audio.1.msg4': '2個中音揚聲器',
  'hardware.param.audio.1.msg5': '2個高音揚聲器',

  'hardware.param.webcam': '攝像頭',
  'hardware.param.webcam.1.msg1': '1300萬像素高分辨率攝像頭',
  'hardware.param.webcam.1.msg2': '自動對焦',
  'hardware.param.webcam.1.msg3': '每秒30幀視頻錄製',
  'hardware.param.webcam.1.msg4': '5個透鏡',
  'hardware.param.webcam.1.msg5': '紅外線濾鏡',
  'hardware.param.webcam.2.title': '廣角攝像頭',
  'hardware.param.webcam.2.msg1': '用於遠程導航的1300萬像素廣角攝像頭',
  'hardware.param.webcam.2.msg2': '支持1080P',
  'hardware.param.webcam.3.title': '深度攝像頭',
  'hardware.param.webcam.3.msg1': '每秒30幀',
  'hardware.param.webcam.3.msg2': '最大可達5米',
  'hardware.param.webcam.3.msg3': '90°視場角',

  'hardware.param.cpu.1.msg1': '四核ARM處理器',
  'hardware.param.cpu.1.msg2': '人機交互系統基於安卓架構搭建',
  'hardware.param.cpu.1.msg3': '支持1080P@給你專屬用戶體驗',
  'hardware.param.cpu.2.msg1': '六核ARM處理器',
  'hardware.param.cpu.2.msg2': '基於ARM架構的自主導航系統@每秒30幀視頻錄製',
  'hardware.param.cpu.2.msg3': '低能耗、高性能',

  'hardware.param.battery': '電池',
  'hardware.param.battery.1.msg1': '滿電情況下可工作8小時',
  'hardware.param.battery.1.msg2': '自動充電 – 220V / 110V',
  'hardware.param.battery.1.msg3': '高性能充電樁',
  'hardware.param.battery.1.msg4': '鋰離子電池',

  'hardware.param.mobility': '移動性',
  'hardware.param.mobility.1.msg1': '2個獨立的50W直驅式無刷直流電機驅動系統',
  'hardware.param.mobility.2.title': '速度',
  'hardware.param.mobility.2.msg1': '最高可達1米每秒',

  'hardware.param.tray': '托盤',
  'hardware.param.tray.1.title': '托盤',
  'hardware.param.tray.1.msg1': '面積：30×25cm 最大可承受3KG重物',
  'hardware.param.tray.2.title': '無線充電',
  'hardware.param.tray.2.msg1': '內置標準無線充電器，可供手機充電',

  'hardware.param.package': '包裝內容',
  'hardware.param.package.1.msg1': '1個訪客機器人',
  'hardware.param.package.1.msg2': '1個充電樁+充電線',
  'hardware.param.package.1.msg3': '說明書',
};
