import React, { useState } from "react"
import { formatMessage } from "umi";
import { Modal, Input, Button } from "antd"
import QRCode from "qrcode.react";
import scanbind from '@/assets/scanbind.png'
import { zhIntl } from '@/utils/intlUtils';
import styles from './index.less'

const SecurityBind = (props: any) => {
  const { visible, onCancel, onOk, qrInfo } = props

  const [code, setCode] = useState('')

  return (
    <Modal
      width={960}
      visible={visible}
      title={null}
      footer={null}
      onCancel={onCancel}
    >
      <div className={styles.itemQRCode}>
        <div className={styles.left}>
          <div className={styles.title}>{zhIntl('通过双因素智能登录')}</div>
          <div className={styles.subTitle}>{zhIntl('温馨提醒，您的所属企业已开启双因素认证，请先扫码绑定')}</div>
          <div className={styles.qrCode}>
            <QRCode
              value={qrInfo || ''}
              size={190}
              style={{ textAlign: 'center' }}
              includeMargin
            />
          </div>
          <div className={styles.saveCodeText}>
            {formatMessage({ id: 'menu.select.default.2FA.save.code' })}
          </div>
          <Input
            style={{ width: '100%', height: 36 }}
            value={code}
            maxLength={6}
            placeholder={formatMessage({ id: 'menu.select.default.2FA.save.code.placeholder' })}
            onChange={(e) => {
              setCode(e.target.value?.trim());
            }}
          />
          <Button
            type="primary"
            style={{ marginTop: 20, padding: '8px 24px', height: 36 }}
            onClick={() => onOk(code)}
          >
            {zhIntl('绑定账号')}
          </Button>
        </div>
        <div className={styles.right}>
          <img src={scanbind} style={{ marginBottom: 50 }} alt='' />
          <div>
            <div className={styles.remarkTitle}>{zhIntl('使用方式')}</div>
            <div className={styles.authRemark}>
              <div className={styles.remarkItem}>
                <span>{zhIntl('1. 下载支持双因素功能的应用，推荐使用')}</span>
                <span style={{ color: '#2EA498' }}>"Microsoft Authenticator"</span>
              </div>
              <div className={styles.remarkItem}>{zhIntl('2. 打开应用扫描上方的二维码，然后在下方填入安全码启用双重认证')}</div>
              <div className={styles.remarkItem}>{zhIntl('3. 下次登录时，填入您应用里显示的安全码，即可安全登入')}</div>
            </div>
          </div>
        </div>
      </div>

      <style jsx='true'>
        {`
          .ant-modal-body {
            padding: 0;
          }
          .ant-modal-content {
            border-radius: 16px;
          }
        `}
      </style>
    </Modal>
  )
}

export default SecurityBind
