import { message, Modal } from 'antd';
import { history as router, formatMessage } from 'umi';
import * as services from './service';
import { encryptAes } from '@/utils/encryption';
import { CLOUD_AES_KEY, INIT_PASSWORD } from '@/utils/constant';

export default {
  namespace: 'init',
  state: {
    activeId: '',
  },
  reducers: {
    save(state, { payload }) {
      return Object.assign({}, state, { ...payload });
    },
  },
  effects: {
    *submit({ payload }, { call, select }) {
      const { activeId } = yield select(state => state.init);
      const data = yield call(services.submit, {
        activeId,
        password: encryptAes(payload.password, CLOUD_AES_KEY),
        oldPassword: encryptAes(INIT_PASSWORD, CLOUD_AES_KEY),
      });
      if (data) {
        if (data.code === 0) {
          Modal.success({
            title: formatMessage({ id: 'login.prompt'}),
            content: formatMessage({ id: 'login.init.success.tip'}),
            onOk() {
              router.push('/login');
            },
          });
        } else {
          message.error(formatMessage({ id: data.msg }));
        }
      }
    },
  },
  subscriptions: {},
};
