import styles from './index.less';
import { formatMessage, getLocale } from 'umi';
import smt1 from '../../assets/help/smt1.png';
import smt2 from '../../assets/help/smt2.png';
import smt3 from '../../assets/help/smt3.png';
import smt4 from '../../assets/help/smt4.png';
import smt5 from '../../assets/help/smt5.png';
import smt6 from '../../assets/help/smt6.png';
import smt7 from '../../assets/help/smt7.png';
import smt8 from '../../assets/help/smt8.png';
import smt9 from '../../assets/help/smt9.png';
import smt10 from '../../assets/help/smt10.png';
import smt11 from '../../assets/help/smt11.png';
import smt12 from '../../assets/help/smt12.png';

import smt1en from '../../assets/help/smt1_en.png';
import smt2en from '../../assets/help/smt2_en.png';
import smt3en from '../../assets/help/smt3_en.png';
import smt4en from '../../assets/help/smt4_en.png';
import smt5en from '../../assets/help/smt5_en.png';
import smt6en from '../../assets/help/smt6_en.png';
import smt7en from '../../assets/help/smt7_en.png';
import smt8en from '../../assets/help/smt8_en.png';
import smt9en from '../../assets/help/smt9_en.png';
import smt10en from '../../assets/help/smt10_en.png';
import smt11en from '../../assets/help/smt11_en.png';
import smt12en from '../../assets/help/smt12_en.png';

import sws1 from '../../assets/help/sws1.png';
import sws3 from '../../assets/help/sws3.png';
import sws4 from '../../assets/help/sws4.png';
import sws5 from '../../assets/help/sws5.png';
import sws6 from '../../assets/help/sws6.png';
import sws7 from '../../assets/help/sws7.png';
import sws8 from '../../assets/help/sws8.png';
import sws9 from '../../assets/help/sws9.png';
import sws10 from '../../assets/help/sws10.png';
import sws11 from '../../assets/help/sws11.png';
import sws12 from '../../assets/help/sws12.png';
import sws13 from '../../assets/help/sws13.png';
import sws14 from '../../assets/help/sws14.png';
import sws15 from '../../assets/help/sws15.png';
import sws16 from '../../assets/help/sws16.png';
import sws17 from '../../assets/help/sws17.png';
import sws18 from '../../assets/help/sws18.png';

import sws1en from '../../assets/help/sws1_en.png';
import sws3en from '../../assets/help/sws3_en.png';
import sws4en from '../../assets/help/sws4_en.png';
import sws5en from '../../assets/help/sws5_en.png';
import sws6en from '../../assets/help/sws6_en.png';
import sws7en from '../../assets/help/sws7_en.png';
import sws8en from '../../assets/help/sws8_en.png';
import sws9en from '../../assets/help/sws9_en.png';
import sws10en from '../../assets/help/sws10_en.png';
import sws11en from '../../assets/help/sws11_en.png';
import sws12en from '../../assets/help/sws12_en.png';
import sws13en from '../../assets/help/sws13_en.png';
import sws14en from '../../assets/help/sws14_en.png';
import sws15en from '../../assets/help/sws15_en.png';
import sws16en from '../../assets/help/sws16_en.png';
import sws17en from '../../assets/help/sws17_en.png';
import sws18en from '../../assets/help/sws18_en.png';

import vst1 from '../../assets/help/vst1.png';
import vst2 from '../../assets/help/vst2.png';
import vst3 from '../../assets/help/vst3.png';
import vst4 from '../../assets/help/vst4.png';
import vst5 from '../../assets/help/vst5.png';
import vst6 from '../../assets/help/vst6.png';

import vst1en from '../../assets/help/vst1_en.png';
import vst2en from '../../assets/help/vst2_en.png';
import vst3en from '../../assets/help/vst3_en.png';
import vst4en from '../../assets/help/vst4_en.png';
import vst5en from '../../assets/help/vst5_en.png';
import vst6en from '../../assets/help/vst6_en.png';

import first1 from '../../assets/help/first1.png';
import first2 from '../../assets/help/first2.png';
import first3 from '../../assets/help/first3.png';
import first4 from '../../assets/help/first4.png';
import first5 from '../../assets/help/first5.png';
import first6 from '../../assets/help/first6.png';
import first7 from '../../assets/help/first7.png';

import first1en from '../../assets/help/first1_en.png';
import first2en from '../../assets/help/first2_en.png';
import first3en from '../../assets/help/first3_en.png';
import first4en from '../../assets/help/first4_en.png';
import first5en from '../../assets/help/first5_en.png';
import first6en from '../../assets/help/first6_en.png';
import first7en from '../../assets/help/first7_en.png';

const getImg = (file, css) => {
  const img = require('../../assets/help/' + file); // eslint-disable-line
  return {
    img,
    style: css || {},
  };
};

const firstData = [
  {
    title: formatMessage({ id: 'help.first.title1' }),
    data: [
      {
        stepTitle: '',
        info: [
          {
            content: `${formatMessage({ id: 'help.first.info1' })}${
              window.location.origin
            }${formatMessage({ id: 'help.first.info1.tip' })}`,
            imgSrc: getLocale() === 'en-US' ? first1en : first1,
          },
          {
            content: formatMessage({ id: 'help.first.info2' }),
            imgSrc: getLocale() === 'en-US' ? first2en : first2,
          },
          {
            content: formatMessage({ id: 'help.first.info3' }),
            imgSrc: getLocale() === 'en-US' ? first3en : first3,
          },
        ],
      },
    ],
    anchor: 'reserve',
  },
  {
    title: formatMessage({ id: 'help.first.title2' }),
    data: [
      {
        stepTitle: '',
        info: [
          {
            content: `${formatMessage({ id: 'help.first.info4' })}${
              window.location.origin
            }${formatMessage({ id: 'help.first.info4.tip' })}`,
            imgSrc: getLocale() === 'en-US' ? first4en : first4,
          },
          {
            content: formatMessage({ id: 'help.first.info5' }),
            imgSrc: getLocale() === 'en-US' ? first5en : first5,
          },
          {
            content: formatMessage({ id: 'help.first.info6' }),
            imgSrc: getLocale() === 'en-US' ? first6en : first6,
          },
        ],
      },
    ],
    anchor: 'search',
  },
  {
    title: formatMessage({ id: 'help.first.title3' }),
    data: [
      {
        stepTitle: '',
        info: [
          {
            content: formatMessage({ id: 'help.first.info7' }),
            imgSrc: getLocale() === 'en-US' ? first7en : first7,
          },
        ],
      },
    ],
    anchor: 'search',
  },
];

const mstData = [
  {
    title: formatMessage({ id: 'help.smt.title1' }),
    data: [
      {
        stepTitle: formatMessage({ id: 'help.smt.stitle1' }),
        info: [
          {
            content: formatMessage({ id: 'help.smt.info1' }),
            imgSrc: getLocale() === 'en-US' ? smt1en : smt1,
          },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.smt.stitle2' }),
        info: [
          {
            content: formatMessage({ id: 'help.smt.info2' }),
            imgSrc: getLocale() === 'en-US' ? smt2en : smt2,
          },
          {
            content: formatMessage({ id: 'help.smt.info3' }),
            imgSrc: getLocale() === 'en-US' ? smt3en : smt3,
          },
          {
            content: formatMessage({ id: 'help.smt.info4' }),
            imgSrc: getLocale() === 'en-US' ? smt4en : smt4,
          },
          { content: formatMessage({ id: 'help.smt.info5' }) },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.smt.stitle3' }),
        info: [
          {
            content: formatMessage({ id: 'help.smt.info6' }),
            imgSrc: getLocale() === 'en-US' ? smt5en : smt5,
          },
          {
            content: formatMessage({ id: 'help.smt.info7' }),
            imgSrc: getLocale() === 'en-US' ? smt6en : smt6,
          },
          {
            content: formatMessage({ id: 'help.smt.info8' }),
            imgSrc: getLocale() === 'en-US' ? smt7en : smt7,
          },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.smt.stitle4' }),
        info: [
          {
            content: `${formatMessage({ id: 'help.smt.info9' })}${
              window.location.origin
            }/login${formatMessage({ id: 'help.smt.info9.tip' })}`,
            imgSrc: getLocale() === 'en-US' ? smt8en : smt8,
          },
          {
            content: formatMessage({ id: 'help.smt.info10' }),
            imgSrc: getLocale() === 'en-US' ? smt9en : smt9,
          },
          {
            content: formatMessage({ id: 'help.smt.info11' }),
            imgSrc: getLocale() === 'en-US' ? smt10en : smt10,
          },
          {
            content: formatMessage({ id: 'help.smt.info12' }),
            imgSrc: getLocale() === 'en-US' ? smt11en : smt11,
          },
        ],
      },
    ],
    anchor: 'reserve',
  },
  {
    title: formatMessage({ id: 'help.smt.title2' }),
    data: [
      {
        stepTitle: '',
        info: [
          {
            content: formatMessage({ id: 'help.smt.info13' }),
            imgSrc: getLocale() === 'en-US' ? smt12en : smt12,
          },
        ],
      },
    ],
    anchor: 'search',
  },
];

const swsData = [
  {
    title: formatMessage({ id: 'help.sws.title1' }),
    data: [
      {
        stepTitle: formatMessage({ id: 'help.sws.stitle1' }),
        info: [
          {
            content: formatMessage({ id: 'help.sws.info1' }),
            imgSrc: getLocale() === 'en-US' ? sws1en : sws1,
          },
          {
            content: formatMessage({ id: 'help.sws.info3' }),
            imgSrc: getLocale() === 'en-US' ? sws3en : sws3,
          },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.sws.stitle2' }),
        info: [
          {
            content: formatMessage({ id: 'help.sws.info4' }),
            imgSrc: getLocale() === 'en-US' ? sws4en : sws4,
          },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.sws.stitle3' }),
        info: [
          {
            content: formatMessage({ id: 'help.sws.info5' }),
            imgSrc: getLocale() === 'en-US' ? sws5en : sws5,
          },
          {
            content: formatMessage({ id: 'help.sws.info6' }),
            imgSrc: getLocale() === 'en-US' ? sws6en : sws6,
          },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.sws.stitle4' }),
        info: [
          {
            content: formatMessage({ id: 'help.sws.info7' }),
            imgSrc: getLocale() === 'en-US' ? sws7en : sws7,
          },
          {
            content: formatMessage({ id: 'help.sws.info8' }),
            imgSrc: getLocale() === 'en-US' ? sws8en : sws8,
          },
          {
            content: formatMessage({ id: 'help.sws.info9' }),
            imgSrc: getLocale() === 'en-US' ? sws9en : sws9,
          },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.sws.stitle5' }),
        info: [
          {
            content: formatMessage({ id: 'help.sws.info10' }),
            imgSrc: getLocale() === 'en-US' ? sws10en : sws10,
          },
          {
            content: formatMessage({ id: 'help.sws.info11' }),
            imgSrc: getLocale() === 'en-US' ? sws11en : sws11,
          },
          {
            content: formatMessage({ id: 'help.sws.info12' }),
            imgSrc: getLocale() === 'en-US' ? sws12en : sws12,
          },
          {
            content: formatMessage({ id: 'help.sws.info13' }),
            imgSrc: getLocale() === 'en-US' ? sws13en : sws13,
          },
        ],
      },
      {
        stepTitle: formatMessage({ id: 'help.sws.stitle6' }),
        info: [
          {
            content: `${formatMessage({ id: 'help.sws.info14' })}${
              window.location.origin
            }/login${formatMessage({ id: 'help.sws.info14.tip' })}`,
            imgSrc: getLocale() === 'en-US' ? sws14en : sws14,
          },
          {
            content: formatMessage({ id: 'help.sws.info15' }),
            imgSrc: getLocale() === 'en-US' ? sws15en : sws15,
          },
          {
            content: formatMessage({ id: 'help.sws.info16' }),
            imgSrc: getLocale() === 'en-US' ? sws16en : sws16,
          },
          {
            content: formatMessage({ id: 'help.sws.info17' }),
            imgSrc: getLocale() === 'en-US' ? sws17en : sws17,
          },
        ],
      },
    ],
    anchor: 'reserve',
  },
  {
    title: formatMessage({ id: 'help.sws.title2' }),
    data: [
      {
        stepTitle: '',
        info: [
          {
            content: formatMessage({ id: 'help.sws.info18' }),
            imgSrc: getLocale() === 'en-US' ? sws18en : sws18,
          },
        ],
      },
    ],
    anchor: 'search',
  },
];

const vstData = [
  {
    title: formatMessage({ id: 'help.vst.title1' }),
    data: [
      {
        stepTitle: formatMessage({ id: 'help.vst.stitle1' }),
        info: [
          {
            content: formatMessage({ id: 'help.vst.info1' }),
            imgSrc: getLocale() === 'en-US' ? vst1en : vst1,
          },
          {
            content: formatMessage({ id: 'help.vst.info2' }),
            imgSrc: getLocale() === 'en-US' ? vst2en : vst2,
          },
          {
            content: formatMessage({ id: 'help.vst.info3' }),
            imgSrc: getLocale() === 'en-US' ? vst3en : vst3,
          },
        ],
      },
    ],
    anchor: 'reserve',
  },
  {
    title: formatMessage({ id: 'help.vst.title2' }),
    data: [
      {
        stepTitle: '',
        info: [
          {
            content: formatMessage({ id: 'help.vst.info4' }),
            imgSrc: getLocale() === 'en-US' ? vst4en : vst4,
          },
          {
            content: formatMessage({ id: 'help.vst.info5' }),
            imgSrc: getLocale() === 'en-US' ? vst5en : vst5,
          },
        ],
      },
    ],
    anchor: 'search',
  },
  {
    title: formatMessage({ id: 'help.vst.title3' }),
    data: [
      {
        stepTitle: '',
        info: [
          {
            content: formatMessage({ id: 'help.vst.info6' }),
            imgSrc: getLocale() === 'en-US' ? vst6en : vst6,
          },
        ],
      },
    ],
    anchor: 'search',
  },
];

export const helpData = {
  title: '本文为您介绍一系列入门操作，方便您快速上手和使用。',
  data: [
    /* {
      question: '员工通讯录头像暂时没有时间修改，如何展现？',
      answer: {
        text: '威思客系统可以自动使用员工姓名生成可爱头像。',
        imgs: [
          getImg('1.png'),
        ],
      }
    }, */
    /* {
      question: '怎么注册用户？',
      answer: {
        text: '进入官网 https://v.virsical.cn 后，点击”立即体验”，提交信息后，即可注册。',
        imgs: [getImg('2.png'), getImg('3.png', { width: 350 })],
      },
    }, */
    {
      question: '如何购买产品？',
      answer: {
        text: '点击”立即体验”后，选择”购买使用”选项，等待客服联系并支付后，开通服务，客服电话400-685-3160。',
        imgs: [getImg('4.png', { width: 300 })],
      },
    },
    {
      question: '密码忘记怎么办？',
      answer: {
        text: '可以在登录界面，点击”忘记密码”，系统会发送重置密码邮件，重置密码后即可登录。',
        imgs: [
          getImg('5.png', { width: 300 }),
          // getImg('6.png', { width: 350 }),
          // getImg('7.png', { width: 350 }),
        ],
      },
    },
    {
      question: '是否支持多语言？',
      answer: {
        text: '支持，支持简体中文、英文环境，跟随系统语言变更，android可在APP的设置中自定义语言。',
        imgs: [],
      },
    },
    {
      question: '短信数不足怎么办？',
      answer: {
        text: '联系客服：400-685-3160。',
        imgs: [],
      },
    },
    {
      question: '想更换当前的版本怎么办？',
      answer: {
        text: '联系客服：400-685-3160。',
        imgs: [],
      },
    },
    {
      question: '如果不想发送短信或者邮件，在哪里可以关闭？',
      answer: {
        text: '用户进入后台管理后，可以在配置管理的通知方式里关闭短信或者邮件的开关。',
        imgs: [getImg('8.png')],
      },
    },
    {
      question: '怎么阻止推销人员、不良记录人员预约或者被邀请？',
      answer: {
        text: '用户进入后台管理后，可以在配置管理的黑名单里添加此类人员，在预约或者邀请人员时，如果该人员被添加至黑名单，则无法进行预约或者邀请。',
        imgs: [getImg('9.png')],
      },
    },
    {
      question: '一次想邀请多个访客怎么办？',
      answer: {
        text: '添加一个访客后，可以点击右侧的加号，继续添加访客',
        imgs: [getImg('10.png', { width: 400 })],
      },
    },
    {
      question: '邀请访客的数量比较大时，有更快捷的方法吗？',
      answer: {
        text: '可以在邀请里批量导入所有访客；也可以使用团体邀请，访客自助登记。',
        imgs: [getImg('11.png'), getImg('12.png')],
      },
    },
  ],
};

export const Firstuse = () => {
  return firstData?.map((item, index) => {
    return (
      <div key={index.toString()} className={styles.productArea}>
        <div className={styles.title} id={item.anchor}>
          {item.title}
        </div>
        {item.data?.map((it, indx) => {
          return (
            <div key={indx.toString()}>
              {it.stepTitle && <div className={styles.stepTitle}>{it.stepTitle}</div>}
              {it.info?.map((v, i) => {
                return (
                  <div key={i.toString()}>
                    {v.content && <div className={styles.content}>{v.content}</div>}
                    {v.imgSrc && <img className={styles.imgArea} src={v.imgSrc} />}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  });
};

export const Meeting = () => {
  return mstData?.map((item, index) => {
    return (
      <div key={index.toString()} className={styles.productArea}>
        <div className={styles.title} id={item.anchor}>
          {item.title}
        </div>
        {item.data?.map((it, indx) => {
          return (
            <div key={indx.toString()}>
              {it.stepTitle && <div className={styles.stepTitle}>{it.stepTitle}</div>}
              {it.info?.map((v, i) => {
                return (
                  <div key={i.toString()}>
                    <div className={styles.content}>{v.content}</div>
                    {v.imgSrc && <img className={styles.imgArea} src={v.imgSrc} />}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  });
};

export const Workspace = () => {
  return swsData?.map((item, index) => {
    return (
      <div key={index.toString()} className={styles.productArea}>
        <div className={styles.title} id={item.anchor}>
          {item.title}
        </div>
        {item.data?.map((it, indx) => {
          return (
            <div key={indx.toString()}>
              {it.stepTitle && <div className={styles.stepTitle}>{it.stepTitle}</div>}
              {it.info?.map((v, i) => {
                return (
                  <div key={i.toString()}>
                    <div className={styles.content}>{v.content}</div>
                    {v.imgSrc && <img className={styles.imgArea} src={v.imgSrc} />}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  });
};

export const Vistor = () => {
  return vstData?.map((item, index) => {
    return (
      <div key={index.toString()} className={styles.productArea}>
        <div className={styles.title} id={item.anchor}>
          {item.title}
        </div>
        {item.data?.map((it, indx) => {
          return (
            <div key={indx.toString()}>
              {it.stepTitle && <div className={styles.stepTitle}>{it.stepTitle}</div>}
              {it.info?.map((v, i) => {
                return (
                  <div key={i.toString()}>
                    <div className={styles.content}>{v.content}</div>
                    {v.imgSrc && <img className={styles.imgArea} src={v.imgSrc} />}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  });
};
