export default {
  'hardware.banner1':
    '依靠AIOT技术，充分利用各类传感器，实时采集多维数据，实时反馈自适，创造一个“以人为本”的办公新生态，将“人-物-空间-环境”实现智慧联结和管理，全方位实现办公数智化。',
  'hardware.title': '硬件设备',
  // 硬件产品第一行
  'hardware.row1.1.title': '窄边框会议展板（标准版）',
  'hardware.row1.1.info1': '10.1寸IPS，1920*1200分辨率',
  'hardware.row1.1.info2': '10点电容式触摸屏',
  'hardware.row1.1.info3': '四核处理器 Arm Cortex-A17，Mali-T764 GPU',
  'hardware.row1.1.info4': '2GB内存、支持无线及蓝牙',

  'hardware.row1.2.title': '窄边框会议展板（人脸识别版）',
  'hardware.row1.2.info1': '10.1寸IPS，1920*1200分辨率',
  'hardware.row1.2.info2': '300W高清摄像头',
  'hardware.row1.2.info3': '支持FID card read、支持ZIGBEE网关',
  'hardware.row1.2.info4': '10点电容式触摸屏',
  'hardware.row1.2.info5': '1四核处理器 Arm Cortex-A17，Mali-T764 GPU',
  'hardware.row1.2.info6': '2GB内存、支持无线及蓝牙',

  'hardware.row1.3.title': '轻薄会议展板',
  'hardware.row1.3.info1': '10.1寸IPS，1280*800分辨率',
  'hardware.row1.3.info2': '200W高清摄像头',
  'hardware.row1.3.info3': '支持LED',
  'hardware.row1.3.info4': '多点触摸屏',
  'hardware.row1.3.info5': '四核处理器 Arm Cortex-A17',
  'hardware.row1.3.info6': '2GB内存、支持无线',

  // 硬件产品第二行
  'hardware.row2.1.title': '访客一体机',
  'hardware.row2.1.info1': '21.5寸、32寸显示器',
  'hardware.row2.1.info2': 'Windows、安卓系统',
  'hardware.row2.1.info3': '支持身份证读卡器、二维码读卡器',
  'hardware.row2.1.info4': '活体双目摄像头',
  'hardware.row2.1.info5': '访客证打印机',

  'hardware.row2.2.title': '桌面一体机',
  'hardware.row2.2.info1': '13.3寸IPS双面屏，1920*1080分辨率',
  'hardware.row2.2.info2': '支持护照扫描摄像头',
  'hardware.row2.2.info3': '二维码扫描摄像头',
  'hardware.row2.2.info4': '支持身份证读卡器',
  'hardware.row2.2.info5': '支持无线连接',

  'hardware.row2.3.title': '播放盒',
  'hardware.row2.3.info1': '四核处理器 Arm Cortex-A17，Mali-T764 GPU',
  'hardware.row2.3.info2': '2GB内存',
  'hardware.row2.3.info3': '支持LVDS、EDP显示屏接口, HDMI接口',
  'hardware.row2.3.info4': '支持无线连接',
  'hardware.row2.3.info5': '支持蓝牙',

  // 硬件产品第三行
  'hardware.row3.1.title': '汉朔水墨屏会议展板',
  'hardware.row3.1.info1': '7.5寸版本',
  'hardware.row3.1.info2': 'EPD点阵电子纸',
  'hardware.row3.1.info3': '电池工作寿命5年',

  'hardware.row3.2.title': '汉朔10.2寸',
  'hardware.row3.2.info1': '10.2寸版本',
  'hardware.row3.2.info2': 'EPD点阵电子纸',
  'hardware.row3.2.info3': '电池工作寿命5年',

  'hardware.row3.3.title': '电子工牌基站',
  'hardware.row3.3.info1': '4路天线并行通信',
  'hardware.row3.3.info2': '基站通信半径15米',
  'hardware.row3.3.info3': '私有协议Esl-Working V2.0',
  'hardware.row3.3.info4': '基站支持POE和AC适配器供电',

  // 硬件产品第四行
  'hardware.row4.1.title': '高清播放盒',
  'hardware.row4.1.info1': '八核处理器 Mali-T764 GPU，4GB内存',
  'hardware.row4.1.info2': '支持两路 EDP 接口支持 4K eDP 显示接口',
  'hardware.row4.1.info3': '支持无线、蓝牙',

  'hardware.row4.2.title': '人体感应器',
  'hardware.row4.2.info1': '绿米',
  'hardware.row4.2.info2': 'ZigBee协议',
  'hardware.row4.2.info3': '网关覆盖半径10米',

  'hardware.row4.3.title': '智能插座',
  'hardware.row4.3.info1': '南京物联',
  'hardware.row4.3.info2': 'ZigBee协议',
  'hardware.row4.3.info3': '网关覆盖半径10米',

  // 硬件产品第五行
  'hardware.row5.1.title': '触摸大屏一体机',
  'hardware.row5.1.info1': '55寸显示屏',

  'hardware.row5.2.title': '触摸大屏一体机',
  'hardware.row5.2.info1': '65寸显示屏',

  'hardware.row5.3.title': '触摸大屏一体机',
  'hardware.row5.3.info1': '75寸显示屏',

  // 硬件产品第六行
  'hardware.row6.1.title': '触摸大屏一体机',
  'hardware.row6.1.info1': 'X86S — 86寸显示屏',
  'hardware.row6.1.info2': 'X98S — 98寸显示屏',

  'hardware.row6.2.title': '厕位占用门锁',

  'hardware.row6.3.title': '厕位占用指示牌',

  // 机器人
  'hardware.banner2':
    '以安卓为基础的OS系统支持对不同功能开发的无限可能性。如办公、医疗看护、酒店、零售等场景',
  'hardware.product.title': '产品介绍',
  'hardware.product.tip1':
    '内置两大系统，一个是可视化的用户操作系统，另一个便是赋予访客机器人自主移动的ROBOX导航系统，配合全身上下多达60个传感器，包括360度的激光雷达、深感摄像头、飞行时间线性传感器等。它可以实时感知周围环境，构建二维地图，“聪明”地规划自己的行动路线，自主规避各种障碍物，实现主动移动。',
  'hardware.product.tip2':
    '具备接受语音指令、面部识别功能，可在播放音乐或者视频的同时自动识别使用者面孔，并随着使用者蹲下或起身来调整屏幕上下倾斜的角度，跟随使用者走动、转弯。',
  'hardware.product.tip3':
    '集成了人工智能和人机交互技术。在办公场所、零售商店、酒店、医院、政务大厅、餐饮以及教育等领域被大量投入使用。',
  'hardware.param': '参数配置',
  'hardware.func': '功能',
  'hardware.func.yingbin': '迎宾接待',
  'hardware.func.gensui': '智能跟随',
  'hardware.func.renlian': '人脸识别',
  'hardware.func.jiangjie': '引导讲解',
  'hardware.func.zixun': '问答咨询',
  'hardware.func.xunhang': '巡航宣传',
  'hardware.func.hudong': '互动娱乐',

  'hardware.func.zhongkong': '智能中控',
  'hardware.func.huichong': '自动回充',
  'hardware.func.shipin': '视频通话',
  'hardware.func.yuancheng': '远程控制',
  'hardware.func.music': '在线音乐',
  'hardware.func.news': '新闻资讯',
  'hardware.func.sheying': '录像摄影',

  'hardware.param.patent': '专利技术',
  'hardware.param.patent.1.msg1': 'ROBOX导航系统',
  'hardware.param.patent.1.msg2': '人机交互及自主导航规划',
  'hardware.param.patent.2.msg1': '二维地图构建',
  'hardware.param.patent.2.msg2': '自主导航',
  'hardware.param.patent.2.msg3': '用户检测及追踪',
  'hardware.param.patent.2.msg4': '智能避障',
  'hardware.param.patent.2.msg5': '路径规划',
  'hardware.param.patent.2.msg6': '面部轮廓识别',
  'hardware.param.patent.3.msg1': '实时传感器融合和数据分析确保全自动导航的安全性',
  'hardware.param.patent.3.msg2': '全自动导航系统',

  'hardware.param.system': '操作系统',
  'hardware.param.system.1.msg1':
    '访客机器人通过听说读看的能力，始终保持学习状态，更好地服务用户，提供极度直观的操作方式。',
  'hardware.param.system.2.title': '语音交互',
  'hardware.param.system.2.msg1':
    '访客机器人使用自动语音识别技术和远场语音识别技术（ASR）、自然语言处理（NLP）以及文本到语音（TTS）引擎实现听取、识别、理解和响应用户的语音指令等功能。',
  'hardware.param.system.3.title': '用户界面',
  'hardware.param.system.3.msg1':
    '访客机器人定制的高清显示屏让你的所说，所听，所见都有增强的视听效果，以获得极致的人机交互体验。',
  'hardware.param.system.4.msg1':
    '访客机器人集成了多个顶尖的人工智能引擎，通过这些引擎的相互协作为您提供最好的服务。它的导航系统可以自我学习周围的环境，了解机器人在哪里，它看到什么，它要去哪里。',
  'hardware.param.system.5.title': '开放性',
  'hardware.param.system.5.msg1': '对第三方开放的应用程序',
  'hardware.param.system.5.msg2': '提供SDK（软件开发工具包）',

  'hardware.param.program': '内置程序',
  'hardware.param.program.ai': 'AI助手',
  'hardware.param.program.home': '访客机器人到家',
  'hardware.param.program.tonghua': '视频通话',
  'hardware.param.program.news1': '新闻资讯',
  'hardware.param.program.dj': '私人DJ',
  'hardware.param.program.sheying1': '无忧摄影',
  'hardware.param.program.help': '移动帮手',

  'hardware.param.connection': '连接',
  'hardware.param.connection.2.title': '蓝牙',
  'hardware.param.connection.2.msg1': '具备蓝牙4.0短距离无线连接设备和配件的能力',

  'hardware.param.size': '尺寸',
  'hardware.param.size.1.title': '大小',
  'hardware.param.size.1.msg1': '长：45cm',
  'hardware.param.size.1.msg2': '宽：35cm',
  'hardware.param.size.1.msg3': '高：100cm',
  'hardware.param.size.2.title': '重量',
  'hardware.param.size.2.msg1': '26磅/12kg',

  'hardware.param.display': '屏幕显示',
  'hardware.param.display.1.title': '10.1寸LCD高清显示',
  'hardware.param.display.1.msg1': '电容式多点触摸屏',
  'hardware.param.display.1.msg2': '具有1600万色的IPS液晶显示屏（1920X1200）',
  'hardware.param.display.1.msg3': '触摸屏像素密度：224（ppi）',
  'hardware.param.display.2.msg1': '最大亮度340 cd / m²（典型值）',
  'hardware.param.display.2.msg2': '防指纹涂层',

  'hardware.param.tiltable': '可倾斜屏幕',
  'hardware.param.tiltable.1.msg1': '自动面部追踪倾斜屏幕角度',
  'hardware.param.tiltable.1.msg2': '倾斜角度：-15°～+ 55°',

  'hardware.param.micro': '麦克风',
  'hardware.param.micro.1.msg1': '麦克风阵列',
  'hardware.param.micro.1.msg2': '4个全向数字麦克风',
  'hardware.param.micro.1.msg3': '实时定位',
  'hardware.param.micro.1.msg4': '波束成型',
  'hardware.param.micro.1.msg5': '声学回声消除',
  'hardware.param.micro.1.msg6': '环境降噪',

  'hardware.param.audio': '音频',
  'hardware.param.audio.1.msg1': '20W喇叭音频输出',
  'hardware.param.audio.1.msg2': '高保真均衡器',
  'hardware.param.audio.1.msg3': '1个低音炮',
  'hardware.param.audio.1.msg4': '2个中音扬声器',
  'hardware.param.audio.1.msg5': '2个高音扬声器',

  'hardware.param.webcam': '摄像头',
  'hardware.param.webcam.1.msg1': '1300万像素高分辨率摄像头',
  'hardware.param.webcam.1.msg2': '自动对焦',
  'hardware.param.webcam.1.msg3': '每秒30帧视频录制',
  'hardware.param.webcam.1.msg4': '5个透镜',
  'hardware.param.webcam.1.msg5': '红外线滤镜',
  'hardware.param.webcam.2.title': '广角摄像头',
  'hardware.param.webcam.2.msg1': '用于远程导航的1300万像素广角摄像头',
  'hardware.param.webcam.2.msg2': '支持1080P',
  'hardware.param.webcam.3.title': '深度摄像头',
  'hardware.param.webcam.3.msg1': '每秒30帧',
  'hardware.param.webcam.3.msg2': '最大可达5米',
  'hardware.param.webcam.3.msg3': '90°视场角',

  'hardware.param.cpu.1.msg1': '四核ARM处理器',
  'hardware.param.cpu.1.msg2': '人机交互系统基于安卓架构搭建',
  'hardware.param.cpu.1.msg3': '支持1080P@给你专属用户体验',
  'hardware.param.cpu.2.msg1': '六核ARM处理器',
  'hardware.param.cpu.2.msg2': '基于ARM架构的自主导航系统@每秒30帧视频录制',
  'hardware.param.cpu.2.msg3': '低能耗、高性能',

  'hardware.param.battery': '电池',
  'hardware.param.battery.1.msg1': '满电情况下可工作8小时',
  'hardware.param.battery.1.msg2': '自动充电 – 220V / 110V',
  'hardware.param.battery.1.msg3': '高性能充电桩',
  'hardware.param.battery.1.msg4': '锂离子电池',

  'hardware.param.mobility': '移动性',
  'hardware.param.mobility.1.msg1': '2个独立的50W直驱式无刷直流电机驱动系统',
  'hardware.param.mobility.2.title': '速度',
  'hardware.param.mobility.2.msg1': '最高可达1米每秒',

  'hardware.param.tray': '托盘',
  'hardware.param.tray.1.title': '托盘',
  'hardware.param.tray.1.msg1': '面积：30×25cm 最大可承受3KG重物',
  'hardware.param.tray.2.title': '无线充电',
  'hardware.param.tray.2.msg1': '内置标准无线充电器，可供手机充电',

  'hardware.param.package': '包装内容',
  'hardware.param.package.1.msg1': '1个访客机器人',
  'hardware.param.package.1.msg2': '1个充电桩+充电线',
  'hardware.param.package.1.msg3': '说明书',
};
