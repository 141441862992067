import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import moment from 'moment';
import { Row, Col, Button, DatePicker, message } from 'antd';
import { formatMessage } from 'umi';
import OmsTable from '@/components/OmsTable';
import * as service from '../service';
import styles from '../index.less';

const { RangePicker } = DatePicker;
const SmsList = ({ token, companyName }) => {
  const columns = [
    {
      title: formatMessage({ id: 'help.sms.account' }),
      dataIndex: 'phone',
      width: 150,
    },
    {
      title: formatMessage({ id: 'help.sms.num' }),
      dataIndex: 'smsNum',
      width: 150,
    },
    {
      title: formatMessage({ id: 'help.sms.send.time' }),
      dataIndex: 'sendTime',
      width: 180,
      render: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: formatMessage({ id: 'help.sms.send.content' }),
      dataIndex: 'content',
      ellipsis: true,
      render: (t) => (
        <div
          title={t}
          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
        >
          {t}
        </div>
      ),
    },
  ];

  const [filters, setFilters] = useState({ current: 1, size: 10, token });
  const [list, setList] = useState([]);
  const [time, setTime] = useState('');
  const [loading, setLoading] = useState(false);

  // 获取列表
  const { run: getSmsList } = useRequest(() => service.smsList(filters), {
    manual: false,
    onSuccess: (res) => {
      if (res?.code === 0) {
        setList(res?.data?.records);
        setFilters({ ...filters, total: res?.data?.total });
        setLoading(false);
      } else {
        message.error(formatMessage({ id: res?.msg }));
      }
    },
    onBefore: () => setLoading(true),
  });

  // 导出列表
  const { run: exportSmsList } = useRequest(() => service.exportSmsList(filters), {
    manual: true,
    onSuccess: (res) => {
      if (res?.code === 0) {
        message.success(formatMessage({ id: 'help.sms.operate.success' }));
      }
    },
  });

  // 导出检测
  const { run: exportCheck } = useRequest(() => service.exportCheck(filters), {
    manual: true,
    onSuccess: (res) => {
      if (res?.code === 0) {
        exportSmsList();
        getSmsList();
      } else {
        message.error(formatMessage({ id: res.msg }));
        setLoading(false);
      }
    },
    onBefore: () => setLoading(true),
  });

  const pagination = {
    onChange(page) {
      setFilters({ ...filters, current: page });
      setTimeout(() => {
        getSmsList();
      }, 200);
    },
  };

  return (
    <div className={styles.smsList}>
      <div className={styles.title}>{formatMessage({ id: 'help.sms.title' })}</div>
      <div>
        <Row style={{ marginBottom: 20 }}>
          <Col span={8} className={styles.companyName}>
            <span style={{ color: '#4b4b4b' }}>
              {formatMessage({ id: 'help.sms.company.name' })}:{' '}
            </span>
            <span>{companyName || ''}</span>
          </Col>
          <Col span={8}>
            <span>{formatMessage({ id: 'help.sms.time' })}: </span>
            <RangePicker
              style={{ width: 350 }}
              value={time}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  startSendTime: e?.[0]?.startOf('day')?.valueOf(),
                  endSendTime: e?.[1]?.endOf('day')?.valueOf(),
                });
                setTime(e);
              }}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button type="primary" className={styles.btn} onClick={() => getSmsList()}>
              {formatMessage({ id: 'help.sms.search' })}
            </Button>
            <Button
              className={styles.btn}
              onClick={() => {
                setTime('');
                setFilters({ current: 1, size: 10, token });
                setTimeout(() => {
                  getSmsList();
                }, 200);
              }}
            >
              {formatMessage({ id: 'help.sms.reset' })}
            </Button>
            <Button className={styles.btn} onClick={() => exportCheck()}>
              {formatMessage({ id: 'help.sms.export' })}
            </Button>
          </Col>
        </Row>
        <OmsTable
          style={{ width: '100%' }}
          loading={loading}
          filter={filters}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          rowKey={(r) => r.id}
        />
        <div style={{ marginTop: -45 }}>{formatMessage({ id: 'help.sms.table.tip' })}</div>
      </div>
    </div>
  );
};

export default SmsList;
