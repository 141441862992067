export default {
  'case.tabs.tabs1':"企業",
  'case.tabs.tabs2':"遊戲",
  'case.tabs.tabs3':"視頻",
  'case.tabs.tabs4':"互聯網",
  'case.tabs.tabs5':"金融",
  'case.tabs.tabs6':"醫療",
  'case.tabs.tabs7':"教育",
  'case.tabs.tabs8':"政務",
  'case.header.title':"行業案例",
  'case.header.title.subtitle':"威思客雲系統 助力行業升級",
  'case.header.title.subtitle.top':"客戶覆蓋《財富》500強60%以上的大型企業及多家中小型企業遍及",
  'case.header.title.subtitle.btm':"互聯網、金融、教育、醫療等多個",
  'case.header.btn.go':"立即體驗",
  'case.all.industry.cases':"全部案例",
  'case.tabs.sub.tabs1.title':"菲仕蘭",
  'case.tabs.sub.tabs2.title':"阿里雲",
  'case.tabs.sub.tabs3.title':"美團點評",
  'case.tabs.sub.tabs1.text':'威思客攜手全球知名企業菲仕蘭乳製品公司，滿足其訪客預約、簽到、訪客信息管理統計的需求，菲仕蘭可按照辦公地統計訪客量制定科學的訪客管理策略，賦予訪客人性化的接待體驗，展現了智能科技的魅力。',
  'case.tabs.sub.tabs2.text':'威思客作為數字化空間管理解決方案領導者，牽手阿里雲創新中心，幫助園區運營者及阿里雲創客平台自身更好管理運營創業孵化平台！',
  'case.tabs.sub.tabs3.text':'根據美團建設智能樓宇的企業場景需求，以及達成訪客通行授權、門禁權限管理控制的目標，威思客為美團成功落地訪客自動預約，實現了訪客管理模式更新轉換，打通智能通行的渠道。',
}
