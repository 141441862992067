import React, { useState } from 'react';
import { formatMessage, getLocale, history, connect } from 'umi';
import { useRequest, useMount } from 'ahooks';
import { Form, Input, Select, Button, message } from 'antd';
import SmsList from './components/SmsList';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import VerifySlideFixed from '@/components/SliderVerify';
import { countryAreaCode } from '@/utils/constant';
import loginCn from '@/assets/login/loginCn.png';
import loginEn from '@/assets/login/loginEn.png';
import { zhIntl } from '@/utils/intlUtils';
import * as service from './service';
import styles from './index.less';

const SmsQuery = ({ dispatch, cloud }) => {
  const [form] = Form.useForm();
  const { phoneAreaCode } = cloud;

  const [account, setAccount] = useState('');
  const [areaCode, setAreaCode] = useState(
    sessionStorage.getItem('isChina') === 'true' ? '+86' : '+852',
  );
  const [codeText, setCodeText] = useState(formatMessage({ id: 'register.Get.verification.code' }));
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [codeStyle, setCodeStyle] = useState('');
  const [validCode, setValidCode] = useState(true);
  const [showSlider, setShowSlider] = useState(false);

  const noSendSms = (time = 60, check = false) => {
    if (check) {
      const maxTime = localStorage.getItem('regisSmsTime');
      if (!maxTime) return;
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) return;
    } else {
      localStorage.setItem('regisSmsTime', Date.now() + time * 1000);
    }
    setValidCode(false);
    setCodeStyle(styles.noClick);
    window.tVal = setInterval(() => {
      const maxTime = localStorage.getItem('regisSmsTime');
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) {
        clearInterval(window.tVal);
        setValidCode(true);
        setCodeStyle('');
        setCodeText(formatMessage({ id: 'register.locales.verification.code' }));
        localStorage.removeItem('regisSmsTime');
      } else {
        setCodeText(`${hasTime}${formatMessage({ id: 'register.Resend.in.seconds' })}`);
      }
    }, 1000);
  };

  // 获取验证码
  const { run: sendCode } = useRequest((params) => service.sendCode(params), {
    manual: true,
    onSuccess: ({ code, msg }) => {
      if (code === 0) {
        message.success(formatMessage({ id: 'help.sms.get.success' }));
        noSendSms();
      } else {
        message.error(formatMessage({ id: msg }));
      }
    },
  });

  // 短信认证
  const { run: smsLogin } = useRequest((params) => service.smsLogin(params), {
    manual: true,
    onSuccess: ({ code, msg, data }) => {
      if (code === 0) {
        message.success(formatMessage({ id: 'help.sms.search.success' }));
        setToken(data?.token);
        setCompanyName(data?.smsTenantName);
        setShowList(true);
      } else {
        message.error(formatMessage({ id: msg }));
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  });

  useMount(() => {
    dispatch({ type: 'cloud/getAreaCode' });
  });

  return (
    <div className={styles.body}>
      {!showList ? (
        <>
          <img src={getLocale() === 'en-US' ? loginEn : loginCn} className={styles.img} />
          <div className={styles.content}>
            <div className={styles.area}>
              <div className={styles.title}>{formatMessage({ id: 'help.sms.title' })}</div>
              <div className={styles.form}>
                <Form layout="vertical" form={form}>
                  <Form.Item
                    name="account"
                    rules={[
                      { required: true, message: formatMessage({ id: 'login.account.tip' }) },
                    ]}
                  >
                    <Input.Group compact style={{ display: 'flex' }}>
                      {Number(account).toString().length < 6 || account.includes('@') ? null : (
                        <Select
                          value={areaCode}
                          key="login-phone"
                          onChange={(e) => setAreaCode(e)}
                          optionLabelProp="label"
                        >
                          {countryAreaCode(phoneAreaCode)}
                        </Select>
                      )}
                      <Input
                        style={{
                          width:
                            Number(account).toString().length < 6 || account.includes('@')
                              ? '100%'
                              : 'calc(100% - 80)',
                        }}
                        allowClear
                        value={account}
                        maxLength={50}
                        placeholder={formatMessage({ id: 'help.sms.account' })}
                        onChange={(e) => {
                          setAccount(e.target.value);
                          form.setFieldsValue({ account: e.target.value });
                        }}
                      />
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    name="code"
                    rules={[
                      { required: true, message: formatMessage({ id: 'login.verify.code.tip' }) },
                    ]}
                  >
                    <Input
                      style={{ textIndent: 11 }}
                      placeholder={formatMessage({ id: 'login.verify.code.tip' })}
                      maxLength={6}
                      suffix={
                        <a
                          className={codeStyle}
                          onClick={() => {
                            form.validateFields(['account']).then(() => {
                              if (validCode) {
                                setShowSlider(true);
                              }
                            });
                          }}
                        >
                          {codeText}
                        </a>
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      style={{ width: '100%', height: 55, fontSize: 18, fontWeight: 500 }}
                      loading={loading}
                      onClick={() => {
                        form.validateFields(['account', 'code']).then((v) => {
                          setLoading(true);
                          smsLogin({
                            account: account.includes('@')
                              ? account.trim()
                              : `${areaCode}${account.trim()}`,
                            code: v.code,
                          });
                        });
                      }}
                    >
                      {formatMessage({ id: 'help.sms.search' })}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <a className={styles.back} onClick={() => history.push('/')}>
              {zhIntl('返回首页')}
            </a>
          </div>
        </>
      ) : (
        <div className={styles.smsQuery}>
          <Header />
          <div className={styles.smsContent}>
            <SmsList token={token} companyName={companyName} />
          </div>
          <Footer />
        </div>
      )}

      {showSlider && (
        <VerifySlideFixed
          isSlideShow={showSlider}
          dispatch={dispatch}
          verifyPointFixedChild={(data) => setShowSlider(data)}
          onCheckSuccess={(value) => {
            if (value) {
              setTimeout(() => {
                setShowSlider(false);
                if (validCode) {
                  setCodeStyle(styles.noClick);
                  sendCode({
                    verification: account.includes('@')
                      ? account.trim()
                      : `${areaCode}${account.trim()}`,
                    source: 5,
                    randomStr: 'captcha',
                    code: value,
                  });
                }
              }, 800);
            }
          }}
        />
      )}

      <style>
        {`
          .ant-select-dropdown {
            width: auto !important;
            border-radius: 14px !important;
            padding: 16px 0 !important;
          }
        `}
      </style>
    </div>
  );
};

export default connect((s) => s)(SmsQuery);
