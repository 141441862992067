export default {
  'index.title': 'Virsical cloud, empowering enterprise smart office',
  'index.title.tip':
    "Committed to digitizing and intelligentizing the entire process of the enterprise's office scene, building an office scene in the cloud era, and helping efficient office work.",
  'index.visitor': 'Smart Visitor',
  'index.visitor.tip':
    'Intelligent and safe scenario-based cloud services to help upgrade the visitor experience',
  'index.meeting': 'Smart Meeting',
  'index.meeting.tip':
    'Reserve meeting anytime and anywhere, make intelligent and efficient meeting experience',
  'index.space': 'Smart Workspace',
  'index.space.tip': 'Visual IOT integration platform, one-stop intelligent overall solution',
  'index.fm': 'Meta FM',
  'index.fm.tip':
    'An online, process, data, intelligent, wisdom, building facilities comprehensive operational management platform',
  'index.esg': 'Meta E',
  'index.esg.tip':
    'With the goal of "carbon neutrality, carbon peak", through comprehensive supervision of a variety of equipment, using digital means to save energy and increase efficiency for enterprises',
  'index.bdp': 'Meta View',
  'index.bdp.tip':
    'One-stop intelligent data platform to drive enterprise decision-making with big data, optimize resources, and improve efficiency',
  'index.experience': 'Experience Now',
  'index.coming': 'Coming Soon',
  'index.advantage': 'Our Advantage',
  'index.case': 'Customer Case',
  'index.advantage.name1': 'Products are more focused',
  'index.advantage.name2': 'Data is safer',
  'index.advantage.name3': 'Lower business costs',
  'index.advantage.name4': 'Service more assured',
  'index.advantage.desc1':
    'Focusing on office scenarios for more than ten years, trusted by more than a thousand companies, helping companies smart office',
  'index.advantage.desc2':
    'Virsical Cloud and Alibaba Cloud provide strict data security services to protect your data privacy and security',
  'index.advantage.desc3':
    'Buy on-demand, buy and use immediately, no longer need to buy a server, no need to consider installation and maintenance, the system is automatically upgraded',
  'index.advantage.desc4':
    'Provide timely and professional technical support, including online customer service, online feedback, and 1V1 advisory services',

  'index.desc.tip1': 'Use globally acclaimed',
  'index.desc.tip2': 'Virsical Cloud Platform',
  'index.desc.tip3': 'for',
  'index.desc.tip4': " Smart Office's",
  'index.desc.tip5': 'Empower',
  'index.desc.tip6':
    "Committed to the whole process of the enterprise's office scene digital, intelligent, build an office scene in the cloud era to help efficient office work.",
  // 'index.desc.tip7': '',
  'index.desc.tip8': 'Cost Saving',
  'index.desc.tip9':
    'Through the unified intelligent management of conference room, work space, reception, network, audio and video equipment, access control and so on, the operation and management cost of the enterprise is reduced.',
  'index.desc.tip10': 'Simple and Easy',
  'index.desc.tip11':
    'Unified platform management, multi terminal equipment support, multi application system integration, Back-stage management make the staff experience are more simple and convenient.',
  'index.desc.tip12': 'Efficient Security',
  'index.desc.tip13':
    'End to end data encryption transmission, perfect security authentication mechanism, based on role-based application authorization management, to ensure enterprise application and data security.',
  'index.desc.tip14':
    'For more information, please call the consultation hotline or apply for a free trial immediately',

  'index.hotline.1': 'Sales Hotline: (Worldwide)',
  'index.hotline.2': 'Service Hotline: (Worldwide)',
  'index.vst.subtitle':
    'Insight into the modern visitor scene, focus on the future of intelligent enterprises, new platform operation and maintenance planning, stable cloud service support, efficient visitor reception process',
  'index.smt.subtitle':
    'Full scenario, full life cycle meeting solutions, for enterprises to bring intelligent, efficient, simple, easy new meeting room booking management experience',
  'index.sws.subtitle':
    'Help enterprises to carry out fine classification management for fixed seats and shared seats, cooperate with the process platform to achieve automatic linkage processing, simplify manual operation of multiple platforms, and cooperate with intelligent hardware to achieve intelligent building space control based on the Internet of Things',
  'index.fm.subtitle':
    'Powerful device integration and docking capabilities and data application management capabilities, through the data communication and IOT platform to achieve visual management of enterprise equipment operating status, the formation of equipment status detection, abnormal alarm, custom maintenance closed-loop management process',
  'index.product.price': 'Product Pricing',
  'index.product.price.unit.year': 'Annual Payment',
  'index.product.dowload.tip1': 'Scan QR code to download the latest Virsical Cloud client',
  'index.product.dowload.tip2':
    'Or in the Apple App Store, search for "Virsical Cloud" to download',
  'index.product.dowload.tip3': 'Scan QR code to download the latest Smart Visitor client',
  'index.product.dowload.tip4': 'Scan QR code to download the latest Meta FM client',
  'index.case.subtitle':
    'Customers cover more than 60% of the Fortune 500 large enterprises and many small and medium-sized enterprises throughout the Internet, finance, education, medical and other industries',
  'index.help.catalogue': 'Catalogue',

  'index.station.china': 'China',
  'index.station.intl': 'International',

  'cloud.country.areacode.china': '(China)',
  'cloud.country.areacode.hk': '(HongKong)',
  'cloud.country.areacode.korea': '(Korea)',
  'cloud.country.areacode.singapore': '(Singapore)',
  'cloud.country.areacode.thailand': '(Thailand)',
  'cloud.country.areacode.usa': '(United States)',

  'home.header.tools': 'Tools and Services',
  'home.header.tools.desc':
    'Virsical launched a new free mapping tool, you can make professional corporate maps for free, office space management, office layout, workstation allocation... Everything is under control. Make administrative work easier and more efficient!',
};
