/* eslint-disable no-nested-ternary */
/**
 * title: 服务条款
 */
import React, { useEffect, useState } from 'react';
import { connect, getLocale } from 'umi';
import { BackTop } from 'antd';
import styles from './index.less';
import headerStyles from '@/components/Header/index.less';
import { serviceZhCn, serviceEnUs, serviceZhTw, policyZhCn, policyEnUs, policyZhTw } from './data';

const Page = ({
  location: {
    query: { type },
  },
}) => {
  const [html, setHtml] = useState(
    getLocale() === 'en-US' ? policyEnUs : getLocale() === 'zh-CN' ? policyZhCn : policyZhTw,
  );

  useEffect(() => {
    if (type === 'service') {
      setHtml(getLocale() === 'en-US' ? serviceEnUs : (
        getLocale() === 'zh-CN' ? serviceZhCn : serviceZhTw
      ));
    }
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: html }} />
      <BackTop />
      <style>
        {`
            .${headerStyles.body} {
                border-bottom: 1px solid rgba(93, 93, 93, 0.1);
            }
            .ant-back-top-content {
              background-color: #2EA498 !important;
            }
            .ant-back-top {
              bottom: 120px !important;
              z-index: 9999 !important;
            }
        `}
      </style>
    </div>
  );
};

export default connect((s) => s)(Page);
