import React from 'react';
import { getLocale } from 'umi';
import LightSpeed from 'react-reveal/LightSpeed';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import ContactUs from '@/components/ContactUs';
import { zhIntl } from '@/utils/intlUtils';
import bg from '@/assets/product/price/bg.png';
import about1 from '@/assets/about1.png';
import about1en from '@/assets/about1_en.png';
import about2 from '@/assets/about2.png';
import styles from './index.less';

const About = () => {
  return (
    <div className={styles.about}>
      <div className={styles.bg}>
        <img src={bg} alt="" />
        <div className={styles.name}>
          <div className={styles.content}>
            <LightSpeed right cascade>
              <div className={styles.productName}>{zhIntl('关于我们')}</div>
            </LightSpeed>
            <Zoom bottom cascade>
              <div className={styles.pageRoute}>
                <span>{zhIntl('首页')}</span> &gt;{' '}
                <span style={{ color: '#fff' }}>{zhIntl('关于我们')}</span>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
      <div className={styles.ourInfo}>
        <Slide left>
          <div className={styles.imgArea}>
            <img
              src={getLocale() === 'en-US' ? about1en : about1}
              className={styles.about1}
              alt=""
            />
            <img src={about2} className={styles.about2} alt="" />
          </div>
        </Slide>
        <Slide right>
          <div className={styles.wordsArea}>
            <div className={styles.title}>{zhIntl('关于威思客')}</div>
            <div className={styles.words}>
              {zhIntl(
                '威发系统是一家 IT 解决方案公司，拥有超过 30 年的系统集成经验以及超过 18 年的独立商业软件开发经验。',
              )}
            </div>
            <div className={styles.words}>
              {zhIntl(
                '威思客智能办公解决方案套件专注于数字化办公、楼宇和园区管理解决方案，具有可视化、交互式访客管理、智能会议管理、工作空间管理、消息发布、无线接入、视频协作和即时消息等功能，旨在提升员工体验和工作效率。',
              )}
            </div>
            <div>{zhIntl('威思客引领数字化转型！')}</div>
          </div>
        </Slide>
      </div>
      <ContactUs />
    </div>
  );
};

export default About;
