export default {
  // 'register.locales': '',
  'register.locales.tips': 'Sign up for a 30 day free trial now',
  'register.locales.tips1': 'More advanced versions of Virsical cloud',
  'register.locales.phone.tips': 'Call the hotline now: ',
  'register.Registered.account': 'Registered account',
  'register.locales.Registered': 'Sign Up',
  'register.locales.bind': 'Bind',
  'register.bind.account': 'Bind account',
  'register.verification.phone': 'Phone verification',
  'register.verification.email': 'Email verification',
  'register.Get.verification.code': 'Get verification code',
  'register.locales.China.phone': 'CN',
  'register.locales.HK.phone': 'HK',
  'register.locales.password': 'Please input a password',
  'register.locales.Privacy.services': 'Please read the terms of service and privacy policy',
  'register.locales.Privacy.services.read': "I have read and agree with Virsical cloud's",
  'register.locales.Terms.of.service': '《Terms of service》',
  'register.locales.Privacy.policy': '《Privacy policy》',
  'register.locales.username': 'Please enter the account number',
  'register.locales.verification.code': 'Reacquire',
  'register.Resend.in.seconds': ' Resend in seconds',
  'register.Please.select.the.authentication.method': 'Please select the authentication method',
  'register.Please.bind.your.account.in.virsical.cloud':
    'Please bind your account in Virsical cloud',
  'register.Welcome.to.sign.up.for.virsical.cloud.service':
    'Welcome to sign up for Virsical cloud service',

  // 'register.locales.error': '',
  'register.locales.error.company': 'Please enter company name',
  'register.locales.error.contacts': 'Please enter contact name',
  'register.locales.error.email': 'Please enter contact email',
  'register.locales.error.phone': 'Please enter  contact phone',
  'register.locales.error.company.length':
    'The length of the company shall not exceed 50 characters',
  'register.locales.error.contacts.length': 'The name is 2-20 characters long',
  'register.locales.error.email.length': 'The maximum mailbox length is 50 characters',
  'register.locales.error.password.length':
    'Please enter the 8-16 digit password (must contain numbers and letters, and can contain the following special characters:.! @#$% ^ & * -)',
  'register.locales.error.phone.error': 'Please enter the correct mobile phone number',
  'register.locales.error.email.error': 'Please enter a valid email address',
  'register.locales.error.password.error': 'The password format is incorrect',
  'register.locales.error.Verification.error': 'Please enter the correct verification code',
  'register.locales.error.Verification': 'Please enter verification code',

  'register.and': 'and',

  'register.verification.success': 'Verification succeeded',
  'register.verification.img': 'Please enter the graphic verification code',
  'register.verification.img.tip': '4-digit verification code',
};
