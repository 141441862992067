import React, { useState } from 'react';
import { Button, Modal, Form } from 'antd';
import Captcha from 'rc-captcha-input';
import { zhIntl } from '@/utils/intlUtils';
import codeImg from '@/assets/code.png';
import styles from './index.less';

const SecurityCode = (props: any) => {
  const { visible, onCancel, onOk } = props;
  const [code, setCode] = useState('');

  return (
    <Modal
      width={880}
      visible={visible}
      title={null}
      footer={null}
      wrapClassName={styles.securityModal}
      onCancel={onCancel}
    >
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>{zhIntl('请输入安全码')}</div>
          <Form>
            <Captcha theme="box" length={6} autoFocus onChange={(v) => setCode(v)} />
            <Button
              disabled={code?.length < 6}
              htmlType="submit"
              size="large"
              type="primary"
              style={{ width: '100%', marginBottom: 30 }}
              onClick={() => onOk(code)}
            >
              {zhIntl('确定')}
            </Button>
          </Form>
        </div>
        <div className={styles.right}>
          <div className={styles.imgArea}>
            <img src={codeImg} width={260} alt="" />
          </div>
          <div className={styles.tips}>
            <div className={styles.title}>{zhIntl('获取步骤')}</div>
            <div>
              {zhIntl('1. 打开您设备(手机、平板、电脑等)上的双因素认证应用，推荐使用')}
              <span style={{ fontWeight: 550 }}>"Microsoft Authenticator"</span>
            </div>
            <div>{zhIntl('2. 找到您之前扫描并绑定的账号')}</div>
            <div>{zhIntl('3. 获取显示的密码代码，填入左侧的输入框')}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SecurityCode;
