/* eslint-disable prefer-template */
const style = `
<style>
.title {
    color: #333333;
    font-size: 30px;
}
.part, .part span {
    font-size: 20px !important;
    font-weight: 400 !important;

}
.subtitle,.subtitle * {
    font-size: 20px;
    font-weight: 400;
}
</style>
`;

export const serviceZhCn =
  style +
  `
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:21px"><span style="font-family:微软雅黑" class="title">服务条款</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">欢迎</span>“您”（以下或称“用户”）与“威发公司”（以下简称“我们”）共同签署本《服务条款》（下称“本协议”），本协议阐述之条款和条件适用于我们通过威思客云平台向您提供的各项产品及/服务。 </span>
</p>
<p style="text-align:center">
    <strong><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑" class="part">序言</span></span></strong>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">本协议内容包括协议正文及所有我们已经发布或将来可能发布并采取合理途径通知的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">您在点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序，或以任何方式接受我们的产品及</span>/服务即表示您已充分阅读、理解并同意接受本协议的条款和条件。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序并停止使用服务。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">一、签约主体</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1.1 本协议由您与我们共同缔结，本协议对您与我们具有同等合同效力。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">二、帐户注册</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">2.1 您在使用我们提供的产品及/服务前需要在威思客云平台注册一个账号。您需要提供真实、准确、完整、最新的个人信息，并且当个人信息变更时，及时修改，以保证其真实准确。若威思客云发现或有足够理由怀疑您的信息不真实、不准确、过期或不完整，我们会终止您的用户帐号，拒绝您使用部分或全部威思客云服务。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">2.2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">由于您的帐户关联您的信息及我们的商业信息，您的帐户仅限您本人使用。未经我们书面同意，您直接或间接授权第三方使用您帐户或获取您帐户项下信息的行为无效。如我们根据您帐户的使用行为等情况判断您帐户的使用可能危及您的帐户安全及</span>/或威思客云平台信息安全的，我们可拒绝提供相应产品及/服务或终止本协议。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">三、数据所有权</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.</span><span style=";font-family:微软雅黑;font-size:14px">1 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">您在威思客云平台创建或存储的内容所有权归您，您有权对其所创建的数据进行任何形式的处置，包括从平台中复制、导出和删除。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.</span><span style=";font-family:微软雅黑;font-size:14px">2 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">除非经您特别允许，否则威思客云不会使用、复制、改编、修改或发布您帐号内创建或存储的内容以作商业、营销或任何类似目的之用。</span> <span style="font-family:微软雅黑">只有出于为您提供服务的目的，当您授权后，威思客云才有权访问、复制、存储、发送您的帐号内容。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.3 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">依照明确的服务约定，威思客云在用户未继续付费且未继续使用达到</span>6个月后，威思客云有权将用户数据从平台上删除，而无需向用户承担任何赔偿责任。该数据包括但不限于用户账户信息、用户使用过程中创建的独创性使用数据。删除的数据将无法从平台上导出或恢复。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">四、不活跃用户账号的处理</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">4.1</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">对免费用户帐号，若连续</span>180天未登录威思客云服务，威思客云保留终止此类帐号的权利。终止后，相关数据也将被删除。数据删除政策可能针对全部或部分服务进行。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">五、使用限制</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.</span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">威思客云限于提供给用户用作正常和合法的业务</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.1，用户不得利用威思客云进行如下行为：</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.1.1 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">含有其他干扰对威思客云正常运营和侵犯其他用户或其他第三方合法权益内容的信息；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.1.2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">对威思客云的产品及</span>/或服务进行全部或部分地翻译、分解、反向编译、反汇编、反项设计、反向工程、破解或其他试图从产品导出程序源代码的行为，或修改或以任何方式变更或修改产品的注册许可文件或产品的任何部分，或在产品的基础上书写或开发衍生软件、衍生产品；</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.3 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">对威思客云产品进行了任何形式的对其他第三方的再授权使用、销售或转让；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.4</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">以任何可能对威思客云的服务器，网络，计算机系统，资源造成损坏，禁用，超负荷，损害或伤害的方式使用服务；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">创建虚假身份作为确认信息或发起通信来误导任何人；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.6</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">违反任何适用的地方，国家或国际法律法规。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">2 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">如果用户使用威思客云产品从事上述</span></span><span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.1条的行为，威思客云有权随时停止服务、并要求用户赔偿由此造成的损失。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">六、费用</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">6</span><span style=";font-family:微软雅黑;font-size:14px">.1我们通过威思客云平台向您提供的服务目前是免费的。如未来我们向您收取合理费用，我们会采取合理途径并提前通过法定程序以本协议约定的有效方式通知您，确保您有充分选择的权利。我们可能会不时更改现在免费的服务的费用。任何费用的增加将不会在您当前订购期限结束之前执行。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">七、用户隐私权</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">7</span><span style=";font-family:微软雅黑;font-size:14px">.1 保护用户个人数据是我们的一项基本原则，我们将会采取合理的措施保护您的个人数据。除法律法规规定的情形外，未经用户许可我们不会向第三方公开、透露用户个人数据。我们对相关信息采用专业加密存储与传输方式，保障您个人数据的安全。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">7</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;<span style="font-family:微软雅黑">我们将运用各种安全技术和程序建立完善的管理制度来保护您的个人数据，以免遭受未经授权的访问、使用或披露。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">八、条款变更</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">8</span><span style=";font-family:微软雅黑;font-size:14px">.1威思客云有权随时对本合约的条款进行修订，并在修订生效日前30个工作日更新在威思客云网站。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">8.2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">如您不同意变更事项，您有权于变更事项确定的生效日前联系我们并反馈相关意见。如反馈意见被采纳，我们将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止接受我们提供的服务，变更事项对您不产生效力；如您在变更事项生效后仍继续接受我们提供的服务，则视为您同意已生效的变更事项。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">九、责任限制</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">9</span><span style=";font-family:微软雅黑;font-size:14px">.1我们依照法律规定履行基础保障义务，但对于下述不可抗力或其他第三人原因导致的本协议履行障碍、履行瑕疵、履行延后或履行内容变更等情形，我们并不承担相应的违约责任：</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（三）在我们已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">9</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">2 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">在任何情况下，威思客云可承担的全部责任不会超过您已支付的服务费用。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p class="subtitle">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">十、</span></span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">免责声明</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">10</span><span style=";font-family:微软雅黑;font-size:14px">.1 威思客云不保证服务会无中断、及时、安全，或错误。使用威思客云服务下载或获得任何材料时，您应自己判断其风险，且全权负责可能会对您的计算机系统、手机、无线设备或数据所造成的任何损害</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">10</span><span style=";font-family:微软雅黑;font-size:14px">.2 除威思客云付费版本软件与服务外，威思客云对任何损害概不负责，无论这些损害是否由于使用、不能使用本站的结果、连到本站的任何Web网站或者任何这类网站中包含的信息所引起（包括但不限于由损失的利润、损失的数据或业务中断而造成的损害），也不管它们是否有保证、合同、侵权行为或任何其它法律根据以及事前已得到这类损害可能发生的消息。如果您由于使用本站的免费信息和免费软件版本导致需要对设备或数据进行维护、修理或纠正，您也必须承担由此而造成的所有费用。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">十一、赔偿责任</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">11</span><span style=";font-family:微软雅黑;font-size:14px">.1如您的行为侵害了其他人的权利、违了法、违反了此条款中任何规定，由此造成或相关的任何损失、赔偿、罚款的索赔，您应进行赔偿，且威思客云、其管理人员、董事、雇员，供应商和附属公司，不对此有赔偿责任。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">十二、协议终止</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">12.1协议终止的情形</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">12.1.1您有权通过以下任一方式终止本协议：</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（一）在满足我们要求的帐户注销条件时您通过威思客云平台自助服务注销您的帐户的；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（二）变更事项生效前您停止使用我们产品及</span>/或服务并明示不愿接受变更事项的；</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（三）您明示不愿继续使用我们产品及</span>/或服务，且符合我们要求的终止条件的。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">12.1.2出现以下情况时，我们可以本协议约定的有效方式通知您终止本协议：</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（一）您的实名认证身份无效、不再合法存续或无法有效核实；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（二）您违反本协议约定，我们依据相关条款规定终止本协议的；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（三）您的帐户被我们依据本协议回收的；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（四）我们基于合理理由相信您的行为可能会使您、我们及威思客云平台等相关方发生严重损害或产生法律责任；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">（五）其它应当终止服务的情况。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">12.2协议终止后的处理</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">12.2.1本协议终止后，除法律有明确规定外，我们无义务向您或您指定的第三方披露您帐户中的任何信息。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">12.2</span><span style=";font-family:微软雅黑;font-size:14px">.2我们有权就您的违约行为</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">，</span></span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">追究相应的责任</span></span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">十三、法律适用、管辖及其他</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">.1本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律；如无相关法律规定的，则应参照商业惯例和（或）行业惯例。 </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">.2您与我们仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇佣与被雇佣或特性授权与被授权关系。 </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">.3除双方另有约定外，您同意我们因经营业务需要有权将本协议项下的权利义务就部分或全部进行转让，并采取合理途径提前通过法定程序以本协议约定的有效方式通知您。 </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">.4因本协议或我们服务所引起或与其有关的任何争议，由我们与您协商解决。协商不成时，任何一方均可向被告住所地人民法院提起诉讼。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">.5倘若本协议任何条款被裁定为无效或不可强制执行，该项条款应被撤销，而其余条款应予遵守和执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。我们未就您或其他人士的某项违约行为采取行动，并不表明我们撤回就任何继后或类似的违约事件采取行动的权利。</span>
</p>
<p>
    <br/>
</p>
`;

export const serviceEnUs =
  style +
  `
<p style="text-align:center">
  <span style=";font-family:微软雅黑;font-size:21px"><span style="font-family:微软雅黑" class="title">Terms of Service</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">You are welcome to sign this Terms of Service (the "This Agreement") with Wafer Systems China Limited ("We"), and the terms and conditions set forth in this Agreement apply to the products and services we provide to you through the Virsical Cloud Platform.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-align:center">
  <strong><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑" class="part">Recital</span></span></strong>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">This Agreement includes the body of the Agreement and all the rules that we have issued or may issue in the future and notify by reasonable means. All rules are an integral part of the agreement and have the same legal effect as the body of the agreement.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">You should read this agreement carefully before clicking to agree/accept to it. Please be sure to read and fully understand the contents of the terms, in particular the terms of exemption or limitation of liability, the application of the law and the terms of dispute resolution.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">By filling out information, reading and agreeing to this Agreement and completing the full registration process, or accepting our products and/or services in any way, you have fully read, understood and agreed to accept the terms and conditions of this Agreement. In the course of reading this Agreement, if you do not agree to this Agreement or any of its terms, you should immediately stop the registration process and stop using the Services.</span>
</p>
<p style="text-indent:28px">
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">1. The main body of the Agreement</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">1.1 This Agreement is concluded by you and us and has the same contractual effect on you as we do.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">2. Account Registration</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">2.1 You need to register an account on the Virsical Cloud platform before using the products and/or services we offer. You need to provide true, accurate, complete and up-to-date personal information, and when personal information changes, modify it in a timely manner to ensure that it is true and accurate. If Virsical Cloud discovers or has sufficient grounds to suspect that your information is untrue, inaccurate, outdated, or incomplete, we will terminate your user account and deny you access to some or all of the Virsical Cloud Services.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">2.2 As your account is linked to your information and our business information, your account is for your own use only. Without our written consent, your direct or indirect authorization of third parties to use your account or obtain information under your account is invalid. If we determine, for example, that the use of your account may compromise your account security and/or the information security of the Virsical Cloud Platform, we may refuse to provide the product and/service or terminate this Agreement. </span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">3. Data ownership</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">3.1 You own the content created or stored on the Virsical Cloud Platform and you have the right to dispose of the data you create in any form, including copying, exporting, and deleting it from the Platform.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.2 Virsical Cloud will not use, copy, adapt, modify or publish content created or stored in your account for commercial, marketing or any similar purpose, except with your special permission.Only for the purpose of providing services to you, when you authorize, will Virsical Cloud have access to, copy, store, and send your account content. </span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.3 In accordance with the express service agreement, Virsical Cloud reserves the right to remove user data from the Platform without any liability to the user after the user has not continued to pay and has not continued to use it for 6 months. This data includes, but is not limited to, user account information and original usage data created during user use. Deleted data cannot be exported or recovered from the platform.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">4. The processing of inactive user accounts</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">4.1</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑"> For free user accounts, Virsical Cloud reserves the right to terminate such accounts if they have not logged in to the Virsical Cloud service for 180 consecutive days. Upon termination, the data will also be deleted. Data deletion policies may be for all or part of the service.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">5. Restrictions on use</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5.</span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">Virsical Cloud is limited to providing users for normal and legitimate business purposes</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5.1 Users may not use The Virsical Cloud to:</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5.1.1 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">Contain information that interferes with the normal operation of Virsical Cloud and infringes the legitimate rights and interests of other users or other third parties;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5.1.2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑"> Translation, decomposition, reverse compilation, disassembly, reverse engineering, cracking or other attempts to export program source code from the Product, or modify or in any way alter or modify any part of the Product's registration license documents or products, or to write or develop derivative software or derivative products on the basis of the Product;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.3 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">Any form of reauthorization, sale or transfer of Virsical Cloud Products to other third parties;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.4</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑"> Use the Services in any manner that may cause damage, disablement, overload, damage or injury to the servers, networks, computer systems, resources of Virsical Cloud;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑"> Create false identities to mislead anyone as confirmation information or initiate communications;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.6</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑"> Violation of any applicable local, national or international laws and regulations.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">2 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">If the user uses The Virsical Cloud Products to engage in the above-mentioned 5.1 acts, Virsical Cloud reserves the right to stop the Services at any time and to require the User to compensate for the resulting losses. </span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">6.Cost</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">6.1 The services we provide to you through the Virsical Cloud platform are currently free of charge. If we charge you a reasonable fee in the future, we will take reasonable steps and notify you in advance through legal procedures in a valid manner as agreed in this Agreement to ensure that you have the right to make a full choice. From time to time, we may change the cost of the service, which is now free. Any increase in fees will not be performed until the end of your current subscription period. </span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">7. User's privacy</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">7.1 Protecting the user's personal data is a fundamental principle of ours and we will take reasonable steps to protect your personal data. Except as required by laws and regulations, we do not disclose or disclose user personal data to third parties without their permission. We use professional encrypted storage and transmission of relevant information to keep your personal data safe.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">7.2 We will use a variety of security technologies and procedures to establish a sound management system to protect your personal data from unauthorized access, use or disclosure.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">8. Changes in terms</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">8.1 Virsical Cloud reserves the right to revise the terms of this contract at any time and to update it on the Virsical Cloud website 30 working days prior to the effective date of the amendment. </span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">8.2 If you do not agree to the change, you have the right to contact us and provide feedback prior to the effective date of the change. If feedback is accepted, we will adjust the changes as appropriate. If you still do not agree to the changes in effect, you shall cease to accept the services provided by us as of the effective date of the changes, and the changes will not have effect on you, and if you continue to accept the services provided by us after the changes become effective, you shall be deemed to agree to the changes that have taken effect.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">9. Limitations of liability</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">9.1 We perform our basic safeguard obligations in accordance with the law, but we shall not be liable for any breach of contract in the event of obstacles to the performance of this Agreement, defects in performance, delays in performance or changes in the performance of the Following Force Majeure or any other third party reasons:</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(1) Force majeure factors such as natural disasters, strikes, riots, war, government actions, judicial executive orders, etc;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(2) Public service factors or third-party factors such as power supply failure and communication network failure;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(3) In the case of our good faith management, due to conventional or urgent equipment and system maintenance, equipment and system failures, network information and data security and other factors.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">9.2 In no event will Virsical Cloud assume full liability beyond the service fees you have paid for.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p class="subtitle">
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">10. Disclaimer</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">10.1  Virsical Cloud does not guarantee that the service will be uninterrupted, timely, secure, or error-free. When downloading or obtaining any material using the Virsical Cloud Service, you are solely responsible for any damage that may be caused to your computer system, mobile phone, wireless device, or data at your own discretion.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">10.2 With the exception of The Virsical Cloud Paid Versions of Software and Services, Virsical Cloud shall not be liable for any damages, whether or not they are caused by the use, inability to use the results of this site, any Web site connected to the Site, or any information contained in any such website (including but not limited to loss).  loss of data or damage caused by business interruption), whether or not they have warranties, contracts, torts or any other legal basis, and have been previously known to have information that such damage may have occurred. If you need to maintain, repair or correct equipment or data as a result of using the site's free information and free software versions, you must also bear all costs incurred as a result.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">11. Liability</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">11.1 You shall be liable for any loss, compensation or fine caused or related to your actions that infringe the rights of others, violate the law, or violate any provision of this clause, and Virsical Cloud, its officers, directors, employees, suppliers and affiliates shall not be liable for such damages.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">12. The agreement shall be terminated</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">12.1 The circumstances in which the agreement is terminated</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">12.1.1 You have the right to terminate this Agreement by:</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(1)  You cancel your account through the Virsical Cloud Platform self-service when the account cancellation conditions we require are met;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(2)  You cease to use our products and/or services and expressly do not wish to accept the changes before the changes take effective;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(3)  You expressly do not wish to continue to use our products and/or services and meet the termination conditions required by us.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">12.1.2 We may notify you of the termination of this Agreement in a valid manner as agreed in this Agreement:</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(1) Your real-name authentication status is invalid, no longer legal or cannot be effectively verified;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(2) If you violate the provisions of this Agreement, we terminate this Agreement in accordance with the relevant provisions;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(3) Your account has been recovered by us in accordance with this Agreement;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(4) We believe for reasonable reasons that your actions may cause serious harm or legal liability to you, us and other interested parties such as the Virsical Cloud Platform;</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">(5) Other circumstances in which the service shall be terminated.</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">12.2 Processing after termination of the agreement</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">12.2.1 Upon termination of this Agreement, we are under no obligation to disclose any information in your account to you or a third party you designate, except as expressly provided by law.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">12.2.2 We reserve the right to hold you accountable for your breach of contract.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">13. Application of laws, jurisdiction and others</span></span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">13.1 The laws of the Mainland of china shall apply to the effect, interpretation, alteration, enforcement and dispute resolution of this Agreement, and in the absence of the relevant legal provisions, reference shall be made to commercial and/or industry practices.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">13.2 You are in a relationship with us only as a separate contractor. This Agreement is not intended to create or create any agent, partnership, joint venture, employment and employment or character authorization and licensing relationship.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">13.3 Unless otherwise agreed by the parties, you agree that we have the right to transfer, in part or in full, the rights and obligations under this Agreement for the purposes of doing business, and to notify you in a reasonable manner in advance through legal procedures in a valid manner as agreed in this Agreement.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">13.4 Any dispute arising out of or relating to this Agreement or our Services shall be resolved by us in consultation with you. If the negotiation fails, either party may bring a suit in the people's court of the defendant's place of residence.</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
  <span style=";font-family:微软雅黑;font-size:14px">13.5 If any provision of this Agreement is found to be invalid or unenforceable, that provision shall be revoked and the remaining provisions shall be observed and enforced. The title of the Terms is for convenience only and does not in any way define, limit, interpret or describe the scope or extent of the Terms. Our failure to act on a breach by you or others does not imply that we withdraw our right to take action in the event of any subsequent or similar breach.</span>
</p>
<p>
  <br/>
</p>
`;

export const serviceZhTw =
  style +
  `
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:21px"><span style="font-family:微软雅黑" class="title">服務條款</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    <span style="font-family:微软雅黑">歡迎</span>
    「您」（以下或稱「用戶」）與「威發公司」（以下簡稱「我們」）共同簽署本《服務條款》（下稱「本協議」），本協議闡述之條款和條件適用於我們通過威思客雲平臺向您提供的各項產品及/服務。 
    </span>
</p>
<p style="text-align:center">
    <strong><span style="font-family: 微软雅黑;font-size: 14px"><span style="font-family:微软雅黑" class="part">序言</span></span></strong>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">本協議內容包括協議正文及所有我們已經發布或將來可能發布並采取合理途徑通知的各類規則。所有規則為協議不可分割的一部分，與協議正文具有同等法律效力。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">您在點擊同意本協議之前，應當認真閱讀本協議。請您務必審慎閱讀、充分理解各條款內容，特別是免除或者限製責任的條款、法律適用和爭議解決條款。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">當您按照註冊頁面提示填寫信息、閱讀並同意本協議且完成全部註冊程序，或以任何方式接受我們的產品及</span>/服務即表示您已充分閱讀、理解並同意接受本協議的條款和條件。閱讀本協議的過程中，如果您不同意本協議或其中任何條款約定，您應立即停止註冊程序並停止使用服務。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">一、簽約主體</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1.1 本協議由您與我們共同締結，本協議對您與我們具有同等合同效力。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">二、帳戶註冊</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">2.1 您在使用我們提供的產品及/服務前需要在威思客雲平臺註冊一個賬號。您需要提供真實、準確、完整、最新的個人信息，並且當個人信息變更時，及時修改，以保證其真實準確。若威思客雲發現或有足夠理由懷疑您的信息不真實、不準確、過期或不完整，我們會終止您的用戶帳號，拒絕您使用部分或全部威思客雲服務。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">2.2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">由於您的帳戶關聯您的信息及我們的商業信息，您的帳戶僅限您本人使用。未經我們書面同意，您直接或間接授權第三方使用您帳戶或獲取您帳戶項下信息的行為無效。如我們根據您帳戶的使用行為等情況判斷您帳戶的使用可能危及您的帳戶安全及</span>/或威思客雲平臺信息安全的，我們可拒絕提供相應產品及/服務或終止本協議。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">三、數據所有權</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.</span><span style=";font-family:微软雅黑;font-size:14px">1 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">您在威思客雲平臺創建或存儲的內容所有權歸您，您有權對其所創建的數據進行任何形式的處置，包括從平臺中復製、導出和刪除。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.</span><span style=";font-family:微软雅黑;font-size:14px">2 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    除非經您特別允許，否則威思客雲不會使用、復製、改編、修改或發布您帳號內創建或存儲的內容以作商業、營銷或任何類似目的之用。
    </span> <span style="font-family:微软雅黑">
    只有出於為您提供服務的目的，當您授權後，威思客雲才有權訪問、復製、存儲、發送您的帳號內容。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3.3 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    依照明確的服務約定，威思客雲在用戶未繼續付費且未繼續使用達到
    </span>
    6個月後，威思客雲有權將用戶數據從平臺上刪除，而無需向用戶承擔任何賠償責任。該數據包括但不限於用戶賬戶信息、用戶使用過程中創建的獨創性使用數據。刪除的數據將無法從平臺上導出或恢復。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    四、不活躍用戶賬號的處理
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">4.1</span>
    <span style=";font-family:微软雅黑;font-size:14px">
    <span style="font-family:微软雅黑">對免費用戶帳號，若連續</span>
    180天未登錄威思客雲服務，威思客雲保留終止此類帳號的權利。終止後，相關數據也將被刪除。數據刪除政策可能針對全部或部分服務進行。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    五、使用限製
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.</span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    威思客雲限於提供給用戶用作正常和合法的業務
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.1，用戶不得利用威思客雲進行如下行為：</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.1.1 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">含有其他幹擾對威思客雲正常運營和侵犯其他用戶或其他第三方合法權益內容的信息；</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5.1.2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    對威思客雲的產品及
    </span>
    /或服務進行全部或部分地翻譯、分解、反向編譯、反匯編、反項設計、反向工程、破解或其他試圖從產品導出程序源代碼的行為，或修改或以任何方式變更或修改產品的註冊許可文件或產品的任何部分，或在產品的基礎上書寫或開發衍生軟件、衍生產品；
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.3 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    對威思客雲產品進行了任何形式的對其他第三方的再授權使用、銷售或轉讓；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.4</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    以任何可能對威思客雲的服務器，網絡，計算機系統，資源造成損壞，禁用，超負荷，損害或傷害的方式使用服務；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    創建虛假身份作為確認信息或發起通信來誤導任何人；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">1.6</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    違反任何適用的地方，國家或國際法律法規。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">2 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    如果用戶使用威思客雲產品從事上述
    </span></span><span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px">
    .1條的行為，威思客雲有權隨時停止服務、並要求用戶賠償由此造成的損失。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    六、費用
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">6</span><span style=";font-family:微软雅黑;font-size:14px">
    .1我們通過威思客雲平臺向您提供的服務目前是免費的。如未來我們向您收取合理費用，我們會采取合理途徑並提前通過法定程序以本協議約定的有效方式通知您，確保您有充分選擇的權利。我們可能會不時更改現在免費的服務的費用。任何費用的增加將不會在您當前訂購期限結束之前執行。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    七、用戶隱私權
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">7</span><span style=";font-family:微软雅黑;font-size:14px">
    .1 保護用戶個人數據是我們的一項基本原則，我們將會采取合理的措施保護您的個人數據。除法律法規規定的情形外，未經用戶許可我們不會向第三方公開、透露用戶個人數據。我們對相關信息采用專業加密存儲與傳輸方式，保障您個人數據的安全。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">7</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;<span style="font-family:微软雅黑">
    我們將運用各種安全技術和程序建立完善的管理製度來保護您的個人數據，以免遭受未經授權的訪問、使用或披露。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    八、條款變更
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">8</span><span style=";font-family:微软雅黑;font-size:14px">
    .1威思客雲有權隨時對本合約的條款進行修訂，並在修訂生效日前30個工作日更新在威思客雲網站。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">8.2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    如您不同意變更事項，您有權於變更事項確定的生效日前聯系我們並反饋相關意見。如反饋意見被采納，我們將酌情調整變更事項。如您對已生效的變更事項仍不同意的，您應當於變更事項確定的生效之日起停止接受我們提供的服務，變更事項對您不產生效力；如您在變更事項生效後仍繼續接受我們提供的服務，則視為您同意已生效的變更事項。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    九、責任限製
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">9</span><span style=";font-family:微软雅黑;font-size:14px">
    .1我們依照法律規定履行基礎保障義務，但對於下述不可抗力或其他第三人原因導致的本協議履行障礙、履行瑕疵、履行延後或履行內容變更等情形，我們並不承擔相應的違約責任：
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （一）因自然災害、罷工、暴亂、戰爭、政府行為、司法行政命令等不可抗力因素；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （二）因電力供應故障、通訊網絡故障等公共服務因素或第三人因素；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （三）在我們已盡善意管理的情況下，因常規或緊急的設備與系統維護、設備與系統故障、網絡信息與數據安全等因素。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">9</span><span style=";font-family:微软雅黑;font-size:14px">.</span><span style=";font-family:微软雅黑;font-size:14px">2 </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    在任何情況下，威思客雲可承擔的全部責任不會超過您已支付的服務費用。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p class="subtitle">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">十、</span></span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    免責聲明
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">10</span><span style=";font-family:微软雅黑;font-size:14px">
    .1 威思客雲不保證服務會無中斷、及時、安全，或錯誤。使用威思客雲服務下載或獲得任何材料時，您應自己判斷其風險，且全權負責可能會對您的計算機系統、手機、無線設備或數據所造成的任何損害
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">10</span><span style=";font-family:微软雅黑;font-size:14px">
    .2 除威思客雲付費版本軟件與服務外，威思客雲對任何損害概不負責，無論這些損害是否由於使用、不能使用本站的結果、連到本站的任何Web網站或者任何這類網站中包含的信息所引起（包括但不限於由損失的利潤、損失的數據或業務中斷而造成的損害），也不管它們是否有保證、合同、侵權行為或任何其它法律根據以及事前已得到這類損害可能發生的消息。如果您由於使用本站的免費信息和免費軟件版本導致需要對設備或數據進行維護、修理或糾正，您也必須承擔由此而造成的所有費用。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    十一、賠償責任
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">11</span><span style=";font-family:微软雅黑;font-size:14px">
    .1如您的行為侵害了其他人的權利、違了法、違反了此條款中任何規定，由此造成或相關的任何損失、賠償、罰款的索賠，您應進行賠償，且威思客雲、其管理人員、董事、雇員，供應商和附屬公司，不對此有賠償責任。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    十二、協議終止
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">
    12.1協議終止的情形
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">
    12.1.1您有權通過以下任一方式終止本協議：
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （一）在滿足我們要求的帳戶註銷條件時您通過威思客雲平臺自助服務註銷您的帳戶的；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （二）變更事項生效前您停止使用我們產品及
    </span>
    /或服務並明示不願接受變更事項的；
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （三）您明示不願繼續使用我們產品及
    </span>
    /或服務，且符合我們要求的終止條件的。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">
    12.1.2出現以下情況時，我們可以本協議約定的有效方式通知您終止本協議：
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （一）您的實名認證身份無效、不再合法存續或無法有效核實；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （二）您違反本協議約定，我們依據相關條款規定終止本協議的；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （三）您的帳戶被我們依據本協議回收的；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （四）我們基於合理理由相信您的行為可能會使您、我們及威思客雲平臺等相關方發生嚴重損害或產生法律責任；
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    （五）其它應當終止服務的情況。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">
    12.2協議終止後的處理
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">
    12.2.1本協議終止後，除法律有明確規定外，我們無義務向您或您指定的第三方披露您帳戶中的任何信息。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">12.2</span><span style=";font-family:微软雅黑;font-size:14px">
    .2我們有權就您的違約行為
    </span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">，</span></span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    追究相應的責任
    </span></span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    十三、法律適用、管轄及其他
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">
    .1本協議之效力、解釋、變更、執行與爭議解決均適用中華人民共和國大陸地區法律；如無相關法律規定的，則應參照商業慣例和（或）行業慣例。 
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">
    .2您與我們僅為獨立訂約人關系。本協議無意結成或創設任何代理、合夥、合營、雇傭與被雇傭或特性授權與被授權關系。 
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">
    .3除雙方另有約定外，您同意我們因經營業務需要有權將本協議項下的權利義務就部分或全部進行轉讓，並采取合理途徑提前通過法定程序以本協議約定的有效方式通知您。 
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">
    .4因本協議或我們服務所引起或與其有關的任何爭議，由我們與您協商解決。協商不成時，任何一方均可向被告住所地人民法院提起訴訟。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px">
    .5倘若本協議任何條款被裁定為無效或不可強製執行，該項條款應被撤銷，而其余條款應予遵守和執行。條款標題僅為方便參閱而設，並不以任何方式界定、限製、解釋或描述該條款的範圍或限度。我們未就您或其他人士的某項違約行為采取行動，並不表明我們撤回就任何繼後或類似的違約事件采取行動的權利。
    </span>
</p>
<p>
    <br/>
</p>
`;

export const policyZhCn =
  style +
  `
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:21px"><span style="font-family:微软雅黑" class="title">隐私政策</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">发布者：威发（西安）软件有限公司</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">生效日期：2022年08月03日</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">最新更新和发布日期：2023年1月11日</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">本声明仅适用于由威发（西安）软件有限公司（简称“我们”或“威思客云”）提供服务或运营控制的威思客云平台自身的功能、产品及服务。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">请您在提交个人数据之前，务必仔细阅读并理解本政策，一旦您提交完成，即表示您已充分理解并同意本政策。本政策仅适用于我们在威思客云平台出售的产品或服务，如对本政策内容有任何疑问、意见或建议，您可通过电话或邮箱与我们联系。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">本政策阐述了我们如何处理您的个人数据，但本政策可能并不涉及所有可能的数据处理情境。未涉及的内容可能由我们在补充政策中，或者在收集数据时提供的通知中发布。</span></span>
</p>
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-align:center" class="part">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">第一部分</span> <span style="font-family:微软雅黑">制定本政策的目的</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">我们制定本政策目的在于帮助您了解以下内容：</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1、我们如何收集您的个人数据；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2、我们如何使用您的个人数据；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、如何披露您的个人数据；</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">4、如何访问或修改您的个人数据；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、我们如何保护您的个人数据；</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">6</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、本政策如何更新。</span></span>
</p>

<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-align:center" class='part'>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">第二部分</span></span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">具体内容</span></span>
</p>
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">一、我们如何收集您的个人数据</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">个人数据是指单独使用或结合其他信息使用时能够确定个人身份的信息。在您购买、使用我们的产品及</span>/或服务时，我们需要/可能需要收集和使用的您的个人数据包括如下两种：</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常购买、使用我们的产品及/或服务，我们也将无法回应您所遇到的问题；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、为实现向您提供我们产品及</span>/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">您理解并同意：</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1、因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人数据类型、范围等会有所区别，请以具体的产品/服务功能为准；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人数据或变更个人数据使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">此外，</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、若你提供的信息中含有其他用户的个人数据，在向我们提供这些个人数据之前，您需确保您已经取得合法的授权。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人数据，均会事先获得您的同意。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人数据，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人数据来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任。我们会使用不低于我们对自身用户个人数据同等的保护手段与措施对间接获取的个人数据进行保护。</span></span>
</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">接入第三方SDK清单：</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">1) 腾讯X5浏览器（TencentSmtt）</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">使用目的：为各系统版本、厂商用户提供统一的web应用使用体验</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">收集个人信息类型：IMSI</p>

<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">2) 腾讯bugly</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">使用目的：为提高服务稳定性，便于相关服务崩溃后快速准确定位存在问题</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">收集个人信息类型：Crash环境（Crash信息及线程堆栈，ROM/RAM/SD卡容量、网络/语言状态App信息：包名、版本、所属进程名）、设备信息、设备厂商、设备名称、操作系统版本、运营商代码，App运行状态，闪退、卡顿、网络信息、日志信息，以及Android_id、IMSI设备信息</p>


<p style="margin-left:16px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">二、我们将如何使用您的个人数据</span></span>
</p>

<p style="text-indent:28px"> 
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">我们可能将所收集的您的个人数据用于以下目的：</span></span>
</p>
<p style="text-indent:28px"> 
  <span style=";font-family:微软雅黑;font-size:14px">1、</span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">创建账户；</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span></span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">实现您的交易或服务请求，包括履行订单；交付、激活或验证产品或服务；参加线上或线下活动；应您的要求进行变更或者提供您请求的信息（例如产品或服务的营销资料）；以及提供技术支持；</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">根据您的浏览、搜索记录、设备信息、订单信息等特征，在您同意的情况下，与您联系；向您推送有关您可能感兴趣的产品和服务的信息；邀请您参与我们的活动（包括促销活动）、市场调查或满意度调查；或向您发送营销信息。如果您不想接收此类信息，则可以随时退订。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">4</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">向您发送重要通知，如操作系统或应用程序更新和安装的通知。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、 认证和管理供应商及业务合作伙伴，与供应商及业务合作伙伴沟通或开展业务。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">6</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">开展内部审计、数据分析和研究，改善我们的产品和服务。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">7</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、 分析业务运营效率并衡量市场份额。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">8</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">在您选择向我们发送错误详情的情况下排查错误。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">9</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">同步、共享和存储您上传或下载的数据以及执行上传和下载所需的数据。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">10</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、保护我们产品、服务和客户或用户的安全，执行与改善我们的防损和反欺诈计划。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">11</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、遵从和执行适用的法律要求，相关的行业标准或我们的政策。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">我们会在遵从相关法律法规规定的前提下，适当的处理您的个人数据，包括：当响应您的交易或服务请求时为履行合同处理您的个人数据；基于您的同意处理您的个人数据；当与您联系、进行营销或市场调查，为改善我们的产品和服务，执行与改善我们的防损和反欺诈计划等目的处理您的个人数据时我们将基于我们或第三方的合法利益；以及基于遵从和执行法律义务处理您的个人数据。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">三、我们如何披露您的个人数据</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1、基于您的明确同意或主动选择；我们可能将披露您的相关信息；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、在某些服务由我们的授权合作伙伴提供的情况下，我们会如本政策描述与该合作伙伴共享您的个人数据。例如，在您通过威思客云平台试用、购买我们的产品及</span>/或服务时，我们必须与物流服务提供商共享您的个人数据才能安排送货，或者安排合作伙伴提供服务；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、在相关法律法规定或相关执法部门要求的情况下，我们也可能会向相关的执法机关或者其他政府机关披露您的个人数据。</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">四、我们如何管理您的个人数据</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1、您应确保提交的所有个人数据都准确无误。我们会尽力维护个人数据的准确和完整，并及时更新这些数据。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、在符合相关法律法规的规定的情况下，您可能</span>(1)有权访问我们持有的关于您的特定的个人数据；(2)要求我们更新或更正您的不准确的个人数据；(3)拒绝或限制我们使用您的个人数据；以及(4)要求我们删除您的个人数据。如果您想行使相关的权利，请反馈给我们。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权，我们则会拒绝处理请求。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3、在符合相关法律法规规定的情况下，当我们基于您的同意处理您的个人数据时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人数据的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人数据。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">五、我们如何保护您的个人数据</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">我们高度重视个人数据安全，对收集的您的个人数据将按照如下方式进行保护：</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1、我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人数据的机密性；我们会使用受信赖的保护机制防止个人数据遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护的相关培训课程，加强员工对于保护个人数据重要性的认识。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、我们会采取合理可行的措施，尽力避免收集无关的个人数据。我们只会在达成本政策所述目的所需的期限内保留您的个人数据，除非法律法规公布的关于强制留存的规定，我们将在您的个人数据超出保留期间后，根据适用法律的要求删除您的个人数据，或对其进行匿名化处理。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、如果您所在企业组织希望注销账户时，可以拨打客服热线（400-685-3160）或发送邮件至service@virsical.com主动申请账户注销，我们将在7个工作日内为您注销账号，并清除所有个人信息。</span></span>
</p>
<p style="margin-left:0;text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">4</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、虽然我们会尽全部所能保护您的信息安全，但请注意任何措施均非能无懈可击。并且，为保证个人数据的安全，请您妥善保护自己的个人数据，仅在必要的情形下向他人提供。如您发现自己的个人数据尤其是您的账号或密码发生泄露，请您立与我们联系，以便我们根据您的申请采取相应措施。</span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">5、如不幸发生个人数据安全事件，我们将按照法律法规的要求向您告知：相关基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人数据主体时，我们会采取合理、有效的方式发布公告。</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">六、本政策如何更新</span></span>
</p>
<p style=";text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">我们保留更新或修改本政策的权利。我们会在这里发布对最新版的隐私政策。对于重大变更，我们还会提供通过不同方式向您发送变更通知。</span></span>
</p>
<p>
    <br/>
</p>
`;

export const policyEnUs =
  style +
  `
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:21px"><span style="font-family:微软雅黑" class="title">Privacy Policy</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">Virsical Cloud ("we") understands the importance of personal data to you and respects your privacy. For this reason, we have developed this Privacy Policy (the the "Policy"). Please read and understand the Policy carefully before submitting your personal data, and once you have done so, you fully understand and agree to it. The Policy applies only to products or services we sell on the Virsical Cloud Platform, and if you have any questions, comments or suggestions about the content of this policy, you may contact us by phone or email.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">The Policy describes how we handle your personal data, but it may not cover all possible data processing situations. Content not covered may be posted in our supplementary policies or in notices provided at the time of data collection.</span></span>
</p>
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-align:center" class="part">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">Part 1 The purpose of this Policy</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">We have developed the Policy to help you understand the following:</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">1. How we collect your personal data;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">2. How we use your personal data;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">3, How to disclose your personal data;</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">4. How to access or modify your personal data;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">5. How we protect your personal data;</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">6. How this Policy is updated.</span></span>
</p>

<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-align:center" class='part'>
    <span style=";font-family:微软雅黑;font-size:14px">Part 2 Specific Contents</span>
</p>
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">I. How we collect your personal data</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">Personal data is information that can be used alone or in conjunction with other information to identify an individual. When you purchase or use our products and/or services, there are two types of personal data that we need/may need to collect and use:</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style=";text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">1. In order to provide you with the basic functions of our products and/or services, you shall authorize us to collect and use the necessary information. If you refuse to provide the appropriate information, you will not be able to purchase, use our products and/or services properly, and we will not be able to respond to your questions.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style=";text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">2. In order to provide you with additional functions of our products and/or services, you may choose to authorize us to collect and use the information. If you refuse to provide it, you will not be able to use the additional features properly or to achieve the functional effects we intend to achieve, but this will not affect your normal use of the basic features of our products and/or services.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">You understand and agree that:</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style=";text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">a. Because we provide you with a wide range of products and services, and different users choose to use the specific product/service scope there are differences, the corresponding, basic / additional functions and the collection and use of personal data type, scope, etc. will be different, please take the specific product / service function.；</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style=";text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">b. From time to time, we may introduce new or optimized features that may require the collection, use of new personal data or changes in the purpose or manner for which personal data is used. In this regard, we will update this policy, pop-up window, page prompts, etc. to explain to you the purpose, scope and use of the corresponding information, and to provide you with your own choice of consent, and with your express consent to collect, use.</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">Furthermore</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style=";text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">a.  If the information you provide contains personal data of other users, you need to ensure that you have obtained a lawful authorization before providing us with such personal data.</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style=";text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">b. Your consent will be obtained in advance if we use the information for other purposes not set forth in this policy, or if we use the information collected for specific purposes for other purposes, or if we voluntarily obtain your personal data from third parties.</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style=";text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">c. If we obtain your information indirectly from a third party, we will explicitly and in writing require that third party collect personal data after obtaining your consent in accordance with the law, and inform you of the content of the information shared, and that sensitive information is provided to us with your clear confirmation before it is provided to us, requiring the third party to make a commitment to the legality and compliance of the source of personal data, if the third party has violated the law, we will explicitly hold the other party responsible for the corresponding legal liability. We use no less than our equivalent protection of our own user personal data to protect indirectly obtained personal data.</span></span>
</p>
<p style="margin-left:16px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">II. How we will use your personal data</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">We may use the personal data collected about you for the following purposes:</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">1. Create an account;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">2. Fulfill your transaction or service request, including fulfilling an order, delivering, activating or validating a product or service, participating in online or offline activities, making changes at your request or providing the information you request (e.g. marketing materials for the product or service), and providing technical support;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">3. Contact you with your consent, with your consent, to push information about products and services that may be of interest to you, to invite you to participate in our activities (including promotions), market research or satisfaction surveys, or to send you marketing messages, based on your browsing, search history, equipment information, order information, etc. If you do not wish to receive such information, you may withdraw it at any time.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">4. Send you important notifications, such as notifications for operating system or application updates and installations.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">5. To provide you with personalized user experience and personalized content.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">6. Certification and management of suppliers and business partners, with suppliers and business partners to communicate or do business.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">7. Conduct internal audit, data analysis and research to improve our products and services.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">8. Analyze business operational efficiency and measure market share.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">9. Troubleshoot the error if you choose to send us the details of the error.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">10. Sync, share, and store the data you upload or download, as well as the data you need to perform the upload and download.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">11. Protect the safety of our products, services and customers or users, and implement and improve our loss prevention and anti-fraud programs.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">12. Comply with and enforce applicable legal requirements, relevant industry standards or our policies.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">We will process your personal data appropriately, subject to applicable laws and regulations, including processing your personal data for the performance of the contract in response to your transaction or service request, processing your personal data with your consent, and processing your personal data for purposes such as contacting you, conducting marketing or market research, performing and improving our loss prevention and anti-fraud programs to improve our products and services, etc., and we will process your personal data in the legitimate interests of us or third parties and processing of your personal data based on compliance and enforcement obligations.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">III. How we disclose your personal data</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">1. Based on your express consent or voluntary choice, we may disclose your relevant information;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">2. In cases where certain services are provided by our authorized partners, we will share your personal data with that partner as described in this policy. For example, when you try and purchase our products and/or services through the Virsical Cloud Platform, we must share your personal data with your logistics service provider to arrange delivery or partner services;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">3. We may also disclose your personal data to relevant law enforcement agencies or other government agencies, subject to relevant laws and regulations or requirements of relevant law enforcement agencies.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">IV. How we manage your personal data</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">1.  You should ensure that all personal data submitted is accurate. We strive to maintain the accuracy and completeness of our personal data and to keep it up to date.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">2. Subject to applicable laws and regulations, you may (1) have access to certain personal data about you held by us, (2) require us to update or correct your inaccurate personal data, (3) refuse or restrict our use of your personal data, and (4) require us to delete your personal data. If you would like to exercise your rights, please give us feedback. For security, you may need to make a written request. If we have reasonable grounds to believe that these requests are deceptive, unsoligent, or impair the privacy of others, we will refuse to process the requests.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">3. In compliance with the relevant laws and regulations, when we process your personal data based on your consent, you also have the right to withdraw your consent at any time. However, the withdrawal of consent will not affect the legality and effectiveness of our processing of your personal data on the basis of your consent prior to withdrawal, nor will it affect our processing of your personal data on other appropriate grounds of legitimacy.</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">V. How we protect your personal data</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">We attach great importance to the security of personal data and will protect your personal data collected as follows:</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">1. We have taken industry standards, reasonable and feasible security measures to protect your personal data from unauthorized access, public disclosure, use, modification, damage or loss. For example, we use encryption to improve the confidentiality of personal data, we use trusted protection mechanisms to prevent malicious attacks on personal data, we deploy access control mechanisms to ensure that only authorized personnel have access to personal data, and we conduct training courses on security and privacy protection to enhance employee awareness of the importance of protecting personal data.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">2. We will take reasonable and feasible measures to avoid collecting irrelevant personal data. We will only retain your personal data for the period required to achieve the purposes set forth in the Cost Policy, and unless the mandatory retention requirements are published by laws and regulations, we will delete or anonymize your personal data as required by applicable law beyond the retention period.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="margin-left:0;text-indent:15px">
    <span style=";font-family:微软雅黑;font-size:14px">3. Although we will do our best to protect your information security, but please note that any measures are not impeccable. Also, in order to ensure the security of your personal data, please protect your personal data and provide it to others only if necessary. If you find that your personal data, especially your account or password, has been compromised, please contact us so that we can take appropriate action based on your application.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:14px">
    <span style=";font-family:微软雅黑;font-size:14px">4. In the event of an unfortunate personal data security incident, we will inform you in accordance with the requirements of laws and regulations: the relevant basic circumstances and possible impact, the measures we have taken or will take, the recommendations you can take and mitigate risks, remedial measures against you, etc. Event-related information we will inform you by mail, letter, telephone, push notice, etc., it is difficult to inform the subject of personal data, we will take a reasonable and effective way to issue an announcement.</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">Vi.How this policy is updated</span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;&nbsp;We reserve the right to update or modify this policy. We will publish the latest version of our Privacy Policy here. For significant changes, we may also provide you with notice of changes in different ways.</span>
</p>
<p>
    <br/>
</p>
`;

export const policyZhTw =
  style +
  `
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:21px"><span style="font-family:微软雅黑" class="title">
    隐私政策
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">發布者：威發（西安）軟件有限公司</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">生效日期：2022年08月03日</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">最新更新和發布日期：2023年1月11日</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">本聲明僅適用于由威發（西安）軟件有限公司（簡稱“我們”或“威思客雲”）提供服務或運營控制的威思客雲平台自身的功能、産品及服務。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">請您在提交個人數據之前，務必仔細閱讀並理解本政策，壹旦您提交完成，即表示您已充分理解並同意本政策。本政策僅適用于我們在威思客雲平台出售的産品或服務，如對本政策內容有任何疑問、意見或建議，您可通過電話或郵箱與我們聯系。</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    本政策闡述了我們如何處理您的個人數據，但本政策可能並不涉及所有可能的數據處理情境。未涉及的內容可能由我們在補充政策中，或者在收集數據時提供的通知中發布。
    </span></span>
</p>
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-align:center" class="part">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    第一部分
    </span> <span style="font-family:微软雅黑">
    製定本政策的目的
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    我們製定本政策目的在於幫助您了解以下內容：
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    1、我們如何收集您的個人數據；
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    2、我們如何使用您的個人數據；
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、如何披露您的個人數據；
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    4、如何訪問或修改您的個人數據；
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、我們如何保護您的個人數據；
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">6</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、本政策如何更新。
    </span></span>
</p>

<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p style="text-align:center" class='part'>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    第二部分
    </span></span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    具體內容
    </span></span>
</p>
<p style="text-align:center">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    一、我們如何收集您的個人數據
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    個人數據是指單獨使用或結合其他信息使用時能夠確定個人身份的信息。在您購買、使用我們的產品及</span>/或服務時，我們需要/可能需要收集和使用的您的個人數據包括如下兩種：
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    1、為實現向您提供我們產品及/或服務的基本功能，您須授權我們收集、使用的必要的信息。如您拒絕提供相應信息，您將無法正常購買、使用我們的產品及/或服務，我們也將無法回應您所遇到的問題；
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、為實現向您提供我們產品及</span>/或服務的附加功能，您可選擇授權我們收集、使用的信息。如您拒絕提供，您將無法正常使用相關附加功能或無法達到我們擬達到的功能效果，但並不會影響您正常使用我們產品及/或服務的基本功能。
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    您理解並同意：
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    1、因我們向您提供的產品和服務種類眾多，且不同用戶選擇使用的具體產品/服務範圍存在差異，相應的，基本/附加功能及收集使用的個人數據類型、範圍等會有所區別，請以具體的產品/服務功能為準；
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、
    我們可能會不時推出新的或優化後的功能，可能需要收集、使用新的個人數據或變更個人數據使用目的或方式。對此，我們將通過更新本政策、彈窗、頁面提示等方式另行向您說明對應信息的收集目的、範圍及使用方式，並為您提供自主選擇同意的方式，且在征得您明示同意後收集、使用。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    此外，
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、若你提供的信息中含有其他用戶的個人數據，在向我們提供這些個人數據之前，您需確保您已經取得合法的授權。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、若我們將信息用於本政策未載明的其他用途，或者將基於特定目的收集而來的信息用於其他目的，或者我們主動從第三方處獲取您的個人數據，均會事先獲得您的同意。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、若我們從第三方處間接獲取您的信息的，我們會在收集前明確以書面形式要求該第三方在已依法取得您同意後收集個人數據，並向您告知共享的信息內容，且涉及敏感信息的在提供給我們使用前需經過您的明確確認，要求第三方對個人數據來源的合法性和合規性作出承諾，如第三方有違反行為的，我們會明確要求對方承擔相應法律責任。我們會使用不低於我們對自身用戶個人數據同等的保護手段與措施對間接獲取的個人數據進行保護。
    </span></span>
</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">接入第三方SDK清單：</p>
<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">1) 騰訊X5浏覽器（TencentSmtt）</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">使用目的：爲各系統版本、廠商用戶提供統壹的web應用使用體驗</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">收集個人信息類型：IMSI</p>

<p style="text-indent:28px;font-family:微软雅黑;font-size:14px">2) 騰訊bugly</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">使用目的：爲提高服務穩定性，便于相關服務崩潰後快速准確定位存在問題</p>
<p style="text-indent:48px;font-family:微软雅黑;font-size:14px">收集個人信息類型：Crash環境（Crash信息及線程堆棧，ROM/RAM/SD卡容量、網絡/語言狀態App信息：包名、版本、所屬進程名）、設備信息、設備廠商、設備名稱、操作系統版本、運營商代碼，App運行狀態，閃退、卡頓、網絡信息、日志信息，以及Android_id、IMSI設備信息</p>

<p style="margin-left:16px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    二、我們將如何使用您的個人數據
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    我們可能將所收集的您的個人數據用於以下目的：
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">1、 創建賬戶；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span></span><span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">實現您的交易或服務請求，包括履行訂單；交付、激活或驗證產品或服務；參加線上或線下活動；應您的要求進行變更或者提供您請求的信息（例如產品或服務的營銷資料）；以及提供技術支持；
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">
    根據您的瀏覽、搜索記錄、設備信息、訂單信息等特征，在您同意的情況下，與您聯系；向您推送有關您可能感興趣的產品和服務的信息；邀請您參與我們的活動（包括促銷活動）、市場調查或滿意度調查；或向您發送營銷信息。如果您不想接收此類信息，則可以隨時退訂。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">4</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">
    向您發送重要通知，如操作系統或應用程序更新和安裝的通知。
    </span></span>
</p>

<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、認證和管理供應商及業務合作夥伴，與供應商及業務合作夥伴溝通或開展業務。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">6</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">
    開展內部審計、數據分析和研究，改善我們的產品和服務。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">7</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、分析業務運營效率並衡量市場份額。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">8</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">
    在您選擇向我們發送錯誤詳情的情況下排查錯誤。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">9</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">
    同步、共享和存儲您上傳或下載的數據以及執行上傳和下載所需的數據。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">10</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、保護我們產品、服務和客戶或用戶的安全，執行與改善我們的防損和反欺詐計劃。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">11</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、</span> <span style="font-family:微软雅黑">
    遵從和執行適用的法律要求，相關的行業標準或我們的政策。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    我們會在遵從相關法律法規規定的前提下，適當的處理您的個人數據，包括：當響應您的交易或服務請求時為履行合同處理您的個人數據；基於您的同意處理您的個人數據；當與您聯系、進行營銷或市場調查，為改善我們的產品和服務，執行與改善我們的防損和反欺詐計劃等目的處理您的個人數據時我們將基於我們或第三方的合法利益；以及基於遵從和執行法律義務處理您的個人數據。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    三、我們如何披露您的個人數據
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    1、基於您的明確同意或主動選擇；我們可能將披露您的相關信息；
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、在某些服務由我們的授權合作夥伴提供的情況下，我們會如本政策描述與該合作夥伴共享您的個人數據。例如，在您通過威思客雲平臺試用、購買我們的產品及
    </span>/或服務時，我們必須與物流服務提供商共享您的個人數據才能安排送貨，或者安排合作夥伴提供服務；</span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、在相關法律法規定或相關執法部門要求的情況下，我們也可能會向相關的執法機關或者其他政府機關披露您的個人數據。
    </span></span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    四、我們如何管理您的個人數據；
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    1、您應確保提交的所有個人數據都準確無誤。我們會盡力維護個人數據的準確和完整，並及時更新這些數據。
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">、
    在符合相關法律法規的規定的情況下，您可能
    </span>
    (1)有權訪問我們持有的關於您的特定的個人數據；(2)要求我們更新或更正您的不準確的個人數據；(3)拒絕或限製我們使用您的個人數據；以及(4)要求我們刪除您的個人數據。如果您想行使相關的權利，請反饋給我們。為保障安全，您可能需要提供書面請求。如果我們有合理依據認為這些請求存在欺騙性、無法實行或損害他人隱私權，我們則會拒絕處理請求。
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    3、在符合相關法律法規規定的情況下，當我們基於您的同意處理您的個人數據時，您還有權隨時撤銷您的同意。但撤銷同意不會影響撤銷前我們基於您的同意處理您個人數據的合法性及效力，也不影響我們基於其他適當的正當性基礎處理您的個人數據。
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    五、我們如何保護您的個人數據
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    我們高度重視個人數據安全，對收集的您的個人數據將按照如下方式進行保護：
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">
    1、我們已采取符合業界標準、合理可行的安全防護措施保護您提供的個人數據遭到未經授權訪問、公開披露、使用、修改、損壞或丟失。例如，我們會使用加密技術提高個人數據的機密性；我們會使用受信賴的保護機製防止個人數據遭到惡意攻擊；我們會部署訪問控製機製，盡力確保只有授權人員才可訪問個人數據；以及我們會舉辦安全和隱私保護的相關培訓課程，加強員工對於保護個人數據重要性的認識。
    </span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">2</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、我們會采取合理可行的措施，盡力避免收集無關的個人數據。我們只會在達成本政策所述目的所需的期限內保留您的個人數據，除非法律法規公布的關於強製留存的規定，我們將在您的個人數據超出保留期間後，根據適用法律的要求刪除您的個人數據，或對其進行匿名化處理。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">3</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、如果您所在企業組織希望注銷賬戶時，可以撥打客服熱線（400-685-3160）或發送郵件至service@virsical.com主動申請賬戶注銷，我們將在7個工作日內爲您注銷賬號，並清除所有個人信息。
    </span></span>
</p>

<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">4</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、雖然我們會盡全部所能保護您的信息安全，但請註意任何措施均非能無懈可擊。並且，為保證個人數據的安全，請您妥善保護自己的個人數據，僅在必要的情形下向他人提供。如您發現自己的個人數據尤其是您的賬號或密碼發生泄露，請您立與我們聯系，以便我們根據您的申請采取相應措施。
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px">5</span><span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    、如不幸發生個人數據安全事件，我們將按照法律法規的要求向您告知：相關基本情況和可能的影響、我們已采取或將要采取的處置措施、您可自主防範和降低風險的建議、對您的補救措施等。事件相關情況我們將以郵件、信函、電話、推送通知等方式告知您，難以逐一告知個人數據主體時，我們會采取合理、有效的方式發布公告。
    </span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px">&nbsp;</span>
</p>
<p>
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑" class="subtitle">
    六、本政策如何更新
    </span></span>
</p>
<p style="text-indent:28px">
    <span style=";font-family:微软雅黑;font-size:14px"><span style="font-family:微软雅黑">
    我們保留更新或修改本政策的權利。我們會在這裏發布對最新版的隱私政策。對於重大變更，我們還會提供通過不同方式向您發送變更通知。
    </span></span>
</p>
<p>
    <br/>
</p>
`;
