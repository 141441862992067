/* eslint-disable camelcase */
import index from './zh_CN/index';
import common from './zh_TW/common';
import login from './zh_TW/login';
import hardware from './zh_TW/hardware';
import case_tw from './zh_TW/case';
import about_tw from './zh_TW/about';
import product_tw from './zh_TW/product';
import download_tw from './zh_TW/download';
import banner_tw from './zh_TW/banner';
import price_tw from './zh_TW/price';
import register_tw from './zh_TW/register';
import intl_tw from './zh_TW/intl.json'
import error from './zh_TW/error';

export default {
  ...index,
  ...common,
  ...login,
  ...hardware,
  ...case_tw,
  ...product_tw,
  ...register_tw,
  ...download_tw,
  ...price_tw,
  ...banner_tw,
  ...about_tw,
  ...intl_tw,
  ...error,
};
