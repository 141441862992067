/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { formatMessage, history, connect, getLocale } from 'umi';
import { useMount } from 'ahooks';
import { Form, Input, Select, Button, Checkbox, Tooltip, message } from 'antd';
import { zhIntl } from '@/utils/intlUtils';
import { QuestionCircleOutlined } from '@ant-design/icons';
import VerifySlideFixed from '@/components/SliderVerify';
import { countryAreaCode } from '@/utils/constant';
import regCn from '@/assets/login/regCn.png';
import regEn from '@/assets/login/regEn.png';
import mapCn from '@/assets/login/mapCn.png';
import mapEn from '@/assets/login/mapEn.png';
import styles from './index.less';

const Register = ({ dispatch, cloud, location }) => {
  const [form] = Form.useForm();
  const { phoneAreaCode } = cloud;

  const [account, setAccount] = useState('');
  const [areaCode, setAreaCode] = useState(
    sessionStorage.getItem('isChina') === 'true' ? '+86' : '+852',
  );
  const [loading, setLoading] = useState(false);
  const [codeStyle, setCodeStyle] = useState('');
  const [validCode, setValidCode] = useState(true);
  const [codeText, setCodeText] = useState(formatMessage({ id: 'register.Get.verification.code' }));
  const [showSlider, setShowSlider] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [initData, setInitData] = useState('');
  const [isTools, setIsTools] = useState(false);

  const noSendSms = (time = 60, check = false) => {
    if (check) {
      const maxTime = localStorage.getItem('regisSmsTime');
      if (!maxTime) return;
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) return;
    } else {
      localStorage.setItem('regisSmsTime', Date.now() + time * 1000);
    }
    setValidCode(false);
    setCodeStyle(styles.noClick);
    window.tVal = setInterval(() => {
      const maxTime = localStorage.getItem('regisSmsTime');
      const hasTime = Math.ceil((Number(maxTime) - Date.now()) / 1000);
      if (hasTime <= 0) {
        clearInterval(window.tVal);
        setValidCode(true);
        setCodeStyle('');
        setCodeText(formatMessage({ id: 'register.locales.verification.code' }));
        localStorage.removeItem('regisSmsTime');
      } else {
        setCodeText(`${hasTime}${formatMessage({ id: 'register.Resend.in.seconds' })}`);
      }
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isCheck) {
      form.setFieldsValue({ pactCheck: true });
    } else {
      form.setFieldsValue({ pactCheck: undefined });
    }
    form.setFieldsValue({ account: account.trim() });
    form.validateFields().then((value) => {
      setLoading(true);
      window.console.log('注册');
      dispatch({
        type: 'tryData/submit',
        payload: {
          ...value,
          account: account.includes('@') ? account.trim() : `${areaCode}${account.trim()}`,
          areaCode,
          signType: isTools ? 'map' : undefined,
        },
      });
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
  };

  useMount(() => {
    dispatch({ type: 'cloud/getCommonInitData' }).then((res) => {
      setInitData(res);
    });
    dispatch({ type: 'cloud/getAreaCode' });
  });

  useEffect(() => {
    if (location?.state?.type) {
      setIsTools(true);
    }
  }, [location]);

  return (
    <div className={styles.body}>
      {isTools ? (
        <img src={getLocale() === 'en-US' ? mapEn : mapCn} className={styles.img} />
      ) : (
        <img src={getLocale() === 'en-US' ? regEn : regCn} className={styles.img} />
      )}
      <div className={styles.content}>
        <div className={styles.area}>
          <div className={styles.title}>{zhIntl('欢迎注册威思客云服务')}</div>
          <div className={styles.form}>
            <Form form={form}>
              <Form.Item
                name="tenantName"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'register.locales.error.company' }),
                  },
                  {
                    max: 50,
                    message: formatMessage({ id: 'register.locales.error.company.length' }),
                  },
                ]}
              >
                <Input
                  maxLength={50}
                  placeholder={formatMessage({ id: 'register.locales.error.company' })}
                />
              </Form.Item>
              <Form.Item
                name="contactRealName"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'register.locales.error.contacts' }),
                  },
                  {
                    min: 2,
                    max: 20,
                    message: formatMessage({ id: 'register.locales.error.contacts.length' }),
                  },
                ]}
              >
                <Input
                  maxLength={20}
                  placeholder={formatMessage({ id: 'register.locales.error.contacts' })}
                />
              </Form.Item>

              <Form.Item
                name="account"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'login.account.tip' }),
                  },
                  {
                    pattern:
                      Number(account).toString().length < 6 || account.includes('@')
                        ? /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
                        : /^\d{6,15}$/,
                    message: formatMessage({ id: 'login.account.regex.err' }),
                  },
                ]}
              >
                <Input.Group compact style={{ display: 'flex' }}>
                  {Number(account).toString().length < 6 || account.includes('@') ? null : (
                    <Select
                      style={{ height: 55 }}
                      value={areaCode}
                      onChange={(e) => setAreaCode(e)}
                      optionLabelProp="label"
                    >
                      {countryAreaCode(phoneAreaCode)}
                    </Select>
                  )}
                  <Input
                    style={{
                      width:
                        Number(account).toString().length < 6 || account.includes('@')
                          ? '100%'
                          : 'calc(100% - 80)',
                    }}
                    placeholder={formatMessage({ id: 'login.account.tip' })}
                    allowClear
                    value={account}
                    maxLength={50}
                    onChange={(e) => setAccount(e.target.value)}
                  />
                </Input.Group>
              </Form.Item>

              <Form.Item
                name="captcha"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'register.locales.error.Verification' }),
                  },
                  {
                    pattern: /^\d{2,6}$/,
                    message: formatMessage({
                      id: 'register.locales.error.Verification.error',
                    }),
                  },
                ]}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Input
                    autoComplete="new-password"
                    maxLength={6}
                    style={{ width: '100%' }}
                    placeholder={formatMessage({ id: 'register.locales.error.Verification' })}
                    suffix={
                      <a
                        className={codeStyle}
                        onClick={() => {
                          form.setFieldsValue({ account: account.trim() });
                          form.validateFields(['account']).then(() => {
                            if (validCode) {
                              setShowSlider(true);
                            }
                          });
                        }}
                      >
                        {codeText}
                      </a>
                    }
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'register.locales.password' }),
                  },
                  {
                    // 密码验证规则，改为动态配置
                    pattern: initData.pwdRegex,
                    message: formatMessage({ id: 'register.locales.error.password.error' }),
                  },
                ]}
              >
                <Input
                  maxLength={50}
                  type="password"
                  autoComplete="new-password"
                  placeholder={formatMessage({ id: 'register.locales.password' })}
                  suffix={
                    <Tooltip
                      placement="top"
                      title={getLocale() === 'en-US' ? initData.pwdTipEn : initData.pwdTipZh}
                    >
                      <QuestionCircleOutlined style={{ color: '#acacac' }} />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <Form.Item
                name="pactCheck"
                rules={[
                  {
                    required: true,
                    message: formatMessage({ id: 'register.locales.Privacy.services' }),
                  },
                ]}
              >
                <div className={styles.pactCheck}>
                  <Checkbox
                    onChange={(e) => {
                      setIsCheck(e.target.checked);
                    }}
                  />
                  <div className={styles.policy}>
                    {formatMessage({ id: 'register.locales.Privacy.services.read' })}
                    <a
                      onClick={() => window.open(`${window.location.origin}/service/?type=service`)}
                    >
                      {formatMessage({ id: 'register.locales.Terms.of.service' })}
                    </a>
                    {formatMessage({ id: 'register.and' })}
                    <a
                      onClick={() => window.open(`${window.location.origin}/service/?type=policy`)}
                    >
                      {formatMessage({ id: 'register.locales.Privacy.policy' })}
                    </a>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: '100%',
                    height: 55,
                    marginTop: -4,
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                  onClick={handleSubmit}
                >
                  {formatMessage({ id: 'register.locales.Registered' })}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <a className={styles.back} onClick={() => history.push('/')}>
          {zhIntl('返回首页')}
        </a>
      </div>

      {showSlider && (
        <VerifySlideFixed
          isSlideShow={showSlider}
          dispatch={dispatch}
          verifyPointFixedChild={(data) => setShowSlider(data)}
          onCheckSuccess={(value) => {
            if (value) {
              setTimeout(() => {
                setShowSlider(false);
                if (validCode) {
                  setCodeStyle(styles.noClick);
                  dispatch({
                    type: 'cloud/sendMailCode',
                    payload: {
                      verification: account.includes('@')
                        ? account.trim()
                        : `${areaCode}${account.trim()}`,
                      source: 1,
                      randomStr: 'captcha',
                      code: value,
                    },
                    callBack: () => {
                      noSendSms();
                    },
                    errorCallBack: () => {
                      setCodeStyle('');
                    },
                  }).then((res) => {
                    if (res === 'success') {
                      message.success(formatMessage({ id: 'register.verification.success' }));
                    }
                  });
                }
              }, 800);
            }
          }}
        />
      )}

      <style>
        {`
          .ant-select-dropdown {
            width: auto !important;
            border-radius: 14px !important;
            padding: 16px 0 !important;
          }
        `}
      </style>
    </div>
  );
};

export default connect((state) => state)(Register);
