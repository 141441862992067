/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { Select } from 'antd';
import { getLocale } from 'umi';
// import china from '@/assets/areacode/china.png';
// import hk from '@/assets/areacode/hk.png';
// import korea from '@/assets/areacode/korea.png';
// import singapore from '@/assets/areacode/singapore.png';
// import thailand from '@/assets/areacode/thailand.png';
// import usa from '@/assets/areacode/usa.png';
// import { zhIntl } from '@/utils/intlUtils';

export const CHINESE = 'zh_CN';
export const ENGLISH = 'en_US';

// 云服务
export const CLOUD_AES_KEY = 'XDH206VF30Nf9q7tXDH206VF30Nf9q7t';
export const PASSWORD_AES_VI = 'XDH206VF30Nf9q7t';
// clientId:clientSecret
export const CLOUD_CLIENT_ID = 'OMS';
export const CLOUD_CLIENT_SECRET = 'OMS';

export const CLOUD_USER_ID = 'test';
export const CLOUD_USER_SECRET = 'test';

export const INIT_PASSWORD = 'K3LTSg32SN';
// 计算签名值key
export const SECRET_KEY = 'mykey123456';

export const COPYRIGHT =
  '© 北京威发新世纪信息技术有限公司 京ICP备13045473号-2 京公网安备 11010102002596号';
export const COPYRIGHT_zhTW =
  '© 北京威發新世紀信息技術有限公司 京ICP備13045473號-2 京公網安備 11010102002596號';
export const COPYRIGHT_enUS = '';

export const ROUTE_MAP = {
  index: '/',
  login: '/login',
  passwd: '/passwd',
  register: '/register',
  product: '/product',
  hardware: '/hardware',
  robot: '/hardware/robot',
  meeting: '/product',
  space: '/product',
  price: '/product/price',
  case: '/case',
  help: '/help',
  about: '/about',
  download: '/download',
  service: '/service',
  findpwd: '/login/find',
  smsquery: '/sms',
  editpwd: '/login/editPwd',
  active: '/login/active',
};

const interfaceDefault = 'vsk/';
export const DEFAULT_INTERFACE = interfaceDefault;

export const RESTFUL_PATH = {
  auth: `${DEFAULT_INTERFACE}virsical-auth`, //
  admin: `${DEFAULT_INTERFACE}virsical-upms`, //
  upms: `${DEFAULT_INTERFACE}virsical-upms`, //
  meeting: 'smt-meeting',
  monitor: 'smt-monitor',
  syncuser: 'virsical-syncuser',
  notice: 'virsical-notice',
  map: 'virsical-map',
  push: 'virsical-push',
  sss: `${DEFAULT_INTERFACE}virsical-sss`, //
  sws: 'sws-workspace',
  lease: 'sws-lease',
  wdf: `${DEFAULT_INTERFACE}virsical-wdf`,
  access: 'smartaccess',
  config: `${DEFAULT_INTERFACE}virsical-config`, //
  visitor: `${DEFAULT_INTERFACE}vst-visitor`, //
  cloudOms: `${DEFAULT_INTERFACE}cloud-oms`,
  cloudAdmin: `${DEFAULT_INTERFACE}admin`, //
};

export const ACCESS_TOKEN = 'access_token';
export const RESTFUL_SIGNATURE = true;
// 接口签名key
export const SING_KEY = 'M3n6hK';

// message最大同时显示数
export const MAX_MESSAGE_COUNT = maxMessageCount;

// 静态资源路径
export const STATIC_RESOURCE_PREFIX = staticResourcePrefix;

// 国家区号
export const countryAreaCode = (codeList) => {
  // const codeList = [
  //   { value: '+86', label: '+86', content: zhIntl('(中国)'), img: china },
  //   { value: '+852', label: '+852', content: zhIntl('(中国香港)'), img: hk },
  //   { value: '+82', label: '+82', content: zhIntl('(韩国)'), img: korea },
  //   { value: '+65', label: '+65', content: zhIntl('(新加坡)'), img: singapore },
  //   { value: '+66', label: '+66', content: zhIntl('(泰国)'), img: thailand },
  //   { value: '+1', label: '+1', content: zhIntl('(美国)'), img: usa },
  // ];

  return codeList?.map((item) => {
    const remarks = JSON.parse(item?.remarks);
    const title = getLocale() === 'en-US' ? remarks?.en : remarks?.zh;
    return (
      <Select.Option value={item.value} label={item.value} key={item.value}>
        <div
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
            width: 'auto',
            height: 24,
            padding: '0 10px',
          }}
        >
          <div style={{ marginRight: 12 }}>{item.value}</div>
          <div>{title}</div>
          {/* <img src={item.img} alt="" width={22} height={14} /> */}
        </div>
      </Select.Option>
    );
  });
};
