export default {
  // 'product.introduc': '',
  'product.Intelligent.Cloud.visitor.platform': '智能化的云访客平台',
  'product.Intelligent.meet.visitor.platform': '智能化的云会议平台',
  'product.introduc.Cloud.visitor.advantage1': '多种登录方式',
  'product.introduc.Cloud.visitor.advantage1.desc':
    '微信小程序，浏览器，APP，随时随地处理访客预约，访客邀请业务。',
  'product.introduc.Cloud.visitor.advantage2': '个性化模版',
  'product.introduc.Cloud.visitor.advantage2.desc': '多种个性化访客卡模版，彰显企业风采。',
  'product.introduc.Cloud.visitor.advantage3': '全方位提醒',
  'product.introduc.Cloud.visitor.advantage3.desc':
    '集成短信，邮件等多种方式访客预约，访客到访提醒，不再错过重要客户。',
  'product.introduc.Cloud.visitor.advantage4': '证件识别',
  'product.introduc.Cloud.visitor.advantage4.desc':
    '自动识别身份证件，大幅度提高等级效率，保证信息准确性。',
  'product.introduc.Cloud.visitor.advantage5': '安全审批流程',
  'product.introduc.Cloud.visitor.advantage5.desc': '鉴别访客身份，提高访问的安全性。',
  'product.introduc.Cloud.visitor.advantage6': '团体邀请管理',
  'product.introduc.Cloud.visitor.advantage6.desc':
    '自动生成邀请链接，满足人员较多的会议，活动等场景需求。',
  'product.introduc.Cloud.visitor.advantage7': '数据分析展示',
  'product.introduc.Cloud.visitor.advantage7.desc':
    '全面透视邀请，到访结果，灵活查询报表，助力决策。',
  'product.introduc.Cloud.visitor.advantage8': '黑名单管理',
  'product.introduc.Cloud.visitor.advantage8.desc': '有效限制不良来访，为企业安全保驾护航。',
  'product.introduc.Cloud.meet.subTitle1': '多种登录方式',
  'product.introduc.Cloud.meet.subTitle1.desc': `智能会议系统具备会议预约、审批、通知、签到、服务、展板和报表统计等全生命周期的会议管理能力，通过AIOT软硬件协作提供：人体感应、环境感知、设备联动、语音交互、人脸识别等全方位的智能会议室解决方案，并与Zoom/Cisco Webex等协作会议系统集成，全方位覆盖线上和线下会议全场景。`,
  'product.introduc.Cloud.meet.subTitle2': '多种会议预约方式',
  'product.introduc.Cloud.meet.subTitle2.desc': `PC端，app端，以及微信小程序、企业微信、钉钉的集成，通过多种方式方便您快捷预约会议，随时随地掌握会议状态。`,
  'product.introduc.Cloud.meet.subTitle3': '智能感知',
  'product.introduc.Cloud.meet.subTitle3.desc': `多种iot智能硬件的全范围监控，实现人体感应、环境感知、设备联动等会议智慧化。`,
  'product.introduc.Cloud.meet.subTitle4': '多种集成扩展应用',
  'product.introduc.Cloud.meet.subTitle4.desc': `Exchange/Office 365集成模式，支持双向同步和多种应用场景，如红外传感器互动、超长会议，周期会议、冲突模式、需审批会议、非对称会议账号等。`,
  'product.introduc.tabs1': '智能访客',
  'product.introduc.visitor.subTitle1': '访客邀约系统化',
  'product.introduc.visitor.subTitle1.desc': `便捷的邀请预约功能，零成本部署企业工作人员或外部访客自助邀约操作，依托PC端、Pad端、移动端等无缝对接邀约需求，打造企业级云访客管理平台。`,
  'product.introduc.visitor.subTitle2': '身份认证渠道化',
  'product.introduc.visitor.subTitle2.desc': `安全开放的访客身份认证理念，访客到达前台可自主确认身份信息，企业员工可第一时间多种渠道接收访客来访通知，最大化贴合企业的访客管理需求。`,
  'product.introduc.visitor.subTitle3': '数据统计信息化',
  'product.introduc.visitor.subTitle3.desc': `稳定的云服务后台支持，满足企业数据化统计访客预约、签入/签出、身份信息等相关记录的需求。具备多字段高效查找、信息统计易操作、云端存储大容量等优势，解决企业访客环境中的数据管理难题。`,
  'product.introduc.visitor.subTitle4': '访客接待高时效',
  'product.introduc.visitor.subTitle4.desc': `智能高效的运维体系，全面贯穿访客卡打印、二维码签入签出、人脸拍照等访客接待流程，配合现代化设备配置，使得访客接待智能化并且流程灵活便捷，满足企业、园区、楼宇等多种场景。`,
  'product.introduc.tabs2': '智能会议',
  'product.introduc.tabs3': '空间管理',
  'product.introduc.Cloud.visitor': '威思客云访客',
  'product.introduc.Cloud.visitor.sub1': '洞见现代访客场景，聚焦智能企业未来',
  'product.introduc.Cloud.visitor.sub2': '全新的平台运维规划，稳定的云服务支持力',
  'product.introduc.Cloud.visitor.sub3': '高效的访客接待流程',
  'product.introduc.meet.visitor': '威思客云会议',
  'product.introduc.meet.visitor.sub1': '全场景、全生命周期的会议解决方案',
  'product.introduc.meet.visitor.sub2': '为企业带来智能、高效、简单、轻松的',
  'product.introduc.meet.visitor.sub3': '新型会议室预订管理体验',
  'product.introduc.header.visitor.btn1': '观看视频',
  'product.introduc.header.visitor.btn2': '免费体验',
  'product.introduc.meet.visitor.advantage1': '多种预约方式',
  'product.introduc.meet.visitor.advantage1.desc':
    '微信小程序、企业微信、钉钉、App、Web浏览器等，随时随地预约和管理会议。',
  'product.introduc.meet.visitor.advantage2': '会议展板',
  'product.introduc.meet.visitor.advantage2.desc':
    '多种规格的可选硬件，多种风格的可选模板，并支持自动开关机、远程监控、故障告警等功能。',
  'product.introduc.meet.visitor.advantage3': '签到及消息通知',
  'product.introduc.meet.visitor.advantage3.desc':
    '短信、邮件、微信、钉钉等多种消息通知方式可选，参会人到达会场后，可以在会议展板上通过账号密码、扫码、人脸识别等多种方式签到。',
  'product.introduc.meet.visitor.advantage4': '会议服务',
  'product.introduc.meet.visitor.advantage4.desc':
    '管理员定义会议服务套餐，预订人会前和会中申请会议服务，并支持会议服务费用和会议室使用费用计费和统计等。',
  'product.introduc.meet.visitor.advantage5': 'Collaboration Meeting集成',
  'product.introduc.meet.visitor.advantage5.desc':
    '支持预约和管理Zoom、WebEx等网络视频会议，和线上线下会议室混合预约，并通过集成Zoom Rooms互动大屏。',
  'product.introduc.meet.visitor.advantage6': 'Exchange/Office365集成',
  'product.introduc.meet.visitor.advantage6.desc':
    '与Exchange或Office 365集成，实现会议数据双向同步。',
  'product.introduc.meet.visitor.advantage7': '智能硬件联动',
  'product.introduc.meet.visitor.advantage7.desc':
    '通过智能硬件，实现人体感应、环境感知、设备联动、语音交互、人脸识别等智慧联动。',
  'product.introduc.meet.visitor.advantage8': '空间状态感应器联动',
  'product.introduc.meet.visitor.advantage8.desc':
    '基于红外智能感应器实时监测数据，实现会议自动签到以及无人自动释放会议室等。',

  'product.introduc.workspace.subTitle1': '数据采集与分析',
  'product.introduc.workspace.subTitle1.desc': `针对企业固定/共享工位、部门/项目组工位、创业空间/园区工位等可视化及分类管理。`,
  'product.introduc.workspace.subTitle2': `“融合”解决方案`,
  'product.introduc.workspace.subTitle2.desc': `通过增加采集和整理可提供数据与分析和流程自动化之间的互联网络使得实现真正的智能成为可能。提供跨越租赁、成本计算、空间管理资产维护、环境和能源管理等元素——并将所有元素合并计算于单一技术平台的解决方案。`,
  'product.introduc.workspace.subTitle3': '平台的解决方案',
  'product.introduc.workspace.subTitle3.desc': `具备地图上搜索位置和预订方式，更加直观找人找位置；移动应用随手携带，随心所用。`,
  'product.introduc.workspace.subTitle4': '管理系统结构',
  'product.introduc.workspace.subTitle4.desc': `提供具有高度可视性、可控性和自动化，并跨功能的能力，以及“至繁归于至简”的管理系统结构。`,

  'product.introduc.workspace.advantage1': '多种预订方式',
  'product.introduc.workspace.advantage1.desc': '浏览器、APP，随时随查看和预订工位。',
  'product.introduc.workspace.advantage2': '工位申请与审批',
  'product.introduc.workspace.advantage2.desc': '让工位使用更加系统化，彰显企业风采。',
  'product.introduc.workspace.advantage3': '工位报修',
  'product.introduc.workspace.advantage3.desc':
    '集成短信，邮件等多种方式访客预约，访客到访提醒，不再错过重要客户。',
  'product.introduc.workspace.advantage4': '工位盘点',
  'product.introduc.workspace.advantage4.desc':
    '自动识别身份证件，大幅度提高等级效率，保证信息准确性。',
  'product.introduc.workspace.advantage5': '地图管理',
  'product.introduc.workspace.advantage5.desc': '鉴别访客身份，提高访问的安全性。',
  'product.introduc.workspace.advantage6': '设施管理',
  'product.introduc.workspace.advantage6.desc':
    '自动生成邀请链接，满足人员较多的会议，活动等场景需求。',
  'product.introduc.workspace.advantage7': '资源管理',
  'product.introduc.workspace.advantage7.desc': '全面透视邀请，到访结果，灵活查询报表，助力决策。',
  'product.introduc.workspace.advantage8': '数据统计',
  'product.introduc.workspace.advantage8.desc': '有效限制不良来访，为企业安全保驾护航。',

  'product.introduc.fm.subTitle1': '信息化',
  'product.introduc.fm.subTitle1.desc':
    '建立信息化管理平台，优化管理流程，提升工作效率，助力降低运营成本',
  'product.introduc.fm.subTitle2': '流程化',
  'product.introduc.fm.subTitle2.desc':
    '固定标准化业务流程，增强协同合作，实现系统统一管理，增强管理透明度',
  'product.introduc.fm.subTitle3': '智能化',
  'product.introduc.fm.subTitle3.desc':
    '集成智能硬件设备，形成统一管控平台，实现万物互联的智能化管控',
  'product.introduc.fm.subTitle4': '智慧化',
  'product.introduc.fm.subTitle4.desc':
    '结合Al人工智能、数字孪生等技术，使楼宇自我感知，增强用户无感体验',

  'product.introduc.fm.advantage1': '项目管理',
  'product.introduc.fm.advantage1.desc':
    '企业总部项目制、站点制独立运行，提高项目运行效率，提升集团统一管理',
  'product.introduc.fm.advantage2': '设备资产',
  'product.introduc.fm.advantage2.desc':
    '统一设备资产管理平台，实现设备运行监控管理，有效延长设备运行周期',
  'product.introduc.fm.advantage3': '服务中心',
  'product.introduc.fm.advantage3.desc': '楼宇用户服务请求统一入口管理，提升楼宇运维管理能力',
  'product.introduc.fm.advantage4': '工单管理',
  'product.introduc.fm.advantage4.desc':
    '完整的工作流引擎自配置应用，通过灵活的配置引擎完美匹配应用场景',
  'product.introduc.fm.advantage5': '巡检管理',
  'product.introduc.fm.advantage5.desc':
    '移动端数字化线上巡检，实现巡检管理实时进度在线管理，数字化管控',
  'product.introduc.fm.advantage6': '预防性维护管理',
  'product.introduc.fm.advantage6.desc':
    '日常维护计划统一管理平台实现线下维护工作线上化，并进行维护任务可视化应用及管理',
  'product.introduc.fm.advantage7': '知识库管理',
  'product.introduc.fm.advantage7.desc':
    '针对不同类型的设备进行故障定义，通过数据积累建立故障原因和处理方法',
  'product.introduc.fm.advantage8': '在岗管理',
  'product.introduc.fm.advantage8.desc':
    '采用WiFi、GPS定位、蓝牙等技术进行在线签到，并实时同步员工在岗情况',

  'producr.introduc.fm.equipment.ledger': '设备台账',
  'producr.introduc.fm.inventory.management': '库存管理',
  'producr.introduc.fm.contract.management': '合同管理',
  'producr.introduc.fm.supplier.management': '供应商管理',

  'product.introduc.esg.tip1': '环境能碳管理系统',
  'product.introduc.esg.tip2':
    '广泛快速的自动采集，精准分析和智能优化，推进企业数字化转型，促进企业ESG目标，响应国家双碳战略',
  'product.introduc.esg.subTitle1': '综合能源环境自动采集',
  'product.introduc.esg.subTitle1.desc': `可实现园区楼宇的水、电、气、暖、环境、光伏、储能、充电桩等综合能源数据快速接入，对实时能源&碳排放数据监测，电力采集使用无线无源装置，免停电，对正常运营零影响零损失，环境监测执行Well标准，监测温湿度、PM2.5、PM10、TVOC、二氧化碳等数据，并配有相关标准解读。`,
  'product.introduc.esg.subTitle2': '南北向系统设备广泛接入',
  'product.introduc.esg.subTitle2.desc': `平台API，采用云云对接，边云对接；边缘API，三方硬件接入、三方服务接入、边缘数据分析、三方集成文档、数据推送API、Kafka、Rabbitmq、Mqtt、Azure Servicebus、AWS SQS、PUBSUB。`,
  'product.introduc.esg.subTitle3': '智能调控，流程闭环',
  'product.introduc.esg.subTitle3.desc': `根据园区楼宇场景化数据分析诊断、系统分析和精准调控形成楼宇智能化监管闭环，以场景化控制策略，替代传统单设备控制操作，有效通过动静、日光等感知器件，实现智能化控制；通过会议系统集成、时间定时管理、远程控制管理、BMS系统集成、中央空调节能控制、智能照明控制、环境智能管理、远程/定时/计划控制等，提高能源调控水平。`,
  'product.introduc.esg.subTitle4': '多维度的可视化数据展示',
  'product.introduc.esg.subTitle4.desc': `采用GIS、BIM、CIM、3D/2D集成展示园区楼宇数据，能源、碳、环境的实时数据、历史数据，同比、环比等，同时具备大屏、信发、PC/APP多端展示及互动功能，运行键康参数一目了然。`,
  'product.introduc.Cloud.esg.advantage1': '助力企业ESG战略',
  'product.introduc.Cloud.esg.advantage1.desc':
    '帮助公司管理能源使用及消耗，识别并解决能源浪费问题，更好地应对投资者和其他利益相关者的关切，减少对环境的负面影响，为公司赢得更好的社会声誉',
  'product.introduc.Cloud.esg.advantage2': '促进数字化转型',
  'product.introduc.Cloud.esg.advantage2.desc':
    '采用物联网、云计算、大数据、AI、5G等信息化技术对园区楼宇综合能源、碳排、环境实现全生命周期动态监测，实现社会责任与企业发展、节能减碳与增产增效的双平衡',
  'product.introduc.Cloud.esg.advantage3': '洞察分析优化',
  'product.introduc.Cloud.esg.advantage3.desc':
    '通过园区楼宇的能耗数据监管，碳排放换算，能耗预测分析，使管理者全面及时掌握能耗使用情况，适时进行节能调控，提高能效管理水平，最终达到开源节流，降本增效的目的',
  'product.introduc.Cloud.esg.advantage4': '高效全景可视',
  'product.introduc.Cloud.esg.advantage4.desc':
    '环境能碳管控平台的建设，基于GIS、BIM、CIM、3D/2D等可视化技术，全景呈现企业能源碳环境运营详情，在企业发展进程中深刻践行绿色发展、低碳发展理念',

  'product.introduc.bdp.tip1': '威思客企业数字洞察',
  'product.introduc.bdp.tip2':
    '完美融合企业行政与智能物联大数据，通过信息发布、互动大屏和数据洞察，实现空间的可视化展示与智能信息发布。助力企业优化空间体验，提升资源利用效率，支持企业科学决策',
  'product.introduc.bdp.subTitle1': '总裁驾驶舱',
  'product.introduc.bdp.subTitle1.desc':
    '为高层管理团队量身定制，整合企业数字行政与智能物联大数据，提供深度数据清洗与优化。全面展示财务、运营、客户、环境等绩效指标以及健康关怀数据分析，通过全局数据总览和趋势预测，为企业决策提供坚实支撑。',
  'product.introduc.bdp.subTitle2': '报表管理与授权',
  'product.introduc.bdp.subTitle2.desc':
    '提供一系列专业价值报表，用户可根据实际需求选择并适配企业数据，快速获取深入见解。同时支持Power BI报表快速导入，实现灵活分类管理和角色权限控制。',
  'product.introduc.bdp.subTitle3': '综合信息发布系统',
  'product.introduc.bdp.subTitle3.desc':
    '支持视频、图片、PDF等多样化媒体形式，系统实时推送报表数据至大屏端展示，简易配置即可实现多屏内容并存及自动切换。与威思客产品深度集成，提供精细化权限管理，满足复杂场景需求。',
  'product.introduc.bdp.subTitle4': '互动式空间资源大屏',
  'product.introduc.bdp.subTitle4.desc':
    '直观展示空间区域的环境指数和资源状态，提供互动式操作，如资源筛选、预约占用、地图路径指引等，显著提升办公体验与效率。与威思客FM系统无缝对接，实现空间设备资源的高效跟踪与维护。',
  'product.introduc.bdp.subTitle5': '互动式大屏',
  'product.introduc.bdp.subTitle5.desc':
    '支持2D/3D地图展示，包括资源总览和环境IAQ指标，提供可视化交互，包括资源扫码预约、报修、路线指引。',
  'product.introduc.bdp.subTitle6': '信息发布引擎',
  'product.introduc.bdp.subTitle6.desc':
    '支持常见媒体格式，可在线编排节目，支持审核，支持插播；采用Android信发设备，支持离线播放，支持多屏播放；支持主流浏览器。',
  'product.introduc.bdp.advantage1': '信息发布引擎',
  'product.introduc.bdp.advantage1.desc':
    '播放设备管理、播放媒体管理、播放内容编排、播放过程控制、设备离线播放、资源组划分',
  'product.introduc.bdp.advantage2': '多屏融合技术',
  'product.introduc.bdp.advantage2.desc':
    '播放内容分屏展示、每屏内容按需配置、多屏无延迟切换、自由配置切换方式',
  'product.introduc.bdp.advantage3': '互动式大屏功能',
  'product.introduc.bdp.advantage3.desc':
    '地图可视化展示、资源状态总览、地图路径指引、资源快速检索、扫码预约使用',
  'product.introduc.bdp.advantage4': '设备资源展示',
  'product.introduc.bdp.advantage4.desc':
    '设备分类筛选、IOT数据采集、IAQ环境指标、设备资源扫码报修、FM系统对接',
  'product.introduc.bdp.advantage5': '成本统计分析',
  'product.introduc.bdp.advantage5.desc':
    '总体成本构成、成本趋势变化、多维度结算分析、多维度TOPN分析',
  'product.introduc.bdp.advantage6': '空间资源利用优化',
  'product.introduc.bdp.advantage6.desc':
    '综合资源利用、利用率趋势变化、利用率分段分布、利用率排行榜、利用率区域对比',
  'product.introduc.bdp.advantage7': '综合影响评估',
  'product.introduc.bdp.advantage7.desc': '环境与资源利用、行为与资源利用、资源利用与能耗',
  'product.introduc.bdp.advantage8': '数字可视化展示',
  'product.introduc.bdp.advantage8.desc': '能源能耗展示、绿色低碳展示、数字资产展示、WELL环境展示',

  'product.introduc.map.tip1': '威思客空间管理制图工具',
  'product.introduc.map.tip2': '免费制作专业的企业地图，办公空间管理、办公室布局、工位分配',
  'product.introduc.map.advantage1': '完全免费',
  'product.introduc.map.advantage1.desc': '无需花费，即可享受专业制图服务。',
  'product.introduc.map.advantage2': '高清导出',
  'product.introduc.map.advantage2.desc': '支持导出为PDF和图片格式，满足不同展示需求。',
  'product.introduc.map.advantage3': '易于使用',
  'product.introduc.map.advantage3.desc': '简化操作流程，让每个人都能成为制图专家。',
  'product.introduc.map.advantage4': '功能强大',
  'product.introduc.map.advantage4.desc':
    '后续可联动空间管理系统，实现对空间资源如会议室、工位、储物柜等的预订管理和占用监测。',
  'product.introduc.map.tools.tip1': '制作办公室工位图，精准布局!',
  'product.introduc.map.tools.tip2':
    '我们只需上传公司的装修平面图即可进行空间工位图绘制。具体怎么使用呢，一起来看看吧!',
  'product.introduc.map.tools.step1': '1、创建自己的企业办公区层级',
  'product.introduc.map.tools.step1.desc':
    '您可以为企业不同办公楼的不同办公区域绘制相应的地图，办公区域层级一目了然。',
  'product.introduc.map.tools.step2': '2、上传对应办公区域平面设计图',
  'product.introduc.map.tools.step2.desc':
    '为各办公空间上传对应的空间装修平面图，即可根据平面图绘制空间地图。',
  'product.introduc.map.tools.step3': '3、绘制办公空间地图',
  'product.introduc.map.tools.step3.desc':
    '根据平面地图摆放工位、会议室等素材，休闲区等不规则区域可通过自定义画笔来绘制，满足您所有空间类型需要。',
  'product.introduc.map.tools.step4': '4、导出空间地图',
  'product.introduc.map.tools.step4.desc':
    '在画好地图后，即可导出企业空间地图pdf或png文件，方便您线下使用。',
  'index.map': '空间管理制图工具',
};
