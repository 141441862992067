export default {
  200: '服務器成功返回請求的數據。',
  201: '新建或修改數據成功。',
  202: '壹個請求已經進入後台排隊（異步任務）。',
  204: '刪除數據成功。',
  400: '發出的請求有錯誤，服務器沒有進行新建或修改數據的操作。',
  401: '用戶沒有權限（令牌、用戶名、密碼錯誤）。',
  403: '用戶得到授權，但是訪問是被禁止的。',
  404: '發出的請求針對的是不存在的記錄，服務器沒有進行操作。',
  406: '請求的格式不可得。',
  410: '請求的資源被永久刪除，且不會再得到的。',
  422: '當創建壹個對象時，發生壹個驗證錯誤。',
  426: '用戶名或密碼錯誤，請重新輸入。',
  500: '服務器發生錯誤，請檢查服務器。',
  502: '網關錯誤。',
  503: '服務不可用，服務器暫時過載或維護。',
  504: '網關超時。',
  '': '未知錯誤',
  100000: '系統異常',
  101000: '非法操作',
  101001: '參數不正確',
  101002: '退出失敗，token爲空',
  101003: '退出失敗，token無效',
  101004: '操作失敗，token爲空',
  101005: '操作失敗，token格式無效',
  101006: '操作失敗，token無效',
  101007: '操作失敗，refreshToken爲空',
  101008: '操作失敗，refreshToken無效',
  101009: '密碼解密失敗',
  101010: '賬號格式不正確',
  101011: '通過refreshToken獲取域信息失敗',
  101012: '不支持認證模式',
  101013: '用戶名或密碼錯誤，請重新輸入',
  101014: '請求頭中client信息爲空',
  101015: '查詢用戶失敗',
  101020: '上傳license文件失敗',
  101021: '上傳license文件異常',
  101022: '更新上傳license文件失敗',
  101023: 'license設備碼校驗失敗',
  101024: 'license有效期校驗失敗',
  101025: 'license無效或不存在',
  101026: 'license功能未開啓',
  101030: '驗證碼不能爲空',
  101031: '驗證碼不合法',
  101032: '驗證碼不存在',
  101040: '簽名或時間戳參數不存在',
  101041: '時間戳參數格式不正確',
  101042: '請求已過期',
  101043: '簽名無效',
  101044: '當前用戶無角色，請聯系管理員',
  101052: '賬號鎖定',
  102000: '非法操作',
  102001: '參數不正確',
  102002: '添加部門對應租戶不存在',
  102003: '當前部門下存在子部門或用戶，請先轉移',
  102004: '保存部門失敗',
  102005: '菜單含有下級不能刪除',
  102006: '當前角色下存在用戶，請先轉移用戶',
  102007: '只有平台管理員才能修改通用角色',
  102008: '租戶不存在',
  102009: '不能刪除自己',
  102010: '添加用戶對應租戶不存在',
  102011: '郵箱已存在',
  102012: '新密碼與確認密碼不壹致',
  102013: '信息不存在',
  102014: '原密碼錯誤',
  102015: '部門不存在',
  102016: '此微信已綁定過',
  102017: '此釘釘已綁定過',
  102018: '不支持除微信、釘釘以外其它綁定方式',
  102019: '此用戶未綁定微信賬號',
  102020: '此用戶未綁定釘釘賬號',
  102021: '威思客token無效',
  102022: '免密認證，不支持當前類型',
  102023: '免密認證，查詢到多個用戶',
  102024: '免密認證，未查詢到用戶',
  102025: '手機號已存在',
  102026: '用戶不存在',
  102027: '平台管理員角色不允許刪除',
  102030: '此角色標識已存在',
  102040: '此租戶域已存在',
  102050: '添加空間對應租戶不存在',
  102051: '當前空間下存在子空間請先轉移',
  102052: '保存空間失敗',
  102053: '此空間沒有綁定節假日',
  102054: '此空間不存在',
  102055: '部門管理員未設置',
  102056: '部門管理員不存在',
  102057: '當前租戶已停用',
  102058: '系統異常',
  102059: '同壹個部門下部門名稱不能重複',
  102060: '此賬號已存在',
  102091: '租戶初始化保存租戶失敗',
  102092: '租戶初始化保存部門失敗',
  102093: '租戶初始化保存部門關系失敗',
  102094: '租戶初始化保存用戶失敗',
  102095: '租戶初始化保存用戶角色關系失敗',
  102096: '用戶信息保存異常',
  102097: '用戶信息更新異常',
  102098: '用戶角色關系保存異常',
  102099: '批量保存用戶異常',
  102100: '批量保存用戶角色異常',
  102101: '屬性code不存在',
  102106: '郵箱賬號不存在',
  102107: '驗證碼無效',
  102108: '設置密碼失敗',
  102109: '驗證碼無效',
  102110: '新密碼和確認密碼不壹致',
  100100: '成功',
  100101: '網絡異常',
  100102: '參數異常',
  100103: '無權訪問',
  100104: '人員數已到達上線',
  100105: '文件不能爲空',
  100106: '基礎服務獲取機構列表失敗',
  100107: '數據超過1000行',
  100108: '導入人員數超出了可添加剩余人員數',
  100109: '保存用戶信息失敗',
  100110: '導入成功，如果導入人員較多請稍後刷新...',
  100111: '郵箱重複請修改',
  100112: '獲取企業套餐失敗',
  100113: '基礎服務獲取用戶失敗',
  100114: '導出失敗',
  100115: '沒有人員數據',
  100116: '驗證碼已過期或不存在',
  100117: '用戶不存在',
  100118: '用戶已經初始化過密碼，不用重複操作',
  102112: '用戶名或者密碼錯誤',
  101048: '賬號多次認證失敗，鎖定{lock}分鍾',
  1001001: '您的套餐已過期',
  1001020: '當前用戶已激活',
  1001023: '該郵箱已使用，請更換郵箱',
  1001027: '驗證碼無效',
  1001028: '驗證碼錯誤，請輸入正確的驗證碼',
  1001031: '參數錯誤',
  1001062: '激活用戶失敗',
  1001083: '手機號已經被注冊',
  1001084: '用戶不存在',

  106000: '系統異常',
  106526: '免密認證失敗',
  102164: '手機號不唯壹',
  102165: '用戶不存在',
  102151: '新密碼與曆史密碼重複',
  102182: '賬戶未激活',
  102183: '手機號不存在，請重新輸入或先驗證手機號',
  102184: '郵箱不存在，請重新輸入或先驗證郵箱',

  1001002: '用戶賬號或驗證碼爲空',
  1001112: '短信服務未配置',
  1001054: '短信服務查詢失敗',
  1001122: '登錄令牌已過期',
  1001124: '操作失敗',
  1001091: '賬號不存在',
  1001125: '導出excel的數據量不能超過5000條。請調整查詢條件，重新導出',

  102125: '新密碼不能與原密碼相同',
  102116: '密碼包含弱密碼，不符合強度要求',

  101054: '請修改默認密碼',
  101055: '密碼過期，請修改密碼',
  1001025: '許可證已過期',
  1001026: '許可數已達到上限',
  1001147: '已超過許可證上限，請重新開通或更新許可證',

  101079: '安全碼爲空',
  101080: '安全碼校驗失敗',
  106503: '獲取應用資訊失敗！',
  106504: '認證失敗！',
  106505: '用戶名或密碼錯誤！',

  102181: '手機號或郵箱已驗證，不支持修改',
  102150: '密碼不能包含用戶名，電話，郵箱箱及郵箱前綴',
};
