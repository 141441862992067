export default {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  426: '用户名或密码错误，请重新输入。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
  '': '未知错误',
  100000: '系统异常',
  101000: '非法操作',
  101001: '参数不正确',
  101002: '退出失败，token为空',
  101003: '退出失败，token无效',
  101004: '操作失败，token为空',
  101005: '操作失败，token格式无效',
  101006: '操作失败，token无效',
  101007: '操作失败，refreshToken为空',
  101008: '操作失败，refreshToken无效',
  101009: '密码解密失败',
  101010: '账号格式不正确',
  101011: '通过refreshToken获取域信息失败',
  101012: '不支持认证模式',
  101013: '用户名或密码错误，请重新输入',
  101014: '请求头中client信息为空',
  101015: '查询用户失败',
  101020: '上传license文件失败',
  101021: '上传license文件异常',
  101022: '更新上传license文件失败',
  101023: 'license设备码校验失败',
  101024: 'license有效期校验失败',
  101025: 'license无效或不存在',
  101026: 'license功能未开启',
  101030: '验证码不能为空',
  101031: '验证码不合法',
  101032: '验证码不存在',
  101040: '签名或时间戳参数不存在',
  101041: '时间戳参数格式不正确',
  101042: '请求已过期',
  101043: '签名无效',
  101044: '当前用户无角色，请联系管理员',
  101052: '账号锁定',
  102000: '非法操作',
  102001: '参数不正确',
  102002: '添加部门对应租户不存在',
  102003: '当前部门下存在子部门或用户，请先转移',
  102004: '保存部门失败',
  102005: '菜单含有下级不能删除',
  102006: '当前角色下存在用户，请先转移用户',
  102007: '只有平台管理员才能修改通用角色',
  102008: '租户不存在',
  102009: '不能删除自己',
  102010: '添加用户对应租户不存在',
  102011: '邮箱已存在',
  102012: '新密码与确认密码不一致',
  102013: '信息不存在',
  102014: '原密码错误',
  102015: '部门不存在',
  102016: '此微信已绑定过',
  102017: '此钉钉已绑定过',
  102018: '不支持除微信、钉钉以外其它绑定方式',
  102019: '此用户未绑定微信账号',
  102020: '此用户未绑定钉钉账号',
  102021: '威思客token无效',
  102022: '免密认证，不支持当前类型',
  102023: '免密认证，查询到多个用户',
  102024: '免密认证，未查询到用户',
  102025: '手机号已存在',
  102026: '用户不存在',
  102027: '平台管理员角色不允许删除',
  102030: '此角色标识已存在',
  102040: '此租户域已存在',
  102050: '添加空间对应租户不存在',
  102051: '当前空间下存在子空间请先转移',
  102052: '保存空间失败',
  102053: '此空间没有绑定节假日',
  102054: '此空间不存在',
  102055: '部门管理员未设置',
  102056: '部门管理员不存在',
  102057: '当前租户已停用',
  102058: '系统异常',
  102059: '同一个部门下部门名称不能重复',
  102060: '此账号已存在',
  102091: '租户初始化保存租户失败',
  102092: '租户初始化保存部门失败',
  102093: '租户初始化保存部门关系失败',
  102094: '租户初始化保存用户失败',
  102095: '租户初始化保存用户角色关系失败',
  102096: '用户信息保存异常',
  102097: '用户信息更新异常',
  102098: '用户角色关系保存异常',
  102099: '批量保存用户异常',
  102100: '批量保存用户角色异常',
  102101: '属性code不存在',
  102106: '邮箱账号不存在',
  102107: '验证码无效',
  102108: '设置密码失败',
  102109: '验证码无效',
  102110: '新密码和确认密码不一致',
  100100: '成功',
  100101: '网络异常',
  100102: '参数异常',
  100103: '无权访问',
  100104: '人员数已到达上线',
  100105: '文件不能为空',
  100106: '基础服务获取机构列表失败',
  100107: '数据超过1000行',
  100108: '导入人员数超出了可添加剩余人员数',
  100109: '保存用户信息失败',
  100110: '导入成功，如果导入人员较多请稍后刷新...',
  100111: '邮箱重复请修改',
  100112: '获取企业套餐失败',
  100113: '基础服务获取用户失败',
  100114: '导出失败',
  100115: '没有人员数据',
  100116: '验证码已过期或不存在',
  100117: '用户不存在',
  100118: '用户已经初始化过密码，不用重复操作',
  102112: '用户名或者密码错误',
  101048: '账号多次认证失败，锁定{lock}分钟',
  1001001: '您的套餐已过期',
  1001020: '当前用户已激活',
  1001023: '该邮箱已使用，请更换邮箱',
  1001027: '验证码无效',
  1001028: '验证码错误，请输入正确的验证码',
  1001031: '参数错误',
  1001062: '激活用户失败',
  1001083: '手机号已经被注册',
  1001084: '用户不存在',

  106000: '系统异常',
  106526: '免密认证失败',
  102164: '手机号不唯一',
  102165: '用户不存在',
  102151: '新密码与历史密码重复',
  102182: '账户未激活',
  102183: '手机号不存在，请重新输入或先验证手机号',
  102184: '邮箱不存在，请重新输入或先验证邮箱',

  1001002: '用户账号或验证码为空',
  1001112: '短信服务未配置',
  1001054: '短信服务查询失败',
  1001122: '登录令牌已过期',
  1001124: '操作失败',
  1001091: '账号不存在',
  1001125: '导出excel的数据量不能超过5000条。请调整查询条件，重新导出',

  102125: '新密码不能与原密码相同',
  102116: '密码包含弱密码，不符合强度要求',

  101054: '请修改默认密码',
  101055: '密码过期，请修改密码',
  1001025: '许可证已过期',
  1001026: '许可数已达到上限',
  1001147: '已超过许可证上限，请重新开通或更新许可证',

  101079: '安全码为空',
  101080: '安全码校验失败',
  106503: '获取应用信息失败！',
  106504: '认证失败！',
  106505: '用户名或密码错误！',

  102181: '手机号或邮箱已验证，不支持修改',
  102150: '密码不能包含用户名，电话，邮箱及邮箱前缀',
};
