import request from '@/utils/request';
import { getSign } from '@/utils/encryption';
import { RESTFUL_PATH } from '@/utils/constant';

export const getPackageList = () => {
  return request(`/${RESTFUL_PATH.cloudOms}/products/version/free`);
};

export const getPackageListPay = () => {
  return request(`/${RESTFUL_PATH.cloudOms}/products/version/pay`);
};

export const getCode = (phone) => {
  const url = `/${RESTFUL_PATH.cloudOms}/home/sendVerifyCode`;
  const timestamp = Date.now();
  const sign = getSign(`phone=${phone}&timestamp=${timestamp}`);
  return request(url, {
    method: 'POST',
    body: JSON.stringify({ phone, timestamp, sign }),
  });
};

export const checkCode = (payload) => {
  return request(`/${RESTFUL_PATH.cloudOms}/home/verifyCode`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const submit = (payload) => {
  return request(`/${RESTFUL_PATH.cloudOms}/home/register`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const areaCode = () => {
  return request(`/${RESTFUL_PATH.upms}/dict/type/common/area-code`);
};

export const thirdRegister = (payload) => {
  return request(`/${RESTFUL_PATH.cloudOms}/third/register/teams`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};

export const thirdBind = (payload) => {
  return request(`/${RESTFUL_PATH.upms}/teams/user/bind`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
};
