export default {
  // 'price.locales': '',
  'price.locales.Use.now': 'Use Now',
  'price.locales.free': 'free',
  'price.locales.Excluding.hardware.terminal': 'Excluding hardware terminal',
  'price.locales.Basic.Edition': 'Basic Edition',
  'price.locales.Standard.Edition': 'Standard Edition',
  'price.locales.Exclusive.Edition': 'Exclusive Edition',
  'price.locales.Advanced.Edition': 'Advanced Edition',
  'price.locales.recommend': 'Recommend',
  'price.locales.month': 'Month',
  'price.locales.contain.Unlimited.invitation': 'Unlimited Invitation',
  'price.locales.contain.Unrestricted.registration': 'Unrestricted Registration',
  'price.locales.contain.Email.notification': 'Email Notification',
  'price.locales.contain.SMS.notification': 'SMS Notification',
  'price.locales.contain.Custom.brand': 'Custom Brand',
  'price.locales.contain.Including.all.functions.of.the.basic.version':
    'Including all functions of the basic edition +',
  'price.locales.contain.Label.printing': 'Label Printing',
  'price.locales.contain.Confidentiality.agreement': 'Confidentiality Agreement',
  'price.locales.contain.Access.type.customization': 'Access type customization',
  'price.locales.contain.Statistical.report': 'Statistical Report',
  'price.locales.contain.facetime': 'Facetime',
  'price.locales.contain.Display.board.function': 'Display board function',
  'price.locales.contain.Display.Panel.monitoring': 'Panel Monitoring',
  'price.locales.contain.Start.the.meeting.now': 'Start the meeting now',
  'price.locales.contain.Meeting.sign.in': 'Meeting sign in',
  'price.locales.contain.Pre.meeting.reminder': 'Pre meeting reminder',
  'price.locales.contain.Extension.of.the.session': 'Extension of the session',
  'price.locales.contain.One.key.maintenance.conference.room':
    'Conference extension one key maintenance conference room',
  'price.locales.contain.Participant.conflict.reminder': 'Participant conflict reminder',
  'price.locales.contain.Conference.equipment.borrowing': 'Conference equipment borrowing',
  'price.locales.contain.Conference.room.up.and.down': 'Conference room up and down',
  'price.locales.contain.Number.of.meeting.rooms': 'Number of meeting rooms',
  'price.locales.contain.Cleaning.service': 'Cleaning Service',
  'price.locales.contain.Meeting.agenda': 'Meeting Agenda',
  'price.locales.contain.Conference.billing': 'Conference Billing',
  'price.locales.contain.Conference.services': 'Conference Services',
  'price.locales.contain.Appointment.cycle.meeting': 'Appointment cycle meeting',
  'price.locales.contain.Book.extra.long.meetings': 'Book extra long meetings',
  'price.locales.contain.Book.multi.room.meetings': 'Book multi room meetings',
  'price.locales.contain.Meeting.room.reservation.authority': 'Meeting room reservation authority',
  'price.locales.contain.Meeting.approval': 'Meeting Approval',
  'price.locales.contain.Meeting.lock.time': 'Meeting lock time',
  'price.locales.contain.Enterprise.wechat': 'Enterprise WeChat',
  'price.locales.contain.Reminder.before.the.end.of.the.meeting':
    'Reminder before the end of the meeting',
  'price.locales.contain.Batch.invitation': 'Batch Invitation',
  'price.locales.contain.Certificate.identification': 'Certificate Identification',
  'price.locales.contain.Blacklist.managemen': 'Blacklist Management',
  'price.locales.contain.Group.invitation': 'Group Invitation',
  'price.locales.contain.Temporary.audit': 'Temporary Audit',
  'price.locales.contain.Nail.nail': 'DingTalk',
  'price.locales.contain.Network.conference': 'Network Conference',
  'price.locales.contain.SMS.advance.notice': 'SMS advance notice',
  'price.locales.contain.Email.advance.notice': 'Email advance notice',
  'price.locales.contain.Including.all.functions.of.Standard.Edition':
    'Including all functions of standard edition +',
  'price.locales.contain.Including.all.functions.of.Standard.Advanced':
    'Including all functions of advanced edition +',

  'price.locales.workspace.desc1': 'Includes 20 work stations',
  'price.locales.workspace.desc2': 'Includes 20 work stations',
  'price.locales.workspace.standard.include1': 'Station Reservation',
  'price.locales.workspace.standard.include2': 'Station Search',
  'price.locales.workspace.standard.include3': 'Map Management',

  'price.locales.workspace.advanced.include1': 'Recommended Booking',
  'price.locales.workspace.advanced.include2': 'Station Relocation',
  'price.locales.workspace.advanced.include3': 'Facility Management',
  'price.locales.workspace.advanced.include4': 'Statistical Statement',
  'price.locales.workspace.advanced.include5': 'Map Premium',
  'price.locales.workspace.advanced.include6': 'Enterprise WeChat',
  'price.locales.workspace.advanced.include7': 'Dingtalk',

  'price.locales.esg.standard.include1': 'Electricity Analysis',
  'price.locales.esg.standard.include2': 'Water Analysis',
  'price.locales.esg.standard.include3': 'Environmental Analysis',
  'price.locales.esg.standard.include4': 'Electricity/water statistics by item',
  'price.locales.esg.standard.include5': 'Water monitoring and alarm',
  'price.locales.esg.standard.include6': 'Electricity monitoring and alarm',
  'price.locales.esg.standard.include7': 'Alarm Log',
  'price.locales.esg.standard.include8': 'Automatic meter reading and reporting',
  'price.locales.esg.standard.include9': 'Large energy consumption screen',
  'price.locales.esg.standard.include10': 'Environmental Screen',
  'price.locales.esg.desc1': 'Minimum purchase of 50 devices',
  'price.locales.esg.device': 'Device',

  'price.locales.bdp.standard.include1': 'Play media management',
  'price.locales.bdp.standard.include2': 'Resource group management',
  'price.locales.bdp.standard.include3': 'Playback content approval',
  'price.locales.bdp.standard.include4': 'The program layout is designed independently',
  'price.locales.bdp.standard.include5': 'Content switching policy control',
  'price.locales.bdp.standard.include6': 'Can turn the page multi-screen playback',
  'price.locales.bdp.standard.include7': 'Remote device monitoring and management',
  'price.locales.bdp.standard.include8': 'Device timing switch',
  'price.locales.bdp.standard.include9': 'Content is pre-delivered and played offline',

  'price.locales.bdp.advantage.include1': 'Resource map display',
  'price.locales.bdp.advantage.include2': 'Environmental information display',
  'price.locales.bdp.advantage.include3': 'Space resource operation interaction',
  'price.locales.bdp.desc1': 'Signaling',
  'price.locales.bdp.desc2': 'Interactive large screen',
  'price.locales.bdp.desc3': 'Playback Terminal',

  'price.locales.smt.desc1': 'Meeting Room',
};
