import React from 'react';
import styles from './index.less';
import { formatMessage } from 'umi';
import banner from '../../assets/hardware/banner.png';
import yingbin from '../../assets/hardware/icon_yingbinjiedai.png';
import gensui from '../../assets/hardware/icon_zhinenggensui.png';
import renlian from '../../assets/hardware/icon_renlianshibie.png';
import jiangjie from '../../assets/hardware/icon_yindaojiangjie.png';
import zixun from '../../assets/hardware/icon_wendazixun.png';
import xunhang from '../../assets/hardware/icon_xunhangxuanchuan.png';
import hudong from '../../assets/hardware/icon_hudongyule.png';
import zhongkong from '../../assets/hardware/icon_zhinengzhongkong.png';
import huichong from '../../assets/hardware/icon_zidonghuichong.png';
import shipin from '../../assets/hardware/icon_shipintonghua.png';
import yuancheng from '../../assets/hardware/icon_yuanchengkongzhi.png';
import music from '../../assets/hardware/icon_zaixianyinyue.png';
import news from '../../assets/hardware/icon_xinwenzixun.png';
import sheying from '../../assets/hardware/icon_luyingsheying.png';

import ai from '../../assets/hardware/icon_aizhushou.png';
import home from '../../assets/hardware/icon_jiqirendaojia.png';
import tonghua from '../../assets/hardware/icon_shipintonghua1.png';
import news1 from '../../assets/hardware/icon_xinwenzixun1.png';
import dj from '../../assets/hardware/icon_sirendj.png';
import sheying1 from '../../assets/hardware/icon_wuyousheying.png';
import help from '../../assets/hardware/icon_yidongbangshou.png';
import map from '../../assets/hardware/map.gif';

// 功能列表
const funcList = [
  { label: formatMessage({ id: 'hardware.func.yingbin' }), icon: yingbin },
  { label: formatMessage({ id: 'hardware.func.gensui' }), icon: gensui },
  { label: formatMessage({ id: 'hardware.func.renlian' }), icon: renlian },
  { label: formatMessage({ id: 'hardware.func.jiangjie' }), icon: jiangjie },
  { label: formatMessage({ id: 'hardware.func.zixun' }), icon: zixun },
  { label: formatMessage({ id: 'hardware.func.xunhang' }), icon: xunhang },
  { label: formatMessage({ id: 'hardware.func.hudong' }), icon: hudong },

  { label: formatMessage({ id: 'hardware.func.zhongkong' }), icon: zhongkong },
  { label: formatMessage({ id: 'hardware.func.huichong' }), icon: huichong },
  { label: formatMessage({ id: 'hardware.func.shipin' }), icon: shipin },
  { label: formatMessage({ id: 'hardware.func.yuancheng' }), icon: yuancheng },
  { label: formatMessage({ id: 'hardware.func.music' }), icon: music },
  { label: formatMessage({ id: 'hardware.func.news' }), icon: news },
  { label: formatMessage({ id: 'hardware.func.sheying' }), icon: sheying },
];

// 参数配置数据
const configData = [
  {
    id: 'patent',
    label: formatMessage({ id: 'hardware.param.patent' }),
    info: [
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.patent.1.msg1' }),
          formatMessage({ id: 'hardware.param.patent.1.msg2' }),
        ],
      },
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.patent.2.msg1' }),
          formatMessage({ id: 'hardware.param.patent.2.msg2' }),
          formatMessage({ id: 'hardware.param.patent.2.msg3' }),
          formatMessage({ id: 'hardware.param.patent.2.msg4' }),
          formatMessage({ id: 'hardware.param.patent.2.msg5' }),
          formatMessage({ id: 'hardware.param.patent.2.msg6' }),
        ],
      },
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.patent.3.msg1' }),
          formatMessage({ id: 'hardware.param.patent.3.msg2' }),
        ],
      },
    ],
  },
  {
    id: 'system',
    label: formatMessage({ id: 'hardware.param.system' }),
    info: [
      {
        title: '',
        msg: [formatMessage({ id: 'hardware.param.system.1.msg1' })],
      },
      {
        title: formatMessage({ id: 'hardware.param.system.2.title' }),
        msg: [formatMessage({ id: 'hardware.param.system.2.msg1' })],
      },
      {
        title: formatMessage({ id: 'hardware.param.system.3.title' }),
        msg: [formatMessage({ id: 'hardware.param.system.3.msg1' })],
      },
      {
        title: 'AI',
        msg: [formatMessage({ id: 'hardware.param.system.4.msg1' })],
      },
      {
        title: formatMessage({ id: 'hardware.param.system.5.title' }),
        msg: [
          formatMessage({ id: 'hardware.param.system.5.msg1' }),
          formatMessage({ id: 'hardware.param.system.5.msg2' }),
        ],
      },
    ],
  },
  {
    id: 'program',
    label: formatMessage({ id: 'hardware.param.program' }),
    info: [
      {
        title: formatMessage({ id: 'hardware.param.program.ai' }),
        msg: ai,
      },
      {
        title: formatMessage({ id: 'hardware.param.program.home' }),
        msg: home,
      },
      {
        title: formatMessage({ id: 'hardware.param.program.tonghua' }),
        msg: tonghua,
      },
      {
        title: formatMessage({ id: 'hardware.param.program.news1' }),
        msg: news1,
      },
      {
        title: formatMessage({ id: 'hardware.param.program.dj' }),
        msg: dj,
      },
      {
        title: formatMessage({ id: 'hardware.param.program.sheying1' }),
        msg: sheying1,
      },
      {
        title: formatMessage({ id: 'hardware.param.program.help' }),
        msg: help,
      },
    ],
  },
  {
    id: 'connection',
    label: formatMessage({ id: 'hardware.param.connection' }),
    info: [
      {
        title: 'WIFI',
        msg: ['MIMO Wi-Fi', '802.11b/g/n/ac'],
      },
      {
        title: formatMessage({ id: 'hardware.param.connection.2.title' }),
        msg: [formatMessage({ id: 'hardware.param.connection.2.msg1' })],
      },
    ],
  },
  {
    id: 'size',
    label: formatMessage({ id: 'hardware.param.size' }),
    info: [
      {
        title: formatMessage({ id: 'hardware.param.size.1.title' }),
        msg: [
          formatMessage({ id: 'hardware.param.size.1.msg1' }),
          formatMessage({ id: 'hardware.param.size.1.msg2' }),
          formatMessage({ id: 'hardware.param.size.1.msg3' }),
        ],
      },
      {
        title: formatMessage({ id: 'hardware.param.size.2.title' }),
        msg: [formatMessage({ id: 'hardware.param.size.2.msg1' })],
      },
    ],
  },
  {
    id: 'display',
    label: formatMessage({ id: 'hardware.param.display' }),
    info: [
      {
        title: formatMessage({ id: 'hardware.param.display.1.title' }),
        msg: [
          formatMessage({ id: 'hardware.param.display.1.msg1' }),
          formatMessage({ id: 'hardware.param.display.1.msg2' }),
          formatMessage({ id: 'hardware.param.display.1.msg3' }),
        ],
      },
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.display.2.msg1' }),
          formatMessage({ id: 'hardware.param.display.2.msg2' }),
        ],
      },
    ],
  },
  {
    id: 'tiltable',
    label: formatMessage({ id: 'hardware.param.tiltable' }),
    info: [
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.tiltable.1.msg1' }),
          formatMessage({ id: 'hardware.param.tiltable.1.msg2' }),
        ],
      },
    ],
  },
  {
    id: 'micro',
    label: formatMessage({ id: 'hardware.param.micro' }),
    info: [
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.micro.1.msg1' }),
          formatMessage({ id: 'hardware.param.micro.1.msg2' }),
          formatMessage({ id: 'hardware.param.micro.1.msg3' }),
          formatMessage({ id: 'hardware.param.micro.1.msg4' }),
          formatMessage({ id: 'hardware.param.micro.1.msg5' }),
          formatMessage({ id: 'hardware.param.micro.1.msg6' }),
        ],
      },
    ],
  },
  {
    id: 'audio',
    label: formatMessage({ id: 'hardware.param.audio' }),
    info: [
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.audio.1.msg1' }),
          formatMessage({ id: 'hardware.param.audio.1.msg2' }),
          formatMessage({ id: 'hardware.param.audio.1.msg3' }),
          formatMessage({ id: 'hardware.param.audio.1.msg4' }),
          formatMessage({ id: 'hardware.param.audio.1.msg5' }),
        ],
      },
    ],
  },
  {
    id: 'webcam',
    label: formatMessage({ id: 'hardware.param.webcam' }),
    info: [
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.webcam.1.msg1' }),
          formatMessage({ id: 'hardware.param.webcam.1.msg2' }),
          formatMessage({ id: 'hardware.param.webcam.1.msg3' }),
          formatMessage({ id: 'hardware.param.webcam.1.msg4' }),
          formatMessage({ id: 'hardware.param.webcam.1.msg5' }),
        ],
      },
      {
        title: formatMessage({ id: 'hardware.param.webcam.2.title' }),
        msg: [
          formatMessage({ id: 'hardware.param.webcam.2.msg1' }),
          formatMessage({ id: 'hardware.param.webcam.2.msg2' }),
        ],
      },
      {
        title: formatMessage({ id: 'hardware.param.webcam.3.title' }),
        msg: [
          formatMessage({ id: 'hardware.param.webcam.3.msg1' }),
          formatMessage({ id: 'hardware.param.webcam.3.msg2' }),
          formatMessage({ id: 'hardware.param.webcam.3.msg3' }),
        ],
      },
    ],
  },
  {
    id: 'cpu',
    label: 'CPU',
    info: [
      {
        title: 'CPU1',
        msg: [
          formatMessage({ id: 'hardware.param.cpu.1.msg1' }),
          formatMessage({ id: 'hardware.param.cpu.1.msg2' }),
          formatMessage({ id: 'hardware.param.cpu.1.msg3' }),
        ],
      },
      {
        title: 'CPU2',
        msg: [
          formatMessage({ id: 'hardware.param.cpu.2.msg1' }),
          formatMessage({ id: 'hardware.param.cpu.2.msg2' }),
          formatMessage({ id: 'hardware.param.cpu.2.msg3' }),
        ],
      },
    ],
  },
  {
    id: 'battery',
    label: formatMessage({ id: 'hardware.param.battery' }),
    info: [
      {
        title: 'CPU1',
        msg: [
          formatMessage({ id: 'hardware.param.battery.1.msg1' }),
          formatMessage({ id: 'hardware.param.battery.1.msg2' }),
          formatMessage({ id: 'hardware.param.battery.1.msg3' }),
          formatMessage({ id: 'hardware.param.battery.1.msg4' }),
        ],
      },
    ],
  },
  {
    id: 'mobility',
    label: formatMessage({ id: 'hardware.param.mobility' }),
    info: [
      {
        title: '',
        msg: [formatMessage({ id: 'hardware.param.mobility.1.msg1' })],
      },
      {
        title: formatMessage({ id: 'hardware.param.mobility.2.title' }),
        msg: [formatMessage({ id: 'hardware.param.mobility.2.msg1' })],
      },
    ],
  },
  {
    id: 'tray',
    label: formatMessage({ id: 'hardware.param.tray' }),
    info: [
      {
        title: formatMessage({ id: 'hardware.param.tray.1.title' }),
        msg: [formatMessage({ id: 'hardware.param.tray.1.msg1' })],
      },
      {
        title: formatMessage({ id: 'hardware.param.tray.2.title' }),
        msg: [formatMessage({ id: 'hardware.param.tray.2.msg1' })],
      },
    ],
  },
  {
    id: 'package',
    label: formatMessage({ id: 'hardware.param.package' }),
    info: [
      {
        title: '',
        msg: [
          formatMessage({ id: 'hardware.param.package.1.msg1' }),
          formatMessage({ id: 'hardware.param.package.1.msg2' }),
          formatMessage({ id: 'hardware.param.package.1.msg3' }),
        ],
      },
    ],
  },
];

const Robot = () => {
  return (
    <div className={styles.hardWare}>
      <div className={styles.banner}>
        <img width="100%" height="100%" src={banner} />
        <div className={styles.bannerText}>{formatMessage({ id: 'hardware.banner2' })}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.productDes}>
          <div className={styles.contentItem}>
            <div className={styles.contentTitle}>
              {formatMessage({ id: 'hardware.product.title' })}
            </div>
            <ul className={styles.descripInfo}>
              <li>
                <span>{formatMessage({ id: 'hardware.product.tip1' })}</span>
              </li>
              <li>
                <span>{formatMessage({ id: 'hardware.product.tip2' })}</span>
              </li>
              <li>
                <span>{formatMessage({ id: 'hardware.product.tip3' })}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className={`${styles.func} ${styles.contentItem}`}>
          <div className={styles.contentTitle}>{formatMessage({ id: 'hardware.func' })}</div>
          <div className={styles.funcList}>
            {funcList.map((item, index) => {
              return (
                <div key={index.toString()}>
                  <div className={styles.listItem}>
                    <img src={item.icon} width="100%" alt="" />
                  </div>
                  <div className={styles.itemLabel}>{item.label}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.contentItem}>
          <div className={styles.contentTitle}>{formatMessage({ id: 'hardware.param' })}</div>
          {configData.map((item, index) => {
            return (
              <div key={item.id}>
                <div className={styles.configItem}>
                  <div className={styles.label}>{item.label}</div>

                  <div
                    className={styles.info}
                    style={{
                      display: item.id === 'program' || item.id === 'patent' ? 'flex' : 'block',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: item.id === 'program' ? 'flex' : 'block',
                        justifyContent: 'space-between',
                      }}
                    >
                      {item.info.map((info, indx) => {
                        return (
                          <>
                            {item.id === 'program' ? (
                              <div className={styles.program}>
                                <div className={styles.programItem}>
                                  <img src={info.msg} width="100%" alt="" />
                                </div>
                                <div className={styles.programTitle}>{info.title}</div>
                              </div>
                            ) : (
                              <div className={styles.msg} key={indx.toString()}>
                                <div>
                                  {info.title && <div className={styles.title}>{info.title}</div>}
                                  {info.msg.map((msg, i) => {
                                    return <div key={i.toString()}>{msg}</div>;
                                  })}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                    {item.id === 'patent' && (
                      <div style={{ width: 800, height: 370, marginRight: 50 }}>
                        <img width="100%" src={map} alt="" />
                      </div>
                    )}
                  </div>
                </div>
                {index !== configData.length - 1 && <div className={styles.divider} />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Robot;
