export default {
  'common.index': 'Home',
  'common.product': 'Product',
  'common.product.hot': 'Popular Products',
  'common.hardware': 'Hardware',
  'common.case': 'Customer',
  'common.service': 'Service',
  'common.sms': 'SMS Search',
  'common.help': 'Help',
  'common.about': 'About',
  'common.product.desc': 'Description',
  'common.product.price': 'Pricing',
  'common.product.download': 'Download',
  'common.visitor': 'Smart Visitor',
  'common.meeting': 'Smart Meeting',
  'common.space': 'Smart Workspace',
  'common.hardware.equipment': 'Equipment',
  'common.visitor.robot': 'Visitor Robot',
  'common.login': 'Sign In',
  'common.free.use': 'Free Trial',
  'common.link.often': 'Most used link',
  'common.service.center': 'Service Center',
  'common.company.profile': 'Company Profile',
  'common.app.download': 'Download',
  'common.android': 'Android',
  'common.ios': 'IOS',
  'common.ipad.visitor': 'Visitor IPad',
  'common.contactus': 'Contact Us',
  'common.customer.hotline': 'Consumer Hotline',
  'common.video.watch': 'Watch the video',
  'common.free.experience': 'Free Trial',

  'common.banner.tip1': 'Use the globally acclaimed Virsical cloud platform',
  'common.banner.tip2': 'Make the office smarter and smarter, and get more efficiency in less time',
  'common.banner.tip3':
    'Insight into the modern visitor scene and focus on the smart visitors of the future',
  'common.banner.tip4':
    'Brand-new business process, stable technical ability, efficient visitor reception',
  'common.banner.tip5': 'Choose the right version to start efficient office collaboration',
  'common.banner.tip6':
    'From individuals to enterprises/organizations, meet the needs of companies of different sizes at all stages, and create more value for you and your partners!',

  'common.banner.meeting': 'Virsical Cloud Conference',
  'common.banner.meeting.tip1':
    'Integrated solution for full life cycle management of meeting service',
  'common.banner.meeting.tip2': 'Full-scenario, full-lifecycle meeting solution',
  'common.banner.meeting.tip3': 'Bring smart, efficient, simple and easy',
  'common.banner.meeting.tip4': 'New conference room booking management experience',

  'common.banner.vistor': 'Virsical Cloud Visitor',
  'common.banner.vistor.tip1':
    'Insight into the modern visitor scene, focusing on the future of smart enterprises',
  'common.banner.vistor.tip2':
    'Brand-new platform operation and maintenance planning, stable cloud service support',
  'common.banner.vistor.tip3': 'Efficient visitor reception process',

  'common.banner.fm': 'Intelligent facility management platform',
  'common.banner.fm.tip1':
    'Powerful device integration and interconnection capabilities and data application management capabilities',
  'common.banner.fm.tip2': 'Through data communication and IOT platform',
  'common.banner.fm.tip3':
    'To realize the visual management of the running state of enterprise equipment',
  'common.banner.fm.tip4':
    'The closed-loop management process of equipment status detection, abnormal alarm and custom maintenance is formed',
};
