export default {
  // 'register.locales': '',
  'register.locales.tips': '注册立即获得30天的免费试用',
  'register.locales.tips1': '更多威思客云高级版本',
  'register.locales.phone.tips': '现在就拨打咨询热线：',
  'register.Registered.account': '注册账号',
  'register.locales.Registered': '注 册',
  'register.locales.bind': '绑 定',
  'register.bind.account': '绑定账号',
  'register.verification.phone': '手机验证',
  'register.verification.email': '邮箱验证',
  'register.Get.verification.code': '获取验证码',
  'register.locales.China.phone': '中国',
  'register.locales.HK.phone': '香港',
  'register.locales.password': '请输入密码',
  'register.locales.Privacy.services': '请阅读服务条款和隐私政策',
  'register.locales.Privacy.services.read': '我已阅读并同意威思客云的',
  'register.locales.Terms.of.service': '《服务条款》',
  'register.locales.Privacy.policy': '《隐私政策》',
  'register.locales.username': '请输入账号',
  'register.locales.verification.code': '重新获取',
  'register.Resend.in.seconds': '秒后重发',
  'register.Please.select.the.authentication.method': '请选择验证方式',
  'register.Please.bind.your.account.in.virsical.cloud': '请绑定您在威思客云的账号',
  'register.Welcome.to.sign.up.for.virsical.cloud.service': '欢迎注册威思客云服务',

  // 'register.locales.error': '',
  'register.locales.error.company': '请输入公司名称',
  'register.locales.error.contacts': '请输入联系人姓名',
  'register.locales.error.email': '请输入联系人邮箱',
  'register.locales.error.phone': '请输入联系人手机',
  'register.locales.error.company.length': '公司长度不得超过50个字符',
  'register.locales.error.contacts.length': '姓名长度为2-20个字符',
  'register.locales.error.email.length': '邮箱最大长度为50个字符',
  'register.locales.error.password.length':
    '请输入8到16位密码（必需包含数字及字母，可包含如下特殊字符：.!@#$%^&*-_）',
  'register.locales.error.phone.error': '请输入正确的手机号',
  'register.locales.error.email.error': '请输入有效的邮箱地址',
  'register.locales.error.password.error': '密码格式不正确',
  'register.locales.error.Verification.error': '请输入正确的验证码',
  'register.locales.error.Verification': '请输入验证码',

  'register.and': '和',

  'register.verification.success': '验证成功！',
  'register.verification.img': '请输入图形验证码',
  'register.verification.img.tip': '4位图形验证码',
};
