export default {
  'common.index': '首页',
  'common.product': '产品',
  'common.product.hot': '热门产品',
  'common.hardware': '硬件',
  'common.case': '客户案例',
  'common.service': '服务中心',
  'common.sms': '短信自助查询',
  'common.help': '使用帮助',
  'common.about': '关于我们',
  'common.product.desc': '产品介绍',
  'common.product.price': '产品定价',
  'common.product.download': '产品下载',
  'common.visitor': '智能访客',
  'common.meeting': '智能会议',
  'common.space': '空间管理',
  'common.hardware.equipment': '硬件设备',
  'common.visitor.robot': '访客机器人',
  'common.login': '登录',
  'common.free.use': '免费试用',
  'common.link.often': '常用链接',
  'common.service.center': '服务中心',
  'common.company.profile': '企业简介',
  'common.app.download': '应用下载',
  'common.android': 'Android',
  'common.ios': 'IOS',
  'common.ipad.visitor': '访客IPad',
  'common.contactus': '联系我们',
  'common.customer.hotline': '客服热线',
  'common.video.watch': '观看视频',
  'common.free.experience': '免费体验',

  'common.banner.tip1': '使用全球广受好评的威思客云平台',
  'common.banner.tip2': '让办公变得更智慧、更智能，用更少的时间获得更高的效率',
  'common.banner.tip3': '洞见现代访客场景，聚焦未来智能访客',
  'common.banner.tip4': '全新的业务流程，稳定的技术能力、高效的访客接待',
  'common.banner.tip5': '选择合适的版本，开启高效办公协作',
  'common.banner.tip6':
    '从个人到企业/组织，满足不同规模公司各阶段需求，为你和你的伙伴创造更多价值！',

  'common.banner.meeting': '威思客云会议',
  'common.banner.meeting.tip1': '会议服务全生命周期管理的一体化解决方案',
  'common.banner.meeting.tip2': '全场景、全生命周期的会议解决方案',
  'common.banner.meeting.tip3': '为企业带来智能、高效、简单、轻松的',
  'common.banner.meeting.tip4': '新型会议室预订管理体验',

  'common.banner.vistor': '威思客云访客',
  'common.banner.vistor.tip1': '洞见现代访客场景，聚焦智能企业未来',
  'common.banner.vistor.tip2': '全新的平台运维规划，稳定的云服务支持力',
  'common.banner.vistor.tip3': '高效的访客接待流程',

  'common.banner.workspace': '威思客云工位',

  'common.banner.fm': '智慧化设施管理平台',
  'common.banner.fm.tip1': '强大的设备集成对接能力和数据应用管理能力',
  'common.banner.fm.tip2': '通过数据中通和IOT平台',
  'common.banner.fm.tip3': '实现对企业设备运行状态的可视化管理',
  'common.banner.fm.tip4': '形成设备状态检测、异常报警、自定义维护的闭环管理流程',
};
