export default {
  'about.Where.we.work': 'Where we work',
  'about.us': 'About Us',
  'about.us.btn': 'Experience Now',
  'about.us.subt':
    'Wafer system was established in 1988 and is subordinate to Weixun Holding Co., Ltd. (Hong Kong Stock Code: 01087). It has 30 years of network system integration and more than 20 years of independent software development experience.',
  'about.us.subt2':
    "Based in China and facing the Asia Pacific and the world, Wafer system has branches and offices in Hong Kong, Beijing, Shanghai, Guangzhou, Chengdu, Suzhou and Shenzhen, and a software R & D center in Xi'an. Wafer system always focuses on Internet IT infrastructure, cloud services and network security, visual space management, intelligent office, mobile office and other fields. It is a leading new generation system integration and intelligent office application service provider in the industry.",
  'about.Guangzhou.name': 'Guangzhou',
  'about.Suzhou.name': 'Suzhou',
  'about.Beijing.name': 'Beijing',
  'about.Shanghai.name': 'Shanghai',
  'about.Shanghai.address':
    'Room t2-1502, SOHO Tianshan Plaza, 1717 Tianshan Road, Changning District, Shanghai',
  'about.Guangzhou.address':
    'Room 805-806, room 8, building B1, Wanda Plaza, No. 79, Wanbo Second Road, Nancun Town, Panyu District, Guangzhou',
  'about.Suzhou.address':
    'A0302, phase 4, International Science Park, No. 1355, Jinjihu Avenue, Suzhou Industrial Park',
  'about.Beijing.address':
    "Room 1907-1909, 19th floor, East Second office building, Oriental Plaza, No. 1, East Chang'an Street, Dongcheng District, Beijing",
  'about.Suzhou.tel': 'Tel: (86512) 69172780',
  'about.Guangzhou.tel': 'Tel: (8620) 38105888',
  'about.Beijing.tel': 'Tel: (8610) 85184988',
  'about.Guangzhou.fax': 'Fax:(8620) 38478669',
  'about.Beijing.fax': 'Fax:(8610) 85188340',
  'about.Suzhou.fax': 'Fax:(86512)69172789',
  'about.Suzhou.zipCode': 'Zip code:215021',
  'about.Beijing.zipCode': 'Zip code:100738',
  'about.Shanghai.tel': 'Tel: (8621) 32093700',
  'about.Shanghai.fax': 'Fax:(8621) 32093877',
  'about.Shanghai.zipCode': 'Zip code:200051',
  'about.Shenzhen.name': 'Shenzhen',
  'about.Shenzhen.address':
    'Room 2006, Zhuoyue building, No. 98, Fuhua 1st Road, Futian District, Shenzhen',
  'about.Shenzhen.tel': 'Tel: (86755) 23618250、23618367',
  // 'about.Shenzhen.fax':"",
  // 'about.Shenzhen.zipCode':'',
  // 'about.HongKong.zipCode':'',
  'about.HongKong.name': 'Hong Kong',
  'about.HongKong.address': "Room 2, 13 / F, 101 King's road, North Point, Hong Kong",
  'about.HongKong.tel': 'Tel: (852) 39008800',
  'about.HongKong.fax': 'Fax:(852) 28891231',

  'about.virsical.title': 'About Virsical',
  'about.virsical.tip1':
    'Wafer System is an IT solutions company with more than 30 years of system integration experience and more than 18 years of independent commercial software development experience.',
  'about.virsical.tip2':
    'Virsical Smart Office Solutions Suite focuses on digital office, building and campus management solutions with visualization, interactive visitor management, intelligent meeting management, workspace management, messaging, wireless access, video collaboration and instant messaging to enhance employee experience and productivity.',
  'about.virsical.tip3': 'Virsical leads the digital transformation!',
};
