/* eslint-disable no-underscore-dangle */
import React from 'react';
import { parse, stringify } from 'qs';
import { Tree } from 'antd';
import CryptoJS from 'crypto-js';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
const { TreeNode } = Tree;

export function isUrl(path) {
  return reg.test(path);
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 *
 * @param nodes
 * @param deptNameKey
 * @returns {*} tree nodes.
 */
export function makeTreeNodeC(nodes, deptNameKey) {
  return nodes.map((v) => {
    const nodeKey = v.id;
    return (
      <TreeNode data={v} title={v[deptNameKey]} key={nodeKey}>
        {v.children.length > 0 && makeTreeNodeC(v.children, deptNameKey)}
      </TreeNode>
    );
  });
}

// 设置cookie
export const setCookie = (name, value, expDays) => {
  const expDate = new Date();
  // 设置Cookie过期日期
  expDate.setDate(expDate.getDate() + expDays);
  // 添加Cookie
  document.cookie = `${name}=${escape(value)};path=/;expires=${expDate.toUTCString()}`;
};

// 读取cookie
export const getCookie = (name) => {
  // 获取name在Cookie中起止位置
  let start = document.cookie.indexOf(`${name}=`);
  if (start !== -1) {
    start = start + name.length + 1;
    // 获取value的终止位置
    let end = document.cookie.indexOf(';', start);
    if (end === -1) end = document.cookie.length;
    // 截获cookie的value值,并返回
    return unescape(document.cookie.substring(start, end));
  }
  return '';
};

// 删除cookie
export const delCookie = (name) => {
  setCookie(name, '', -1);
};

/**
 *
 * @param len
 * @param radix
 * @returns {string}
 */
export const generateUUID = (len, radix = null) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;
  const base = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len;) {
      // eslint-disable-next-line no-bitwise
      uuid[i] = chars[0 | (Math.random() * base)];
      i += 1;
    }
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    // eslint-disable-next-line no-multi-assign
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36;) {
      if (!uuid[i]) {
        // eslint-disable-next-line no-bitwise
        r = 0 | (Math.random() * 16);
        // eslint-disable-next-line no-bitwise
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
      i += 1;
    }
  }
  return uuid.join('');
};

// 定义类型
const class2type = {};
'Boolean Number String Function Array Date RegExp Object Error'.split(' ').forEach((e) => {
  class2type[`[object ${e}]`] = e.toLowerCase();
});

/**
 * 判断类型
 * @param obj
 * @returns {string|*|string|"undefined"|"object"|"boolean"|"number"|"string"|"function"|"symbol"|"bigint"}
 * @private
 */
export const typeOf = (obj) => {
  if (obj == null) {
    return String(obj);
  }
  return typeof obj === 'object' || typeof obj === 'function'
    ? class2type[Object?.prototype.toString.call(obj)] || 'object'
    : typeof obj;
};

// 获取URL地址的参数值。
// name为URL参数名
// 例如：?param1=abc&param2=123
// 当调用GetURLparam("param2"）时，获取到的值为：123
export const getUrlParam = (name) => {
  const regExp = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = window.location.search.substr(1).match(regExp);
  if (r !== null) return r[2];
  return null;
};

// 提交数据对于字符串进行前后空格清除
export const sendDataTrim = (data) => {
  if (JSON.stringify(data) === '{}') {
    return data;
  }
  const objData = JSON.parse(data);
  Object.keys(objData).forEach((v) => {
    if (objData[v] && typeof objData[v] === 'string') {
      objData[v] = objData[v].trim();
    }
  });

  return JSON.stringify(objData);
};

// url 参数解析去掉空格
export const sendUrlTrim = (url) => {
  if (url.indexOf('?') > -1) {
    const path = url.slice(0, url.indexOf('?'));
    const newParams = stringify(parse(url.substr(url.indexOf('?') + 1)), {
      encodeValuesOnly: true,
      encoder: (v) => {
        return encodeURIComponent(v.trim());
      },
    });
    if (newParams.length) {
      return `${path}?${newParams}`;
    }
  }
  return url;
};

/**
 * 滑块验证码加密 AES加密
 * @word 要加密的内容
 * @keyWord String  服务器随机返回的关键字
 *  */
 export function aesEncrypt(word,keyWord="XwKsGlMcdPMEhR1B"){
  const key = CryptoJS.enc.Utf8.parse(keyWord);
  const srcs = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return encrypted.toString();
}

/**
 * 滑块验证码解密 AES解密
 * @param data
 * @param aesKey
 * @return {string}
 */
export const aesDecrypt = (data, aesKey) => {
  if (!data) {
    return '';
  }
  /* 密钥 */
  const key = CryptoJS.enc.Utf8.parse(aesKey);

  /* 为密钥偏移量 */
  const iv = CryptoJS.enc.Utf8.parse(aesKey);

  const decrypted = CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypted).toString(); // 返回的是base64格式的密文
};

