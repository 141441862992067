import React from 'react';
import Swiper from 'swiper/js/swiper.js';
import 'swiper/css/swiper.min.css';

class SwiperBanner extends React.Component {
  componentDidMount() {
    new Swiper('.swiper-container', {
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        stopOnLastSlide: false,
      },
      speed: 10000,
      loop: true,
      slidesPerView: 'auto',
    });
  }

  render() {
    const { children } = this.props;
    return (
      <div className="swiper-container">
        <div className="swiper-wrapper">{children}</div>

        <style jsx='true'>
          {`
            .swiper-container .swiper-wrapper {
              transition-timing-function: linear;
            }
          `}
        </style>
      </div>
    );
  }
}

export default SwiperBanner;
