export default {
  'hardware.banner1':
    'Relying on AIOT technology, making full use of various sensors, creating a new "people-oriented" office ecology, and realizing office digital intelligence in an all-round way.',
  'hardware.title': 'Hardware Equipment',
  // 硬件产品第一行
  'hardware.row1.1.title': 'Narrow frame conference exhibition board (Standard version)',
  'hardware.row1.1.info1': '10.1 inch IPS, 1920*1200 resolution',
  'hardware.row1.1.info2': '10-point capacitive touch screen',
  'hardware.row1.1.info3': 'Quad-core processor Arm Cortex-A17, Mali-T764 GPU',
  'hardware.row1.1.info4': '2GB memory, support wireless and Bluetooth',

  'hardware.row1.2.title': 'Narrow frame conference exhibition board (Face recognition version)',
  'hardware.row1.2.info1': '10.1 inch IPS, 1920*1200 resolution',
  'hardware.row1.2.info2': '300W HD camera',
  'hardware.row1.2.info3': 'Support FID card read, support ZIGBEE gateway',
  'hardware.row1.2.info4': '10-point capacitive touch screen',
  'hardware.row1.2.info5': '1 Quad-core processor Arm Cortex-A17, Mali-T764 GPU',
  'hardware.row1.2.info6': '2GB memory, support wireless and Bluetooth',

  'hardware.row1.3.title': 'Thin and light conference display board',
  'hardware.row1.3.info1': '10.1 inch IPS, 1280*800 resolution',
  'hardware.row1.3.info2': '200W HD camera',
  'hardware.row1.3.info3': 'Support LED',
  'hardware.row1.3.info4': 'Multi-touch screen',
  'hardware.row1.3.info5': 'Quad-core processor Arm Cortex-A17',
  'hardware.row1.3.info6': '2GB memory, support wireless',

  // 硬件产品第二行
  'hardware.row2.1.title': 'Visitor all-in-one',
  'hardware.row2.1.info1': '21.5 inch, 32 inch monitor',
  'hardware.row2.1.info2': 'Windows, Android',
  'hardware.row2.1.info3': 'Support ID card reader, QR code reader',
  'hardware.row2.1.info4': 'Live binocular camera',
  'hardware.row2.1.info5': 'Visitor card printer',

  'hardware.row2.2.title': 'Desktop all-in-one',
  'hardware.row2.2.info1': '13.3 inch IPS double-sided screen, 1920*1080 resolution',
  'hardware.row2.2.info2': 'Support passport scanning camera',
  'hardware.row2.2.info3': 'QR code scanning camera',
  'hardware.row2.2.info4': 'Support ID card reader',
  'hardware.row2.2.info5': 'Support wireless connection',

  'hardware.row2.3.title': 'Play Box',
  'hardware.row2.3.info1': 'Quad-core processor Arm Cortex-A17, Mali-T764 GPU',
  'hardware.row2.3.info2': '2GB RAM',
  'hardware.row2.3.info3': 'Support LVDS, EDP display interface, HDMI interface',
  'hardware.row2.3.info4': 'Support wireless connection',
  'hardware.row2.3.info5': 'Support bluetooth',

  // 硬件产品第三行
  'hardware.row3.1.title': 'Hanshow Ink Screen Conference Exhibition Board',
  'hardware.row3.1.info1': '7.5 inch version',
  'hardware.row3.1.info2': 'EPD dot matrix electronic paper',
  'hardware.row3.1.info3': 'Battery working life: 5 years',

  'hardware.row3.2.title': 'Hanshow 10.2 inch',
  'hardware.row3.2.info1': '10.2 inch version',
  'hardware.row3.2.info2': 'EPD dot matrix electronic paper',
  'hardware.row3.2.info3': 'Battery working life: 5 years',

  'hardware.row3.3.title': 'Electronic work card base station',
  'hardware.row3.3.info1': '4-way antenna parallel communication',
  'hardware.row3.3.info2': 'Base station communication radius 15 meters',
  'hardware.row3.3.info3': 'Private protocol Esl-Working V2.0',
  'hardware.row3.3.info4': 'The base station supports POE and AC adapter power supply',

  // 硬件产品第四行
  'hardware.row4.1.title': 'HD Playback Box',
  'hardware.row4.1.info1': 'Eight-core processor Mali-T764 GPU, 4GB memory',
  'hardware.row4.1.info2': 'Support two EDP ports, support 4K eDP display ports',
  'hardware.row4.1.info3': 'Support wireless, Bluetooth',

  'hardware.row4.2.title': 'Human body sensor',
  'hardware.row4.2.info1': 'Green rice',
  'hardware.row4.2.info2': 'ZigBee protocol',
  'hardware.row4.2.info3': 'Gateway coverage radius of 10 meters',

  'hardware.row4.3.title': 'Smart Socket',
  'hardware.row4.3.info1': 'Nanjing IOT',
  'hardware.row4.3.info2': 'ZigBee Protocol',
  'hardware.row4.3.info3': 'Gateway coverage radius of 10 meters',

  // 硬件产品第五行
  'hardware.row5.1.title': 'Touch screen all-in-one machine',
  'hardware.row5.1.info1': '55 inch display',

  'hardware.row5.2.title': 'Touch screen all-in-one machine',
  'hardware.row5.2.info1': '65 inch display',

  'hardware.row5.3.title': 'Touch screen all-in-one machine',
  'hardware.row5.3.info1': '75 inch display',

  // 硬件产品第六行
  'hardware.row6.1.title': 'Touch screen all-in-one machine',
  'hardware.row6.1.info1': 'X86S — 86-inch display',
  'hardware.row6.1.info2': 'X98S — 98-inch display',

  'hardware.row6.2.title': 'Occupation of toilet seat door lock',

  'hardware.row6.3.title': 'Toilet occupancy sign',

  // 机器人
  'hardware.banner2':
    'The Android-based OS system supports unlimited possibilities for the development of different functions.',
  'hardware.product.title': 'Product Description',
  'hardware.product.tip1':
    'There are two built-in systems, one is a visual user operating system, and the other is the ROBOX navigation system that allows the visitor robot to move autonomously, with up to 60 sensors up and down the whole body, including 360-degree lidar, deep-sensing camera, and linear flight time Sensors, etc. It can perceive the surrounding environment in real time, construct a two-dimensional map, "smart" plan its course of action, autonomously avoid various obstacles, and realize active movement.',
  'hardware.product.tip2':
    "With the function of receiving voice commands and facial recognition, it can automatically recognize the user's face while playing music or video, and adjust the tilt angle of the screen as the user squats or gets up to follow the user's walk and turn.",
  'hardware.product.tip3':
    'Integrated artificial intelligence and human-computer interaction technology. It has been widely used in office space, retail stores, hotels, hospitals, government halls, catering and education.',
  'hardware.param': 'Parameter configuration',
  'hardware.func': 'Features',
  'hardware.func.yingbin': 'Welcome Reception',
  'hardware.func.gensui': 'Smart Follow',
  'hardware.func.renlian': 'Face Recognition',
  'hardware.func.jiangjie': 'Guided Explanation',
  'hardware.func.zixun': 'Q&A Consultation',
  'hardware.func.xunhang': 'Cruise Promotion',
  'hardware.func.hudong': 'Interactive Entertainment',

  'hardware.func.zhongkong': 'Intelligent central control',
  'hardware.func.huichong': 'Automatic Recharge',
  'hardware.func.shipin': 'Video Call',
  'hardware.func.yuancheng': 'Remote Control',
  'hardware.func.music': 'Online Music',
  'hardware.func.news': 'News',
  'hardware.func.sheying': 'Video Photography',

  'hardware.param.patent': 'Patented Technology',
  'hardware.param.patent.1.msg1': 'ROBOX navigation system',
  'hardware.param.patent.1.msg2': 'Human-computer interaction and autonomous navigation planning',
  'hardware.param.patent.2.msg1': 'Two-dimensional map construction',
  'hardware.param.patent.2.msg2': 'Autonomous navigation',
  'hardware.param.patent.2.msg3': 'User detection and tracking',
  'hardware.param.patent.2.msg4': 'Intelligent obstacle avoidance',
  'hardware.param.patent.2.msg5': 'Route Plan',
  'hardware.param.patent.2.msg6': 'Facial contour recognition',
  'hardware.param.patent.3.msg1':
    'Real-time sensor fusion and data analysis ensure the safety of fully automated navigation',
  'hardware.param.patent.3.msg2': 'Automatic navigation system',

  'hardware.param.system': 'Operating System',
  'hardware.param.system.1.msg1':
    'Through the ability of listening, speaking, reading, and seeing, the visitor robot always maintains a learning state, better serves users, and provides an extremely intuitive operation mode.',
  'hardware.param.system.2.title': 'Voice Interaction',
  'hardware.param.system.2.msg1':
    "The visitor robot uses automatic speech recognition technology and far-field speech recognition technology (ASR), natural language processing (NLP), and text-to-speech (TTS) engine to realize functions such as listening, recognizing, understanding and responding to users'voice commands.",
  'hardware.param.system.3.title': 'User Interface',
  'hardware.param.system.3.msg1':
    'The high-definition display customized by the visitor robot allows you to say, hear, and see with enhanced audio-visual effects to obtain the ultimate human-computer interaction experience.',
  'hardware.param.system.4.msg1':
    'The guest robot integrates a number of top artificial intelligence engines, through the cooperation of these engines to provide you with the best service. Its navigation system can learn the surrounding environment by itself, understand where the robot is, what it sees, and where it is going.',
  'hardware.param.system.5.title': 'Openness',
  'hardware.param.system.5.msg1': 'Applications open to third parties',
  'hardware.param.system.5.msg2': 'Provide SDK (software development kit)',

  'hardware.param.program': 'Built-in Program',
  'hardware.param.program.ai': 'AI Assistant',
  'hardware.param.program.home': 'The visitor robot arrives home',
  'hardware.param.program.tonghua': 'Video Call',
  'hardware.param.program.news1': 'News',
  'hardware.param.program.dj': 'Private DJ',
  'hardware.param.program.sheying1': 'Worry-free Photography',
  'hardware.param.program.help': 'Mobile Helper',

  'hardware.param.connection': 'Connect',
  'hardware.param.connection.2.title': 'Bluetooth',
  'hardware.param.connection.2.msg1':
    'Capable of Bluetooth 4.0 short-range wireless connection of devices and accessories',

  'hardware.param.size': 'Size',
  'hardware.param.size.1.title': 'Size',
  'hardware.param.size.1.msg1': 'long:45cm',
  'hardware.param.size.1.msg2': 'Width:35cm',
  'hardware.param.size.1.msg3': 'high:100cm',
  'hardware.param.size.2.title': 'weight',
  'hardware.param.size.2.msg1': '26 lbs/12kg',

  'hardware.param.display': 'Screen Display',
  'hardware.param.display.1.title': '10.1 inch LCD HD display',
  'hardware.param.display.1.msg1': 'Capacitive multi-touch screen',
  'hardware.param.display.1.msg2': 'IPS LCD display with 16 million colors (1920X1200)',
  'hardware.param.display.1.msg3': 'Touch screen pixel density: 224 (ppi)',
  'hardware.param.display.2.msg1': 'Maximum brightness 340 cd / m² (typical value)',
  'hardware.param.display.2.msg2': 'Anti-fingerprint Coating',

  'hardware.param.tiltable': 'Tiltable Screen',
  'hardware.param.tiltable.1.msg1': 'Automatic face tracking tilt screen angle',
  'hardware.param.tiltable.1.msg2': 'Tilt angle: -15°～+55°',

  'hardware.param.micro': 'Microphone',
  'hardware.param.micro.1.msg1': 'Microphone array',
  'hardware.param.micro.1.msg2': '4 omnidirectional digital microphones',
  'hardware.param.micro.1.msg3': 'Real-time Positioning',
  'hardware.param.micro.1.msg4': 'Beamforming',
  'hardware.param.micro.1.msg5': 'Acoustic echo cancellation',
  'hardware.param.micro.1.msg6': 'Environmental noise reduction',

  'hardware.param.audio': 'Audio',
  'hardware.param.audio.1.msg1': '20W speaker audio output',
  'hardware.param.audio.1.msg2': 'High fidelity equalizer',
  'hardware.param.audio.1.msg3': '1 subwoofer',
  'hardware.param.audio.1.msg4': '2 midrange speakers',
  'hardware.param.audio.1.msg5': '2 tweeters',

  'hardware.param.webcam': 'Camera',
  'hardware.param.webcam.1.msg1': '13 million pixel high resolution camera',
  'hardware.param.webcam.1.msg2': 'Auto Focus',
  'hardware.param.webcam.1.msg3': '30 frames per second video recording',
  'hardware.param.webcam.1.msg4': '5 lenses',
  'hardware.param.webcam.1.msg5': 'Infrared filter',
  'hardware.param.webcam.2.title': 'Wide-angle camera',
  'hardware.param.webcam.2.msg1': '13 million pixel wide-angle camera for remote navigation',
  'hardware.param.webcam.2.msg2': 'Support 1080P',
  'hardware.param.webcam.3.title': 'Depth Camera',
  'hardware.param.webcam.3.msg1': '30 frames per second',
  'hardware.param.webcam.3.msg2': 'Up to 5 meters',
  'hardware.param.webcam.3.msg3': '90° field of view',

  'hardware.param.cpu.1.msg1': 'Quad-core ARM Processor',
  'hardware.param.cpu.1.msg2': 'Human-computer interaction system based on Android architecture',
  'hardware.param.cpu.1.msg3': 'Support 1080P@ to give you exclusive user experience',
  'hardware.param.cpu.2.msg1': 'Six-core ARM Processor',
  'hardware.param.cpu.2.msg2':
    'Autonomous navigation system based on ARM architecture @30 frames per second video recording',
  'hardware.param.cpu.2.msg3': 'Low energy consumption, high performance',

  'hardware.param.battery': 'Battery',
  'hardware.param.battery.1.msg1': 'It can work for 8 hours under full power',
  'hardware.param.battery.1.msg2': 'Automatic charging-220V / 110V',
  'hardware.param.battery.1.msg3': 'High-performance charging pile',
  'hardware.param.battery.1.msg4': 'Lithium Ion Battery',

  'hardware.param.mobility': 'Mobility',
  'hardware.param.mobility.1.msg1':
    '2 independent 50W direct drive brushless DC motor drive systems',
  'hardware.param.mobility.2.title': 'Speed',
  'hardware.param.mobility.2.msg1': 'Up to 1 meter per second',

  'hardware.param.tray': 'Tray',
  'hardware.param.tray.1.title': 'Tray',
  'hardware.param.tray.1.msg1': 'Area: 30×25cm can bear 3KG heavy objects',
  'hardware.param.tray.2.title': 'Wireless charging',
  'hardware.param.tray.2.msg1': 'Built-in standard wireless charger for mobile phone charging',

  'hardware.param.package': 'Package Contents',
  'hardware.param.package.1.msg1': '1 visitor robot',
  'hardware.param.package.1.msg2': '1 charging pile + charging cable',
  'hardware.param.package.1.msg3': 'Manual',
};
