/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react';
import styles from './index.less';
import banner from '../../assets/hardware/hardwarebanner.png';
import img1_1 from '../../assets/hardware/img_1_1.png';
import img1_2 from '../../assets/hardware/img_1_2.png';
import img1_3 from '../../assets/hardware/img_1_3.png';
import img2_1 from '../../assets/hardware/img_2_1.jpg';
import img2_2 from '../../assets/hardware/img_2_2.jpg';
import img2_3 from '../../assets/hardware/img_2_3.jpg';
import img3_1 from '../../assets/hardware/img_3_1.jpg';
import img3_2 from '../../assets/hardware/img_3_2.jpg';
import img3_3 from '../../assets/hardware/img_3_3.jpg';
import img4_1 from '../../assets/hardware/img_4_1.jpg';
import img4_2 from '../../assets/hardware/img_4_2.jpg';
import img4_3 from '../../assets/hardware/img_4_3.png';
import img5_1 from '../../assets/hardware/img_5_1.png';
import img5_2 from '../../assets/hardware/img_5_2.jpg';
import img5_3 from '../../assets/hardware/img_5_3.png';
import img6_1 from '../../assets/hardware/img_6_1.jpg';
import img7_2 from '../../assets/hardware/img_7_2.png';
import img7_3 from '../../assets/hardware/img_7_3.png';
import { formatMessage } from 'umi';

const hardwareList = [
  {
    title: formatMessage({ id: 'hardware.row1.1.title' }),
    img: img1_1,
    price: '4500.00',
    imgWidth: 171,
    infoheight: '420px',
    infomargin: '30px',
    info: [
      'WH-VSM-PAD-SMBO222B',
      formatMessage({ id: 'hardware.row1.1.info1' }),
      formatMessage({ id: 'hardware.row1.1.info2' }),
      formatMessage({ id: 'hardware.row1.1.info3' }),
      formatMessage({ id: 'hardware.row1.1.info4' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row1.2.title' }),
    img: img1_2,
    price: '4800.00',
    imgWidth: 171,
    infoheight: '420px',
    infomargin: '8px',
    info: [
      'WH-VSM-PAD-SMBO212H',
      formatMessage({ id: 'hardware.row1.2.info1' }),
      formatMessage({ id: 'hardware.row1.2.info2' }),
      formatMessage({ id: 'hardware.row1.2.info3' }),
      formatMessage({ id: 'hardware.row1.2.info4' }),
      formatMessage({ id: 'hardware.row1.2.info5' }),
      formatMessage({ id: 'hardware.row1.2.info6' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row1.3.title' }),
    img: img1_3,
    price: '3500.00',
    imgWidth: 178,
    infoheight: '420px',
    infomargin: '16px',
    info: [
      'WH-VSM-PAD-T800C-B',
      formatMessage({ id: 'hardware.row1.3.info1' }),
      formatMessage({ id: 'hardware.row1.3.info2' }),
      formatMessage({ id: 'hardware.row1.3.info3' }),
      formatMessage({ id: 'hardware.row1.3.info4' }),
      formatMessage({ id: 'hardware.row1.3.info5' }),
      formatMessage({ id: 'hardware.row1.3.info6' }),
    ],
  },
  // 第二行
  {
    title: formatMessage({ id: 'hardware.row2.1.title' }),
    img: img2_1,
    price: '20000.00',
    imgWidth: 122,
    imgHeight: 137,
    infoheight: '403px',
    infomargin: '35px',
    info: [
      formatMessage({ id: 'hardware.row2.1.info1' }),
      formatMessage({ id: 'hardware.row2.1.info2' }),
      formatMessage({ id: 'hardware.row2.1.info3' }),
      formatMessage({ id: 'hardware.row2.1.info4' }),
      formatMessage({ id: 'hardware.row2.1.info5' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row2.2.title' }),
    img: img2_2,
    price: '12000.00',
    imgWidth: 141,
    infoheight: '403px',
    infomargin: '24px',
    info: [
      'SenseId',
      formatMessage({ id: 'hardware.row2.2.info1' }),
      formatMessage({ id: 'hardware.row2.2.info2' }),
      formatMessage({ id: 'hardware.row2.2.info3' }),
      formatMessage({ id: 'hardware.row2.2.info4' }),
      formatMessage({ id: 'hardware.row2.2.info5' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row2.3.title' }),
    img: img2_3,
    price: '1200.00',
    imgWidth: 200,
    infoheight: '403px',
    infomargin: '19px',
    info: [
      'YL3288',
      formatMessage({ id: 'hardware.row2.3.info1' }),
      formatMessage({ id: 'hardware.row2.3.info2' }),
      formatMessage({ id: 'hardware.row2.3.info3' }),
      formatMessage({ id: 'hardware.row2.3.info4' }),
      formatMessage({ id: 'hardware.row2.3.info5' }),
    ],
  },
  // 第三行
  {
    title: formatMessage({ id: 'hardware.row3.1.title' }),
    img: img3_1,
    price: '1100.00',
    imgWidth: 152,
    infoheight: '346px',
    infomargin: '35px',
    info: [
      'WH-VSM-PAD-HSXXXL',
      formatMessage({ id: 'hardware.row3.1.info1' }),
      formatMessage({ id: 'hardware.row3.1.info2' }),
      formatMessage({ id: 'hardware.row3.1.info3' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row3.2.title' }),
    img: img3_2,
    price: '2000.00',
    imgWidth: 184,
    infoheight: '346px',
    infomargin: '35px',
    info: [
      'WH-VSM-PAD-HSXXXXL',
      formatMessage({ id: 'hardware.row3.2.info1' }),
      formatMessage({ id: 'hardware.row3.2.info2' }),
      formatMessage({ id: 'hardware.row3.2.info3' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row3.3.title' }),
    img: img3_3,
    price: '1800.00',
    imgWidth: 169,
    infoheight: '346px',
    infomargin: '35px',
    info: [
      formatMessage({ id: 'hardware.row3.3.info1' }),
      formatMessage({ id: 'hardware.row3.3.info2' }),
      formatMessage({ id: 'hardware.row3.3.info3' }),
      formatMessage({ id: 'hardware.row3.3.info4' }),
    ],
  },
  // 第四行
  {
    title: formatMessage({ id: 'hardware.row4.1.title' }),
    img: img4_1,
    price: '2000.00',
    imgWidth: 197,
    imgHeight: 111,
    infoheight: '346px',
    infomargin: '35px',
    info: [
      'RK3399',
      formatMessage({ id: 'hardware.row4.1.info1' }),
      formatMessage({ id: 'hardware.row4.1.info2' }),
      formatMessage({ id: 'hardware.row4.1.info3' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row4.2.title' }),
    img: img4_2,
    price: '158.00',
    imgWidth: 185,
    // imgHeight: 120,
    infoheight: '346px',
    infomargin: '35px',
    info: [
      formatMessage({ id: 'hardware.row4.2.info1' }),
      formatMessage({ id: 'hardware.row4.2.info2' }),
      formatMessage({ id: 'hardware.row4.2.info3' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row4.3.title' }),
    img: img5_2,
    price: '660.00',
    imgWidth: 150,
    infoheight: '346px',
    infomargin: '35px',
    info: [
      formatMessage({ id: 'hardware.row4.3.info1' }),
      formatMessage({ id: 'hardware.row4.3.info2' }),
      formatMessage({ id: 'hardware.row4.3.info3' }),
    ],
  },
  // 第五行
  {
    title: formatMessage({ id: 'hardware.row5.1.title' }),
    img: img6_1,
    price: '11897.00',
    imgWidth: 148,
    infoheight: '232px',
    infomargin: '35px',
    info: ['X55S', formatMessage({ id: 'hardware.row5.1.info1' })],
  },
  {
    title: formatMessage({ id: 'hardware.row5.2.title' }),
    img: img6_1,
    price: '19897.00',
    imgWidth: 148,
    infoheight: '232px',
    infomargin: '35px',
    info: ['X65S', formatMessage({ id: 'hardware.row5.2.info1' })],
  },
  {
    title: formatMessage({ id: 'hardware.row5.3.title' }),
    img: img6_1,
    price: '30897.00',
    imgWidth: 148,
    infoheight: '232px',
    infomargin: '35px',
    info: ['X75S', formatMessage({ id: 'hardware.row5.3.info1' })],
  },
  // 第六行
  {
    title: formatMessage({ id: 'hardware.row6.1.title' }),
    img: img6_1,
    price: '43897.00',
    imgWidth: 148,
    infoheight: '232px',
    infomargin: '35px',
    info: [
      formatMessage({ id: 'hardware.row6.1.info1' }),
      formatMessage({ id: 'hardware.row6.1.info2' }),
    ],
  },
  {
    title: formatMessage({ id: 'hardware.row6.2.title' }),
    img: img7_2,
    price: '730.00',
    imgWidth: 186,
    infoheight: '232px',
    infomargin: '35px',
  },
  {
    title: formatMessage({ id: 'hardware.row6.3.title' }),
    img: img7_3,
    price: '360.00',
    imgWidth: 179,
    infoheight: '232px',
    infomargin: '35px',
  },
];

const Hardware = () => {
  return (
    <div className={styles.hardWare}>
      <div className={styles.banner}>
        <img width="100%" height="100%" src={banner} />
        <div className={styles.hardwareBannerText}>{formatMessage({ id: 'hardware.banner1' })}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentItem} style={{ background: '#FFF' }}>
          <div className={styles.contentTitle}>{formatMessage({ id: 'hardware.title' })}</div>
          <div className={styles.hardwareList}>
            {hardwareList?.map((item, index) => {
              return (
                <div className={styles.hardWareItem} key={index.toString()}>
                  <div className={styles.imgArea}>
                    <img
                      src={item.img}
                      width={item.imgWidth}
                      height={item.imgHeight || ''}
                      alt=""
                    />
                  </div>
                  <div className={styles.infoArea} style={{ height: 'auto' }}>
                    <div className={styles.itemTitle}>{item.title}</div>
                    <div className={styles.itemPrice}>￥{item.price}</div>
                    <div className={styles.itemInfo}>
                      {item?.info?.map((v, i) => {
                        return (
                          <div style={{ marginBottom: 14 }} key={i.toString()}>
                            {v}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hardware;
