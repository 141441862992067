export default {
  'help.use': '使用帮助',
  'help.use.tip': '本文为您介绍一系列入门操作，方便您快速上手和使用。',
  'help.use.first': '首次使用',
  'help.smt': '会议',
  'help.sws': '工位',
  'help.vst': '访客',

  // 首次使用
  'help.first.title1': '1、注册',
  'help.first.title2': '2、登录',
  'help.first.title3': '3、站点及语言切换',
  'help.first.info1': '访问网站',
  'help.first.info1.tip': '，点击注册，跳转到注册页面',
  'help.first.info2': '输入相关信息后，点击”注册”，完成后，自动跳转到登录页',
  'help.first.info3': '输入邮箱/手机，密码后进入系统',
  'help.first.info4': '访问网站',
  'help.first.info4.tip': '，点击”登录”',
  'help.first.info5': '跳转到登录页，输入邮箱/手机，密码后进入系统',
  'help.first.info6': '如果是Teams用户，请点击使用Mircosoft 365账号登录。',
  'help.first.info7': '支持中国站和国际站互相切换，并且可以选择使用简体中文或英文显示',

  // 会议
  'help.smt.title1': '1、预约会议',
  'help.smt.title2': '2、会议室查询',
  'help.smt.stitle1': '(1) 使用管理员账号登录',
  'help.smt.stitle2': '(2) 新建区域',
  'help.smt.stitle3': '(3) 新建会议室',
  'help.smt.stitle4': '(4) 预约会议室',
  'help.smt.info1': '点击"管理端"进入后台管理',
  'help.smt.info2': '左侧菜单选择”系统管理”，然后选择”区域管理”，点击”…”符号',
  'help.smt.info3': '点击”添加子级”',
  'help.smt.info4': '填写对应信息，”区域功能”选择”会议室区域”，部分信息选填',
  'help.smt.info5': '提交后，完成区域的创建',
  'help.smt.info6': '左侧菜单选择”会议管理”，然后选择”会议室管理”，点击”添加会议室”',
  'help.smt.info7': '填写带”*”符号的信息，选择区域',
  'help.smt.info8': '确定后，会议室创建成功',
  'help.smt.info9': '管理员、普通用户可登录',
  'help.smt.info9.tip': '。如果您当前在管理端，请在右上角点击”客户端”进行切换。',
  'help.smt.info10': '左侧菜单选择”智能会议”，然后选择”会议预定”，点击”预定会议”',
  'help.smt.info11': '填写会议室名称、选择会议室、设置时间及参会人后，提交',
  'help.smt.info12': '会议预定成功',
  'help.smt.info13': '左侧菜单选择”智能会议”，然后选择”会议列表”，可以查看和自己相关的会议情况。',

  // 工位
  'help.sws.title1': '1、预定工位',
  'help.sws.title2': '2、我的预定',
  'help.sws.stitle1': '(1) 新建工作日',
  'help.sws.stitle2': '(2) 新建工位',
  'help.sws.stitle3': '(3) 上传地图',
  'help.sws.stitle4': '(4) 地图设置工位位置',
  'help.sws.stitle5': '(5) 绑定工位',
  'help.sws.stitle6': '(6) 工位预定',
  'help.sws.info1':
    '左侧菜单选择”系统管理”，然后选择”工作日管理”，点击”添加”, 输入工作日名称及时间段',
  'help.sws.info2': '输入工作日名称及时间段',
  'help.sws.info3': '点击”确定”，完成工作日的添加',
  'help.sws.info4':
    '左侧菜单选择”工位管理”，然后选择”工位”，点击”添加工位”。输入工位相关信息，工位类型选择”移动工位”，选择”工位部门”，最后点击”确定”，完成添加。',
  'help.sws.info5': '左侧菜单选择”地图管理”，然后选择”地图维护”，点击”地图配置”',
  'help.sws.info6': '点击”上传图片”，将准备好的平面图上传，确定后保存',
  'help.sws.info7': '点击”地图编辑”',
  'help.sws.info8': '显示平面图，点击右侧蓝色工位图标，然后在平面图任意位置点击，设置工位的位置',
  'help.sws.info9': '点击保存，设置工位位置完成',
  'help.sws.info10': '点击”空间绑定”',
  'help.sws.info11': '点击已经设置好的工位位置，然后点击左上角的”资源绑定”',
  'help.sws.info12': '选择工位，设置”移动工位”，只有”移动工位”才可以预定',
  'help.sws.info13': '点击确定，完成绑定',

  'help.sws.info14': '管理员、普通用户可登录',
  'help.sws.info14.tip': '。如果您当前在管理端，请在右上角点击”客户端”进行切换。',
  'help.sws.info15':
    '左侧菜单选择”智能工位”，然后选择”工位预定”，设置”区域”后，显示平面图，点击图中的工位',
  'help.sws.info16': '显示预订信息，点击”确认预订”',
  'help.sws.info17': '预定成功',
  'help.sws.info18': '左侧菜单选择”智能工位”，然后选择”我的预定”，可以查询到当前用户的相关预定。',

  // 访客
  'help.vst.title1': '1、邀请访客',
  'help.vst.title2': '2、签到',
  'help.vst.title3': '3、访问记录',
  'help.vst.stitle1': '(1) 新建邀请',
  'help.vst.info1':
    '左侧菜单选择”访客管理”，然后选择”邀请记录”，点击”新增”，添加访客信息，设置访客类型、接待地点、访问事由及时间后。',
  'help.vst.info2': '点击"+"符号，添加访客信息。',
  'help.vst.info3': '点击"确定" 完成邀请信息提交',
  'help.vst.info4': '邀请记录里选择已到访的邀请信息，点击”详情及操作”',
  'help.vst.info5': '点击”签到”，完成签到',
  'help.vst.info6': '在左侧菜单中选择“访客管理”，然后选择“访问记录”，查询访客访问记录',

  'help.sms.get.success': '发送成功',
  'help.sms.title': '短信自助查询',
  'help.sms.account': '手机号/邮箱',
  'help.sms.account.tip': '请输入手机号/邮箱',
  'help.sms.search': '查询',
  'help.sms.reset': '重置',
  'help.sms.export': '导出',
  'help.sms.company.name': '公司名称',
  'help.sms.time': '时间',
  'help.sms.num': '短信条数',
  'help.sms.send.time': '发送时间',
  'help.sms.send.content': '发送内容',
  'help.sms.operate.success': '操作成功',
  'help.sms.search.success': '查询成功',

  'help.table.tip1': '本页有',
  'help.table.tip2': '条数据，共',
  'help.table.tip3': '条',

  'help.sms.table.tip': '※ 短信条数最终以运营商计算数据为准',
};
