import React, { useState } from 'react';
import { formatMessage } from 'umi';
import { Menu, Card, Modal, Affix } from 'antd';
import LightSpeed from 'react-reveal/LightSpeed';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import styles from './index.less';
import { Firstuse, Meeting, Workspace, Vistor } from './helpData';
import bg from '@/assets/case/bg.png';
import { zhIntl } from '@/utils/intlUtils';

const { SubMenu } = Menu;
const Case = () => {
  const [showImg, setShowImg] = useState(false);

  const [status, setStatus] = useState('first1');
  const [openKeys, setOpenKeys] = useState(['first']);
  const rootSubmenuKeys = ['first', 'vst', 'smt', 'sws'];
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleClick = (v) => {
    setStatus(v.key);
    setTimeout(() => {
      if (v.key === 'first1') {
        window.scrollTo({
          top: 800,
          behavior: 'instant',
        });
      }
      if (v.key === 'first2') {
        window.scrollTo({
          top: 2250,
          behavior: 'instant',
        });
      }
      if (v.key === 'first3') {
        window.scrollTo({
          top: 3700,
          behavior: 'instant',
        });
      }
      if (v.key === 'smt1') {
        window.scrollTo({
          top: 800,
          behavior: 'instant',
        });
      }
      if (v.key === 'smt2') {
        window.scrollTo({
          top: 6500,
          behavior: 'instant',
        });
      }
      if (v.key === 'sws1') {
        window.scrollTo({
          top: 800,
          behavior: 'instant',
        });
      }
      if (v.key === 'sws2') {
        window.scrollTo({
          top: 9000,
          behavior: 'instant',
        });
      }
      if (v.key === 'vst1') {
        window.scrollTo({
          top: 800,
          behavior: 'instant',
        });
      }
      if (v.key === 'vst2') {
        window.scrollTo({
          top: 2300,
          behavior: 'instant',
        });
      }
      if (v.key === 'vst3') {
        window.scrollTo({
          top: 3400,
          behavior: 'instant',
        });
      }
    }, 500);
  };

  return (
    <div className={styles.help}>
      <div className={styles.bg}>
        <img src={bg} alt="" />
        <div className={styles.name}>
          <div className={styles.content}>
            <LightSpeed right cascade>
              <div className={styles.productName}>{zhIntl('使用帮助')}</div>
            </LightSpeed>
            <Zoom bottom cascade>
              <div className={styles.pageRoute}>
                <span>{zhIntl('首页')}</span> &gt; <span>{zhIntl('服务中心')}</span> &gt;{' '}
                <span style={{ color: '#fff' }}>{zhIntl('使用帮助')}</span>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <Fade bottom cascade>
          <div className={styles.title}>{formatMessage({ id: 'help.use' })}</div>
        </Fade>
        <LightSpeed left cascade>
          <div className={styles.subTitle}>{formatMessage({ id: 'help.use.tip' })}</div>
        </LightSpeed>
      </div>
      <div className={styles.content}>
        <div className={styles.helpBody}>
          {status.includes('first') && <Firstuse />}
          {status.includes('smt') && <Meeting />}
          {status.includes('sws') && <Workspace />}
          {status.includes('vst') && <Vistor />}
        </div>
        <div className={styles.helpMenu}>
          <Affix offsetTop={130}>
            <Card title={zhIntl('目录')} style={{ height: 'auto' }}>
              <Menu
                onClick={(v) => handleClick(v)}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
              >
                <SubMenu key="first" title={formatMessage({ id: 'help.use.first' })}>
                  <Menu.Item key="first1">{formatMessage({ id: 'help.first.title1' })}</Menu.Item>
                  <Menu.Item key="first2">{formatMessage({ id: 'help.first.title2' })}</Menu.Item>
                  <Menu.Item key="first3">{formatMessage({ id: 'help.first.title3' })}</Menu.Item>
                </SubMenu>
                <SubMenu key="vst" title={formatMessage({ id: 'help.vst' })}>
                  <Menu.Item key="vst1">{formatMessage({ id: 'help.vst.title1' })}</Menu.Item>
                  <Menu.Item key="vst2">{formatMessage({ id: 'help.vst.title2' })}</Menu.Item>
                  <Menu.Item key="vst3">{formatMessage({ id: 'help.vst.title3' })}</Menu.Item>
                </SubMenu>
                <SubMenu key="smt" title={formatMessage({ id: 'help.smt' })}>
                  <Menu.Item key="smt1">{formatMessage({ id: 'help.smt.title1' })}</Menu.Item>
                  <Menu.Item key="smt2">{formatMessage({ id: 'help.smt.title2' })}</Menu.Item>
                </SubMenu>
                <SubMenu key="sws" title={formatMessage({ id: 'help.sws' })}>
                  <Menu.Item key="sws1">{formatMessage({ id: 'help.sws.title1' })}</Menu.Item>
                  <Menu.Item key="sws2">{formatMessage({ id: 'help.sws.title2' })}</Menu.Item>
                </SubMenu>
              </Menu>
            </Card>
          </Affix>
        </div>
      </div>

      {showImg && (
        <Modal
          visible={true}
          footer={null}
          onCancel={() => {
            setShowImg(false);
          }}
        >
          <img src={showImg} style={{ width: '100%' }} />
        </Modal>
      )}
      <style jsx="true">
        {`
          .ant-modal-close-x {
            position: relative;
            top: -10px;
            left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            color: #fff;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 50%;
          }
          .ant-modal {
            width: auto !important;
            max-width: 900px;
          }
        `}
      </style>
    </div>
  );
};

export default Case;
