/* eslint-disable no-param-reassign */
import request from '@/utils/request';
import { getLocale } from 'umi'
import { RESTFUL_PATH, CLOUD_USER_ID, CLOUD_USER_SECRET } from '@/utils/constant';
import { stringify } from 'qs';
import { whiteDown } from '@/utils/download';
import { base64String } from '@/utils/encryption';

// 获取验证码
export function sendCode(data) {
  if (data.email) {
    // eslint-disable-next-line no-param-reassign
    data.email = data.email.toLowerCase();
  }
  return request(`/${RESTFUL_PATH.cloudOms}/users/send/captcha`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

// 短信认证
export function smsLogin(params) {
  const data = {
    account: params.account,
    code: params.code,
  };
  const form = new FormData();
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const k in data) {
    form.append(k, data[k]);
  }
  const Authorization = base64String(`${CLOUD_USER_ID}:${CLOUD_USER_SECRET}`);
  return request(`/${RESTFUL_PATH.cloudOms}/users/websiteCustomer/login`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${Authorization}`,
    },
    data: form,
  });
}

// 获取短信列表
export function smsList(params) {
  delete params.time;
  return request(`/${RESTFUL_PATH.cloudOms}/sms/websiteCustomer/records/page?${stringify(params)}`, {
    method: 'GET',
  });
}

// 导出短信列表
export const exportSmsList = (params) => {
  delete params.page;
  params.lang = getLocale().replace('-', '_');
  return whiteDown('', `/${RESTFUL_PATH.cloudOms}/sms/websiteCustomer/records/export?${stringify(params)}`);
};

// 导出检测
export const exportCheck = (params) => {
  return request(`/${RESTFUL_PATH.cloudOms}/sms/websiteCustomer/records/exportCheckSize?${stringify(params)}`, {
    method: 'GET',
  });
};
