export default {
  // 'product.introduc': '',
  'product.Intelligent.Cloud.visitor.platform': 'Intelligent cloud visitor platform',
  'product.Intelligent.meet.visitor.platform': 'Intelligent cloud conference platform',
  'product.introduc.Cloud.visitor.advantage1': 'Multiple login methods',
  'product.introduc.Cloud.visitor.advantage1.desc':
    'Wechat applet, browser and app handle visitor reservation and visitor invitation business anytime and anywhere.',
  'product.introduc.Cloud.visitor.advantage2': 'Personalized template',
  'product.introduc.Cloud.visitor.advantage2.desc':
    'A variety of personalized visitor card templates show the enterprise style.',
  'product.introduc.Cloud.visitor.advantage3': 'Comprehensive reminder',
  'product.introduc.Cloud.visitor.advantage3.desc':
    'Integrate SMS, e-mail and other methods to make an appointment and remind visitors of their visit, so as not to miss important customers.',
  'product.introduc.Cloud.visitor.advantage4': 'Certificate identification',
  'product.introduc.Cloud.visitor.advantage4.desc':
    'Automatically identify identity documents, greatly improve grade efficiency and ensure information accuracy.',
  'product.introduc.Cloud.visitor.advantage5': 'Safety approval process',
  'product.introduc.Cloud.visitor.advantage5.desc':
    'Identify visitors and improve access security.',
  'product.introduc.Cloud.visitor.advantage6': 'Group invitation management',
  'product.introduc.Cloud.visitor.advantage6.desc':
    'Group invitation management automatically generates invitation links to meet the needs of meetings, activities and other scenarios with more people.',
  'product.introduc.Cloud.visitor.advantage7': 'Data analysis display',
  'product.introduc.Cloud.visitor.advantage7.desc':
    'Comprehensive perspective invitation, visit results, flexible search reports and help decision-making.',
  'product.introduc.Cloud.visitor.advantage8': 'Blacklist management',
  'product.introduc.Cloud.visitor.advantage8.desc':
    'Effectively restrict bad visits and escort the safety of enterprises.',
  'product.introduc.Cloud.meet.subTitle1': 'Multiple login methods',
  'product.introduc.Cloud.meet.subTitle1.desc': `The intelligent conference system with multiple login modes has the conference management capabilities of the whole life cycle, such as conference reservation, approval, notification, check-in, service, display board and report statistics. Through aiot software and hardware cooperation, it provides all-round intelligent conference room solutions such as human sensing, environmental perception, equipment linkage, voice interaction and human face recognition, It is also integrated with zoom / Cisco WebEx and other collaborative conference systems, covering the whole scene of online and offline meetings in an all-round way.`,
  'product.introduc.Cloud.meet.subTitle2': 'Multiple meeting reservation methods',
  'product.introduc.Cloud.meet.subTitle2.desc': `PC, app, and WeChat small program, enterprise WeChat, nail integration, through a variety of ways to facilitate your quick appointment meeting, whenever and wherever possible to grasp the state of the meeting.`,
  'product.introduc.Cloud.meet.subTitle3': 'Intelligent Perception',
  'product.introduc.Cloud.meet.subTitle3.desc': `Full range monitoring of various IOT intelligent hardware to realize intelligent meetings such as human body sensing, environment sensing and equipment linkage.`,
  'product.introduc.Cloud.meet.subTitle4': 'Multiple integrated extended applications',
  'product.introduc.Cloud.meet.subTitle4.desc': `Exchange / Office 365 integrated mode supports two-way synchronization and a variety of application scenarios, such as infrared sensor interaction, ultra long conference, periodic conference, conflict mode, meeting to be approved, asymmetric conference account, etc.`,
  'product.introduc.tabs1': 'Smart Visitor',
  'product.introduc.visitor.subTitle1': 'Systematization of visitor invitation',
  'product.introduc.visitor.subTitle1.desc': `Convenient invitation and reservation function, zero cost deployment of self-service invitation operation of enterprise staff or external visitors, and building an enterprise level cloud visitor management platform relying on the seamless connection of invitation requirements such as PC end, pad end and mobile end.`,
  'product.introduc.visitor.subTitle2': 'Identity authentication channelization',
  'product.introduc.visitor.subTitle2.desc': `With the concept of safe and open visitor identity authentication, visitors can independently confirm their identity information when they arrive at the front desk, and enterprise employees can receive visitor notification through various channels at the first time, so as to meet the visitor management needs of the enterprise to the greatest extent.`,
  'product.introduc.visitor.subTitle3': 'Data statistics informatization',
  'product.introduc.visitor.subTitle3.desc': `Stable cloud service background support to meet the needs of enterprise data statistics of visitor appointment, check-in / check-out, identity information and other related records. It has the advantages of multi field efficient search, easy operation of information statistics and large capacity of cloud storage to solve the data management problems in the enterprise visitor environment.`,
  'product.introduc.visitor.subTitle4': 'High timeliness of visitor reception',
  'product.introduc.visitor.subTitle4.desc': `The intelligent and efficient operation and maintenance system comprehensively runs through the visitor reception processes such as visitor card printing, QR code check-in and check-out, face photography and so on. With the configuration of modern equipment, the visitor reception is intelligent and the process is flexible and convenient, meeting a variety of scenarios such as enterprises, parks and buildings.`,
  'product.introduc.tabs2': 'Smart Meeting',
  'product.introduc.tabs3': 'Smart Workspace',
  'product.introduc.Cloud.visitor': 'Virsical Cloud Visitors',
  'product.introduc.Cloud.visitor.sub1':
    'Insight into modern visitor scenarios and focus on the future of intelligent enterprises',
  'product.introduc.Cloud.visitor.sub2':
    'New platform operation and maintenance planning and stable cloud service support',
  'product.introduc.Cloud.visitor.sub3': 'Efficient visitor reception process',
  'product.introduc.meet.visitor': 'Virsical Cloud Meeting',
  'product.introduc.meet.visitor.sub1': 'Full scene and full life cycle conference solution',
  'product.introduc.meet.visitor.sub2':
    'Bring intelligent, efficient, simple and easy service to enterprises',
  'product.introduc.meet.visitor.sub3': 'New conference room reservation management experience',
  'product.introduc.header.visitor.btn1': 'Watch Video',
  'product.introduc.header.visitor.btn2': 'Free Experience',
  'product.introduc.meet.visitor.advantage1': 'Multiple appointment methods',
  'product.introduc.meet.visitor.advantage1.desc':
    'Wechat applet, enterprise wechat, nailing, app, web browser, etc. make appointments and manage meetings anytime, anywhere.',
  'product.introduc.meet.visitor.advantage2': 'Conference Board',
  'product.introduc.meet.visitor.advantage2.desc':
    'The conference display board has optional hardware of various specifications and optional templates of various styles, and supports functions such as automatic on-off, remote monitoring, fault alarm and so on.',
  'product.introduc.meet.visitor.advantage3': 'Sign in and message notification',
  'product.introduc.meet.visitor.advantage3.desc':
    'SMS, email, wechat, nailing and other message notification methods are optional. After arriving at the venue, participants can sign in through account password, code scanning, face recognition and other methods on the conference display board.',
  'product.introduc.meet.visitor.advantage4': 'Conference Services',
  'product.introduc.meet.visitor.advantage4.desc':
    'The administrator defines the conference service package, the subscriber applies for conference services before and during the conference, and supports the billing and statistics of conference service fees and conference room usage fees.',
  'product.introduc.meet.visitor.advantage5': 'Collaboration meeting integration',
  'product.introduc.meet.visitor.advantage5.desc':
    'It supports reservation and management of online video conferences such as zoom and WebEx, mixed reservation with online and offline conference rooms, and interactive large screen through integrated zoom rooms.',
  'product.introduc.meet.visitor.advantage6': 'Exchange/Office365 integration',
  'product.introduc.meet.visitor.advantage6.desc':
    'Integrate with exchange or office 365 to realize two-way synchronization of conference data.',
  'product.introduc.meet.visitor.advantage7': 'Intelligent hardware linkage',
  'product.introduc.meet.visitor.advantage7.desc':
    'Through intelligent hardware, intelligent linkage such as human sensing, environment perception, device linkage, voice interaction and face recognition can be realized.',
  'product.introduc.meet.visitor.advantage8': 'Space state sensor linkage',
  'product.introduc.meet.visitor.advantage8.desc':
    'Real time monitoring data based on infrared intelligent sensor to realize automatic conference check-in and unmanned automatic release of conference room.',

  'product.introduc.fm.subTitle1': 'Informatization',
  'product.introduc.fm.subTitle1.desc':
    'Establish an information management platform to optimize management processes, improve work efficiency, and help reduce operating costs',
  'product.introduc.fm.subTitle2': 'Process',
  'product.introduc.fm.subTitle2.desc':
    'Fixed standardized business processes, enhance collaboration, realize unified system management, and enhance management transparency',
  'product.introduc.fm.subTitle3': 'Intelligentization',
  'product.introduc.fm.subTitle3.desc':
    'Intelligent hardware devices were integrated to form a unified control platform to realize intelligent control of the Internet of everything',
  'product.introduc.fm.subTitle4': 'Wisdom',
  'product.introduc.fm.subTitle4.desc':
    "Combined with Al artificial intelligence, digital twin and other technologies, the building is self-aware and the user's sensorless experience is enhanced",

  'product.introduc.fm.advantage1': 'Project Management',
  'product.introduc.fm.advantage1.desc':
    'The project system and site system of the enterprise headquarters operate independently to improve the project operation efficiency and enhance the unified management of the group',
  'product.introduc.fm.advantage2': 'Equipment Assets',
  'product.introduc.fm.advantage2.desc':
    'Unified device asset management platform to monitor and manage device operation and effectively prolong device operation cycle',
  'product.introduc.fm.advantage3': 'Service Center',
  'product.introduc.fm.advantage3.desc':
    'Unified entrance management of building user service requests improves building operation and maintenance management ability',
  'product.introduc.fm.advantage4': 'Work-order Management',
  'product.introduc.fm.advantage4.desc':
    'A complete workflow engine self-configure applications, and the flexible configuration engine perfectly matches application scenarios',
  'product.introduc.fm.advantage5': 'Inspection Management',
  'product.introduc.fm.advantage5.desc':
    'Mobile terminal digital online inspection, real-time progress of inspection management online management, digital control',
  'product.introduc.fm.advantage6': 'Preventive Maintenance Management',
  'product.introduc.fm.advantage6.desc':
    'The unified management platform of daily maintenance plan realizes offline maintenance work online, and implements visual application and management of maintenance tasks',
  'product.introduc.fm.advantage7': 'Knowledge Base Management',
  'product.introduc.fm.advantage7.desc':
    'Define faults for different types of devices, and establish fault causes and troubleshooting methods based on data accumulation',
  'product.introduc.fm.advantage8': 'On-the-job Management',
  'product.introduc.fm.advantage8.desc':
    'Using WiFi, GPS positioning, Bluetooth and other technologies for online check-in, and real-time synchronization of staff on the job',

  'producr.introduc.fm.equipment.ledger': 'Equipment Ledger',
  'producr.introduc.fm.inventory.management': 'Inventory Management',
  'producr.introduc.fm.contract.management': 'Contract Management',
  'producr.introduc.fm.supplier.management': 'Supplier Management',

  'product.introduc.esg.tip1': 'Energy carbon environmental management system',
  'product.introduc.esg.tip2':
    'Extensive and fast automatic collection, precise analysis and intelligent optimization to promote enterprise digital transformation, promote enterprise ESG goals, and respond to the national dual carbon strategy',
  'product.introduc.esg.subTitle1': 'Automatic collection of comprehensive energy and environment',
  'product.introduc.esg.subTitle1.desc': `It can realize rapid access to comprehensive energy data such as water, electricity, gas, heating, environment, photovoltaic, energy storage and charging pile of buildings in the park, monitor real-time energy and carbon emission data, use wireless passive devices for power collection, avoid power outage, have zero impact and zero loss on normal operation, and implement Well standards for environmental monitoring. Monitor temperature and humidity, PM2.5, PM10, TVOC, carbon dioxide and other data, and equipped with relevant standard interpretation.`,
  'product.introduc.esg.subTitle2': 'The north-south system devices are widely accessible',
  'product.introduc.esg.subTitle2.desc': `Platform API, using cloud docking, edge cloud docking; Edge API, three-party hardware access, three-party service access, edge data analysis, three-party integration document, data push API, Kafka, Rabbitmq, Mqtt, Azure Servicebus, AWS SQS, PUBSUB.`,
  'product.introduc.esg.subTitle3': 'Intelligent control, closed-loop process',
  'product.introduc.esg.subTitle3.desc': `According to the scene data analysis and diagnosis, system analysis and precise regulation of buildings in the park, a closed loop of intelligent supervision of buildings is formed. The traditional single-device control operation is replaced by the scene control strategy, and intelligent control is effectively realized through dynamic and static sensing devices, sunlight and other sensing devices. Through conference system integration, time timing management, remote control management, BMS system integration, central air conditioning energy saving control, intelligent lighting control, environmental intelligent management, remote/timing/planning control, etc., improve the level of energy regulation.`,
  'product.introduc.esg.subTitle4': 'Multi-dimensional visual data presentation',
  'product.introduc.esg.subTitle4.desc': `GIS, BIM, CIM, 3D/2D integrated display of the park building data, energy, carbon, environmental real-time data, historical data, year-on-year, month-on-month, etc., at the same time with large screen, message, PC/APP multi-terminal display and interactive functions, the operation of the health parameters at a glance.`,
  'product.introduc.Cloud.esg.advantage1': 'Support enterprise ESG strategy',
  'product.introduc.Cloud.esg.advantage1.desc':
    'Help companies manage energy use and consumption, identify and address energy waste, better respond to the concerns of investors and other stakeholders, reduce negative impacts on the environment, and earn a better social reputation for the company',
  'product.introduc.Cloud.esg.advantage2': 'Facilitating digital transformation',
  'product.introduc.Cloud.esg.advantage2.desc':
    'The use of the Internet of Things, cloud computing, big data, AI, 5G and other information technologies to achieve the comprehensive energy, carbon emission and environment of the park to achieve the full life cycle dynamic monitoring, social responsibility and enterprise development, energy conservation and carbon reduction and production and efficiency of the double balance',
  'product.introduc.Cloud.esg.advantage3': 'Insight analysis optimization',
  'product.introduc.Cloud.esg.advantage3.desc':
    'Through the supervision of energy consumption data, carbon emission conversion, and energy consumption prediction and analysis of buildings in the park, the managers can comprehensively and timely grasp the energy consumption situation, timely regulate energy conservation, improve energy efficiency management, and finally achieve the purpose of reducing energy consumption, reducing cost and increasing efficiency',
  'product.introduc.Cloud.esg.advantage4': 'High efficiency panoramic vision',
  'product.introduc.Cloud.esg.advantage4.desc':
    "The construction of the environmental carbon control platform, based on GIS, BIM, CIM, 3D/2D and other visual technologies, provides a panoramic view of the details of the enterprise's energy carbon environment operation, and deeply practices the concept of green development and low-carbon development in the process of enterprise development",

  'product.introduc.bdp.tip1': 'Virsical Meta View',
  'product.introduc.bdp.tip2':
    'Perfect integration of enterprise administration and intelligent big data, through information release, interactive large screen and data insight, to achieve visual display of space and intelligent information release. Help enterprises optimize space experience, improve resource utilization efficiency, and support enterprises to make scientific decisions',
  'product.introduc.bdp.subTitle1': 'CEO cockpit',
  'product.introduc.bdp.subTitle1.desc':
    'Tailor-made for the senior management team, integrate enterprise digital administration and big data connected with smart things to provide deep data cleaning and optimization. Comprehensive display of financial, operational, customer, environmental and other performance indicators as well as health care data analysis, through the global data overview and trend prediction, to provide solid support for corporate decision-making.',
  'product.introduc.bdp.subTitle2': 'Report management and authorization',
  'product.introduc.bdp.subTitle2.desc':
    'Provide a series of professional value reports, users can select and adapt enterprise data according to actual needs, and quickly obtain in-depth insights. It also supports quick import of Power BI reports to implement flexible classification management and role permission control.',
  'product.introduc.bdp.subTitle3': 'Integrated information release system',
  'product.introduc.bdp.subTitle3.desc':
    'Support video, pictures, PDF and other diversified media forms, the system real-time push report data to the large screen display, simple configuration can achieve multi-screen content co-existence and automatic switching. Deep integration with Virsical products to provide refined rights management to meet the needs of complex scenarios.',
  'product.introduc.bdp.subTitle4': 'Interactive space resource screen',
  'product.introduc.bdp.subTitle4.desc':
    'Visually display the environmental index and resource status of the space area, provide interactive operations, such as resource screening, reservation occupation, map path guidance, etc., significantly improve office experience and efficiency. Seamless connection with Virsical Meta FM system to achieve efficient tracking and maintenance of space equipment resources.',
  'product.introduc.bdp.subTitle5': 'Interactive large screen',
  'product.introduc.bdp.subTitle5.desc':
    'Support 2D/3D map display, including resource overview and environment IAQ indicators, provide visual interaction, including resource scanning reservation, repair, route guidance.',
  'product.introduc.bdp.subTitle6': 'Information publishing engine',
  'product.introduc.bdp.subTitle6.desc':
    'Support common media formats, online programming, support audit, support interstitials; Android messaging device, support offline playback, support multi-screen playback; Support for major browsers.',
  'product.introduc.bdp.advantage1': 'Information publishing engine',
  'product.introduc.bdp.advantage1.desc':
    'Play device management, play media management, play content arrangement, play process control, offline device playback, resource group division',
  'product.introduc.bdp.advantage2': 'Multi-screen fusion technology',
  'product.introduc.bdp.advantage2.desc':
    'Split display of playback content, on-demand configuration of each screen content, multi-screen no delay switching, free configuration of switching mode',
  'product.introduc.bdp.advantage3': 'Interactive large screen function',
  'product.introduc.bdp.advantage3.desc':
    'Map visual display, resource status overview, map path guidance, resource quick retrieval, scanning code reservation use',
  'product.introduc.bdp.advantage4': 'Equipment resource display',
  'product.introduc.bdp.advantage4.desc':
    'Equipment classification and screening, IOT data collection, IAQ environment indicators, equipment resource scan code repair, FM system docking',
  'product.introduc.bdp.advantage5': 'Statistical analysis of cost',
  'product.introduc.bdp.advantage5.desc':
    'Overall cost composition, cost trend change, multidimensional settlement analysis, multidimensional TOPN analysis',
  'product.introduc.bdp.advantage6': 'Optimize the utilization of space resources',
  'product.introduc.bdp.advantage6.desc':
    'Comprehensive resource utilization, utilization trend change, utilization segment distribution, utilization ranking, utilization region comparison',
  'product.introduc.bdp.advantage7': 'Comprehensive impact assessment',
  'product.introduc.bdp.advantage7.desc':
    'Environment and resource use, behavior and resource use, Resource use and energy consumption',
  'product.introduc.bdp.advantage8': 'Digital visual display',
  'product.introduc.bdp.advantage8.desc':
    'Energy consumption exhibition, green and low-carbon exhibition, digital asset exhibition, WELL environment exhibition',

  'product.introduc.map.tip1': 'Virsical Workspace Drawing Tool',
  'product.introduc.map.tip2':
    'Free production of professional enterprise maps, office space management, office layout, workstation allocation',
  'product.introduc.map.advantage1': 'Completely Free',
  'product.introduc.map.advantage1.desc': 'Enjoy professional mapping services at no cost.',
  'product.introduc.map.advantage2': 'HD Export',
  'product.introduc.map.advantage2.desc':
    'Export to PDF and picture formats to meet different display requirements.',
  'product.introduc.map.advantage3': 'Easy to use',
  'product.introduc.map.advantage3.desc':
    'Simplify the operation process so that everyone can become a cartographic expert.',
  'product.introduc.map.advantage4': 'Powerful Function',
  'product.introduc.map.advantage4.desc':
    'Subsequently, the space management system can be linked to realize the reservation management and occupation monitoring of space resources such as conference rooms, stations, lockers, etc.',
  'product.introduc.map.tools.tip1': 'Make office position map, accurate layout!',
  'product.introduc.map.tools.tip2':
    "We only need to upload the decoration plan of the company to draw the space station map. How to use it specifically, let's have a look!",
  'product.introduc.map.tools.step1': '1、Create your own corporate office hierarchy',
  'product.introduc.map.tools.step1.desc':
    'You can draw corresponding maps for different office areas in different office buildings of the enterprise, and the office area hierarchy is clear.',
  'product.introduc.map.tools.step2': '2、Upload the corresponding office area graphic design',
  'product.introduc.map.tools.step2.desc':
    'Upload the corresponding space decoration plan for each office space, and you can draw a space map according to the plan.',
  'product.introduc.map.tools.step3': '3、Map your office space',
  'product.introduc.map.tools.step3.desc':
    'Work stations, meeting rooms and other materials can be placed according to the plane map, and irregular areas such as leisure areas can be drawn by custom brushes to meet all your space types.',
  'product.introduc.map.tools.step4': '4、Derived spatial map',
  'product.introduc.map.tools.step4.desc':
    'After drawing the map, you can export the enterprise space map pdf or png file, which is convenient for you to use offline.',
  'index.map': 'Workspace Drawing Tool',
};
