/* eslint-disable no-param-reassign */
/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import request from 'umi-request';
import { getSign } from 'virsical-sign';
import { formatMessage } from 'umi';
import { message } from 'antd';
import {
  RESTFUL_SIGNATURE,
  RESTFUL_PATH,
  SING_KEY,
  ACCESS_TOKEN,
  DEFAULT_INTERFACE,
} from './constant';

request.interceptors.response.use((response) => {
  // console.log(response,'ssssssdddddd')
  // eslint-disable-next-line no-underscore-dangle
  return new Response(response.body || response._bodyBlob, {
    status: 200,
    statusText: 'ok',
    headers: response.headers,
  });
});

export const path = {};
Object.keys(RESTFUL_PATH).forEach((key) => {
  path[`/${RESTFUL_PATH[key]}`] = key;
});

export const questMethodAddToUrl = (url, option = {}) => {
  let newUrl = url;
  const newOption = option;
  if (Object?.prototype?.hasOwnProperty.call(option, 'method')) {
    const { method } = option;
    if (method.trim().toLowerCase() === 'put') {
      newUrl = `${url}/update`;
      newOption.method = 'POST';
    }
    if (method.trim().toLowerCase() === 'delete') {
      const index = url.lastIndexOf('/');
      newUrl = `${url.substring(0, index)}/delete${url.substring(index)}`;
      newOption.method = 'POST';
    }
    if (method.trim().toLowerCase() === 'get') {
      newOption.method = 'GET';
    }
    if (method.trim().toLowerCase() === 'post') {
      newOption.method = 'POST';
    }
  }
  return {
    newUrl,
    newOption,
  };
};

export default (url, options = { headers: {} }) => {
  if (options.method) {
    // eslint-disable-next-line no-param-reassign
    if (!options.data || !(options.data instanceof FormData)) {
      // eslint-disable-next-line no-param-reassign
      options.headers = {
        'Content-Type': 'application/json',
        ...options.headers,
      };
    }
  }
  // eslint-disable-next-line no-prototype-builtins
  if (!options?.headers?.hasOwnProperty('Authorization'))
    // eslint-disable-next-line no-param-reassign
    options.headers.Authorization = sessionStorage.getItem('access_token') || '';
  // options.headers.Authorization = ''
  // eslint-disable-next-line no-param-reassign
  options.headers.token = sessionStorage.getItem('token') || '';
  // options.headers.token = ''

  if (url.indexOf('/products/version/') > -1) {
    // eslint-disable-next-line no-param-reassign
    delete options.headers.token;
    // eslint-disable-next-line no-param-reassign
    delete options.headers.Authorization;
  }

  /* copy allinone 签名代码start */
  const { headers } = options;
  /* if (headers) {
    if (!('Content-Type' in headers)) {
      headers['Content-Type'] = 'application/json';
    }
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
  } */
  if (sessionStorage.getItem(ACCESS_TOKEN)) {
    headers.Authorization = `${sessionStorage.getItem(ACCESS_TOKEN)}`;
    headers['vsk-auth'] = sessionStorage.getItem(ACCESS_TOKEN);
  }
  const { newUrl, newOption } = questMethodAddToUrl(url, options);
  if (RESTFUL_SIGNATURE) {
    // 开始签名
    //  取第二个 '/' 前的字符
    let restfulPath = newUrl.substring(0, newUrl.indexOf('/', 1));
    if (DEFAULT_INTERFACE && DEFAULT_INTERFACE.includes('/')) {
      restfulPath = newUrl.substring(0, newUrl.indexOf('/', newUrl.indexOf('/', 1) + 1));
    }
    let signPath = newUrl;
    // 判断url中如果包含restful前缀，需要清除，签名用
    if (Object?.prototype?.hasOwnProperty.call(path, restfulPath)) {
      signPath = newUrl.replace(restfulPath, '');
    }

    const tokenSign =
      sessionStorage.getItem(ACCESS_TOKEN)?.replace('bearer', '').trim() || window.access_token;
    // 生成签名用的时间戳
    const timestamp = new Date().getTime();
    // post 请求需要签名 body or data
    if (
      newOption &&
      newOption.method &&
      newOption.method === 'POST' &&
      (newOption.body || newOption.data)
    ) {
      if (newOption.body) {
        headers['vsk-signature'] = getSign(
          signPath,
          tokenSign,
          SING_KEY,
          timestamp,
          newOption.body,
        );
      } else if (newOption.data) {
        headers['vsk-signature'] = getSign(
          signPath,
          tokenSign,
          SING_KEY,
          timestamp,
          newOption.data,
        );
      }
    } else {
      headers['vsk-signature'] = getSign(signPath, tokenSign, SING_KEY, timestamp);
    }
    // headers.test = 'test';
    headers['vsk-timestamp'] = timestamp;
  }

  // eslint-disable-next-line no-param-reassign
  options.headers = headers;
  /* copy allinone 签名代码end */

  return request(url, options)
    .then((data) => {
      if (data?.code !== 0 && !Number.isNaN(data.msg)) {
        if (data.msg === '101048') {
          data.msg = formatMessage({ id: data?.msg }, { lock: data?.data?.lockTime });
        } else {
          data.msg = data.msg ? data.msg : '';
        }
      }
      return data;
    })
    .catch(async (err) => {
      if (typeof err === 'string') {
        message.error(err);
      }
    });
};
