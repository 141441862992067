import React from 'react';
import { formatMessage } from 'umi';
import { Table } from 'antd';
import type { TableProps } from 'antd/es/table';

type Props<RecordType = any> = Omit<TableProps<RecordType>, 'columns'> & {
  columns?: any[];
  filter?: any;
  rowKey?: any;
  onChange?: any;
};

const OmsTable = (props: Props) => {
  const {
    pagination,
    rowKey,
    dataSource,
    columns,
    scroll,
    rowClassName,
    filter,
    onChange,
    ...restProps
  } = props;

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      scroll={scroll}
      onChange={onChange}
      pagination={
        pagination === false
          ? false
          : {
              ...pagination,
              current: filter?.page || filter?.current,
              total: filter?.total,
              showTotal: (total) =>
                `${formatMessage({ id: 'help.table.tip1' })} ${dataSource?.length} ${formatMessage({ id: 'help.table.tip2' })} ${total} ${formatMessage({ id: 'help.table.tip3' })}`,
              showSizeChanger: false,
            }
      }
      {...restProps}
    />
  );
};

export default OmsTable;
