/**
 * Created : vincent
 * Date : 5/18/22 4:19 PM
 * Email : wangxiao@wafersystems.com
 */

import { io } from "socket.io-client";

let _intl = null;
let _locale = null;

/**
 * 设置国际化方法
 * @param {*} intl
 */
export function setIntl(intl) {
  _intl = intl;
}

/**
 * 设置国际化语言
 * @param {*} locale
 */
export function setLocale(locale) {
  _locale = locale;
}

/**
 * 初始化国际化资源中文和key对照关系Map
 *
 * @returns {Map} 反馈国际化资源和id对应Map
 */
export function initIntlMap() {
  const { messages } = _intl("zh-CN");
  Object.keys(messages).forEach((id) => {
    const value = messages[id];
    intlMap.set(value, id);
  });
  lang = _locale();
  return intlMap;
}

// 判断是否开发环境
const isDev = process.env.NODE_ENV === "development";

// 中国国际化资源，key 为中文内容，value为对应的id
let intlMap = new Map();
let keyMap = new Map(); // 暂时用于帮助intl socket进行排除，等待node使用es模式读取全文件后删

// 语言
let lang = null;

/**
 * 设置国际化资源中文和key对照关系Map
 *
 * @param {Map} valuesMap - The map with keys and values to set as the new intlMap.
 */
export function setIntlMap(valuesMap) {
  intlMap = valuesMap;
  for (let [key, value] of valuesMap.entries()) {
    keyMap.set(value, key);
  }
}

// 国际化 socket 链接
let intlSocket;

/**
 * 初始化国际化 socket 链接
 *
 * @param {number} [port=8999] - socket 端口
 * @returns {void}
 */
export function devIntlSocket(port = 8999) {
  if (!isDev) return;

  const socket = io(`http://localhost:${port}`);
  intlSocket = socket;
  intlSocket.on("connect", () => {
    console.log("connect", socket.id); // x8WIv7-mJelg7on_ALbx
  });

  intlSocket.on("disconnect", (reason) => {
    console.log("disconnect", reason); // undefined
  });
  intlSocket.on("connect_error", () => {
    // 连接失败不重试
    intlSocket.close();
  });
}

// 字符串首字母大写
function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}

/**
 * @param {string} zhMessage - 中文资源
 * @param {Object=} [values=null] - 国际化参数
 * @param {boolean=} [upper=false] - 是否大写
 * @returns {string} International Resources
 */
export function zhIntl(zhMessage, values = null, upper = false) {
  if (lang === null) {
    lang = _locale();
  }
  // 当前是中文环境，并没有传递其他参数，则可以直接显示, dev环境不处理，用于展示国际化未配置提示
  if (!isDev && lang === "zh-CN" && !values && typeof zhMessage === "string") {
    return zhMessage;
  }
  const zhMsg = typeof zhMessage === "string" ? zhMessage : zhMessage.id;
  // 获取中文所有语言包
  const findID = intlMap.get(zhMessage);
  // console.log('findID', getIntl);
  const text = _intl(lang).formatMessage({ id: findID || zhMsg }, values);
  // 未找对对应ID，给与提示
  // console.log(zhMessage, keyMap.get(zhMessage), text, intlMap.get(text));
  // 兼容中文相同，ID不同，并被覆盖ID情况
  // console.log(intlSocket);
  if (zhMessage && !findID && !intlMap.get(text)) {
    // console.warn(`${zhMsg} not found id`);
    if (intlSocket?.connected && !keyMap.get(zhMessage)) {
      intlSocket?.emit?.("intl", zhMessage);
    }
  }
  if (upper) {
    return capitalizeWords(text);
  }
  return text;
}

/**
 * @param {string} key - 国际化资源
 * @param {Object=} [obj=null]  - 国际化参数
 * @param {boolean=} [upper = false] - 是否大写
 * @returns {string} International Resources
 */
function formatMessage(key, obj = null, upper = false) {
  if (lang === null) {
    lang = _locale();
  }
  let text = "";
  if (obj) {
    text = _intl(lang).formatMessage(key, obj);
  } else {
    text = _intl(lang).formatMessage(key);
  }
  if (upper) {
    return capitalizeWords(text);
  }
  return text;
}

export default formatMessage;
