/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
// 高阶组件需要用withRouter包裹一下
// import { withRouter } from 'umi';
// 引入动画库的俩个组件
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Header from '../components/Header/index';
import Footer from '../components/Footer/index';
import styles from './index.less';
import zixunCn from '@/assets/but_zaixianzixun.png';
import zixunEn from '@/assets/but_zaixianzixun_en.png';
import { ROUTE_MAP } from '@/utils/constant';
import { getLocale } from 'umi';

// 根据前进还是后退显示不同的转场动画效果
const ANIMATION_MAP = {
  PUSH: 'forward',
  POP: 'back',
};

const noHeader = (pathname) => {
  const signPages = [ROUTE_MAP.login, ROUTE_MAP.register, ROUTE_MAP.passwd, ROUTE_MAP.smsquery, ROUTE_MAP.index, ROUTE_MAP.findpwd, ROUTE_MAP.active, ROUTE_MAP.editpwd];
  for (const v of signPages) {
    if (pathname === v || pathname === `${v}/`) {
      return true;
    }
  }
  return false;
};

function BasicLayout(props) {
  const headListener = () => {
    const header = document.getElementById('head');
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (header) {
      if (scrollTop === 0 ) {
        header.style.top = '0px';
        header.style.boxShadow = 'none'
      }
      if (scrollTop > 0) {
        header.style.top = `-${header.offsetHeight}px`;
      } 
      if (scrollTop > 300 ) {
        header.style.top = '0px';
        header.style.boxShadow = 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
      }
    }
  }

  useEffect(() => {
    // setHeader(document.getElementById('head'));
    document.addEventListener('scroll', () => headListener());

    setTimeout(() => {
      if (window) {
        if (/Mac/i.test(navigator.platform) || /IOS/i.test(navigator.platform)) {
          document.querySelector('body').style.letterSpacing = '0';
        }
      }
      if (document) {
        setTimeout(() => {
          const a = document.querySelector('#udesk_btn > a');
          if (a) {
            a.querySelector('img').parentNode.removeChild(a.querySelector('img'));
          } else {
            return;
          }
          const currentPath = window.location.href;
          if (!currentPath.includes('/web/register') && !currentPath.includes('/web/bind')) {
            const img1 = document.createElement('img');
            // const img2 = document.createElement('img');
            img1.src = getLocale() === 'en-US' ? zixunEn : zixunCn;
            // img2.src = png2;
            // debugger
            // a.style.marginTop = '100px';
            a.appendChild(img1);
            // a.appendChild(img2);
          }
        }, 900);
      }
    }, 0);

    return document.removeEventListener('scroll', headListener);
  }, []);

  if (noHeader(props.location.pathname)) {
    return <>{props.children}</>;
  }
  const { location, history } = props;
  return (
    // 这个组件的详细应用 见官网 这里就直接写现成的了

    <div className={styles.body}>
      <div id="head" className={styles.header}>
        <Header />
      </div>
      <TransitionGroup
        childFactory={(child) =>
          React.cloneElement(child, { classNames: ANIMATION_MAP[history.action] })
        }
      >
        <CSSTransition key={location.pathname} timeout={300}>
          <div style={{ minHeight: '75vh', paddingTop: 80 }}>
            {props.children}
          </div>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div>
  );
}

export default BasicLayout;
