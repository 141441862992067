/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Menu, Divider } from 'antd';
import { useMount } from 'ahooks';
import { history, connect, formatMessage, getLocale, setLocale } from 'umi';
import styles from './index.less';
import bg from '@/assets/home/header.png';
import logoCn from '@/assets/logo/logoCn.png';
import logoEn from '@/assets/logo/logoEn.png';
import locale from '@/assets/locale.svg';
import localeh from '@/assets/localeh.svg';
import { ROUTE_MAP } from '../../utils/constant';
import { DownOutlined } from '@ant-design/icons';
import { zhIntl } from '@/utils/intlUtils';
import { getPageQuery } from '@/utils/utils';

const menus = [
  { label: formatMessage({ id: 'common.index' }), key: ROUTE_MAP.index },
  {
    label: (
      <>
        <span style={{ marginRight: 4 }}>{formatMessage({ id: 'common.product' })}</span>
        <DownOutlined style={{ position: 'relative', top: 1 }} />
      </>
    ),
    key: ROUTE_MAP.product,
    children: [
      {
        type: 'group',
        label: formatMessage({ id: 'common.product.desc' }),
        key: ROUTE_MAP.product,
        children: [
          {
            label: formatMessage({ id: 'common.visitor' }),
            key: `${ROUTE_MAP.product}?pdtype=vst`,
          },
          {
            label: formatMessage({ id: 'common.meeting' }),
            key: `${ROUTE_MAP.product}?pdtype=smt`,
          },
          {
            label: formatMessage({ id: 'common.space' }),
            key: `${ROUTE_MAP.product}?pdtype=sws`,
          },
          {
            label: formatMessage({ id: 'index.fm' }),
            key: `${ROUTE_MAP.product}?pdtype=fm`,
          },
          {
            label: formatMessage({ id: 'index.esg' }),
            key: `${ROUTE_MAP.product}?pdtype=esg`,
          },
          {
            label: formatMessage({ id: 'index.bdp' }),
            key: `${ROUTE_MAP.product}?pdtype=bdp`,
          },
        ],
      },
      {
        label: formatMessage({ id: 'common.product.price' }),
        key: ROUTE_MAP.price,
      },
      {
        type: 'group',
        label: zhIntl('工具及服务'),
        key: ROUTE_MAP.price,
        children: [
          {
            label: zhIntl('空间管理制图工具'),
            key: `${ROUTE_MAP.product}?pdtype=map`,
          },
        ],
      },
      {
        label: formatMessage({ id: 'common.product.download' }),
        key: ROUTE_MAP.download,
      },
    ],
  },
  { label: formatMessage({ id: 'common.case' }), key: ROUTE_MAP.case },
  {
    label: (
      <>
        <span style={{ marginRight: 4 }}>{formatMessage({ id: 'common.service' })}</span>
        <DownOutlined style={{ position: 'relative', top: 1 }} />
      </>
    ),
    key: 'service',
    children: [
      { label: formatMessage({ id: 'common.sms' }), key: ROUTE_MAP.smsquery },
      { label: formatMessage({ id: 'common.help' }), key: ROUTE_MAP.help },
    ],
  },
  { label: formatMessage({ id: 'common.about' }), key: ROUTE_MAP.about },
];

const Page = (props) => {
  const chinaUrl = sessionStorage.getItem('china');
  const intlUrl = sessionStorage.getItem('intl');
  const [active, setActive] = useState(false);

  const listener = (e) => {
    const lang = document.getElementById('lang');
    if (e.target !== lang && !lang?.contains(e.target)) {
      setActive(false);
    }
  };

  useMount(() => {
    const { dispatch } = props;
    dispatch({ type: 'cloud/getCommonInitData' });
    dispatch({ type: 'cloud/getSet' });
    if (getPageQuery()?.lang) {
      setLocale(getPageQuery()?.lang);
    }
    if (getPageQuery()?.version) {
      if (getPageQuery()?.version === 'china') sessionStorage.setItem('isChina', 'true');
      if (getPageQuery()?.version === 'intl') sessionStorage.setItem('isChina', 'false');
    }
  });

  useEffect(() => {
    return document.removeEventListener('click', listener);
  }, []);

  const menuList = (
    <Menu>
      <div className={styles.station}>
        {chinaUrl ? (
          <>
            <div className={styles.title}>
              <div>{zhIntl('中国站')}</div>
              <Divider type="vertical" style={{ background: '#000', marginTop: 5 }} />
              <div style={{ fontSize: 14 }}>{chinaUrl}</div>
            </div>
            <div className={styles.lang}>
              <div
                style={{ marginRight: 50 }}
                className={styles.langItem}
                onClick={() => {
                  window.location.replace(`https://${chinaUrl}?lang=zh-CN&version=china`);
                  // sessionStorage.setItem('isChina', 'true');
                }}
              >
                简体中文
              </div>
              <div
                className={styles.langItem}
                onClick={() => {
                  window.location.replace(`https://${chinaUrl}?lang=en-US&version=china`);
                  // sessionStorage.setItem('isChina', 'true');
                }}
              >
                English
              </div>
            </div>
          </>
        ) : null}
        {intlUrl ? (
          <>
            <div className={styles.title} style={{ marginTop: chinaUrl ? 20 : 0 }}>
              <div>{zhIntl('国际站')}</div>
              <Divider type="vertical" style={{ background: '#000', marginTop: 5 }} />
              <div style={{ fontSize: 14 }}>{intlUrl}</div>
            </div>
            <div className={styles.lang}>
              <div
                className={styles.langItem}
                style={{ marginRight: 50 }}
                onClick={() => {
                  window.location.replace(`https://${intlUrl}?lang=zh-CN&version=intl`);
                  // sessionStorage.setItem('isChina', 'false');
                }}
              >
                简体中文
              </div>
              <div
                className={styles.langItem}
                onClick={() => {
                  window.location.replace(`https://${intlUrl}?lang=en-US&version=intl`);
                  // sessionStorage.setItem('isChina', 'false');
                }}
              >
                English
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Menu>
  );

  document.addEventListener('click', (e) => listener(e), false);

  return (
    <div
      className={styles.header}
      style={{
        backgroundImage: props.type === 'home' ? `url(${bg})` : '#fff',
        backgroundSize: '100% 100%',
      }}
    >
      <div className={styles.content}>
        <img
          src={getLocale() === 'en-US' ? logoEn : logoCn}
          height={42}
          alt=""
          onClick={() => history.push('/')}
        />
        <div style={{ flex: 'auto', display: 'flex', justifyContent: 'center' }}>
          <Menu
            mode="horizontal"
            items={menus}
            onClick={(item) => history.push(item.keyPath?.[0])}
          />
        </div>
        <div className={styles.right}>
          <Dropdown overlay={menuList} trigger="click" overlayClassName={styles.dropdownHeader}>
            <img
              id="lang"
              src={active ? localeh : locale}
              style={{ color: '#000' }}
              alt=""
              onClick={() => setActive(true)}
            />
          </Dropdown>
          <Button type="primary" className={styles.btnStyle} onClick={() => history.push('/login')}>
            {formatMessage({ id: 'common.login' })}
          </Button>
          <Button
            className={styles.btnStyle}
            style={{ marginLeft: 10, background: 'transparent' }}
            onClick={() => history.push('/register')}
          >
            {zhIntl('注册')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state)(Page);
