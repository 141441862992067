export default {
  'common.index': '首頁',
  'common.product': '產品',
  'common.product.hot': '熱門產品',
  'common.hardware': '硬件',
  'common.case': '客戶案例',
  'common.service': '服務中心',
  'common.sms': '短信自助查詢',
  'common.help': '使用幫助',
  'common.about': '關於我們',
  'common.product.desc': '產品介紹',
  'common.product.price': '產品定價',
  'common.product.download': '產品下載',
  'common.visitor': '智能訪客',
  'common.meeting': '智能會議',
  'common.space': '空間管理',
  'common.hardware.equipment': '硬件設備',
  'common.visitor.robot': '訪客機器人',
  'common.login': '登錄',
  'common.free.use': '免費試用',
  'common.link.often': '常用鏈接',
  'common.service.center': '服務中心',
  'common.company.profile': '企業簡介',
  'common.app.download': '應用下載',
  'common.android': 'Android',
  'common.ios': 'IOS',
  'common.ipad.visitor': '訪客IPad',
  'common.contactus': '聯系我們',
  'common.customer.hotline': '客服熱線',
  'common.video.watch': '觀看視頻',
  'common.free.experience': '免費體驗',

  'common.banner.tip1': '使用全球廣受好評的威思客雲平臺',
  'common.banner.tip2': '讓辦公變得更智慧、更智能，用更少的時間獲得更高的效率',
  'common.banner.tip3': '洞見現代訪客場景，聚焦未來智能訪客',
  'common.banner.tip4': '全新的業務流程，穩定的技術能力、高效的訪客接待',
  'common.banner.tip5': '選擇合適的版本，開啟高效辦公協作',
  'common.banner.tip6':
    '從個人到企業/組織，滿足不同規模公司各階段需求，為你和你的夥伴創造更多價值！',

  'common.banner.meeting': '威思客雲會議',
  'common.banner.meeting.tip1': '會議服務全生命周期管理的壹體化解決方案',
  'common.banner.meeting.tip2': '全場景、全生命周期的會議解決方案',
  'common.banner.meeting.tip3': '為企業帶來智能、高效、簡單、輕松的',
  'common.banner.meeting.tip4': '新型會議室預訂管理體驗',

  'common.banner.vistor': '威思客雲訪客',
  'common.banner.vistor.tip1': '洞見現代訪客場景，聚焦智能企業未來',
  'common.banner.vistor.tip2': '全新的平臺運維規劃，穩定的雲服務支持力',
  'common.banner.vistor.tip3': '高效的訪客接待流程',

  'common.banner.fm': '智慧化設施管理平台',
  'common.banner.fm.tip1': '強大的設備集成對接能力和數據應用管理能力',
  'common.banner.fm.tip2': '通過數據中通和IOT平台',
  'common.banner.fm.tip3': '實現對企業設備運行狀態的可視化管理',
  'common.banner.fm.tip4': '形成設備狀態檢測、異常報警、自定義維護的閉環管理流程',
};
