export default {
  'about.Where.we.work': '我們的工作地點',
  'about.us': '關於我們',
  'about.us.btn': '立即體驗',
  'about.us.subt':
    '威發系統成立於1988年，隸屬於威訊控股有限公司（港股代碼：01087)。擁有30年網絡系統集成及20餘年獨立軟件開發經驗。',
  'about.us.subt2':
    '威發系統立足中國，面向亞太及全球，在香港、北京，上海，廣州，成都、蘇州、深圳等地設有分公司和辦事處，在西安設有軟件研發中心。威發系統始終專註於互聯網IT基礎建設、雲服務及網絡安全、可視化空間管理及智能辦公、移動辦公等領域，是業界領先的新一代系統集成及智能辦公應用服務提供商。',
  'about.Guangzhou.name': '廣州',
  'about.Suzhou.name': '蘇州',
  'about.Beijing.name': '北京',
  'about.Shanghai.name': '上海',
  'about.Shanghai.address': '上海市長寧區天山路1717號SOHO天山廣場T2-1502室',
  'about.Guangzhou.address': '廣州市番禺區南村鎮萬博二路79號萬達廣場B1棟8屋805-806室',
  'about.Suzhou.address': '蘇州市工業園區金雞湖大道1355號國際科技園4期A0302',
  'about.Beijing.address': '北京市東城區東長安街1號東方廣場東二辦公樓19層1907-1909室',
  'about.Suzhou.tel': '電話：(86512)69172780',
  'about.Guangzhou.tel': '電話：(8620) 38105888',
  'about.Beijing.tel': '電話：(8610) 85184988',
  'about.Guangzhou.fax': '傳真：(8620) 38478669',
  'about.Beijing.fax': '傳真：(8610) 85188340',
  'about.Suzhou.fax': '傳真：(86512)69172789',
  'about.Suzhou.zipCode': '郵編：215021',
  'about.Beijing.zipCode': '郵編：100738',
  'about.Shanghai.tel': '電話：(8621) 32093700',
  'about.Shanghai.fax': '傳真：(8621) 32093877',
  'about.Shanghai.zipCode': '郵編：200051',
  'about.Shenzhen.name': '深圳',
  'about.Shenzhen.address': '深圳市福田區福華一路98號卓越大廈2006室',
  'about.Shenzhen.tel': '電話：(86755) 23618250、23618367',
  // 'about.Shenzhen.fax':"",
  // 'about.Shenzhen.zipCode':'',
  // 'about.HongKong.zipCode':'',
  'about.HongKong.name': '香港',
  'about.HongKong.address': '香港北角英皇道101號13樓2號室',
  'about.HongKong.tel': '電話：(852) 39008800',
  'about.HongKong.fax': '傳真：(852) 28891231',

  'about.virsical.title': '關于威思客',
  'about.virsical.tip1':
    '威發系統是壹家 IT 解決方案公司，擁有超過 30 年的系統集成經驗以及超過 18 年的獨立商業軟件開發經驗。',
  'about.virsical.tip2':
    '威思客智能辦公解決方案套件專注于數字化辦公、樓宇和園區管理解決方案，具有可視化、交互式訪客管理、智能會議管理、工作空間管理、消息發布、無線接入、視頻協作和即時消息等功能，旨在提升員工體驗和工作效率。',
  'about.virsical.tip3': '威思客引領數字化轉型！',
};
