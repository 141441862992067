export default {
  namespace: 'global',
  state: { location: {} },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  subscriptions: {
    init({ history, dispatch }) {
      history.listen(() => {
        // ssr 模式下location信息需要手动保存
        dispatch({ type: 'set', payload: { location: history.location } });
      });
    },
  },
};
