/* eslint-disable camelcase */
import index from './zh_CN/index';
import common from './zh_CN/common';
import login from './zh_CN/login';
import hardware from './zh_CN/hardware';
import help from './zh_CN/help';
import case_zh from './zh_CN/case';
import about_zh from './zh_CN/about';
import product_zh from './zh_CN/product';
import download_zh from './zh_CN/download';
import banner_zh from './zh_CN/banner';
import price_zh from './zh_CN/price';
import register_zh from './zh_CN/register';
import intl_zh from './zh_CN/intl.json';
import error from './zh_CN/error';

export default {
  ...index,
  ...common,
  ...login,
  ...hardware,
  ...help,
  ...case_zh,
  ...banner_zh,
  ...download_zh,
  ...register_zh,
  ...product_zh,
  ...price_zh,
  ...about_zh,
  ...intl_zh,
  ...error,
}
