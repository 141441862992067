import React from "react";
import SwiperBanner from '@/components/SwiperBanner';
import partner from '@/assets/home/partner.png';
import san60 from '@/assets/home/360.png';
import lilisi from '@/assets/home/lilisi.png';
import bzhan from '@/assets/home/bzhan.png';
import zijie from '@/assets/home/zijie.png';
import xiaohong from '@/assets/home/xiaohong.png';
import minsheng from '@/assets/home/minsheng.png';
import zhongxin from '@/assets/home/zhongxin.png';
import zhongjin from '@/assets/home/zhongjin.png';
import yijia from '@/assets/home/yijia.png';
import shangtang from '@/assets/home/shangtang.png';
import styles from './index.less';

const Partener = () => {
  const part3Data1 = [san60, lilisi, bzhan, zijie, xiaohong]
  const part3Data2 = [minsheng, zhongxin, zhongjin, yijia, shangtang]

  return (
    <div className={styles.part3} style={{ background: `url(${partner})` }}>
      <div>
        <SwiperBanner>
          {part3Data1.map(item => (
            <img className='swiper-slide' src={item} key={item} />
          ))}
        </SwiperBanner>
      </div>
      <div dir='rtl'>
        <SwiperBanner>
          {part3Data2.map(item => (
            <img className='swiper-slide' src={item} key={item} />
          ))}
        </SwiperBanner>
      </div>
    </div>
  )
}

export default Partener
